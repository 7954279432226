import React from 'react';

import Information from './Tabs/Information';
import Entitlements from './Tabs/Entitlements';
import Workflows from './Tabs/Workflows';
import DeliveryTimes from './Tabs/DeliveryTimes';
import EmailMessages from './Tabs/EmailMessages';
import StockImages from './Tabs/StockImages';
import DeliveryDocuments from './Tabs/DeliveryDocuments';
import SellOff from './Tabs/SellOff';
import Rooms from './Tabs/Rooms/Rooms.component';

export const FETCH_DELIVERY_DOCUMENT_FOOTER = '@@STOCK/FETCH_DELIVERY_DOCUMENT_FOOTER';
export const CAN_EDIT_STOCK = 'CAN_EDIT_STOCK';
export const NEW_STOCK_ROLES = 'NEW_STOCK_ROLES';
export const ADDRESS_LIST = 'ADDRESS_LIST';
export const CAN_ADD_MULTIPLE_ADDRESS = 'CAN_ADD_MULTIPLE_ADDRESS';
export const ROOMS_LIST = 'ROOMS_LIST';
export const INTERNAL_CRM_ACCOUNT_ID = 'INTERNAL_CRM_ACCOUNT_ID';
export const RESET_INTERNAL_CRM_ACCOUNT_ID = 'RESET_INTERNAL_CRM_ACCOUNT_ID';
export const KITS_LIST_BY_WAREHOUSE_ID = 'KITS_LIST_BY_WAREHOUSE_ID';
export const AVAILABILITY_SLOTS_LIST = 'AVAILABILITY_SLOTS_LIST';

export const createStockBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'stocks',
    url: '/stocks'
  },
  {
    name:'maintainStock',
    url: ''
  }
];

export const labsAndLocationBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'stocks',
    url: '/stocks'
  },
  {
    name:'maintainLabsAndLocationPartner',
    url: ''
  }
];

export const zmccBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'stocks',
    url: '/stocks'
  },
  {
    name:'maintainRMSZMCC',
    url: ''
  }
];

export const createStockTabsConstant = (history, stockId) => [
  {
    id: 1,
    tabTitle: 'information',
    className: '',
    render: isActive => {
      return <Information isActive={isActive} history={history} stockId={stockId} />;
    },
  },
  {
    id: 2,
    tabTitle: 'entitlements',
    className: '',
    render: isActive => {
      return <Entitlements isActive={isActive} history={history} />;
    },
  },
  {
    id: 3,
    tabTitle: 'workflows',
    className: '',
    render: isActive => {
      return <Workflows isActive={isActive} history={history} />;
    },
  },
  {
    id: 4,
    tabTitle: 'deliveryTimes',
    className: '',
    render: isActive => {
      return <DeliveryTimes isActive={isActive} history={history} />;
    },
  },
  {
    id: 5,
    tabTitle: 'messages',
    className: '',
    render: isActive => {
      return <EmailMessages isActive={isActive} history={history} />;
    },
  },
  {
    id: 6,
    tabTitle: 'attachmentsStock',
    className: '',
    render: isActive => {
      return <StockImages isActive={isActive} history={history} />;
    },
  },
  {
    id: 7,
    tabTitle: 'deliveryDocuments',
    className: '',
    render: isActive => {
      return <DeliveryDocuments isActive={isActive} history={history} />;
    },
  },
  {
    id: 8,
    tabTitle: 'demoSale',
    className: '',
    render: isActive => {
      return <SellOff isActive={isActive} history={history} />;
    },
  },
];

export const createStockTabsConstantForLabsAndLocation = (history, stockId,  stockDetails) => [
  {
    id: 1,
    tabTitle: 'information',
    className: '',
    render: isActive => {
      return <Information isActive={isActive} history={history} stockId={stockId} />;
    },
  },
  {
    id: 2,
    tabTitle: 'entitlements',
    className: '',
    render: isActive => {
      return <Entitlements isActive={isActive} history={history} />;
    },
  },
  {
    id: 3,
    tabTitle: 'workflows',
    className: '',
    render: isActive => {
      return <Workflows isActive={isActive} history={history} />;
    },
  },
  {
    id: 5,
    tabTitle: 'messages',
    className: '',
    render: isActive => {
      return (
        <EmailMessages isActive={isActive} history={history}  stockDetails={stockDetails} />
      );
    },
  },
  {
    id: 6,
    tabTitle: 'attachmentsStock',
    className: '',
    render: isActive => {
      return <StockImages isActive={isActive} history={history} />;
    },
  },
];

export const createStockTabsConstantForRMSZmcc = (history, stockId, stockDetails) => [
  {
    id: 1,
    tabTitle: 'information',
    className: '',
    render: isActive => {
      return <Information isActive={isActive} history={history} stockId={stockId} />;
    },
  },
  {
    id: 2,
    tabTitle: 'entitlements',
    className: '',
    render: isActive => {
      return <Entitlements isActive={isActive} history={history} />;
    },
  },
  {
    id: 3,
    tabTitle: 'workflows',
    className: '',
    render: isActive => {
      return <Workflows isActive={isActive} history={history} />;
    },
  },
  {
    id: 5,
    tabTitle: 'messages',
    className: '',
    render: isActive => {
      return (
        <EmailMessages isActive={isActive} history={history} stockDetails={stockDetails} />
      );
    },
  },
  {
    id: 8,
    tabTitle: 'rooms',
    className: '',
    render: isActive => {
      return (
        <Rooms stockId={stockId} history={history}/>
      );
    },
  },
  {
    id: 6,
    tabTitle: 'attachmentsStock',
    className: '',
    render: isActive => {
      return <StockImages isActive={isActive} history={history} />;
    },
  },
  {
    id: 7,
    tabTitle: 'demoSale',
    className: '',
    render: isActive => {
      return <SellOff isActive={isActive} history={history} />;
    },
  },
];
import React, { Component } from 'react';
import cn from 'classnames';
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Icon from 'library/common/commonComponents/Icon/Icon';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFilter = (key, multiSelect) => (value, field, fullValue) => {
    this.props.handleFilterChange(key, value, fullValue);
  };

  resetFilter = key => () => {
    this.props.handleFilterChange(key, null, null);
  };

  resetFilters = () => {
    this.props.resetFilters();
  };

  getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    if (selectedValues && selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }
    return displayPlaceholder;
  };

  renderFilters = () => {
    const newFilters = [];
    const filters = {
      stock: { displayValue: [{ key: 'locationName', separator: '' }], multiple: true },
      category: {
        displayValue: [{ key: 'systemMainComponentName', separator: '', optionsArrayKey: 'systemMainComponent' }],
        multiple: true,
      },
      status: { displayValue: [{ key: 'translatedStatus', separator: '' }], multiple: true },
      condition: { displayValue: [{ key: 'translatedStatus', separator: '' }], multiple: true },
    };
    Object.keys(filters).forEach(key => {
      if (
        this.props.selected &&
        this.props.selected[key] &&
        (typeof this.props.selected[key] !== 'object' || this.props.selected[key].length) &&
        this.props.selected[`${key}FullValue`]
      ) {
        newFilters.push(
          <div className='filter-chips-container' key={key}>
            {this.getPlaceholder(
              this.props.selected[`${key}FullValue`],
              filters[key].displayValue,
              filters[key].multiple,
            )}
            <span onClick={this.resetFilter(key)}>&times;</span>
          </div>,
        );
      }
    });
    return newFilters;
  };

  render() {
    const { isShowFilter, isLabsLocationType, countries, categoriesAndSystems, kitStatus, conditions, isZmccType } =
      this.props;
    const filters = this.renderFilters();
    const labelFilter = isLabsLocationType ? strings.partnerSite : isZmccType ? strings.zmcc : strings.stock;
    return (
      <>
        <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
          <div className='container-fluid'>
            <div className='row'>
              <Dropdown
                data={countries}
                hasSection
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                idKey='id'
                field='stock'
                displayValue={[{ key: 'locationName', separator: '' }]}
                optionsArrayKey='stockInformation'
                titleDisplay={[
                  { key: 'shortName', separator: ' ' },
                  { key: 'name', separator: '' },
                ]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={labelFilter}
                onChange={this.handleFilter('stock', true)}
                mainContainerStyle='col-lg-3'
                value={this.props.selected && this.props.selected.stock}
                fullValue={this.props.selected && this.props.selected.stockFullValue}
              />

              <Dropdown
                data={categoriesAndSystems}
                hasSection
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                field='category'
                idKey='systemMainComponentId'
                displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                optionsArrayKey='systemMainComponent'
                titleDisplay={[{ key: 'systemClassName', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.categorySystem}
                onChange={this.handleFilter('category', true)}
                mainContainerStyle='col-lg-3 mt-3 mt-md-0'
                value={this.props.selected && this.props.selected.category}
                fullValue={this.props.selected && this.props.selected.categoryFullValue}
              />
              <Dropdown
                data={kitStatus}
                hasSection={false}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                idKey='kitStatusId'
                field='status'
                displayValue={[{ key: 'translatedStatus', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label='Status'
                onChange={this.handleFilter('status', false)}
                mainContainerStyle='col-lg-3 mt-3 mt-md-0'
                value={this.props.selected && this.props.selected.status}
                fullValue={this.props.selected && this.props.selected.statusFullValue}
              />
              <Dropdown
                data={conditions}
                hasSection={false}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                idKey='kitStatusId'
                field='condition'
                displayValue={[{ key: 'translatedStatus', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.conditionText}
                onChange={this.handleFilter('condition', false)}
                mainContainerStyle='col-lg-3 mt-3 mt-md-0'
                value={this.props.selected && this.props.selected.condition}
                fullValue={this.props.selected && this.props.selected.conditionFullValue}
              />
            </div>
          </div>
        </div>
        {filters && !!filters.length && (
          <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center'>
            <div className='mr-2'>
              <strong>Filters:</strong>
            </div>
            {filters}
            <div className='filter-chips-delete-all' onClick={this.resetFilters}>
              <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Filter;

import { NavLink } from 'react-router-dom';
import React, { PureComponent } from 'react';

import strings from 'resources/locales/Translate';
import pdfEn from 'resources/pdf/Guidance_Accounting_Treatment_Final_15.07.2020.pdf';
import pdfGe from 'resources/pdf/Demo_Inventory_Guidelines_dt_Version 1.0_Juni 2020.pdf';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { scrollToTop } from 'library/utilities/scrollActions';
import { images } from 'library/common/commonConstants/ImageConstants';

import { footerLinks, footerModel } from './footerModel';
import Icon from '../Icon/Icon';
import './footer.styles.scss';

class Footer extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", () => this.updateDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateDimensions());
  }

  updateDimensions = () => {
    if (window.innerWidth < 500) {
      this.setState({width: 500, height: 102});
    } else {
      let update_width = window.innerWidth;
      let update_height = Math.round(update_width / 4.4);
      this.setState({width: update_width, height: update_height});
    }
  };

  render() {
    const {width} = this.state;
    const {language} = this.props.language;
    const pdf = language === 'en' ? pdfEn : pdfGe;
    const currentDate = new Date();
    const dateFrom = '2006';
    const dateTo = getDateInFormat('YYYY', currentDate);

    return (
      <div className='main-container position-relative'>
        <div className='container-fluid'>
          <div className='d-flex flex-wrap'>
            <div className='d-none d-sm-block col-12 col-sm p-0'>
              <p className="footer-header-text mb-3">
                {strings.navigation}
              </p>
              <div className='mb-4'>
                {
                  footerModel.navigation.map((option, key) => (
                    option.label !== 'loan' ?
                      <div key={key} className='d-flex align-items-center flex-wrap'>
                        <NavLink to={option.toPath} exact={true} activeClassName='active'
                                 className="nav-link">{strings[option.label]}</NavLink>
                      </div> :
                      <div key={key} onClick={() => {
                        scrollToTop(500)
                      }} className='d-flex align-items-center flex-wrap cursor-pointer'>
                        <p className="nav-link">{strings[option.label]}</p>
                      </div>
                  ))
                }
              </div>
            </div>
            <div className='col-12 col-sm p-0'>
              <p className='footer-header-text mb-3'>
                {strings.address}
              </p>
              <div className='mb-4'>
                {
                  footerModel.address.map((option, key) => (
                    <div key={key} className='d-flex align-items-center flex-wrap'>
                      <p className="footer-options-text">
                        {option.label}
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='col-12 col-sm p-0'>
              <p className='footer-header-text mb-3'>
                {strings.contact}
              </p>
              <div className='mb-4'>
                {
                  footerModel.contact.map((option, key) => (
                    <div key={key} className='d-flex align-items-center flex-wrap mb-3'>
                      <Icon style={{marginRight: 8}} width={24} height={24} name={option['iconKey']} fill='#000'/>
                      <p className="footer-options-text">
                        {option.label}
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='col-12 col-sm p-0'>
              <p className='footer-header-text mb-3 d-none d-sm-block'>
                {strings.socialMedia}
              </p>
              <div className='mb-4 d-flex d-sm-block'>
                {
                  footerModel.socialMedia.map((option, key) => (
                    <div key={key} className='d-flex align-items-center flex-wrap mb-3'>
                      <a className='footer-options-text d-none d-sm-block' href={option.link} target="_blank"
                         rel="noopener noreferrer">
                        <Icon style={{marginRight: width >= 576 ? 8 : 16}} width={width >= 576 ? 24 : 32}
                              height={width >= 576 ? 24 : 32} name={option['iconKey']} fill='#000' viewBox={option.label === 'X' ? '0 0 30 30': '0 0 40 40'}/>

                        {option.label}
                      </a>
                    </div>
                  ))
                }
                <div className='d-flex align-items-center flex-wrap mb-3'>
                  <a className='footer-options-text d-none d-sm-block'
                     href='https://www.instagram.com/zeissgroup/?hl=de' target="_blank"
                     rel="noopener noreferrer">
                    <img src={images.instagramIcon} style={{marginRight: width >= 576 ? 11 : 20}} alt="instagram"
                         className='ml-1' width={width >= 576 ? 18 : 24}
                         height={width >= 576 ? 18 : 24}/>
                    <span>Instagram</span>
                  </a>
                </div>
                <div className='d-flex align-items-center flex-wrap mb-3'>
                  <a className='footer-options-text d-none d-sm-block' href='https://www.yammer.com/zeiss.com/#/home'
                     target="_blank"
                     rel="noopener noreferrer">
                    <img src={images.yammerIcon} style={{marginRight: width >= 576 ? 11 : 20}} alt="flickr"
                         className='ml-1' width={width >= 576 ? 18 : 24}
                         height={width >= 576 ? 18 : 24}/>
                    <span>Yammer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className="order-2 order-md-1 mt-4 mt-md-2">
              <p className='bottom-text'>© {`${dateFrom}–${dateTo}`} ZEISS</p>
            </div>
            <div className='d-flex flex-wrap order-1 order-md-2 mt-2'>
              <a href={footerLinks.imprints[language]} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.imprintsText}</p>
              </a>
              <a href={footerLinks.legalNotice[language]} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.legalNoticesText}</p>
              </a>
              <a href={footerLinks.dataProtection[language]} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.dataProtectionText}</p>
              </a>
              <a href={pdf} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.termsAndConditionText}</p>
              </a>
              <a href={footerLinks.carlZeissInternational} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.carlZeissInternational}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

import {
    UPDATE_ZMCC_KIT_FORM,
    FETCH_WAREHOUSES_FOR_ZMCC_BU,
    FETCH_SYSTEM_CLASSES_ZMCC_BU,
    FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
    FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
    FETCH_WAREHOUSE_USERS_ZMCC_BU,
    FETCH_ZMCC_BU_CONDITIONS,
    FETCH_ZMCC_KIT_DETAILS,
    RESET_ZMCC_BU_KIT_FORM,
  } from './CreateZmccKit.constants';
  
  const EMPTY_FORM = {
    formData: {},
    isFormValid: false,
  };
  
  export const INITIAL_STATE = {
    kitInformationId: null,
    kitDetails: null,
    generalInformationForm: EMPTY_FORM,
    informationForm: {
      formData: {},
      isFormValid: false,
    },
    kitStatusForm: {
      formData: {
        kitCondition: 6,
        kitConditionFullValue: { kitStatusId: 6, translatedStatus: 'Ok' },
      },
      isFormValid: false,
    },
    furtherImportantComponentForm: EMPTY_FORM,
    salesComponentForm: EMPTY_FORM,
    freeAccessoriesForm: EMPTY_FORM,
    kitImages: [],
    kitAttachments: [],
    kitImagesToDelete: [],
    kitAttachmentsToDelete: [],
    stocks: [],
    rooms: [],
    zmccUsers: [],
    systemClasses: [],
    systemMainComponent: [],
    conditions: [],
    activeUntil: null,
    vrDetalisForm: EMPTY_FORM,
  };
  
  export const CreateZMCCKitReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case UPDATE_ZMCC_KIT_FORM:
        return { ...state, ...action.payload };
      case FETCH_WAREHOUSES_FOR_ZMCC_BU:
        return { ...state, stocks: action.payload };
      case FETCH_SYSTEM_CLASSES_ZMCC_BU:
        return { ...state, systemClasses: action.payload };
      case FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU:
        return { ...state, systemMainComponent: action.payload };
      case FETCH_WAREHOUSE_ROOMS_ZMCC_BU:
          return { ...state, rooms: action.payload };
      case FETCH_WAREHOUSE_USERS_ZMCC_BU:
        return { ...state, zmccUsers: action.payload };
      case FETCH_ZMCC_BU_CONDITIONS:
        return { ...state, conditions: action.payload };
      case FETCH_ZMCC_KIT_DETAILS:
        return { ...state, ...action.payload, kitInformationId: action.payload.kitDetails.kitInformationId };
      case RESET_ZMCC_BU_KIT_FORM:
        return { ...INITIAL_STATE };
      default:
        return state;
    }
  };
  
  export default CreateZMCCKitReducer;
  
import { useState } from 'react';
import * as $ from 'jquery';

export const useSemBooking = (props) => {
  const isXbBooking = props.match && props.match.url.includes('/XB');
  const isSemBooking = props.match && props.match.url.includes('/SEM');
  const isXRMBooking = props.match && props.match.url.includes('/XRM');
  const isLMBooking = props.match && props.match.url.includes('/LM');

  const { transactions } = props.zmccTransactionDetails;
  const { formType } = props;
  let zmccXbBooking = false;
  let zmccXrmBooking= false;
  let zmccLmBooking = false;
  let zmccSemBooking = false;
  
    if ((transactions?.zmccXbBooking || formType === 'xb')) {
      zmccXbBooking = true
    }else if (transactions?.zmccXrmBooking || formType === 'xrm') {
      zmccXrmBooking = true;
    }
    else if (transactions?.zmccLmBooking || formType === 'lm') {
      zmccLmBooking = true;
    }else if (transactions?.zmccSemBooking || formType === 'sem' ) {
      zmccSemBooking = true;
      
    }


    const [activeStep, setActiveStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const goToPrevStep = () => {
      setActiveStep(prevState => prevState - 1);
    };

    const saveZmccSemData = async (actionFrom, tabId) =>{
      setUnsavedChanges(false);
      const {success, data} = await props.saveSemTransaction(actionFrom, props.history, isSemBooking? 'SEM': isXbBooking? 'XB': isXRMBooking? 'XRM': isLMBooking ? 'LM' : '', tabId );
      if (success && data.zmccSavedStatus  == 'CREATED'){
        setActiveStep(1);
      }
    }

    const onContinueBooking = (id) =>{
      setIsLoading(true);
      props.cancelSemZmccLoan(props.formValue.transactionsId, props.history);
      setIsLoading(false);
      $(`#${id}`).modal('hide');
      props.history.push("/");
    }

    const onCancelClick = (id) =>{
      $(`#${id}`).modal('hide');
    }

    const cancelSemZmccLoanData = () => {
      setUnsavedChanges(false);
      $('#cancel-zmcc-loan-data-modal').modal('show');
    }
    
    return {
        activeStep,
        setActiveStep,
        goToPrevStep,
        saveZmccSemData,
        onContinueBooking,
        onCancelClick,
        cancelSemZmccLoanData,
        isLoading,
        setIsLoading,
        isXbBooking,
        isSemBooking,
        isXRMBooking,
        isLMBooking,
        zmccXrmBooking,
        zmccLmBooking,
        zmccXbBooking,
        zmccSemBooking,
        unsavedChanges,
        setUnsavedChanges
    };
}
import React, { Fragment } from 'react';
import { get } from 'lodash';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import { checkIsBeforeDate } from 'library/utilities/checkIsBeforeDate';
import {
  getIconByCondition,
  getIconByTransactionStatus,
  getIconByCurrentPosition,
  getIconFor24HourReservation,
  getNameOfReturnGoodsBookingCondition,
} from 'library/utilities/getStatusAndConditionIcons';
import Icon from 'library/common/commonComponents/Icon/Icon';

import DeliveryTooltip from './Components/DeliveryTooltip';
import './bookingStyles.scss';

export const dataTable = (loanTypeFilter, transactionType) => [
  {
    title: '#',
    name: 'number',
    isSort: false,
    render: item => {
      return <span>{item.transactionNumber}</span>;
    },
  },
  {
    title: `${strings.status}`,
    name: 'status',
    isSort: false,
    render: item => {
      const {
        transactionKit,
        transactionStatus,
        transactionPosition,
        twentyFourHourReservation,
        mileStoneDelayForDelivery,
        mileStoneDelayForBegin,
        mileStoneDelayForEnd,
        mileStoneDelayForChecked,
        mileStoneDelayForReturnDelivery,
        returnDelivery,
      } = item;
      const checkCondition =
        transactionKit &&
        transactionKit.kitInformation &&
        transactionKit.kitInformation.kitCondition &&
        transactionKit.kitInformation.kitCondition.kitConditionTranslated.length;
      const checkStatus = transactionStatus && transactionStatus.name;
      const checkCurrentPosition = transactionPosition && transactionPosition.transactionPositionTranslated.length;
      const isExpandable = false;
      const isDelay =
        mileStoneDelayForBegin ||
        mileStoneDelayForChecked ||
        mileStoneDelayForDelivery ||
        mileStoneDelayForEnd ||
        mileStoneDelayForReturnDelivery;

      return (
        <div className='d-flex flex-wrap'>
          {checkStatus ? <>{getIconByTransactionStatus(transactionStatus.transactionStatusId, isExpandable)}</> : null}
          {checkCondition ? <>{getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}</> : null}
          {checkCurrentPosition ? (
            <div className='d-flex align-items-center'>
              {transactionPosition.transactionPositionTranslated.map(position => (
                <Fragment key={position.positionText}>
                  {getIconByCurrentPosition(position, isExpandable, twentyFourHourReservation, returnDelivery)}
                </Fragment>
              ))}
            </div>
          ) : null}
          {twentyFourHourReservation ? <>{getIconFor24HourReservation(isExpandable)}</> : null}
          {isDelay ? <Icon name='conflictBooking' width={16} height={16} fill='#000' className='mr-1' /> : null}
        </div>
      );
    },
  },
  {
    title: strings.name,
    name: 'name',
    isSort: !!loanTypeFilter,
    render: item => {
      const { name } = item;
      return <>{name}</>;
    },
  },
  {
    title: strings.demoKit,
    name: 'demoKit',
    isSort: false,
    render: item => {
      const { transactionKit } = item;
      const name = get(transactionKit, 'kitInformation.kitName', '');
      return <>{name}</>;
    },
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: loanTypeFilter === 1,
    render: item => {
      const { transactionKit } = item;
      const serialNumber = get(transactionKit, 'kitInformation.serialNumber')
          ? transactionKit.kitInformation.serialNumber
          : 'NA';
      return <>{serialNumber}</>;
    },
  },
  {
    title: ['listReconditioning', 'incomingGoods', 'goodReceipt'].includes(transactionType) ? strings.orderDeliveryNumber : strings.shipped,
    name: ['listReconditioning', 'incomingGoods', 'goodReceipt'].includes(transactionType) ? 'deliveryNumber' : 'delivery',
    isSort: true,
    render: (item, index) => {
      if (['listReconditioning', 'incomingGoods', 'goodReceipt'].includes(transactionType)) {
        const orderDeliveryNumber = get(item, 'returnGoodsBooking.deliveryNoteNumber') || '-';
        return (
          <div className='orderDelivery-tr' id={`orderDelivery-${index}`}>
            <div>
              {orderDeliveryNumber}
            </div>
          </div>
        );
      }
      const isDelay = !checkIsBeforeDate(item.targetDeliveryFrom, item.deliveryDate);
      return (
        <div className='delivery-tr' id={`delivery-${index}`}>
          <div className='pr-1'>
            <div className={cn({ 'delay-text': isDelay })}>
              <strong>{item.targetDeliveryFrom ? getDateInUTCFormat('DD.MM.YYYY', item.targetDeliveryFrom) : ''}</strong>
            </div>
            <div>{item.deliveryDate ? getDateInUTCFormat('DD.MM.YYYY', item.deliveryDate) : ''}</div>
          </div>
          <DeliveryTooltip transactionDetails={item} id={`delivery-${index}`} />
        </div>
      );
    },
  },
  {
    title: `${
      ((transactionType === 'listReconditioning') && strings.status) ||
      (['incomingGoods', 'goodReceipt'].includes(transactionType) && strings.completeness) ||
      strings.begin
    }`,
    name: `${
      ((transactionType === 'listReconditioning') && 'returnDeliveryStatus') ||
      (['incomingGoods', 'goodReceipt'].includes(transactionType) && 'completeness') ||
      'begin'
    }`,
    isSort: true,
    render: item => {
      if (transactionType === 'listReconditioning') {
        const statusId = get(item, 'returnGoodsBooking.goodsCondition');
        return (
          <div>
            {statusId ? getNameOfReturnGoodsBookingCondition(statusId) : '-'}
          </div>
        );
      }
      if (['incomingGoods', 'goodReceipt'].includes(transactionType)) {
        const completeness = get(item, 'returnGoodsBooking.completeness');
        const completenessValue = completeness ? strings.yes : strings.no;
        return (
          <div>
            {item.returnGoodsBooking ? completenessValue : '-'}
          </div>
        );
      }
      const isDelay = !checkIsBeforeDate(item.targetReservationFrom, item.reservationFrom);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>
              {item.targetReservationFrom ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationFrom) : ''}
            </strong>
          </div>
          <div>{item.reservationFrom ? getDateInUTCFormat('DD.MM.YYYY', item.reservationFrom) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.end,
    name: 'end',
    isSort: true,
    render: item => {
      const isDelay = !checkIsBeforeDate(item.targetReservationTo, item.reservationTo);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>{item.targetReservationTo ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationTo) : ''}</strong>
          </div>
          <div>{item.reservationTo ? getDateInUTCFormat('DD.MM.YYYY', item.reservationTo) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.receiptInStock,
    name: 'returnDelivery',
    isSort: true,
    render: item => {
      const isDelay = !checkIsBeforeDate(item.targetReturnDeliveryTo, item.returnDelivery);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>
              {item.targetReturnDeliveryTo ? getDateInUTCFormat('DD.MM.YYYY', item.targetReturnDeliveryTo) : ''}
            </strong>
          </div>
          <div>{item.returnDelivery ? getDateInUTCFormat('DD.MM.YYYY', item.returnDelivery) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.location,
    name: 'stock',
    isSort: !!loanTypeFilter,
    render: item => {
      const { transactionKit, stockInformation } = item;
      let stockName = stockInformation && stockInformation.locationName;
      if (item.type === 'kit loan' && transactionKit) {
        stockName =
          transactionKit && transactionKit.stockInformation
            ? transactionKit.stockInformation.locationName
            : '';
      }
      return <span>{stockName}</span>;
    },
  },
  {
    title: strings.countryLand,
    name: 'country',
    isSort: true,
    render: item => {
      const { country } = item;
      return <span>{country && country.name}</span>;
    },
  },
  {
    title: strings.sapErp,
    name: 'sapErp',
    isSort: true,
    isHidden: transactionType !== 'outgoingGoods',
    render: item => {
      const { sapErp } = item;
      return <span>{sapErp}</span>;
    },
  },
  {
    title: strings.borrower,
    name: 'borrower',
    isSort: true,
    render: item => {
      return <>{`${item.borrower ? item.borrower.firstName : ''} ${item.borrower ? item.borrower.lastName : ''}`}</>;
    },
  },
  {
    title: strings.city,
    name: 'city',
    isSort: false,
    render: item => {
      const { city } = item;
      return <>{city}</>;
    },
  },
];

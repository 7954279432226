import React from 'react';

import strings from 'resources/locales/Translate';

import { InputField } from '../InputField/InputField';
import './inputGroupStyles.scss';

export default class InputGroup extends InputField {
  render() {
    const {
      label,
      isRequired,
      type,
      extraProps,
      placeholder,
      inputGroupText,
      showExtraInfo,
      infoMessage,
      isAppend,
      disabled,
    } = this.props;
    const { error, errorMessage, value, isInfoOpen } = this.state;
    return (
      <div className='input-group-field'>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label}</p>
            {showExtraInfo && (
              <p className='input-info cursor-pointer' onClick={this.toggleInfoMessage}>
                i
              </p>
            )}
            {isInfoOpen && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
          {!isRequired && <span className='optional-text'>{strings.optionalField}</span>}
        </div>

        <div className='input-group'>
          {!isAppend && (
            <div className='input-group-prepend rounded-0'>
              <span className={'input-group-text ' + (error && 'input-error')} id='basic-addon2'>
                {inputGroupText}
              </span>
            </div>
          )}
          <input
            type={type}
            className={'form-control rounded-0 position-relative ' + (error && 'input-error')}
            placeholder={placeholder}
            {...extraProps}
            onChange={this.handleOnChange}
            onBlur={this.onBlur}
            value={value !== null && value !== undefined ? value : ''}
            disabled={disabled}
          />
          {isAppend && (
            <div className='input-group-append rounded-0'>
              <span className={'input-group-text ' + (error && 'input-error')} id='basic-addon2'>
                {inputGroupText}
              </span>
            </div>
          )}
        </div>

        {error && <span className='text-danger error-block'>{strings[errorMessage]}</span>}
      </div>
    );
  }
}

import React, { Component } from 'react';

import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { deliveryDocumentsModel } from './DeliveryDocuments.constants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';

import './DeliveryDocuments.style.scss';

class DeliveryDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressForm: { formData: {}, isFormValid: false },
      lawForm: { formData: {}, isFormValid: false },
      managementForm: { formData: {}, isFormValid: false },
      deliveryDocuments: null,
      loading: false,
      signatureForm: { formData: {}, isFormValid: false },
      legalParagraphForm: { formData: {}, isFormValid: false },
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.deliveryDocuments && this.props.isActive && !prevProps.isActive) {
      if (!this.state.loading) {
        this.setState({ loading: true }, async () => {
          const { success, data } = await this.props.fetchDeliveryDocumentFooter();
          if (success) {
            this.setState({
              deliveryDocuments: data,
              loading: false,
              addressForm: {
                formData: {
                  companyName: data.companyName,
                  groupName: data.groupName,
                  streetAndHouseNumber: data.streetAndHouseNumber,
                  postalCodeAndCity: data.postalCodeAndCity,
                  webAddress: data.webAddress,
                },
                isFormValid: false,
              },
              lawForm: {
                formData: {
                  placeOfJurisdiction: data.placeOfJurisdiction,
                  vatIdentificationNumber: data.vatIdentificationNumber,
                  taxNumber: data.taxNumber,
                  weeeNumber: data.weeeNumber,
                },
                isFormValid: false,
              },
              managementForm: {
                formData: {
                  name1: data.name1,
                  name2: data.name2,
                  name3: data.name3,
                  name4: data.name4,
                },
                isFormValid: false,
              },
              signatureForm: {
                formData: {
                  signature: data.signature,
                  showCustomerInfoPage: data.showCustomerInfoPage,
                  showDeliveryDocument: data.showDeliveryDocument,
                  showStorageLocation: data.showStorageLocation,
                },
                isFormValid: true,
              },
              legalParagraphForm: {
                formData: {
                  legalParagraph: data.legalParagraph,
                  legalParagraphDe: data.legalParagraphDe,
                },
                isFormValid: true,
              },
            });
          }
        });
      }
    }
  }

  handleForm = ({ values, field, formName }) => {
    this.setState(prevState => ({
      [formName]: {
        ...prevState[formName],
        formData: {
          ...((prevState[formName] && prevState[formName].formData) || {}),
          ...values,
        },
      },
    }));
  };

  generateDataForAPI = async () => {
    let { addressForm, lawForm, managementForm, signatureForm, legalParagraphForm } = this.state;
    const { id: stockId } = this.props.createStockReducer.stockDetails;
    addressForm = validateForm({ form: addressForm, formName: 'addressForm', model: deliveryDocumentsModel.address });
    managementForm = validateForm({
      form: managementForm,
      formName: 'managementForm',
      model: deliveryDocumentsModel.management,
    });
    scrollToTop(500);
    if (!addressForm.isFormValid || !managementForm.isFormValid) {
      this.setState({ addressForm, managementForm });
      if (!managementForm.isFormValid) {
        this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
      } else {
        if (!addressForm.formData.webAddressError || !addressForm.formData.webAddress) {
          this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
        } else {
          this.props.toggleActionMessage(true, 'error', 'webAddressErrorMessage');
        }
      }
    } else {
      const valuesToSend = {};
      const data = {
        ...addressForm.formData,
        ...managementForm.formData,
        ...lawForm.formData,
        ...signatureForm.formData,
        ...legalParagraphForm.formData,
      };
      Object.keys(data).forEach(key => {
        if (!key.includes('Error')) {
          if (key === 'showCustomerInfoPage' || key === 'signature' || key === 'showDeliveryDocument' || key === 'showStorageLocation') {
            valuesToSend[key] = data[key];
          } else {
            valuesToSend[key] = data[key] || '';
          }
        }
      });
      this.props.createDeliveryDocumentFooter(valuesToSend, stockId);
    }
  };

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  render() {
    const { canEditStock } = this.props.createStockReducer;
    const { addressForm, lawForm, managementForm, signatureForm, legalParagraphForm } = this.state;
    return (
      <div className='delivery-document-container'>
        <div className='container-fluid form-container'>
          <div className='mb-3'>
            <FormComponent
              disableForm={!canEditStock}
              formName='signatureForm'
              model={deliveryDocumentsModel.signature}
              formValue={signatureForm.formData}
              onChange={this.handleForm}
              dataTest="signatureForm"
            />
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h4 className='form-title'>{strings.deliveryDocumentsHeading}</h4>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-12 col-md-4'>
              <h6 className='delivery-address-section'>{strings.addressText}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='addressForm'
                model={deliveryDocumentsModel.address}
                formValue={addressForm.formData}
                onChange={this.handleForm}
              />
            </div>
            <div className='col-12 col-md-4 mt-3 mt-md-0'>
              <h6 className='delivery-address-section'>{strings.management}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='managementForm'
                model={deliveryDocumentsModel.management}
                formValue={managementForm.formData}
                onChange={this.handleForm}
              />
            </div>
            <div className='col-12 col-md-4 mt-3 mt-md-0'>
              <h6 className='delivery-address-section'>{strings.law}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='lawForm'
                model={deliveryDocumentsModel.law}
                formValue={lawForm.formData}
                onChange={this.handleForm}
              />
            </div>
          </div>
          <div className='mb-2'>
            <FormComponent
              disableForm={!canEditStock}
              formName='legalParagraphForm'
              model={deliveryDocumentsModel.legalParagraph}
              formValue={legalParagraphForm.formData}
              onChange={this.handleForm}
            />
          </div>
          {canEditStock && (
            <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} />
          )}
        </div>
      </div>
    );
  }
}

export default DeliveryDocuments;

import React from 'react';
import cn from 'classnames';

import Icon from 'library/common/commonComponents/Icon/Icon';
import DotLoader from 'library/common/commonComponents/DotLoader';

import './smallButton.styles.scss';

const SmallButton = props => {
  const {
    className,
    disabled,
    onClick,
    value,
    iconName,
    viewBox,
    isLoading,
    bgFill,
    size,
    fill,
  } = props;
  const defaultColor = bgFill ? '#fff' : '#0088d0';
  const colorOfIcon = fill || defaultColor;

  return (
    <div className='small-button-container'>
      <button
        className={cn(`small-button ${className}`, { 'px-3': isLoading, 'dark': bgFill, 'light': !bgFill })}
        disabled={disabled}
        onClick={onClick}  
      >
        {!isLoading ? (
          <>
            {value}
            {iconName && (
              <Icon 
                className='ml-2'
                name={iconName}
                width={size || 16}
                height={size || 16}
                fill={colorOfIcon}
                viewBox={viewBox}
              /> 
            )}
          </>
        ) : (
          <DotLoader />
        )}
      </button>
    </div>
  )
};

SmallButton.defaultProps = {
  onClick: () => {}
};

export default SmallButton;

import React from 'react';
import cn from 'classnames';

import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

const COMPLETED = 'COMPLETED';

const Task = ({ userId, task, isDisabledUncheckedTasks, handleTaskStatus }) => {
  const { completedBy, completedOn, status, demoUnitTask } = task;
  const name = completedBy ? `${completedBy.firstName} ${completedBy.lastName}` : '-';
  const date = completedOn ? getDateInFormat('DD.MM.YYYY', completedOn) : '-';
  const isSelected = status === COMPLETED;
  const completedById = completedBy && completedBy.id;
  const isDisabledCheckedTask = userId !== completedById;
  const isDisabledTask = isSelected ? isDisabledCheckedTask : isDisabledUncheckedTasks;

  return (
    <div className={cn({ 'mb-2': isSelected, 'mb-4': !isSelected })}>
      <Checkbox
        label={demoUnitTask.description}
        selected={isSelected}
        field={demoUnitTask.id}
        onChange={handleTaskStatus}
        className={cn({ 'mb-0': isSelected })}
        disabled={isDisabledTask}
      />
      {isSelected && (
        <div className='createdBy-block text-gray '>
          <span>{name}, </span>
          <span>{date}</span>
        </div>
      )}
    </div>
  );
};

export default Task;

import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Icon from 'library/common/commonComponents/Icon/Icon';
import './rightHeaderStyles.scss';
import { ViewTypes } from 'library/common/commonComponents/ReactBigScheduler';
import '../LeftHeader/leftHeaderStyles.scss';
import strings from 'resources/locales/Translate';

export default class RightHeader extends Component {
  state = {
    date: new Date(),
    isCalendarVisible: false,
  };

  toggleCalendar = () => {
    this.setState(prevState => {
      return {
        isCalendarVisible: !prevState.isCalendarVisible,
      };
    });
  };

  onChange = date => {
    this.setState({ date });
    this.props.onChangeDate(date);
  };

  displaySelectedDate() {
    const { date } = this.state;
    const { dateToDisplay } = this.props;
    return moment(dateToDisplay).isSameOrBefore(date) ? date : new Date(dateToDisplay);
  }

  render() {
    const { isCalendarVisible } = this.state;
    const { dateToDisplay, isZMCCCalendar, viewTypeFromScheduler, viewModel } = this.props;
    let firstDayOfWeek = '';
    let lastDayOfWeek = '';
    if (viewTypeFromScheduler == ViewTypes.WeekDay && viewModel != null) {
      firstDayOfWeek = moment(viewModel.startDate).format('DD');
      lastDayOfWeek = moment(viewModel.endDate).format('DD');
    }
    return (
      <div className=''>
        <div className='right-header row'>
          <div className='app-datepicker cursor-pointer'>
            <div
              className={
                'd-flex datepicker-view align-items-center text-overflow-ellipsis' +
                (isCalendarVisible && 'calendar-shadow')
              }
              onClick={this.toggleCalendar}
            >
              <Icon name='calendar' width={24} height={24} fill={'rgba(0,0,0,0.5)'} />
              <h4 className='current-date'>{moment(dateToDisplay).format('MMMM YYYY')}</h4>
            </div>
            <DatePicker
              minDetail='decade'
              className='datepicker-component-container date-adjust'
              calendarClassName='datepicker-calendar-container calendar-shadow'
              clearIcon={null}
              showLeadingZeros={true}
              isOpen={isCalendarVisible}
              onChange={this.onChange}
              value={this.displaySelectedDate()}
              {...this.props}
            />
          </div>
          {isZMCCCalendar && (
            <div style={{ marginTop: '5px' }} className='d-flex align-items-center'>
              <button className='zmcc-date-switch-button' onClick={() => this.props.zmccPrevClick()}>
                <Icon name={'arrow'} width={24} height={24} fill={'rgb(0, 136, 208)'} />
              </button>
              {viewTypeFromScheduler == ViewTypes.WeekDay
                ? `${firstDayOfWeek} - ${lastDayOfWeek} ${moment(dateToDisplay).format('MMMM')}`
                : viewTypeFromScheduler == ViewTypes.Day
                ? moment(dateToDisplay).format('D MMM')
                : moment(dateToDisplay).format('MMMM')}

              <button className='zmcc-back-arrow-calendar' onClick={() => this.props.zmccNextClick()}>
                <Icon name={'arrow'} width={24} height={24} style={{ marginTop: '-3px' }} fill={'rgb(0, 136, 208)'} />
              </button>
              <div className='left-header'>
                <button onClick={() => this.props.onChangeDate(moment().format("YYYY-MM-DD"))}>{strings.todayText}</button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

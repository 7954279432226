import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import OperatorsSchedules from './OperatorsSchedules';
import './index.styles.scss'

const MODAL_ID = 'operators-availability-schedule-modal';

const getTimeIntervalsWithGap = (startTime, endTime, gap) => {
  const timeInveterals = new Set();

  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  while (start <= end) {
    timeInveterals.add(start.format('HH:mm'));
    timeInveterals.add(start.add(gap, 'minutes').format('HH:mm'));
  }
  return timeInveterals;
};

function OperatorsModal({
  id = MODAL_ID,
  onOpen = () => {},
  transactionDetails = {},
  stockOperators = [],
  fetchOperatorScheduleOfStockOnGivenDate,
  changeLoader,
  formType,
  getDataToSendFromInformationTab,
  onClickEdit,
  editing
}) {
  const timeInveterals = [...getTimeIntervalsWithGap(8, 19, 30)];

  const handleOnAssignClick = (dataToSend, operatorsAndTheirSchedules) => {
    console.log(dataToSend, operatorsAndTheirSchedules);
  };

  return (
    <Modal
      id={MODAL_ID}
      // onCloseClick={closeModal}
      title={strings.assignOperator}
      confirmTitle={strings.assignOperator}
      className={'modal-lg custom-modal-width'}
      buttonStyles={'hide'}
      titleOfCancel={strings.cancel}
    >
      <OperatorsSchedules
        timeIntervals={timeInveterals}
        onSubmit={handleOnAssignClick}
        fetchOperatorScheduleOfStockOnGivenDate={fetchOperatorScheduleOfStockOnGivenDate}
        trasanctionDetails={transactionDetails}
        zmccOperators={stockOperators}
        changeLoader={changeLoader}
        formType={formType}
        getDataToSendFromInformationTab={getDataToSendFromInformationTab}
        onClickEdit={onClickEdit}
        editing={editing}
      ></OperatorsSchedules>
    </Modal>
  );
}

export default React.memo(OperatorsModal);

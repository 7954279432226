import { batchActions } from 'redux-batched-actions';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';
import { updateKeysForSellOffAttachments, updateKeysForSellOffImages } from 'library/utilities/fileHelperFunctions';
import { checkStockRoles } from 'library/utilities/checkRoles';
import zeissInstance from "library/utilities/AxiosInstance";
import { FETCH_CREATE_KIT_FOR_SALE_DETAILS, UPDATE_CREATE_KIT_FOR_SALE_FORM, RESET_CREATE_KIT_FOR_SALE } from './CreateKitForSale.constants';
import {
  generalInformationKitForSaleModel,
  generalInformationSingleItemModel,
  kitForSaleInformationModel,
  singleItemForSaleInformationModel,
  kitStatusModel,
  salesDetailsModel,
} from './CreateKitForSale.constants';

import { uploadImagesForKitForSale } from './CreateKitForSale.services';

const FOR_SALE_KIT_CONDITION_ID = 3;

export const fetchKitDetail = (id, isCopyKit = false, isSingleItemForSale, user) => async dispatch => {
  const query = 'kitName,description,systemValue,marketingMaterial,hideKit,stockInformation[id,department,locationName,locationShortName,businessUnit[name],country[name,shortName],userRole[role[role]],workflowCategoryAnswers[workflowCategoryQuestions[workflowCategoryQuestionsId],value]],businessUnit[id],storageLocation,sapERP,systemClass[systemClassName,systemClassId],systemMainComponent[systemMainComponentId,systemMainComponentName],serialNumber,dateOfManufacture,country[name],commodityCode,packageDimensions,kitCondition[kitConditionId,kitConditionTranslations[translatedCondition]],comment,salesComponents,furtherImportantComponents,freeAccessories,kitInformationId,ibase,serialNumber,dateCreated,kitStatuses[kitStatusId,kitStatusesTranslated[translatedStatus]],transactionPosition[transactionPositionId,transactionPositionTranslated[positionText]],qrCode,isTransactionAssociated,sellOffPlatformInformation[kitSalesDetails[internalNotes,deliveryAddress,purchaseOrder,salesOrder,internalOrder,technicalControl,desinfection,completenessCheck,productionOrder,internalStockOrder,deliveryDate],quantity,consumedQuantity,saleComment,deliveryTimeInWeeks,contactPerson,localSalePrice,transferPrice,saleDate,internalIdentifier,quantity,sellOffPlatformImages[sellOffImageId,link,fileType,fileName,size],sellOffPlatformAttachments[sellOffAttachmentsId,link,fileType,fileName,size],sellOffCondition[id,sellOffKitConditionTranslated[name]],sellOffVisibility[id,sellOffVisibilityTranslated[name]],currency[id,name]],kitReservation[reservationFrom,reservationTo,crmQuoteId,createdBy[firstName,lastName,id,accountId],createdOn]';
  const url = `${URLS.kit}/${id}`;

  dispatch(changeLoader(true));
  try {
    let kitDetails = {};
    const { status, data } = await zeissInstance.post(url, { graphql : query });
    if (status === 200) {
      const consumedQuantity = get(data, 'sellOffPlatformInformation.consumedQuantity') || 0;
      const initialQuantity = get(data, 'sellOffPlatformInformation.quantity') || 0;
      const remainingQuantity = initialQuantity - consumedQuantity;

      if (isCopyKit) {
        kitDetails = {
          ...data,
          kitInformationId: null,
          availabilityTo: '',
          serialNumber: '',
          sapERP: '',
          storageLocation: '',
          ibase: '',
          marketingMaterial: false,
          dateOfManufacture: null,
          kitCondition: { kitConditionId: FOR_SALE_KIT_CONDITION_ID },
          sellOffPlatformInformation: {
            ...data.sellOffPlatformInformation,
            quantity: 1,
            consumedQuantity: null,
          },
        };
      } else {
        kitDetails = {
          ...data,
          sellOffPlatformInformation: {
            ...data.sellOffPlatformInformation,
            quantity: remainingQuantity,
          },
        };
      }
      const formData = createFormKitForSale(kitDetails, isSingleItemForSale, user);
      dispatch(
        batchActions([
          dispatch({
            type: FETCH_CREATE_KIT_FOR_SALE_DETAILS,
            payload: { kitDetails, ...formData },
          }),
          dispatch(changeLoader(false)),
        ]),
      );

    }
  } catch (err) {
    logNetworkError(err);
    dispatch(changeLoader(false));
  }
};

export const createFormKitForSale = (kitDetails, isSingleItemForSale, user) => {
  const isOrdered = get(kitDetails, 'kitCondition.kitConditionId') === 11;
  const isSold = get(kitDetails, 'kitCondition.kitConditionId') === 4;
  const stock = get(kitDetails, 'stockInformation');
  const roles = ['Shop Manager', 'Stock Controller'];
  const isHavePermission = (user && user.admin) || checkStockRoles(roles, user, stock);
  const isCreateSalesDetailsForm = (isOrdered || isSold) && isHavePermission;
  const generalInformationKitForSaleForm = {
    formData: {
      currencyForTransferPrice: 'EUR',
      kitName: kitDetails.kitName,
      description: kitDetails.description,
      currencyId: kitDetails.sellOffPlatformInformation.currency.id,
      localPrice: kitDetails.sellOffPlatformInformation.localSalePrice,
      transferPrice: kitDetails.sellOffPlatformInformation.transferPrice,
    },
    isFormValid: false,
  };
  const kitForSaleForm = {
    formData: {
      stockInformationId: kitDetails.stockInformation.id,
      stockInformationIdFullValue: kitDetails.stockInformation,
      sapERP: kitDetails.sapERP,
      sellOffConditionId: kitDetails.sellOffPlatformInformation.sellOffCondition.id,
      storageLocation: kitDetails.storageLocation,
      internalIdentifier: kitDetails.sellOffPlatformInformation.internalIdentifier,
      sellOffVisibilityId: kitDetails.sellOffPlatformInformation.sellOffVisibility.id,
      saleDate: moment(kitDetails.sellOffPlatformInformation.saleDate).isValid() ? new Date(kitDetails.sellOffPlatformInformation.saleDate) : '',
      deliveryTimeInWeeks: kitDetails.sellOffPlatformInformation.deliveryTimeInWeeks,
      quantity: kitDetails.sellOffPlatformInformation.quantity,
      consumedQuantity: kitDetails.sellOffPlatformInformation.consumedQuantity,
      salesComment: kitDetails.sellOffPlatformInformation.saleComment,
      contactPerson: kitDetails.sellOffPlatformInformation.contactPerson,
      dateOfManufacture: kitDetails.dateOfManufacture ? new Date(kitDetails.dateOfManufacture) : null,
    },
    isFormValid: false,
  };
  if (isSingleItemForSale) {
    kitForSaleForm.formData = {
      ...kitForSaleForm.formData,
      salesComponents: kitDetails.salesComponents,
    };
  } else {
    generalInformationKitForSaleForm.formData = {
      ...generalInformationKitForSaleForm.formData,
      ibase: kitDetails.ibase,
      hideKit: kitDetails.hideKit,
    };
    kitForSaleForm.formData = {
      ...kitForSaleForm.formData,
      businessUnitId: kitDetails.businessUnit.id,
      systemClassId: get(kitDetails, 'systemClass.systemClassId', null),
      systemMainComponentId: get(kitDetails, 'systemMainComponent.systemMainComponentId', null),
      serialNumber: kitDetails.serialNumber,
    };
  }
  const kitStatusForSaleForm = {
    formData: {
      kitConditionId: (kitDetails.kitCondition && kitDetails.kitCondition.kitConditionId) || null,
    },
    isFormValid: false,
  };
  const furtherImportantComponentForm = {
    formData: {
      furtherImportantComponents: kitDetails.furtherImportantComponents || '',
    },
    isFormValid: false,
  };
  const salesComponentForm = {
    formData: {
      salesComponents: kitDetails.salesComponents || '',
    },
    isFormValid: false,
  };
  const freeAccessoriesForm = {
    formData: {
      freeAccessories: kitDetails.freeAccessories || '',
    },
    isFormValid: false,
  };
  const salesDetailsForm = {
    formData: {},
    isFormValid: false,
  };

  if (isCreateSalesDetailsForm) {
    salesDetailsForm.formData = {
      internalNotes: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.internalNotes', null),
      crmQuoteID: get(kitDetails, 'kitReservation.crmQuoteId', null),
      customerId: get(kitDetails, 'kitReservation.createdBy.accountId', null),
      deliveryAddress: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.deliveryAddress', null),
      purchaseOrder: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.purchaseOrder', null),
      salesOrder: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.salesOrder', null),
      internalOrder: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.internalOrder', null),
      technicalControl: !!get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.technicalControl'),
      desinfection: !!get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.desinfection'),
      completenessCheck: !!get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.completenessCheck'),
      productionOrder: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.productionOrder', null),
      internalStockOrder: get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.internalStockOrder', null),
      deliveryDate:  get(kitDetails, 'sellOffPlatformInformation.kitSalesDetails.deliveryDate', null) ? new Date(kitDetails.sellOffPlatformInformation.kitSalesDetails.deliveryDate) : '',
    };
  }
  let kitImages = get(kitDetails, 'sellOffPlatformInformation.sellOffPlatformImages', []);
  kitImages = updateKeysForSellOffImages(kitImages);
  let kitAttachments = get(kitDetails, 'sellOffPlatformInformation.sellOffPlatformAttachments', []);
  kitAttachments = updateKeysForSellOffAttachments(kitAttachments);

  return {
    generalInformationKitForSaleForm,
    kitForSaleForm,
    kitStatusForSaleForm,
    furtherImportantComponentForm,
    salesComponentForm,
    freeAccessoriesForm,
    kitImages,
    kitAttachments,
    kitInformationId: kitDetails.kitInformationId,
    salesDetailsForm,
  };
};

export const resetCreateKit = () => ({ type: RESET_CREATE_KIT_FOR_SALE });

export const updateKitForSaleForm = payload => ({
  type: UPDATE_CREATE_KIT_FOR_SALE_FORM,
  payload,
});

export const fetchSystemClassData = businessUnitId => async () => {
  try {
    const { status, data } = await fetchMasterDataService(URLS.systemClass.replace('{id}', businessUnitId));
    if (status === 200) {
      return data.map(item => ({
        ...item,
        systemClassName: item.systemClassText,
      }));
    }
    return [];
  } catch (err) {
    logNetworkError(err);
    return [];
  }
};

export const fetchSystemMainComponentData = systemClassId => async () => {
  try {
    const { status, data } = await fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId));
    if (status === 200) {
      return data.map(item => ({
        ...item,
        systemMainComponentName: item.systemMainComponentText,
      }));
    }
    return [];
  } catch (err) {
    logNetworkError(err);
    return [];
  }
};

export const uploadKitImage = (files, kitInformationId) => () => {
  if (files.length > 0) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('imgFile', file);
    });

    return uploadImagesForKitForSale(URLS.uploadKitImageForSale(kitInformationId), formData);
  }
};

export const uploadKitAttachment = (files, kitInformationId, updateOnEnd = false) => dispatch => {
  if (updateOnEnd) {
    dispatch(changeLoader(true));
  }
  if (files.length) {
    const key = 'attachments';
    const formData = new FormData();
    let count = 0;
    files.forEach(file => {
      if (!file.kitAttachmentsId) {
        formData.append(key, file);
        count++;
      }
    });
    if (!count) {
      if (updateOnEnd) {
        scrollToTop(500);
        dispatch(
          batchActions([
            dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage')),
            dispatch(changeLoader(false)),
          ]),
        );
      }
      return { success: false };
    }

    return uploadImagesForKitForSale(URLS.selloffplatformAttachments(kitInformationId), formData)
      .then(({ status }) => {
        if (status === 200 && updateOnEnd) {
          dispatch(toggleActionMessage(true, 'success', 'kitAttachmentUploadedSuccessfully'));
          dispatch(changeLoader(false));
          scrollToTop(500);
        }
      })
      .catch(err => {
        logNetworkError(err);
        if (updateOnEnd) {
          scrollToTop(500);
          dispatch(
            batchActions([
              dispatch(changeLoader(false)),
              dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
            ]),
          );
        }
      });
  } else {
    if (updateOnEnd) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage'));
    }
  }
};

export const saveCreateKitManually = () => async (dispatch, getState) => {
  const { createKitForSaleReducer } = getState();
  const {
    generalInformationKitForSaleForm,
    kitForSaleForm,
    kitStatusForSaleForm,
    salesComponentForm,
    furtherImportantComponentForm,
    freeAccessoriesForm,
    salesDetailsForm,
    kitAttachmentsToDelete,
    kitImagesToDelete,
    kitInformationId,
  } = createKitForSaleReducer;
  const formValues = {
    generalInformationKitForSaleForm,
    kitForSaleForm,
    kitStatusForSaleForm,
    salesComponentForm,
    furtherImportantComponentForm,
    freeAccessoriesForm,
    salesDetailsForm,
  };
  const valuesToSend = {
    ...formValues.generalInformationKitForSaleForm.formData,
    ...formValues.kitForSaleForm.formData,
    ...formValues.kitStatusForSaleForm.formData,
    ...formValues.salesComponentForm.formData,
    ...formValues.furtherImportantComponentForm.formData,
    ...formValues.freeAccessoriesForm.formData,
    ...formValues.salesDetailsForm.formData,
    sellOffDeleteAttachmentIds: kitAttachmentsToDelete,
    sellOffDeleteImageIds: kitImagesToDelete,
    isKitSingleItem: false,
    kitInformationId: kitInformationId || 0,
  };
  const isSoldStatus = formValues.generalInformationKitForSaleForm.formData &&
    formValues.generalInformationKitForSaleForm.formData.soldPriceType &&
    formValues.kitStatusForSaleForm.formData &&
    (formValues.kitStatusForSaleForm.formData.kitConditionId === 4);

  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue') || (key.includes('soldPriceType') && !isSoldStatus)) {
      delete valuesToSend[key];
    }
    if (valuesToSend.saleDate) {
      valuesToSend.saleDate = moment(valuesToSend.saleDate).format('YYYY-MM-DD');
    }
    if (key === 'dateOfManufacture') {
      valuesToSend.dateOfManufacture = valuesToSend.dateOfManufacture || '';
    }
    if (key === 'consumedQuantity') {
      const consumedQuantity = valuesToSend.consumedQuantity || 0;
      valuesToSend.quantity = Number(valuesToSend.quantity) + Number(consumedQuantity);
      delete valuesToSend.consumedQuantity;
    }
  });

  const isSalesDetails = !isEmpty(salesDetailsForm.formData);
  valuesToSend.isSalesDetails = isSalesDetails;

  const validGeneralInformationKitForSaleForm = validateForm({ form: generalInformationKitForSaleForm, model: generalInformationKitForSaleModel });
  const validKitForSaleForm = validateForm({ form: kitForSaleForm, model: kitForSaleInformationModel });
  const validKitStatusForm = validateForm({ form: kitStatusForSaleForm, model: kitStatusModel });
  const validSalesDetailsForm = validateForm({ form: salesDetailsForm, model: salesDetailsModel });

  if (!(validGeneralInformationKitForSaleForm.isFormValid && validKitForSaleForm.isFormValid && validKitStatusForm.isFormValid) || (isSalesDetails && !validSalesDetailsForm.isFormValid)) {
    const emptySalesDetailsForm = {
      formData: {},
      isFormValid: false,
    };
    if (!validGeneralInformationKitForSaleForm.isFormValid ) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
    }
    return dispatch(
      updateKitForSaleForm({
        generalInformationKitForSaleForm: validGeneralInformationKitForSaleForm,
        kitForSaleForm: validKitForSaleForm,
        kitStatusForSaleForm: validKitStatusForm,
        salesDetailsForm: isSalesDetails ? validSalesDetailsForm : emptySalesDetailsForm,
      }),
    );
  }

  const kitImages = createKitForSaleReducer.kitImages;
  const kitAttachments = createKitForSaleReducer.kitAttachments;
  let imagesToUpload = [];
  let attachmentsToUpload = [];
  imagesToUpload = kitImages.filter(image => !image.kitImagesId);
  attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);

  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.kitManual, valuesToSend);
    if (data) {
      if (attachmentsToUpload && attachmentsToUpload.length) {
        await dispatch(uploadKitAttachment(attachmentsToUpload, data.selloffInformatioId));
      }
      if (imagesToUpload && imagesToUpload.length) {
        await dispatch(uploadKitImage(imagesToUpload, data.selloffInformatioId));
      }
      scrollToTop(500);
      dispatch(
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(toggleActionMessage(true, 'success', 'kitSavedSuccessMessage')),
        ]),
      );

      return { success: true, selloffInformatioId: data.selloffInformatioId, kitInformation: data.kitInformation.kitInformationId };
    }
  } catch (err) {
    dispatch(changeLoader(false));
    if (err && err.response) {
      if (err.response.status === 409) {
        dispatch(toggleActionMessage(true, 'error', 'iBaseConflictError'));
      } else if (err.response.status === 422) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      } else if (err.response.status === 403) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      }
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
    return { success: false };
  }
};

export const saveCreateSingleItem = () => async (dispatch, getState) => {
  const { createKitForSaleReducer } = getState();
  const {
    generalInformationKitForSaleForm,
    kitForSaleForm,
    kitStatusForSaleForm,
    kitAttachmentsToDelete,
    kitImagesToDelete,
    kitInformationId,
    salesDetailsForm,
  } = createKitForSaleReducer;
  const formValues = {
    generalInformationKitForSaleForm,
    kitForSaleForm,
    kitStatusForSaleForm,
    salesDetailsForm,
  };
  const valuesToSend = {
    ...formValues.generalInformationKitForSaleForm.formData,
    ...formValues.kitForSaleForm.formData,
    ...formValues.kitStatusForSaleForm.formData,
    ...formValues.salesDetailsForm.formData,
    sellOffDeleteAttachmentIds: kitAttachmentsToDelete,
    sellOffDeleteImageIds: kitImagesToDelete,
    kitInformationId: kitInformationId || 0,
  };
  const isSoldStatus = formValues.generalInformationKitForSaleForm.formData &&
    formValues.generalInformationKitForSaleForm.formData.soldPriceType &&
    formValues.kitStatusForSaleForm.formData &&
    (formValues.kitStatusForSaleForm.formData.kitConditionId === 4);

  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue') || (key.includes('soldPriceType') && !isSoldStatus)) {
      delete valuesToSend[key];
    }
    if (valuesToSend.saleDate) {
      valuesToSend.saleDate = moment(valuesToSend.saleDate).format('YYYY-MM-DD');
    }
    if (key === 'dateOfManufacture') {
      valuesToSend.dateOfManufacture = valuesToSend.dateOfManufacture || '';
    }
    if (key === 'consumedQuantity') {
      const consumedQuantity = valuesToSend.consumedQuantity || 0;
      valuesToSend.quantity = Number(valuesToSend.quantity) + Number(consumedQuantity);
      delete valuesToSend.consumedQuantity;
    }
  });

  const isSalesDetails = !isEmpty(salesDetailsForm.formData);
  valuesToSend.isSalesDetails = isSalesDetails;

  const validGeneralInformationKitForSaleForm = validateForm({ form: generalInformationKitForSaleForm, model: generalInformationSingleItemModel });
  const validKitForSaleForm = validateForm({ form: kitForSaleForm, model: singleItemForSaleInformationModel });
  const validKitStatusForm = validateForm({ form: kitStatusForSaleForm, model: kitStatusModel });
  const validSalesDetailsForm = validateForm({ form: salesDetailsForm, model: salesDetailsModel });

  if (!(validGeneralInformationKitForSaleForm.isFormValid && validKitForSaleForm.isFormValid && validKitStatusForm.isFormValid) || (isSalesDetails && !validSalesDetailsForm.isFormValid)) {
    const emptySalesDetailsForm = {
      formData: {},
      isFormValid: false,
    };
    if (!validGeneralInformationKitForSaleForm.isFormValid ) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
    }
    return dispatch(
      updateKitForSaleForm({
        generalInformationKitForSaleForm: validGeneralInformationKitForSaleForm,
        kitForSaleForm: validKitForSaleForm,
        kitStatusForSaleForm: validKitStatusForm,
        salesDetailsForm: isSalesDetails ? validSalesDetailsForm : emptySalesDetailsForm,
      }),
    );
  }

  const kitImages = createKitForSaleReducer.kitImages;
  const kitAttachments = createKitForSaleReducer.kitAttachments;
  let imagesToUpload = [];
  let attachmentsToUpload = [];
  imagesToUpload = kitImages.filter(image => !image.kitImagesId);
  attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);

  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.singleItem, valuesToSend);
    if (data) {
      if (attachmentsToUpload && attachmentsToUpload.length) {
        await dispatch(uploadKitAttachment(attachmentsToUpload, data.selloffInformatioId));
      }
      if (imagesToUpload && imagesToUpload.length) {
        await dispatch(uploadKitImage(imagesToUpload, data.selloffInformatioId));
      }
      dispatch(changeLoader(false));
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'kitSavedSuccessMessage'));

      return { success: true, selloffInformatioId: data.selloffInformatioId, kitInformation: data.kitInformation.kitInformationId };
    }
  } catch (err) {
    dispatch(changeLoader(false));
    if (err && err.response) {
      if (err.response.status === 409) {
        dispatch(toggleActionMessage(true, 'error', 'iBaseConflictError'));
      } else if (err.response.status === 422) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      } else if (err.response.status === 403) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      }
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
    return { success: false };
  }
};

export const fetchStockInfo = id => async (dispatch, getState) => {
  try {
    const { kitForSaleForm } = getState().createKitForSaleReducer;
    const { status, data } = await fetchMasterDataService(URLS.getStockInfo(id));
    if ((status === 200) && data.stockSellOffPlatformModel && data.stockSellOffPlatformModel.shopManagers) {
      const shopMenegers = data.stockSellOffPlatformModel.shopManagers;
      const contactPerson = shopMenegers && shopMenegers.length ? `${shopMenegers[0].firstName} ${shopMenegers[0].lastName}` : '';

      dispatch(updateKitForSaleForm({
        kitForSaleForm: {
          ...kitForSaleForm,
          formData: {
            ...((kitForSaleForm && kitForSaleForm.formData) || {}),
            contactPerson,
          },
        },
      }));
    }
    return [];
  } catch (err) {
    logNetworkError(err);
  }
};

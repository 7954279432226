import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import strings from 'resources/locales/Translate';

export const breadcrumbsData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'c2cLoans',
    url: '/c2c-loans',
  },
];

export const dataTableC2CLoans = user => [
  {
    title: '#',
    name: 'transactionNumber',
    isSort: false,
    render: item => (
      <NavLink to={`/c2c/${item.transactionsId}/view-c2c-loan`} data-test={'c2c-transaction-details-link'}>
        <span>{item.c2cId}</span>
      </NavLink>
    ),
  },
  {
    title: strings.mainComponent,
    name: 'name',
    isSort: true,
    render: item => (
      <NavLink to={`/kit_details/${item.transactionKit.kitInformation.kitInformationId}`} data-test={'c2c-kit-details-link'}>
        <span>
          {item.transactionKit && item.transactionKit.kitInformation && item.transactionKit.kitInformation.kitName}
        </span>
      </NavLink>
    ),
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: true,
    render: item => (
      <span>
        {item.transactionKit && item.transactionKit.kitInformation && item.transactionKit.kitInformation.serialNumber}
      </span>
    ),
  },
  {
    title: strings.startDate,
    name: 'begin',
    isSort: true,
    render: item => {
      return (
        <span>{item.startDate && moment(item.startDate).isValid() && moment(item.startDate).format('DD.MM.YYYY')}</span>
      );
    },
  },
  {
    title: strings.endDate,
    name: 'end',
    isSort: true,
    render: item => (
      <span>{item.endDate && moment(item.endDate).isValid() && moment(item.endDate).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    title: strings.currentPosition,
    name: 'currentPosition',
    isSort: false,
    render: item => {
      const currentPosition = get(
        item,
        'transactionKit.kitInformation.transactionPosition.transactionPositionTranslated[0].positionText',
        '',
      );
      return <span>{currentPosition}</span>;
    },
  },
];

export const dataTableChildTable = (user, lastTransactionsId) => [
  {
    title: strings.kitLoanId,
    name: 'transactionsId',
    isSort: false,
    render: item => <span>{item.transactionsId}</span>,
  },
  {
    title: strings.shipped,
    name: 'targetDeliveryFrom',
    isSort: false,
    render: item => (
      <span>
        {item.targetDeliveryFrom &&
          moment(item.targetDeliveryFrom).isValid() &&
          moment(item.targetDeliveryFrom).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: strings.receiptAtTheCustomer,
    name: 'targetReservationFrom',
    isSort: false,
    render: item => (
      <span>
        {item.targetReservationFrom &&
          moment(item.targetReservationFrom).isValid() &&
          moment(item.targetReservationFrom).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: strings.checkBeforeReturn,
    name: 'targetReturnDeliveryFrom',
    isSort: false,
    render: item => (
      <span>
        {item.targetReturnDeliveryFrom &&
          moment(item.targetReturnDeliveryFrom).isValid() &&
          moment(item.targetReturnDeliveryFrom).subtract(1, 'day').format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: strings.returnDelivery,
    name: 'targetReturnDeliveryFrom',
    isSort: false,
    render: item => (
      <span>
        {item.targetReturnDeliveryFrom &&
          moment(item.targetReturnDeliveryFrom).isValid() &&
          moment(item.targetReturnDeliveryFrom).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: `${strings.receivedNextCustomer} / ${strings.atStock.toLowerCase()}`,
    name: 'receivedNextCustomer',
    isSort: false,
    render: item => (
      <span>
        {item.allNextC2c && item.allNextC2c.length && item.allNextC2c[0].targetReservationFrom
          ? dayjs(item.allNextC2c[0].targetReservationFrom).format('DD.MM.YYYY')
          : item.targetReconditioningFrom &&
            moment(item.targetReconditioningFrom).isValid() &&
            moment(item.targetReconditioningFrom).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: strings.checked,
    name: 'targetReconditioningTo',
    isSort: false,
    render: item => lastTransactionsId === item.transactionsId && (
      <span>
        {item.allNextC2c && item.allNextC2c.length && item.allNextC2c[0].targetReservationFrom
        ? ''
        : item.targetReconditioningTo &&
        moment(item.targetReconditioningTo).isValid() &&
        moment(item.targetReconditioningTo).format('DD.MM.YYYY')}
      </span>
    ),
  },
];

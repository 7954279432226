import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import {
  FETCH_COUNTRY_LIST,
  FETCH_CONDITIONS,
  FETCH_KIT_STATUS,
  FETCH_BUSINESS_UNIT_LIST,
} from 'library/common/commonConstants/storeConstants';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { createKitBreadcrumbs, generalInformationModel } from './CreateKit.constants';
import { createKitTabsConstant } from './Tabs/CreateKit.tabs';

export default class CreateKit extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.kitInformationId && !state.isEditView) {
      return { isEditView: true };
    }
    return null;
  }

  constructor(props) {
    super(props);
    let currentTab = 1;
    if (props.history.location.state && props.history.location.state.tab) {
      if (props.history.location.state.tab === 'components') {
        currentTab = 2;
      }
      if (props.history.location.state.tab === 'accessories') {
        currentTab = 3;
      }
    }
    let kitInformationId = this.props.match.params && this.props.match.params.id;
    let isCopyKit = this.props.match.path.includes('copy-kit');
    if (props.history.location.state && props.history.location.state.kitInformationId) {
      kitInformationId = props.history.location.state.kitInformationId;
      isCopyKit = props.history.location.state.isCopyKit;
    }
    const isEditView = !!kitInformationId;
    document.title = 'Create Kit';
    if (isEditView && !isCopyKit) {
      document.title = 'Maintain Kit';
    } else if (isCopyKit) {
      document.title = 'Copy Kit';
    }
    this.state = {
      currentTab,
      isCopyKit,
      isEditView,
      kitInformationId,
      loading: isEditView || isCopyKit,
    };
  }

  componentDidMount() {
    const { fetchMasterData } = this.props;
    this.fetchKitDetail();
    fetchMasterData(URLS.kitStatus, FETCH_KIT_STATUS);
    fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
    fetchMasterData(URLS.businessUnitList, FETCH_BUSINESS_UNIT_LIST);
    fetchMasterData(`${URLS.countryList}?loantype=dla-stocks`, FETCH_COUNTRY_LIST);
  }

  componentWillUnmount() {
    const { resetCreateKit } = this.props;
    resetCreateKit();
  }

  fetchKitDetail = async () => {
    const { isCopyKit, isEditView, kitInformationId } = this.state;
    if (isCopyKit || isEditView) {
      await this.props.fetchKitDetail(kitInformationId, isCopyKit);
      this.setState({ loading: false });
    }
  };

  handleForm = ({ values, field, formName }) => {
    this.props.updateFormCreateKit({
      [formName]: {
        ...this.props.formValue[formName],
        formData: {
          ...((this.props.formValue[formName] && this.props.formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
  };

  addKitImageFile = files => {
    const { kitImages } = this.props;
    const newImages = [...kitImages, ...files];
    this.props.updateFormCreateKit({ kitImages: newImages });
  };

  removeKitImageFile = index => {
    const { kitImages, kitImagesToDelete } = this.props.formValue;
    const fileToRemove = kitImages[index];
    let newKitImagesToDelete = [...kitImagesToDelete];
    if (fileToRemove.kitImagesId) {
      newKitImagesToDelete.push(fileToRemove.kitImagesId);
    }
    const newKitImages = kitImages.filter((_, i) => i !== index);
    this.props.updateFormCreateKit({ kitImages: newKitImages, kitImagesToDelete: newKitImagesToDelete });
  };

  getTitle = () => {
    const { isEditView, isCopyKit } = this.state;
    if (isEditView && !isCopyKit) {
      return strings.maintainKits;
    } else if (isCopyKit) {
      return strings.copyKit;
    }
    return strings.createKit;
  };

  saveKit = async tab => {
    const { isCopyKit } = this.state;
    const { history, saveCreateKit } = this.props;
    const { success, kitInformationId } = await saveCreateKit(tab, isCopyKit);
    if (success) {
      history.replace(`/kit_details/${kitInformationId}`);
    }
  };

  cancelCreateKit = () => {
    const { history } = this.props;
    history.replace('/kits');
  };

  checkForShowingVRDetailsTab = () => {
    const { user } = this.props;
    const { stockInformation } = this.props.kitDetails;
    const stockId= stockInformation && stockInformation.id;
    const roles=['Stock Controller','Dispatcher'];
      if (user && user.admin){
        return true;
      }
      if (user && user.stockRoles && user.stockRoles.length > 0) {
        return roles.some(role => {
          return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
        });
      }
      return false;
  };

  render() {
    const { currentTab, isCopyKit, loading, isEditView } = this.state;
    const { displayActionMessage, type, message, isLoading, showAsIs } = this.props.appActions;
    const { kitImages, kitDetails, formValue, history, kitInformationId, updateFormCreateKit, fetchKitDetail } = this.props;
    const { generalInformationForm } = formValue;
    const { toggleActionMessage } = this.props;
    const isVrKit = kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassName && kitDetails.systemClass.systemClassName.toLowerCase() === 'VR Demo Kit'.toLowerCase();

    return (
      <div className='create-kit-container'>
        {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : (strings[message] || message)} />}
        <div className='container-fluid'>
          <Breadcrumbs data={createKitBreadcrumbs(isEditView)} />
          <h1 className='page-title'>{this.getTitle()}</h1>

          <div className='row'>
            <div className='col-sm-5 mb-3'>
              <h4 className='header-form-title'>{strings.imagesText}</h4>
              <FileUploader
                mimeType='image/*,.czi'
                files={kitImages}
                maxSize={50}
                errorMessage={strings.maxFileSize}
                onFileAdd={this.addKitImageFile}
                onRemove={this.removeKitImageFile}
              />
            </div>
            <div className='col-sm-7 mb-3'>
              <h4 className='header-form-title'>{strings.generalInformation}</h4>
              <FormComponent
                dataTest={'create-kit-form-component'}
                model={generalInformationModel}
                formName='generalInformationForm'
                formValue={generalInformationForm.formData}
                onChange={this.handleForm}
              />
            </div>
          </div>
        </div>

        {!loading && (
          <Tabs
            currentTab={currentTab}
            tabs={createKitTabsConstant({
              history,
              isCopyKit,
              kitDetails,
              saveKit: this.saveKit,
              cancelCreateKit: this.cancelCreateKit,
              formValue,
              handleForm: this.handleForm,
              kitInformationId,
              updateFormCreateKit,
              fetchKitDetail,
              toggleActionMessage,
              isVrKit,
              checkForShowingVRDetailsTab: this.checkForShowingVRDetailsTab,
              deletereparationSlotForKit: this.props.deletereparationSlotForKit,
            })}
          />
        )}

        {isLoading && <div className='loader' />}
      </div>
    );
  }
}

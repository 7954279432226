import React, {Component} from 'react';
import PropTypes from 'prop-types';
import strings from "resources/locales/Translate";
import Button from "library/common/commonComponents/Button/Button";
import Icon from "library/common/commonComponents/Icon/Icon";

import './kitList.styles.scss'
import {images} from "library/common/commonConstants/ImageConstants";
import {NavLink} from "react-router-dom";

export default class KitList extends Component {

  state = {
    isEndReached: false,
    isStartReached: false,
    width: 0,
    height: 0
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", () => this.updateDimensions());
    this.checkIfEndOrStartReached()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateDimensions());
  }

  updateDimensions = () => {
    if (window.innerWidth < 500) {
      this.setState({width: 500, height: 102});
    } else {
      let updateWidth = window.innerWidth;
      let updateHeight = Math.round(updateWidth / 4.4);
      this.setState({width: updateWidth, height: updateHeight});
    }
  };

  checkIfEndOrStartReached = () => {
    const {referenceId} = this.props;

    let element = document.getElementById(referenceId);
    if (element) {
      this.setState({
        isStartReached: element.scrollLeft <= 20,
        isEndReached: element.offsetWidth + element.scrollLeft >= element.scrollWidth - 20,
      });
    }
  };

  handleScrollWithButton = (scrollDirection) => () => {
    const {referenceId} = this.props;
    const {width} = this.state;
    let i = 0;
    let interval = setInterval(() => {
      let element = document.getElementById(referenceId);
      if (element) {
        element.scrollLeft = scrollDirection === 'left' ? element.scrollLeft - 7 : element.scrollLeft + 7;
        i++;
        if (i >= (width < 576 ? 50 : 100)
          || (scrollDirection === 'left' ? (element.scrollLeft === 0) : (element.offsetWidth + element.scrollLeft === element.scrollWidth))) {
          clearInterval(interval);
        }
      }
    }, 1);
  };

  handleImageLoadError = (e) => {
    e.target.src = images.imagePlaceholder;
  };

  loanItemComponent = (item, key) => {
    const {kitListData, cart, onBookNow, authentication} = this.props;
    const kitImg = item.kitImages && item.kitImages.length && item.kitImages[0].preview;
    const genericImg = item.kitGenericDevicePicture && item.kitGenericDevicePicture.length && item.kitGenericDevicePicture[0].preview;
    const displayedImg = kitImg || genericImg || images.imagePlaceholder;
    const {stockInformation} = item;
    let stockName = '';

    if (stockInformation) {
      stockName = `${stockInformation.country.shortName} ${stockInformation.businessUnit.name} ${stockInformation.locationShortName}`;
    }
    let alreadyAddedToCart = cart && cart.some(cartItem => cartItem.resourceId === item.kitInformationId);

    return (
      <div key={key}
           className={'item-container position-relative ' + (key === kitListData.length - 1 ? 'pr-4' : 'pr-0')}>
        <div className="card card-view">
          {
            item.newTag &&
            <div className={'new-label d-flex align-items-center justify-content-center'}>
              <p className={'new-label-text'}>
                {strings.new}
              </p>
            </div>
          }
          <img
            src={displayedImg}
            className="card-img-top item-image"
            onError={this.handleImageLoadError}
            alt="..."
          />
          <div className="card-body d-flex justify-content-between">
            <div>
              <p className="card-title item-name">{item.kitName}</p>
              <p className="item-content kit-number">{strings.kitNumber}: {item.kitInformationId}</p>
              <p className="item-content stock">{strings.stock}: {stockName}</p>
            </div>
            <div className={'item-footer-buttons mt-3'}>
              {this.checkRoles(item).canView && authentication.isLoggedIn ?
                (<NavLink to={{pathname: '/kit_details/' + item.kitInformationId}}
                          className={'details-button'}>{strings.details}</NavLink>) : null}

              {this.checkRoles(item).canBook && !alreadyAddedToCart && authentication.isLoggedIn ?
                <div className={'book-now-button'}>
                  <Button
                    data-test="kitlist-book-now-button"
                    value={strings.bookNow}
                    iconName='arrow'
                    bgFill={false}
                    onClick={() => onBookNow(item)}
                  />
                </div> : null}

              {alreadyAddedToCart && <div className={'book-now-button'}><span>{strings.addedToCart}</span></div>}

            </div>
          </div>
        </div>
      </div>
    )
  };

  checkRoles(item) {
    const {user, isLoggedIn} = this.props.authentication;
    const {stockInformation} = item;

    const rolesToCheckForBooking = ['manager', 'stock controller', 'dispatcher', 'borrower', 'external borrower', 'read only user'];
    const rolesToCheckForViewing = ['manager', 'stock controller', 'dispatcher', 'borrower', 'external borrower', 'inbound staff', 'outbound staff', 'read only user'];
    let canBook = false;
    let canView = false;

    if (isLoggedIn) {
      if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0) {
        const {role} = stockInformation.userRole[0].role;
        canBook = user.admin || rolesToCheckForBooking.some(checkRole => role.toLowerCase() === checkRole);
        canView = user.admin || rolesToCheckForViewing.some(checkRole => role.toLowerCase() === checkRole);
      }
      if(user.admin) {
        canBook = true;
        canView = true;
      }
    }

    return {canBook, canView};
  }

  render() {
    const {isEndReached, isStartReached} = this.state;
    const {title, kitListData, referenceId} = this.props;

    return (
      <div className='main-container position-relative container-fluid p-0'>
        <p className='title'>{title}</p>
        <div
          onScroll={this.checkIfEndOrStartReached}
          id={referenceId} className='d-flex card-container'>
          {
            kitListData.map((item, key) => (
              this.loanItemComponent(item, key)
            ))
          }
        </div>
        <div
          data-test="kitlist-right-button"
          className={'right-arrow-container cursor-pointer ' + (isEndReached ? 'd-none' : 'd-flex')}
          onClick={this.handleScrollWithButton('right')}>
          <Icon width={40} height={40} name={'arrow'} fill={"#fff"}/>
        </div>
        <div
          data-test="kitlist-left-button"
          className={'left-arrow-container cursor-pointer ' + (isStartReached ? 'd-none' : 'd-flex')}
          onClick={this.handleScrollWithButton('left')}>
          <Icon width={40} height={40} name={'arrow'} fill={"#fff"}/>
        </div>
      </div>
    );
  }
}

KitList.propTypes = {
  title: PropTypes.string,
  kitListData: PropTypes.array.isRequired
};

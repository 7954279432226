import React, { useEffect, useState, useCallback } from 'react';
import get from 'lodash/get';

import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

const getTableCols = () => [
  {
    title: '#',
    name: 'number',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.kitInformation.kitInformationId}</span>
      )
    },
  },
  {
    title: strings.kitName,
    name: 'kitName',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.kitInformation.kitName}</span>
      )
    },
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.kitInformation.serialNumber}</span>
      )
    },
  },
  {
    title: strings.stock,
    name: 'stockName',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.kitInformation.stockInformation && item.kitInformation.stockInformation.locationName}</span>
      )
    },
  },
  {
    title: strings.taskCompleted,
    name: 'taskCompleted',
    isSort: false,
    render: (item) => {
      return (
        <div className='d-flex justify-content-center'>
          <Checkbox
            selected={item.status === 'COMPLETED'}
            disabled={true}
            small
            className='ml-2'
          />
        </div>
      )
    },
  },
  {
    title: `${strings.solvedBy} / ${strings.date}`,
    name: 'solvedBy',
    isSort: false,
    render: (item) => {
      const { completedBy, completedOn } = item;
      const firstName = get(completedBy, 'firstName');
      const lastName = get(completedBy, 'lastName');
      const name = completedBy ? `${firstName} ${lastName}` : '';
      const date = completedOn ? getDateInFormat('DD.MM.YYYY', completedOn) : '';
      const solvedBy = name && date ? `${name} / ${date}` : '';

      return (
        <span>{solvedBy}</span>
      )
    },
  },
];

export const useStatusList = ({ user, fetchStatusListData, statusList, match }) => {
  const isNoEmptyStatusList = statusList.content && statusList.content.length > 0;
  const [state, setState] = useState({
    page: 1,
    size: 10,
  });

  useEffect(() => {
    if (match && match.params.id) {
      fetchStatusListData(state, match.params.id, match.params.demoUnitStatus);
    }
  }, [state, fetchStatusListData, match]);

  const handleItemsPerPage = useCallback(size => {
    setState(prevState => ({
      ...prevState,
      size,
    }));
  }, []);

  const handlePageChange = useCallback(page => {
     setState(prevState => ({
      ...prevState,
      page: page + 1,
    }));
  }, []);

  const tableCols = getTableCols();

  return {
    isNoEmptyStatusList,
    state,
    handleItemsPerPage,
    handlePageChange,
    tableCols,
  };
};

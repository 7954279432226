import Validators from 'library/utilities/Validators';

export const deliveryOptions = [
  { name: 'My Delivery Address', id: 1 },
  { name: 'My Address', id: 2 },
  { name: `Borrower's Delivery Address`, id: 3 },
  { name: 'New Delivery Address', id: 4 },
];

export const reservationPeriodModelSingleLoan = [
  {
    label: 'reservationFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'reservationFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    validateOnChange: true,
  },
  {
    label: 'reservationTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'reservationTo',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
    validateOnChange: true,
  },
];

export const reservationPeriodModelCourseLoan = [
  {
    label: 'reservationFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'reservationFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-md-6',
    dateFormat: 'DD.MM.YYYY',
    isClearable: true,
    validateOnChange: true,
  },
  {
    label: 'reservationTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'reservationTo',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-md-6',
    dateFormat: 'DD.MM.YYYY',
    isClearable: true,
    activeStartDate: null,
    validateOnChange: true,
  },
];

export const descriptionModelKitLoan = [
  {
    label: 'reasonForLoan',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'reasonForLoanId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'reasonForLoanId',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'nameShortDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'name',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const taskIdModel = {
  label: 'taskId',
  value: '',
  type: 'text',
  placeholder: '',
  field: 'crmTaskId',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-12',
};

export const opportunityIdCRMmodel = [
  {
    label: 'opportunityIdCRM',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'opportunityIdCrm',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: false,
    styleClass: 'col-12',
  },
];

export const showLoanValueFieldModel = [
  {
    label: 'loanValue',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'loanValue',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: false,
    styleClass: 'col-12',
    currency: '',
    extraProps: {
      maxLength: 20,
    },
  },
];

export const descriptionModelSingleLoan = [
  {
    label: 'reasonForLoan',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'reasonForLoanId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'reasonForLoanId',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: '',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'nameShortDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'name',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'referenceToSapERP',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapErp',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: false,
    styleClass: 'col-12',
  },
];

export const campaignIdModelForm = [
  {
    label: 'campaignId',
    value: "",
    type: "inputgroup",
    field: "campaignId",
    styleClass: 'col-12',
    validators: [
      { check: Validators.campaignIdValidation, message: 'campaignIdErrorMessage' }, 
      { check: Validators.campaignIdShouldBeNumeric, message: 'campaignShouldBeNumeric' }
    ],
    required: false,
    inputGroupText: "C -",
    isAppend: false,
    validateOnBlur: true
  }
]

export const descriptionModelCourseLoan = [
  {
    label: 'reasonForLoan',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'reasonForLoanId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [{ name: 'Course Loan', id: 1 }],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: true,
  },
  {
    label: 'nameShortDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'name',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: '',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const helpForBuildingAndDismantlingModel = [
  {
    label: 'helpForBuildingAndDismantling',
    selected: false,
    type: 'checkbox',
    field: 'helpForBuildingAndDismantling',
    styleClass: 'col-12',
  },
];
export const additionalLoanRequestField = {
  label: 'additionalSingleLoanRequest',
  value: '',
  type: 'textarea',
  placeholder: '',
  field: 'additionalLoanRequest',
  validators: [],
  required: false,
  styleClass: 'col-12',
};

export const referenceToSapERPField = {
  label: 'kitLoanOrderText',
  value: '',
  type: 'text',
  placeholder: '',
  field: 'sapErp',
  validators: [{ check: Validators.number, message: 'invalidNumberError' }],
  required: false,
  styleClass: 'col-12',
};

export const componentsModel = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stock',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
];

export const borrowerModel = [
  {
    label: 'borrower',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'borrowerId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    idKey: 'accountId',
  },
];

export const typeOfDeliveryAddressModel = [
  {
    label: 'deliveryAddress',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'deliveryAddress',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    displayValue: [{ key: 'name', separator: '' }],
    idKey: 'id',
  },
];

export const deliveryAddressModel = [
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: true,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr',
      },
      {
        id: 'ms',
        value: 'ms',
      },
    ],
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'houseNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'stateOrProvince',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'state',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    excludeData: [],
    styleClass: 'col-sm-8',
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
];

export const deliveryCommentModel = [
  {
    label: 'deliveryComment',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'deliveryComment',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];

export const pickUpCommentModel = [
  {
    label: 'pickupComment',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'pickUpComment',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];

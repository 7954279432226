import React, { Component } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import { scrollToTop } from 'library/utilities/scrollActions';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { getLocale } from 'library/utilities/getLocale';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { FormComponent } from 'library/common/commonComponents/FormComponent/Optimized';
import { WORKFLOW_CATEGORY_QUESTION_IDS } from './workflows.constants'

import './Workflows.style.scss';
import {
  addEmailModel,
  additionalFilesModel,
  externallyManagedStockModel,
  refurbishmentForDemoKitModel,
  refurbishmentForSingleLoanModel,
} from './workflows.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import AddEmailAddress from './AddEmail/AddEmailAddress.component';
import Button from 'library/common/commonComponents/Button/Button';
import $ from 'jquery';
import AddEditSlotPopup from '../AddEditSlotPopup';
import AvailabilitySlotList from '../AvailabiltySlotList/AvailabliitySlotList.component';
import AddKitLoanDismantleEmail from './AddKitLoanDismantleEmail/AddKitLoanDisamtleEmail.component';
import AddCourseLoanDismantleEmail from './AddCourseLoanDismantleEmail/AddCourseLoanDismantleEmail.component';

// This should changed based on the stock information
const ALL_LOAN_REASONS = [1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

class Workflows extends Component {
  state = {
    stockWorkflowSettings: null,
    dateToShow: null,
    errorDateToShow: null,
    fromDate: null,
    errorInputs: [],
    erpCheckStatus: false,
    selectedCurrency: {
      id: 0,
      value: '',
    },
    isAdmin: false,
    externallyManagedStockForm: {},
    externallyManagedStockStatus: false,
    refurbishmentForDemoKit: false,
    refurbishmentForSingleLoan: false,
    refurbishmentForDemoKitForm: {},
    refurbishmentForSingleLoanForm: {},
    showPickUpRequestButton: false,
    newEmailAddress: [],
    isDisplay: false,
    emailAdress: '',
    error: '',
    showAdditionalFields: false,
    showAdditionalFieldsForm: {},
    ccEmailAddress: '',
    newCcEmailAddressArray: [],
    pickUpRequestEmailError: '',
    ccEmailError: '',
    editingAvailabilitySlotData: {},
    isEditClicked: false,
    showAdaptedLaonReaons: false,
    selectAdapedLoanReasons: [],
    modelFormForAdaptLoanReasons: [],
    adaptedLoanReasonAnswer: null,
    showKitDismantleEmail: false,
    kitDismantleEmail: '',
    newKitDismantleEmailArray: [],
    kitDismantleEmailError: '',
    showCourseDismantleEmail: false,
    courseDismantleEmail: '',
    newCourseDismantleEmailArray: [],
    courseDismantleEmailError: '',
  };

  componentDidMount() {
    this.fetchWorkflowData();
    this.fetchCurrencyData();
    const user = fetchFromStorage(identifiers.user);
    this.setState({ isAdmin: user && user.admin });
    this.fetchdistributionChannelList();
    this.fetchbillingsList();
    this.fetchorderTypesList();
    this.props.getSystemAvailabiltySoltsList(this.props.stockId);
    this.getAdaptedLoanReaonsBasedOnStockId();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      costFactorPricePerLoanForKit,
      costFactorPricePerDayDelayForKit,
      costFactorPerLoanForSingleLoan,
      numberOfDaysSingleLoanFree,
    } = this.state;
    if (
      !this.state.stockWorkflowSettings &&
      ((this.props.isActive && !prevProps.isActive) || (this.props.stockId && !prevProps.stockId))
    ) {
      this.fetchWorkflowData();
    }
    if (prevProps.language.language !== this.props.language.language) {
      this.fetchWorkflowData();
      this.fetchdistributionChannelList();
      this.fetchbillingsList();
      this.fetchorderTypesList();
    }
    if(prevProps.stockId != this.props.stockId){
      this.getAdaptedLoanReaonsBasedOnStockId()
    }

  }

  getAdaptedLoanReaonsBasedOnStockId = async () => {
    const stockId = this.props.stockId;
    const {getLoanReaonsBasedOnStock} = this.props;
    const resp = await getLoanReaonsBasedOnStock(stockId);
    if(resp?.success === true){
      this.setState((prev => {
        return {modelFormForAdaptLoanReasons: resp?.adaptedLoanReasonsModel || []}
      }), () => {
      })
    }
  }

  fetchCurrencyData = () => {
    this.setState({ loading: false }, async () => {
      const { data, success } = await this.props.fetchCurrency();
      if (success) {
        this.setState({ currency: data });
      }
    });
  };
  fetchdistributionChannelList = () => {
    this.setState({ loading: false }, async () => {
      const { data, success } = await this.props.distributionChannelList();
      if (success) {
        this.setState({ distributionChannelList: data });
      }
    });
  };
  fetchbillingsList = () => {
    this.setState({ loading: false }, async () => {
      const { data, success } = await this.props.billingsList();
      if (success) {
        this.setState({ billingsList: data });
      }
    });
  };
  fetchorderTypesList = () => {
    this.setState({ loading: false }, async () => {
      const { data, success } = await this.props.orderTypesList();
      if (success) {
        this.setState({ orderTypesList: data });
      }
    });
  };

  fetchWorkflowData = () => {
    if (this.props.stockId && !this.state.loading) {
      this.setState({ loading: true }, async () => {
        const { data, success } = await this.props.fetchWorkflowData(this.props.stockId);
        const {
          stockWorkflowDTOS,
          activeUntilDate,
          currency,
          billing,
          distributionChannel,
          customerIdSap,
          goodsRecipientIdSap,
          externalWarehouseText,
          orderType,
          partner,
          shippingInstruction,
          submissionData,
          costFactorPricePerLoanForKit,
          costFactorPricePerDayDelayForKit,
          costFactorPerLoanForSingleLoan,
          numberOfDaysSingleLoanFree,
          logisticsProviderEmailAddress,
          crateId,
          pickUpComment,
          trackingNumber,
          logisticsProviderCcEmailAddress,
          kitLoanHelpForDismantleEmails,
          courseLoanHelpForDismantleEmails,
        } = data;
        if (success) {
          let tempArray = [];
          if (logisticsProviderEmailAddress && logisticsProviderEmailAddress.length > 0) {
            tempArray = logisticsProviderEmailAddress.map(item => ({ id: Date.now(), email: item }));
          }
          let ccEmailAddress = [];
          if (logisticsProviderCcEmailAddress && logisticsProviderCcEmailAddress.length > 0) {
            ccEmailAddress = logisticsProviderCcEmailAddress.map(item => ({ id: Date.now(), email: item }));
          }
          let kitDismantleEmails = [];
          if (kitLoanHelpForDismantleEmails !== '' && kitLoanHelpForDismantleEmails !== null) {
            const sepratedArray = kitLoanHelpForDismantleEmails.split(',');
            kitDismantleEmails = sepratedArray.map(item => ({ id: Date.now(), email: item }));
          }
          let courseDismantleEmails = [];
          if (courseLoanHelpForDismantleEmails !== '' && courseLoanHelpForDismantleEmails !== null) {
            const tempValues = courseLoanHelpForDismantleEmails.split(',');
            courseDismantleEmails = tempValues.map(item => ({ id: Date.now(), email: item }));
          }
          this.setState({
            stockWorkflowSettings: stockWorkflowDTOS,
            dateToShow: activeUntilDate,
            loading: false,
            selectedCurrency: currency,
            newEmailAddress: tempArray,
            showAdditionalFieldsForm: {
              formData: { pickUpComment: pickUpComment, trackingNumber: trackingNumber, crateId: crateId },
            },
            newCcEmailAddressArray: ccEmailAddress,
            newKitDismantleEmailArray: kitDismantleEmails,
            newCourseDismantleEmailArray: courseDismantleEmails
          });
          let demoKitFormData = {};
          let singleLoanFormData = {};
          let refurbishmentForDemoKitForm = {};
          let refurbishmentForSingleLoanForm = {};
          if (this.props.language.language === 'de') {
            demoKitFormData.costFactorPricePerLoanForKit =
              costFactorPricePerLoanForKit && costFactorPricePerLoanForKit.toString().includes('.')
                ? costFactorPricePerLoanForKit.toString().replace('.', ',')
                : costFactorPricePerLoanForKit;
            demoKitFormData.costFactorPricePerDayDelayForKit =
              costFactorPricePerDayDelayForKit && costFactorPricePerDayDelayForKit.toString().includes('.')
                ? costFactorPricePerDayDelayForKit.toString().replace('.', ',')
                : costFactorPricePerDayDelayForKit;
            singleLoanFormData.costFactorPerLoanForSingleLoan =
              costFactorPerLoanForSingleLoan && costFactorPerLoanForSingleLoan.toString().includes('.')
                ? costFactorPerLoanForSingleLoan.toString().replace('.', ',')
                : costFactorPerLoanForSingleLoan;
            singleLoanFormData.numberOfDaysSingleLoanFree = numberOfDaysSingleLoanFree;
            refurbishmentForDemoKitForm.formData = demoKitFormData;
            refurbishmentForSingleLoanForm.formData = singleLoanFormData;
            this.setState({ refurbishmentForDemoKitForm: refurbishmentForDemoKitForm });
            this.setState({ refurbishmentForSingleLoanForm: refurbishmentForSingleLoanForm });
          } else {
            demoKitFormData.costFactorPricePerLoanForKit = costFactorPricePerLoanForKit;
            demoKitFormData.costFactorPricePerDayDelayForKit = costFactorPricePerDayDelayForKit;
            singleLoanFormData.costFactorPerLoanForSingleLoan = costFactorPerLoanForSingleLoan;
            singleLoanFormData.numberOfDaysSingleLoanFree = numberOfDaysSingleLoanFree;
            refurbishmentForDemoKitForm.formData = demoKitFormData;
            refurbishmentForSingleLoanForm.formData = singleLoanFormData;
            this.setState({ refurbishmentForDemoKitForm: refurbishmentForDemoKitForm });
            this.setState({ refurbishmentForSingleLoanForm: refurbishmentForSingleLoanForm });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 21) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ erpCheckStatus: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 26) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ externallyManagedStockStatus: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 27) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ refurbishmentForDemoKit: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 28) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ showAdditionalFields: checked,refurbishmentForSingleLoan: checked});
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 30) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ showPickUpRequestButton: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 14) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ showKitDismantleEmail: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 16) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ showCourseDismantleEmail: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === 35) {
                  let checked = stockWorkflow.categoryAnswer === 'yes' ? true : false;
                  this.setState({ showAdditionalFields: checked });
                }
              });
            });
          }
          if (stockWorkflowDTOS && stockWorkflowDTOS.length > 0) {
            stockWorkflowDTOS.map((section, key) => {
              section.stockWorkflowSettingDTO.map((stockWorkflow, key) => {
                if (stockWorkflow.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason) {
                  this.setAndHandleAdaptLoanReasonsOnRender(stockWorkflow);
                }
              });
            });
          }
          let formData = {};
          let externallyManagedStockForm = {};
          formData.billingFullValue = billing;
          if (billing && billing.id) {
            formData.billing = billing.id;
          }
          formData.orderTypeFullValue = orderType;
          if (orderType && orderType.id) {
            formData.orderType = orderType.id;
          }
          formData.distributionChannelFullValue = distributionChannel;
          if (distributionChannel && distributionChannel.id) {
            formData.distributionChannel = distributionChannel.id;
          }
          formData.customerIdSap = customerIdSap;
          formData.goodsRecipientIdSap = goodsRecipientIdSap;
          formData.partner = partner;
          formData.shippingInstruction = shippingInstruction;
          formData.submissionData = submissionData;
          formData.text = externalWarehouseText;
          externallyManagedStockForm.formData = formData;

          this.setState(prevState => ({ externallyManagedStockForm: externallyManagedStockForm }));
        }
      });
    }
  };

  handleInput = categoryId => (value, field) => {
    const { stockWorkflowSettings, errorInputs } = this.state;
    const errorCategoryQuestionIds = errorInputs.filter(item => item !== categoryId);
    this.setState({ errorInputs: errorCategoryQuestionIds });
    stockWorkflowSettings.forEach(stockWorkflowSetting => {
      stockWorkflowSetting.stockWorkflowSettingDTO.forEach(category => {
        const isLeadTime = category.categoryQuestionId === 17 || category.categoryQuestionId === 18;
        const maxInputLength =
          category.categoryQuestionId === 22 ||
          category.categoryQuestionId === 23 ||
          category.categoryQuestionId === 24 || 
          category.categoryQuestionId === 39;
        if (category.categoryQuestionId === categoryId) {
          if (isLeadTime && value.trim().length > 3) return;
          if (maxInputLength && value.trim().length > 4) return;
          category.categoryAnswer = value;
        }
      });
    });
    this.setState({ stockWorkflowSettings });
  };

  onChangeCurrency = (value, field) => {
    const select = this.state.currency.find(item => item.id === value);
    if (select) {
      this.setState({ selectedCurrency: select });
    }
  };

  getAllLoanReasons = () => {
    const allLoanReaons = [];
    const {modelFormForAdaptLoanReasons} = this.state
    modelFormForAdaptLoanReasons.forEach((model) => {
      if(model?.reasonForLoanId){
        return allLoanReaons.push(model.reasonForLoanId)
      }
    })
    return allLoanReaons
  }

  setAndHandleAdaptLoanReasonsOnRender = (stockWorkflow) => {
    if(stockWorkflow && stockWorkflow?.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason && stockWorkflow?.categoryAnswer === "no"){
      this.setState(prevState => {
        return {...prevState, selectAdapedLoanReasons: this.getAllLoanReasons()}
      })
      return
    }
    if(stockWorkflow && stockWorkflow?.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason && stockWorkflow?.categoryAnswer !== "no"){
      const answerAndReasonIds = stockWorkflow?.categoryAnswer;
      if(answerAndReasonIds){
        let ansSplit = answerAndReasonIds.split("-")
        try{
          let answer = ansSplit[0].trim()
          if(answer === "yes"){
            let reasonForLoanIdsArray = ansSplit[1].trim()
            reasonForLoanIdsArray = JSON.parse(reasonForLoanIdsArray)
            this.setState(prevState => {
              return {...prevState, showAdaptedLaonReaons: true, selectAdapedLoanReasons: [...prevState.selectAdapedLoanReasons, ...reasonForLoanIdsArray]}
            })
          }
        }catch(err){
          console.log("Unable to parse the loan Ids: ", err)
        }
      }
      return;
    }
    if(this.state.selectAdapedLoanReasons.length === 0){
      this.setState({selectAdapedLoanReasons: this.getAllLoanReasons()})
    }
  }

  setLaonReaons = (reasonForLoanId, checked) => {
    this.setState(prevState => {
      if(this.state.selectAdapedLoanReasons.indexOf(reasonForLoanId) == -1 && checked){
        return {...prevState, selectAdapedLoanReasons: [...prevState.selectAdapedLoanReasons, reasonForLoanId]}
      }
      if(!checked){
        let filteredNumbers = prevState.selectAdapedLoanReasons.filter(number => number !== reasonForLoanId);
        return {...prevState, selectAdapedLoanReasons: [...filteredNumbers, ]}
      }
    })
  }

  handleCheckbox = (categoryId, ...otherValues) => (checked, field) => {
    
    if(otherValues.length >= 1){
      let {reasonForLoanId} = otherValues[0]
      this.setLaonReaons(reasonForLoanId, checked)
      return;
    }

    const { stockWorkflowSettings, errorDateToShow } = this.state;
    stockWorkflowSettings.forEach(stockWorkflowSetting => {
      stockWorkflowSetting.stockWorkflowSettingDTO.forEach(category => {
        if (category.categoryQuestionId === categoryId) {
          if (categoryId == '1') {
            category.categoryAnswer = checked ? 'yes' : false;
            stockWorkflowSetting.stockWorkflowSettingDTO.forEach(again => {
              if (again.categoryQuestionId == '36') {
                again.categoryAnswer = false;
              }
            });
          } else if (categoryId == '36') {
            category.categoryAnswer = checked ? 'yes' : false;
            stockWorkflowSetting.stockWorkflowSettingDTO.forEach(again => {
              if (again.categoryQuestionId == '1') {
                again.categoryAnswer = false;
              }
            });
          } else {
            category.categoryAnswer = checked ? 'yes' : false;
          }
        }
      });
    });
    if (categoryId === 13 && errorDateToShow) {
      this.setState({ errorDateToShow: null });
    }
    if (categoryId === 25 && !checked) {
      this.setState({ selectedCurrency: null });
    }
    if (categoryId === 21) {
      this.setState({ erpCheckStatus: checked });
    }
    if (categoryId === 26) {
      this.setState({ externallyManagedStockStatus: checked });
    }
    if (categoryId === 27) {
      this.setState({ refurbishmentForDemoKit: checked });
    }
    if (categoryId === 28) {
      this.setState({ refurbishmentForSingleLoan: checked });
    }
    if (categoryId === 30) {
      this.setState({ showPickUpRequestButton: checked });
    }
    if (categoryId === 35) {
      this.setState({ showAdditionalFields: checked });
    }
    if (categoryId === 14) {
      this.setState({ showKitDismantleEmail: checked });
    }
    if (categoryId === 16) {
      this.setState({ showCourseDismantleEmail: checked });
    }
    if(categoryId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason){
      this.setState({showAdaptedLaonReaons: checked});
      if(checked === false || !checked){
        this.setState({selectAdapedLoanReasons: this.getAllLoanReasons()})
      }
    }
    this.setState({ stockWorkflowSettings });
  };

  saveStockWorkflow = async () => {
    const {
      stockWorkflowSettings,
      dateToShow,
      externallyManagedStockForm,
      externallyManagedStockStatus,
      refurbishmentForDemoKit,
      refurbishmentForSingleLoan,
      refurbishmentForDemoKitForm,
      refurbishmentForSingleLoanForm,
      newEmailAddress,
      showPickUpRequestButton,
      showAdditionalFieldsForm,
      newCcEmailAddressArray,
      newKitDismantleEmailArray,
      newCourseDismantleEmailArray,
    } = this.state;

    const currencyId = this.state.selectedCurrency && this.state.selectedCurrency.id;
    const { businessUnitFullValue, crmAccountId } = this.props;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    if (externallyManagedStockStatus) {
      const newFormData = validateForm({
        form: externallyManagedStockForm,
        formName: 'externallyManagedStockForm',
        model: this.externallyManagedStockModel(),
      });
      if (!newFormData.isFormValid) {
        return this.setState({ externallyManagedStockForm: newFormData });
      }
    }
    if (isRMSLL) {
      if (!crmAccountId) {
        scrollToTop(500);
        this.props.toggleActionMessage(true, 'error', 'crmAccountIdError');
        return;
      }
    }
    const objectToSend = {
      activeUntilDate: dateToShow ? getDateInFormat('YYYY-MM-DD', dateToShow) : null,
      currencyId,
      stockWorkflowSaveDTO: [],
      
    };
    if (externallyManagedStockStatus) {
      objectToSend.orderType = externallyManagedStockForm.formData.orderType;
      objectToSend.distributionChannel = externallyManagedStockForm.formData.distributionChannel;
      objectToSend.customerIdSap = externallyManagedStockForm.formData.customerIdSap;
      objectToSend.goodsRecipientIdSap = externallyManagedStockForm.formData.goodsRecipientIdSap;
      objectToSend.billing = externallyManagedStockForm.formData.billing;
      objectToSend.partner = externallyManagedStockForm.formData.partner;
      objectToSend.externalWarehouseText = externallyManagedStockForm.formData.text;
      objectToSend.shippingInstruction = externallyManagedStockForm.formData.shippingInstruction;
      objectToSend.submissionData = externallyManagedStockForm.formData.submissionData;
    }

    const newFormData = validateForm({
      form: refurbishmentForDemoKitForm,
      formName: 'refurbishmentForDemoKitForm',
      model: refurbishmentForDemoKitModel,
    });
    const refurbishmentForSingleLoanNewFormData = validateForm({
      form: refurbishmentForSingleLoanForm,
      formName: 'refurbishmentForSingleLoanForm',
      model: refurbishmentForSingleLoanModel,
    });
    if (refurbishmentForDemoKit) {
      if (!newFormData.isFormValid) {
        this.setState({ refurbishmentForDemoKitForm: newFormData });
      } else {
        objectToSend.costFactorPricePerLoanForKit = refurbishmentForDemoKitForm.formData.costFactorPricePerLoanForKit
          .toString()
          .includes(',')
          ? refurbishmentForDemoKitForm.formData.costFactorPricePerLoanForKit.replace(',', '.')
          : refurbishmentForDemoKitForm.formData.costFactorPricePerLoanForKit;
        objectToSend.costFactorPricePerDayDelayForKit =
          refurbishmentForDemoKitForm.formData.costFactorPricePerDayDelayForKit.toString().includes(',')
            ? refurbishmentForDemoKitForm.formData.costFactorPricePerDayDelayForKit.replace(',', '.')
            : refurbishmentForDemoKitForm.formData.costFactorPricePerDayDelayForKit;
      }
    }
    if (refurbishmentForSingleLoan) {
      if (!refurbishmentForSingleLoanNewFormData.isFormValid) {
        this.setState({ refurbishmentForSingleLoanForm: refurbishmentForSingleLoanNewFormData });
      } else {
        objectToSend.costFactorPerLoanForSingleLoan =
          refurbishmentForSingleLoanForm.formData.costFactorPerLoanForSingleLoan.toString().includes(',')
            ? refurbishmentForSingleLoanForm.formData.costFactorPerLoanForSingleLoan.replace(',', '.')
            : refurbishmentForSingleLoanForm.formData.costFactorPerLoanForSingleLoan;
        objectToSend.numberOfDaysSingleLoanFree =
          refurbishmentForSingleLoanForm.formData && refurbishmentForSingleLoanForm.formData.numberOfDaysSingleLoanFree;
      }
    }
    if (this.state.emailAdress !== '') {
      this.setState({ pickUpRequestEmailError: strings.clickOnAdd });
      this.props.toggleActionMessage(true, 'error', 'clickOnAdd');
      scrollToTop(500);
      return;
    }
    if ((showPickUpRequestButton && newEmailAddress.length <= 0)) {
      this.setState({ pickUpRequestEmailError: strings.requiredErrorMessage });
      this.props.toggleActionMessage(true, 'error', 'requiredErrorMessage');
      scrollToTop(500);
      return;
    }
    
    if(newKitDismantleEmailArray.length){
      objectToSend.kitLoanHelpForDismantleEmails = newKitDismantleEmailArray.map(e => e.email).filter(e => e !== "" && e !== undefined && e !== null)  
    }else{
      objectToSend.kitLoanHelpForDismantleEmails = []
    }
    if(newCourseDismantleEmailArray.length){
      objectToSend.courseLoanHelpForDismantleEmails = newCourseDismantleEmailArray.map(e => e.email).filter(e => e !== "" && e !== undefined && e !== null)
    }else{
      objectToSend.courseLoanHelpForDismantleEmails = []  
    }
  
    const tempArray = newEmailAddress && newEmailAddress.map(item => item.email);
    objectToSend.logisticProviderEmails = tempArray;
    objectToSend.logisticProviderCcEmails = newCcEmailAddressArray ? newCcEmailAddressArray.map(item => item.email) : [];
    objectToSend.pickUpComment = showAdditionalFieldsForm.formData.pickUpComment;
    objectToSend.trackingNumber = showAdditionalFieldsForm.formData.trackingNumber;
    objectToSend.crateId = showAdditionalFieldsForm.formData.crateId;
    let isEmptyFields = false;
    let errorCategoryQuestionIds = [];
    let errorDateToShow = null;
    if (stockWorkflowSettings.length > 0) {
      if (this.isValid()) {
        const automizedMilestoneWorkflowConfig =stockWorkflowSettings[0] && stockWorkflowSettings[0]['stockWorkflowSettingDTO'] && stockWorkflowSettings[0]['stockWorkflowSettingDTO'].find(workflow => workflow.categoryQuestionId == 1);
        const automizedMilestoneWorkflowConfigWithoutCheckedDate =stockWorkflowSettings[0] && stockWorkflowSettings[0]['stockWorkflowSettingDTO'] && stockWorkflowSettings[0]['stockWorkflowSettingDTO'].find(workflow => workflow.categoryQuestionId == 36);
        if ((automizedMilestoneWorkflowConfig && automizedMilestoneWorkflowConfig.categoryAnswer == 'yes') && (automizedMilestoneWorkflowConfigWithoutCheckedDate && automizedMilestoneWorkflowConfigWithoutCheckedDate.categoryAnswer == 'yes')){
          scrollToTop(500);
          return this.props.toggleActionMessage(true, 'error', 'warningForAutomizedOptions');
        }
        stockWorkflowSettings.forEach(stockWorkflowSetting => {
          stockWorkflowSetting.stockWorkflowSettingDTO.forEach(item => {
            const isLeadTime = item.categoryQuestionId === 17 || item.categoryQuestionId === 18;
            const erpInputckeck =
              item.categoryQuestionId === 22 || item.categoryQuestionId === 23 || item.categoryQuestionId === 24 || item.categoryQuestionId === 39;
            let categoryAnswer = item.categoryAnswer;
            const { designation } = this.props.businessUnitFullValue;

            if (isLeadTime && (!categoryAnswer.trim() || categoryAnswer.trim() === '0') && designation !== 'RMS_L_L') {
              isEmptyFields = true;
              errorCategoryQuestionIds.push(item.categoryQuestionId);
            }
            if (
              erpInputckeck &&
              (!categoryAnswer.trim() || categoryAnswer.trim() === '0') &&
              this.state.erpCheckStatus
            ) {
              isEmptyFields = true;
              errorCategoryQuestionIds.push(item.categoryQuestionId);
            }
            if (item.categoryQuestionId === 13 && item.categoryAnswer === 'yes' && isRMSLL && !dateToShow) {
              errorDateToShow = 'requiredErrorMessage';
            }
            if (item.categoryQuestionId === 25 && item.categoryAnswer === 'yes' && !currencyId) {
              isEmptyFields = true;
              errorCategoryQuestionIds.push(item.categoryQuestionId);
            }
            if (item.categoryQuestionId === 13 && item.categoryAnswer !== 'yes') {
              objectToSend.activeUntilDate = null;
            }
            if (item.questionType === 'checkbox') {
              categoryAnswer = item.categoryAnswer === 'yes' ? 'yes' : 'no';
            }
            if(item.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason && this.state.showAdaptedLaonReaons){
              categoryAnswer =  item.categoryAnswer.includes("yes") ? "yes" : "no";
            }
            const objectToStore = {
              categoryQuestionId: item.categoryQuestionId,
              categoryAnswer,
            };
            objectToSend.stockWorkflowSaveDTO.push(objectToStore);
          });
        });
        const {showAdaptedLaonReaons, selectAdapedLoanReasons} = this.state
        if(showAdaptedLaonReaons && selectAdapedLoanReasons.length === 0){
          scrollToTop(500);
          this.props.toggleActionMessage(true, 'error', 'adaptLoanValidationMessage');
          return
        }
        let finalAdaptLoanReasonAnswer = [...selectAdapedLoanReasons];
        if(showAdaptedLaonReaons && selectAdapedLoanReasons.length >= 1){
          let {stockWorkflowSaveDTO} = objectToSend
          stockWorkflowSaveDTO = stockWorkflowSaveDTO.map((workflow) => {
            let {categoryAnswer, categoryQuestionId} = workflow
            if((categoryAnswer === "yes" || categoryAnswer?.includes("yes")) && categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason){
              const removeDuplicates = new Set(selectAdapedLoanReasons)
              finalAdaptLoanReasonAnswer = [...removeDuplicates]
              categoryAnswer = `${"yes"} - ${JSON.stringify(finalAdaptLoanReasonAnswer)}`
              return {...workflow, categoryAnswer}
            }
            return {...workflow}
          })
          objectToSend.stockWorkflowSaveDTO = stockWorkflowSaveDTO;
        }

        if (
          isEmptyFields ||
          errorDateToShow ||
          (refurbishmentForDemoKit && !newFormData.isFormValid) ||
          (refurbishmentForSingleLoan && !refurbishmentForSingleLoanNewFormData.isFormValid)
        ) {
          this.setState({ errorDateToShow });
          this.setState({ errorInputs: errorCategoryQuestionIds });
          this.setState({ error: strings.requiredErrorMessage });
          this.props.toggleActionMessage(true, 'error', 'requiredErrorMessage');
        } else {
          await this.props.createStockWorkflow(objectToSend, this.props.stockId, isRMSLL);
          this.props.fetchWorkflowData(this.props.stockId); 
          this.props.getNewRoles(this.props.stockId);
          
        }
      } else {
        this.props.toggleActionMessage(true, 'error', 'invalidNumberError');
      }
    } else {
      this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
    }
    scrollToTop(500);
  };

  isValid = () => {
    const { stockWorkflowSettings } = this.state;
    return stockWorkflowSettings
      .map(stockWorkflowSetting => {
        return stockWorkflowSetting.stockWorkflowSettingDTO.filter(item => {
          return item.questionType === 'text';
        });
      })
      .filter(stockWorkflowSetting => {
        return stockWorkflowSetting.length > 0;
      })
      .every(stockWorkflowSetting => {
        return stockWorkflowSetting.every(item => {
          if ((item.categoryQuestionId === 31 || item.categoryQuestionId === 32) && item.categoryAnswer !== '') {
            return !Validators.required(item.categoryAnswer, strings.requiredErrorMessage);
          } else {
            return !Validators.number(item.categoryAnswer, strings.invalidNumberError);
          }
        });
      });
  };

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  externallyManagedStockModel = () => {
    const { orderTypesList, billingsList, distributionChannelList } = this.state;
    const model = [...externallyManagedStockModel];
    model[0].options = orderTypesList;
    model[1].options = distributionChannelList;
    model[4].options = billingsList;
    return model;
  };

  handleForm = ({ values, field, formName }) => {
    if (field == 'partner') {
      values['partner'] = values['partner'].toUpperCase();
      this.setState(prevState => ({
        [formName]: {
          ...prevState[formName],
          formData: {
            ...((prevState[formName] && prevState[formName].formData) || {}),
            ...values,
          },
        },
      }));
    }
    if (field == 'submissionData') {
      values['submissionData'] = values['submissionData'].toUpperCase();
      this.setState(prevState => ({
        [formName]: {
          ...prevState[formName],
          formData: {
            ...((prevState[formName] && prevState[formName].formData) || {}),
            ...values,
          },
        },
      }));
    }
    if (
      field === 'costFactorPricePerLoanForKit' ||
      field === 'costFactorPricePerDayDelayForKit' ||
      field === 'costFactorPerLoanForSingleLoan'
    ) {
      const regexEn = /^(\d+(\.\d{0,3})?|\.?\d{1,2})?$/;
      const regexDe = /^(\d+(\,\d{0,3})?|\,?\d{1,2})?$/;
      const regex = this.props.language.language === 'en' ? regexEn : regexDe;
      const isValid = regex.test(
        values.costFactorPricePerLoanForKit ||
          values.costFactorPricePerDayDelayForKit ||
          values.costFactorPerLoanForSingleLoan,
      );
      if (
        isValid ||
        values.costFactorPricePerLoanForKit === '' ||
        values.costFactorPricePerDayDelayForKit === '' ||
        values.costFactorPerLoanForSingleLoan === ''
      ) {
        return this.setState(prevState => ({
          [formName]: {
            ...prevState[formName],
            formData: {
              ...((prevState[formName] && prevState[formName].formData) || {}),
              ...values,
            },
          },
        }));
      }
    } else {
      this.setState(prevState => ({
        [formName]: {
          ...prevState[formName],
          formData: {
            ...((prevState[formName] && prevState[formName].formData) || {}),
            ...values,
          },
        },
      }));
    }
  };

  handleOnChangeEmail = (e, type) => {
    if (type === 'ccEmail') {
      this.setState({ ccEmailAddress: e, ccEmailError: '' });
    } else if (type === 'kitDismantleEmail') {
      this.setState({ kitDismantleEmail: e, kitDismantleEmailError: '' });
    } else if (type === 'courseDismantleEmail') {
      this.setState({ courseDismantleEmail: e, courseDismantleEmailError: '' });
    } else {
      this.setState({ emailAdress: e, pickUpRequestEmailError: '' });
    }
  };

  handleOnAdd = type => {
    if (type === 'ccEmail') {
      const { ccEmailAddress } = this.state;
      if (ccEmailAddress === '') {
        this.setState({ ccEmailError: 'requiredErrorMessage' });
        return;
      }
      if (ccEmailAddress !== '') {
        const validator = Validators.email(ccEmailAddress, strings.invalidEmailError);
        if (validator.error === true) {
          this.setState({ ccEmailError: strings.invalidEmailError });
          return;
        }
      }
      let tempvalues = [...this.state.newCcEmailAddressArray, { id: Date.now(), email: this.state.ccEmailAddress }];
      this.setState({ newCcEmailAddressArray: tempvalues, ccEmailAddress: '', ccEmailError: '' });
    } else {
      const { emailAdress } = this.state;
      if (emailAdress === '') {
        this.setState({ pickUpRequestEmailError: 'requiredErrorMessage' });
        return;
      }
      const validator = Validators.email(emailAdress, strings.invalidEmailError);
      if (validator.error === true) {
        this.setState({ pickUpRequestEmailError: 'invalidEmailError' });
        return;
      }

      let tempvalues = [...this.state.newEmailAddress, { id: Date.now(), email: this.state.emailAdress }];
      this.setState({ newEmailAddress: tempvalues, emailAdress: '', pickUpRequestEmailError: '' });
    }
  };

  handleAddKitDismantleEmail = () => {
    const { kitDismantleEmail } = this.state;
    if (kitDismantleEmail === '') {
      this.setState({ kitDismantleEmailError: 'requiredErrorMessage' });
      return;
    }
    if (kitDismantleEmail !== '') {
      const validator = Validators.email(kitDismantleEmail, strings.invalidEmailError);
      if (validator.error === true) {
        this.setState({ kitDismantleEmailError: strings.invalidEmailError });
        return;
      }
    }
    let tempvalues = [...this.state.newKitDismantleEmailArray, { id: Date.now(), email: this.state.kitDismantleEmail }];
    this.setState({ newKitDismantleEmailArray: tempvalues, kitDismantleEmail: '', kitDismantleEmailError: '' });
  };

  handleAddCourseDismantleEmail = () => {
    const { courseDismantleEmail } = this.state;
    if (courseDismantleEmail === '') {
      this.setState({ courseDismantleEmailError: 'requiredErrorMessage' });
      return;
    }
    if (courseDismantleEmail !== '') {
      const validator = Validators.email(courseDismantleEmail, strings.invalidEmailError);
      if (validator.error === true) {
        this.setState({ courseDismantleEmailError: strings.invalidEmailError });
        return;
      }
    }
    let tempvalues = [...this.state.newCourseDismantleEmailArray, { id: Date.now(), email: this.state.courseDismantleEmail }];
    this.setState({ newCourseDismantleEmailArray: tempvalues, courseDismantleEmail: '', courseDismantleEmailError: '' });
  };

  handleOnChangeExstingValue = (e, index, type) => {
    if (type === 'ccEmail') {
      const { newCcEmailAddressArray } = this.state;
      let newCcChangedValues = [...newCcEmailAddressArray];
      newCcChangedValues[index].email = e;
      this.setState({ newCcEmailAddressArray: newCcChangedValues });
    } else if (type === 'kitDismantleEmail') {
      const { newKitDismantleEmailArray } = this.state;
      let newKitChangedValues = [...newKitDismantleEmailArray];
      newKitChangedValues[index].email = e;
      this.setState({ newKitDismantleEmailArray: newKitChangedValues });
    } else if (type === 'courseDismantleEmail') {
      const { newCourseDismantleEmailArray } = this.state;
      let newCourseChangedValues = [...newCourseDismantleEmailArray];
      newCourseChangedValues[index].email = e;
      this.setState({ newCourseDismantleEmailArray: newCourseChangedValues });
    } else {
      const { newEmailAddress } = this.state;
      let tempValues = [...newEmailAddress];
      tempValues[index].email = e;
      this.setState({ newEmailAddress: tempValues });
    }
  };

  deleteEmailAdress = (index, type) => {
    if (type === 'ccEmail') {
      const { newCcEmailAddressArray } = this.state;
      const tempValues = newCcEmailAddressArray.filter((item, i) => index !== i);
      this.setState({ newCcEmailAddressArray: tempValues });
    } else if (type === 'kitDismantleEmail') {
      const { newKitDismantleEmailArray } = this.state;
      const tempValues = newKitDismantleEmailArray.filter((item, i) => index !== i);
      this.setState({ newKitDismantleEmailArray: tempValues });
    } else if (type === 'courseDismantleEmail') {
      const { newCourseDismantleEmailArray } = this.state;
      const tempValues = newCourseDismantleEmailArray.filter((item, i) => index !== i);
      this.setState({ newCourseDismantleEmailArray: tempValues });
    } else {
      const { newEmailAddress } = this.state;
      const tempValues = newEmailAddress.filter((item, i) => index !== i);
      this.setState({ newEmailAddress: tempValues });
    }
  };

  checkStockController = (user, stockId) => {
    let isPermission = true;
    if (user.admin) {
      return false;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (role.roleName.toLowerCase() === 'stock controller' && stockId === role.stockId) {
          isPermission = false;
        }
      });
    }
    return isPermission;
  };

 renderDescription = (description) => {
    return description ? (
      <div className="position-relative">
        <p className="input-icon cursor-pointer mr-1 mt-1">i</p>
        <p className="info-desc position-absolute p-1">{description}</p>
      </div>
    ) : null;
  };
  
  checkbox = (item, section) => {
    const {
      dateToShow,
      errorDateToShow,
      externallyManagedStockForm,
      externallyManagedStockStatus,
      erpCheckStatus,
      refurbishmentForDemoKit,
      refurbishmentForSingleLoan,
      refurbishmentForDemoKitForm,
      refurbishmentForSingleLoanForm,
      showPickUpRequestButton,
      error,
      newEmailAddress,
      showAdditionalFieldsForm,
      showAdditionalFields,
      pickUpRequestEmailError,
      ccEmailError,
      showAdaptedLaonReaons,
      showKitDismantleEmail,
      showCourseDismantleEmail,
    } = this.state;
    const { canEditStock, language, businessUnitFullValue, stockId } = this.props;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    const isRMSZMCC = designation === 'RMS_ZMCC';
    const disabledIds = [7, 8, 9, 15, 17, 10, 11, 12, 16, 18, 21, 25, 27, 28, 30, 33];
    const isDisabledForPartner = !canEditStock || (isRMSLL && disabledIds.includes(item.categoryQuestionId));
    const isERPVisibleForPartner =
      item.categoryQuestionId === 21 ? !this.state.isAdmin || (this.state.isAdmin && isRMSLL) : isDisabledForPartner;
    const showCheckbox = this.state.isAdmin;
    const disableEmailInput = newEmailAddress.length >= 5;
    const user = fetchFromStorage(identifiers.user);
    const disableFields = this.checkStockController(user, stockId);
    if (isRMSLL && !isRMSZMCC && item.categoryQuestionId === 13) {
      return (
        <div className='d-flex align-items-center'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={strings.activeUntil}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          <DateInput
            label=''
            field='date'
            placeholder={strings.pleaseSelectDate + ' ...'}
            mainContainerStyle='ml-3'
            onChange={this.onChangeDate}
            minDate={new Date()}
            selectedDate={dateToShow ? new Date(dateToShow) : null}
            locale={getLocale(language.locale)}
            isRequired={true}
            error={strings[errorDateToShow]}
            disabled={item.categoryAnswer !== 'yes'}
          />
        </div>
      );
    } else if (item.categoryQuestionId === 25) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          <div className={'text-input-container col-12 col-sm-6'}>
            <div className={'col-6 text-field'}>
              <Dropdown
                label={'Currency'}
                placeholder='Please Select'
                field='currencyId'
                data={this.state.currency}
                isRequired={true}
                options={[]}
                hasSection={false}
                multiSelect={false}
                filter={false}
                idKey='id'
                key={item.categoryQuestionId}
                displayValue={[{ key: 'value', separator: '' }]}
                selectAllPlaceholder='pleaseSelect'
                onChange={this.onChangeCurrency}
                fullValue={this.state.selectedCurrency}
                disabled={item.categoryAnswer !== 'yes'}
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              />
            </div>
          </div>
        </div>
      );
    } else if (item.categoryQuestionId === 26) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner || !showCheckbox || !erpCheckStatus}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {externallyManagedStockStatus && (
            <div className={'text-input-container col-12 col-md-6'}>
              <div className={'col-12 text-field'}>
                <FormComponent
                  formName='externallyManagedStockForm'
                  model={this.externallyManagedStockModel()}
                  formValue={externallyManagedStockForm.formData}
                  onChange={this.handleForm}
                  disableForm={!erpCheckStatus || !this.state.isAdmin}
                />
              </div>
            </div>
          )}
        </div>
      );
    } else if (item.categoryQuestionId === 27) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {refurbishmentForDemoKit && (
            <>
              <div className={'text-input-container col-12 col-md-6'}>
                <div className={'col-12 text-field'}>
                  <FormComponent
                    formName='refurbishmentForDemoKitForm'
                    model={refurbishmentForDemoKitModel}
                    formValue={refurbishmentForDemoKitForm.formData}
                    onChange={this.handleForm}
                    disableForm={disableFields}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else if (item.categoryQuestionId === 28) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {refurbishmentForSingleLoan && (
            <>
              <div className={'text-input-container col-12 col-md-6'}>
                <div className={'col-12 text-field'}>
                  <FormComponent
                    formName='refurbishmentForSingleLoanForm'
                    model={refurbishmentForSingleLoanModel}
                    formValue={refurbishmentForSingleLoanForm.formData}
                    onChange={this.handleForm}
                    disableForm={disableFields}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else if (item.categoryQuestionId === 30) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {showPickUpRequestButton && (
            <AddEmailAddress
              onChange={(e, type) => this.handleOnChangeEmail(e, type)}
              onAdd={type => this.handleOnAdd(type)}
              exstingEmailAdress={this.state.exstingEmailAdress}
              newEmailAddress={this.state.newEmailAddress}
              emailAdress={this.state.emailAdress}
              handleOnChangeExstingValue={(e, index, type) => this.handleOnChangeExstingValue(e, index, type)}
              deleteEmail={(index, type) => this.deleteEmailAdress(index, type)}
              error={pickUpRequestEmailError}
              disabled={disableEmailInput}
              newCcEmailAddressArray={this.state.newCcEmailAddressArray}
              ccEmailAddress={this.state.ccEmailAddress}
              ccEmailError={ccEmailError}
            />
          )}
        </div>
      );
    } else if (item.categoryQuestionId === 35) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {showAdditionalFields && (
            <FormComponent
              formName='showAdditionalFieldsForm'
              model={additionalFilesModel}
              formValue={showAdditionalFieldsForm.formData}
              onChange={this.handleForm}
              // disableForm={!erpCheckStatus || !this.state.isAdmin}
            />
          )}
        </div>
      );
    } else if (item.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason) {
      let categoryQuestionAnswer = item?.categoryAnswer.toString();
      return (
        <div className='d-flex flex-column' style={{ maxWidth: 700 }}>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            label={item.categoryQuestionName}
            selected={categoryQuestionAnswer?.includes('yes') ? true : false}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
            disabled={isDisabledForPartner}
          />
          {this.renderDescription(item.description)}
          </div>
          {showAdaptedLaonReaons && this.renderAdaptedLoanReason()}
        </div>
      );
    } else if (item.categoryQuestionId === 14) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {showKitDismantleEmail && (
            <AddKitLoanDismantleEmail
              kitDismantleEmail={this.state.kitDismantleEmail}
              kitDismantleEmailError={this.state.kitDismantleEmailError}
              newKitDismantleEmailArray={this.state.newKitDismantleEmailArray}
              onChange={(e, type) => this.handleOnChangeEmail(e, type)}
              handleOnChangeExstingValue={(e, index, type) => this.handleOnChangeExstingValue(e, index, type)}
              onAdd={this.handleAddKitDismantleEmail}
              deleteEmail={(index, type) => this.deleteEmailAdress(index, type)}
            />
          )}
        </div>
      );
    } else if (item.categoryQuestionId === 16) {
      return (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row"> 
          <Checkbox
            key={item.categoryQuestionId}
            disabled={isDisabledForPartner}
            label={item.categoryQuestionName}
            selected={item.categoryAnswer === 'yes'}
            onChange={this.handleCheckbox(item.categoryQuestionId)}
          />
          {this.renderDescription(item.description)}
          </div>
          {showCourseDismantleEmail && (
            <AddCourseLoanDismantleEmail
              courseDismantleEmail={this.state.courseDismantleEmail}
              courseDismantleEmailError={this.state.courseDismantleEmailError}
              newCourseDismantleEmailArray={this.state.newCourseDismantleEmailArray}
              onChange={(e, type) => this.handleOnChangeEmail(e, type)}
              handleOnChangeExstingValue={(e, index, type) => this.handleOnChangeExstingValue(e, index, type)}
              onAdd={this.handleAddCourseDismantleEmail}
              deleteEmail={(index, type) => this.deleteEmailAdress(index, type)}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row"> 
        <Checkbox
          key={item.categoryQuestionId}
          disabled={isERPVisibleForPartner || isDisabledForPartner}
          label={item.categoryQuestionId===33?(this.props.language.language==="en"?"Activate Demo Ready Checklist":"Checkliste “Demo Ready”"):item.categoryQuestionName}
          selected={item.categoryAnswer === 'yes'}
          onChange={this.handleCheckbox(item.categoryQuestionId)}
        />
        {this.renderDescription(item.description)}
        </div>
      );
    }
  };

  //RENDERING REASON FOR LOANS AS TABLE
  renderAdaptedLoanReason = () => {
    const {modelFormForAdaptLoanReasons} = this.state
    const { canEditStock, language, businessUnitFullValue, stockId } = this.props;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    const isRMSZMCC = designation === 'RMS_ZMCC';
    const disabledIds = [7, 8, 9, 15, 17, 10, 11, 12, 16, 18, 21, 25, 27, 28, 30, 33];    
    const isDisabledForPartner = !canEditStock || (isRMSLL && disabledIds.includes(WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason));
    const checkboxes = modelFormForAdaptLoanReasons.map((reasonCheckboxForm, index) => {
      return (
        <Checkbox
          key={reasonCheckboxForm.reasonForLoanId}
          label={strings[reasonCheckboxForm.label]}
          selected={this.state.selectAdapedLoanReasons.indexOf(reasonCheckboxForm.reasonForLoanId) !== -1}
          onChange={this.handleCheckbox(WORKFLOW_CATEGORY_QUESTION_IDS.adaptLoanReason, { reasonForLoanId: reasonCheckboxForm.reasonForLoanId })}
          disabled={isDisabledForPartner}
        />
      );
    });

    // Split checkboxes into rows with two checkboxes each
    const checkboxRows = [];
    for (let i = 0; i < checkboxes.length; i += 2) {
      checkboxRows.push(
        <tr key={i / 2}>
          <td>{checkboxes[i]}</td>
          <td>{i + 1 < checkboxes.length ? checkboxes[i + 1] : null}</td>
        </tr>
      );
    }

    return (
      <table style={{marginLeft: 30}}>
        <tbody>{checkboxRows}</tbody>
      </table>
    );
  };

  inputFieldComponent = (item, section) => {
    const { canEditStock, businessUnitFullValue } = this.props;
    const { errorInputs, erpCheckStatus } = this.state;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    const disabledIds = [7, 8, 9, 15, 17, 10, 11, 12, 16, 18, 22, 23, 24, 31, 32, 39];
    const isDisabledForPartner = !canEditStock || (isRMSLL && disabledIds.includes(item.categoryQuestionId));
    const hasError = errorInputs.find(error => error === item.categoryQuestionId);

    let disablForErpInterfaceIds = [22, 23, 24, 31, 32, 39];
    let disablForErpInterface = false;
    if (disablForErpInterfaceIds.includes(item.categoryQuestionId)) {
      if (!erpCheckStatus || !this.state.isAdmin) {
        disablForErpInterface = true;
      }
    }
    const hideDayslabelForErp = disablForErpInterfaceIds.includes(item.categoryQuestionId);
    let showOptionalLabel = true;
    const optionalLbelIds = [32];
    if (optionalLbelIds.includes(item.categoryQuestionId)) {
      showOptionalLabel = false;
    }

    return (
      <div className={'text-input-container col-12 col-sm-6'}>
        <div className={'col-12 text-field'}>
          <InputField
            isRequired={showOptionalLabel}
            disabled={isDisabledForPartner || disablForErpInterface}
            label={item.categoryQuestionName}
            key={item.categoryQuestionId}
            onChange={this.handleInput(item.categoryQuestionId)}
            value={item.categoryAnswer}
            validators={[{ check: Validators.number, message: strings.invalidNumberError }]}
            error={hasError && strings.requiredErrorMessage}
          />
        </div>
        <div className={cn('text-input-label-view', { 'mb-4': hasError })}>{!hideDayslabelForErp && strings.days}</div>
      </div>
    );
  };

  mapSectionComponents = section =>
    section.stockWorkflowSettingDTO.map((stockWorkflow, key) => (
      <div className={'input-container'} key={key}>
        {stockWorkflow.questionType === 'checkbox' && this.checkbox(stockWorkflow, section)}
        {stockWorkflow.questionType === 'text' && this.inputFieldComponent(stockWorkflow, section)}
      </div>
    ));

  mapWorkflowDataModel = stockWorkflowSettings =>
    stockWorkflowSettings &&
    stockWorkflowSettings.map((section, key) => (
      <div key={key}>
        <p className='title'>
          {this.props.businessUnitFullValue && this.props.businessUnitFullValue.designation === 'RMS_L_L'
            ? strings.llPartnerStatus:
            this.props.businessUnitFullValue && this.props.businessUnitFullValue.designation === 'RMS_ZMCC'
            ? strings.zmccStatus
            : section.categoryName}
        </p>
        {this.mapSectionComponents(section)}
      </div>
    ));

  onChangeDate = date => {
    this.setState({ dateToShow: date, errorDateToShow: null });
  };

  showAddSlotModal = () => {
    this.props.getPendingKitsListByWarehouseWhereSlotIsNotAvailable(this.props.stockId);
    $('#system-availability-slot').modal('show');
    this.setState({isEditClicked: false})
  };

  onEditClickOFAvailabilitySlot = (item) => {
    this.props.getAllKitsListByWarehouse(this.props.stockId);
    $('#system-availability-slot').modal('show');
    this.setState({editingAvailabilitySlotData: item, isEditClicked: true})
  }

  onEditClose = (value) => {
    this.setState({isEditClicked: value})
  }

  render() {
    const { stockWorkflowSettings, editingAvailabilitySlotData } = this.state;
    const { canEditStock, businessUnitFullValue } = this.props;
    const {designation} = businessUnitFullValue || {};
    const isZMCCWarehouse = designation == 'RMS_ZMCC';
    return (
      <div className='workflow-container'>
        <div className='container-fluid form-container'>
          {stockWorkflowSettings && !stockWorkflowSettings.length && (
            <div className='text-center'> {strings.noDataFoundText}</div>
          )}{
            isZMCCWarehouse && (<>
                <div className='col-12 row'>
                  <div className='col-2 mt-2 p-0 solt-availabilty-title'>{strings.systemAvailability}</div>
                  <div className='col-2'><Button value={strings.addSlot} 
                      iconName='plus'
                      bgFill={false}
                      onClick={this.showAddSlotModal} 
                    /></div>
                    <AddEditSlotPopup
                    id= 'system-availability-slot'
                    editingAvailabilitySlotData={editingAvailabilitySlotData}
                    isEditMode={this.state.isEditClicked}
                    onEditClose={(e) => this.onEditClose(e)}
                    />
              </div>
              <div className='mt-5'>
                <AvailabilitySlotList 
                  availabilitySlotList={this.props.availabilitySlotList}
                  onEditClickOFAvailabilitySlot={(item) => this.onEditClickOFAvailabilitySlot(item)}
                  language={this.props.language.language}
                  warehouseId={this.props.stockId}
                  deleteAvailabiltySlot={this.props.deleteAvailabiltySlot}
                />
              </div>
          </>
          )}
          {this.mapWorkflowDataModel(stockWorkflowSettings)}
          {stockWorkflowSettings && stockWorkflowSettings.length > 0 && canEditStock && (
            <div className={'buttons-container'}>
              <CreateStockActionButtons onSave={this.saveStockWorkflow} onCancel={this.cancelStockCreation} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Workflows;

import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { useToDoList } from './ToDoList.hook';

export const ToDoList = (props) => {
  const { formValue } = props;
  const { todosListForm } = formValue;
  const {
    todosList,
    todoText,
    onChangeInput,
    addIntoTodosList,
    removeATodoFromList,
    errorMsg
  } = useToDoList(props);
  
    return(
        <>
        <h1 className='mt-4 zmcc-title-heading'>{strings.toDoList.toUpperCase()}</h1>
        <div className='p-0 mt-3'>
        {todosListForm.formData.zmccTodosListDataList && todosListForm.formData.zmccTodosListDataList.map((item,index) => (
            <div className='row m-0 mb-4' key={index}>
               
                <div className='col-sm-5 border-for-todo-list'>
                <p className='zmcc-todo-list-font-style'>
                  {item.title === 'accomodationNeeded' ? strings.bookAccomodation: ''}
                </p>
                <p className='zmcc-todo-list-font-style'>
                  {item.title  === 'flightNeeded'? strings.bookflight : ''}
                </p>
                <p className='zmcc-todo-list-font-style'>
                  {item.title === 'visitMuseumOfOptics' ? strings.visitMuseum:'' }
                </p>

                  {item.zmccTodosText}
                  </div>
                <div className='col-sm-1 border-for-todo-list'>
                <button
                    type='button'
                    className='delete-button delete-icon-name'
                    onClick={() => removeATodoFromList(index)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' className='mr-2' />
                    {strings.delete}
                  </button>
                </div>
            </div>
        ))}
        </div>
           
        <div className='row mt-4'>
        <div className='col-sm-4 mt-2'>
        <InputField
          label={''}
          field='todoText'
          placeholder=''
          isRequired={false}
          type='text'
          validators={[]}
          value={todoText}
          error={errorMsg}
          onChange={onChangeInput}
          hideOptionalText={true}
        />
      </div>
      <div className='col-auto mt-2'>
        <Button
          value={strings.addMoreButtonText}
          onClick={() => addIntoTodosList(todoText)}
          bgFill={false}
          iconName='plus'
          disabled={todosListForm.formData.zmccTodosListDataList && todosListForm.formData.zmccTodosListDataList.length === 20}
        />
      </div>
        </div>
      </>
    )
}

export default ToDoList;
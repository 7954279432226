import { connect } from 'react-redux';

import {
  addItemToCart,
  fetchCartDataFromLocalStorage,
  removeItemFromCart,
  updateItem,
} from 'modules/LoanList/LoanListActions';
import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { reserveKit } from 'modules/KitLoan/KitLoanActions';

import Calendar from './Calendar';
import {
  addBookings,
  removeBooking,
  fetchAvailableKitList,
  fetchStocksForUser,
  checkAvailability,
  fetchCalendarFilters,
  getStockInformation,
  sendKitSignalizeDemand,
  fetchSystemClass,
  labsLocationAdvanceBookingError,
  fetchClandarBlocking,
  zmccCreateSlotAvailaibility,
  zmccRemoveSlotAvailaibility,
  createZmccBooking,
  updateZmccDatesFilters
} from './CalendarActions';

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    calendar: state.calendar,
    loanList: state.loanList,
    user: state.authReducer.user,
    appActions: state.appActionsReducer,
    countries: state.masterDataReducer.countries,
    loanTypes: state.masterDataReducer.loanTypes,
    categoriesAndSystems: state.masterDataReducer.systemClassWithStock,
    filters: state.calendar.filters,
    labsLocationFilters: state.calendar.labsLocationFilters,
    zmccCalendarFilters: state.calendar.zmccFilters,
    labsLocationSystemClasses: state.bookingReducer.labsLocationSystemClasses,
    transactionStatuses: state.bookingReducer.transactionStatuses,
    labsLocationTransactionStatuses: state.bookingReducer.labsLocationTransactionStatuses
  };
};

export default connect(mapStateToProps, {
  addItemToCart,
  removeItemFromCart,
  addBookings,
  removeBooking,
  fetchAvailableKitList,
  fetchMasterData,
  updateItem,
  toggleActionMessage,
  fetchStocksForUser,
  fetchMasterDataPost,
  fetchCartDataFromLocalStorage,
  checkAvailability,
  changeLoader,
  fetchCalendarFilters,
  getStockInformation,
  sendKitSignalizeDemand,
  fetchSystemClass,
  labsLocationAdvanceBookingError,
  fetchClandarBlocking,
  zmccCreateSlotAvailaibility,
  zmccRemoveSlotAvailaibility,
  reserveKit,
  createZmccBooking,
  updateZmccDatesFilters,
})(Calendar);

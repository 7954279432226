import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Loader from 'library/common/commonComponents/Loader';
import Message from 'library/common/commonComponents/Message';
import { getTransactionMessages } from '../../TransactionDetailsActions';

export class Messages extends Component {
  state = {
    loading: true,
    loadMoreComments: false,
    loadMoreDamage: false,
    loadMorePickupRequests: false,
  };

  componentDidMount() {
    const isZmccTrans = this.props.match && this.props.match.url.includes('isZmcc');
    const zmccTransactionId = this.props.match && this.props.match.params.id;
    this.props.getTransactionMessages({
      page: 1,
      type: 'comment',
      transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
    });
    this.props
    .getTransactionMessages({
      page: 1,
      type: 'pickup-request',
      transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
    });
    this.props
      .getTransactionMessages({
        page: 1,
        type: 'damage-loss-report',
        transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
      })
      .then(res => {
        this.setState({ loading: false });
      });
  }

  onLoadMore = type => () => {
    let page = get(this.props, 'commentsData.number', 1);
    let stateField = 'loadMoreComments';
    if (type === 'damage-loss-report') {
      page = get(this.props, 'damageLossReportData.number', 1);
      stateField = 'loadMoreDamage';
    }
    else if (type === 'reuest-pickup'){
      page = get(this.props, 'pickupRequestsData.number', 1);
      stateField = 'loadMorePickupRequests';
    }
    this.setState({ [stateField]: true }, () => {
      this.props
        .getTransactionMessages({
          type,
          transactionsId: this.props.transactionsId,
          page: page + 1,
        })
        .then(res => {
          this.setState({ loadMoreComments: false, loadMoreDamage: false });
        });
    });
  };

  renderMessages(data, type) {
    let title = strings.messages;
    let emptyMessage = strings.noMessage;
    let dataTest = 'transaction-details-comments';
    let dataTestButton = 'comments-load-more-button';
    let stateField = 'loadMoreComments';
    if (type === 'damage-loss-report') {
      title = strings.damageOrLossReports;
      emptyMessage = strings.noMessageDamage;
      stateField = 'loadMoreDamage';
      dataTest = 'transaction-details-damage-loss-report';
      dataTestButton = 'damage-load-more-button';
    }
    else if(type === 'request-pickup'){
      title = strings.pickupRequest;
      emptyMessage = strings.noPickupRequests;
      stateField = 'loadMorePickupRequests';
      dataTest = 'transaction-details-pickup-request';
      dataTestButton = 'pickup-request-load-more-button';
    }
    return (
      <div className='col col-md-6 col-12 pr-md-3'>
        <div className='section-title mt-2'>
          {title} {!!data && !!data.totalElements && `(${data.totalElements})`}
        </div>
        {!!data.content && !!data.content.length ? (
          data.content.map(message => (
            <Message dataTest={dataTest} key={message.id} message={message} />
          ))
        ) : (
          <div className='text-left' data-test='empty-message'>{emptyMessage}</div>
        )}
        {!!data.content && !!data.content.length && !data.last && (
          <div className='d-flex flex-row justify-content-center'>
            <Button
              dataTest={dataTestButton}
              bgFill={false}
              iconName='refresh'
              loading={this.state[stateField]}
              value={strings.loadMoreMessages}
              styleClass='btn-lighter btn-bordered message-load-more-button'
              onClick={this.onLoadMore(type)}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { commentsData, damageLossReportData, pickupRequestsData } = this.props;
    const isZmccTransaction = this.props.match && this.props.match.url.includes('isZmcc');
    return (
      <>
        {this.props.transactionDetails && this.props.transactionDetails.c2cLoan && (
          <div className={'blue-bg-message'}>
            <div className='container-fluid'>{strings.c2cLoanProcessText(this.props.transactionDetails.c2cId)}</div>
          </div>
        )}
        <div className='container-fluid my-3'>
          {(commentsData.content && commentsData.content.length) || 
          (damageLossReportData.content && damageLossReportData.content.length) || !loading ? (
            <div className='row no-gutters align-items-start'>
              {this.renderMessages(commentsData, 'comment')}
              {!isZmccTransaction && this.renderMessages(damageLossReportData, 'damage-loss-report')}
              {this.props.showRequestPopup && this.renderMessages(pickupRequestsData, 'request-pickup')}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, transactionDetailsReducer }) => ({
  commentsData: transactionDetailsReducer.commentsData,
  damageLossReportData: transactionDetailsReducer.damageLossReportData,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  transactionsId:
    transactionDetailsReducer.transactionDetails &&
    transactionDetailsReducer.transactionDetails.transactionsId,
  pickupRequestsData: transactionDetailsReducer.pickupRequestsData
});

export default connect(
  mapStateToProps,
  { getTransactionMessages },
)(Messages);

import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';

import MenuCell from './Components/MenuCell'
import { getllStockRoles, getRMSZMCCStockRoles } from 'library/common/commonConstants/roles';

export const useUserPermissions = ({
  userPermissions,
  getInitialData,
  fetchRolesList,
  fetchUserPermissions,
  roles,
  language,
  updateDifferentUserPermissionsForStosk,
  clearUserPermissions,
  getCountriesByBusinessUnit,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [state, setState] = useState({
    page: 1,
    size: 10,
    filters: {
      businessUnit: null,
      country: null,
      user: null,
    },
    sort: {
      locationName: null,
      locationShortName: null,
      'country.name': null,
      'businessUnit.name': null,
      firstName: null,
      email: null,
    },
  });
  const [permissionList, setPermissionList] = useState([]);
  const [updatedRoles, setUpdatedRoles] = useState([]);
  const [globalRole, setGlobalRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(null);

  const adminId = 9;
  const rolesWithoutAdmin = roles.filter(item => item.id !== adminId);

  useEffect(() => {
    return () => {
      clearUserPermissions();
      setPermissionList([]);
    }
  }, [clearUserPermissions]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    if (state.filters.businessUnit) {
      getCountriesByBusinessUnit(state.filters.businessUnit);
    }
  }, [state.filters.businessUnit, getCountriesByBusinessUnit]);

  useEffect(() => {
    if (state.filters.user && state.filters.businessUnit) {
      fetchUserPermissions(state);
    }
  }, [state, fetchUserPermissions, language]);

  useEffect(() => {
    fetchRolesList();
    if (state.filters.businessUnit) {
      getCountriesByBusinessUnit(state.filters.businessUnit);
    }
  }, [language, fetchRolesList, state.filters.businessUnit, getCountriesByBusinessUnit]);

  useEffect(() => {
    setPermissionList(userPermissions.content);
  }, [userPermissions]);

  const toggleFilterVisibility = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const handleSort = name => {
    setState(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = ''));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : '') : 'asc';

      return newData;
    });
  };

  const handlePageChange = page => {
    setGlobalRole(null);
    if (updatedRoles && updatedRoles.length) {
      setPageNumber(page);
      return openSaveChangesPopup();
    }
    setState(prevState => ({
      ...prevState,
      page: page + 1,
    }));
  };

  const handleFilter = (value, field, fullValue) => {
    setGlobalRole(null);

    if (field === 'businessUnit') {
      return setState(prevState => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [field]: value,
          [`${field}FullValue`]: fullValue,
          country: null,
          countryFullValue: null,
        },
      }));
    }
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: value,
        [`${field}FullValue`]: fullValue,
      },
    }));
  };

  const updatePermissionForStock = (id, role) => {
    setGlobalRole(null);
    const newPermissionList = permissionList.map(item => {
      if (item.id === id) {
        return { ...item, allUserRole: [{ ...item.allUserRole[0], role }] };
      }

      return item;
    });

    setPermissionList(newPermissionList);
    setUpdatedRoles(prevState => {
      const newState = [...prevState];
      const hasId = newState.find(item => item.id === id);

      if (hasId) {
        return newState.map(item => {
          if (item.id === id) {
            return ({ id, role });
          }
          return item;
        });
      }

      newState.push({ id, role });
      return newState;
    });
  };

  const handleCancelButton = () => {
    setPermissionList(userPermissions.content);
    setUpdatedRoles([]);
  };

  const handleCancelButtonOnPopup = () => {
    setPermissionList(userPermissions.content);
    setUpdatedRoles([]);
    closeSaveChangesPopup();
  };

  const updatePage = () => {
    setState(prevState => ({
      ...prevState,
      page: pageNumber + 1,
    }));
    setPageNumber(null);
  };

  const openSaveChangesPopup = () => {
    if ($(`#saveChangesPopup`) && $(`#saveChangesPopup`).modal) {
      $(`#saveChangesPopup`).modal('show');
    }
  };

  const closeSaveChangesPopup = () => {
    if ($(`#saveChangesPopup`) && $(`#saveChangesPopup`).modal) {
      $(`#saveChangesPopup`).modal('hide');
    }
    updatePage();
  };

  const handleSavePermissionForStock = async () => {
    const newRoles = updatedRoles.map(item => ({
      accountId: state.filters.user,
      roleId: item.role ? item.role.id : null,
      stockId: item.id,
    }));

    setLoading(true);
    await updateDifferentUserPermissionsForStosk(newRoles, state);
    setLoading(false);
    setUpdatedRoles([]);
    closeSaveChangesPopup();
  };

  const selectedBusinessUnitType = state.filters.businessUnitFullValue?.type;

  const getTableCols = () => [
    {
      title: strings.stock,
      name: 'locationName',
      isSort: true,
      render: item => {
        return <div>{item.locationName}</div>;
      },
    },
    {
      title: strings.shortName,
      name: 'locationShortName',
      isSort: true,
      render: item => {
        return <div>{item.locationShortName}</div>;
      },
    },
    {
      title: strings.country,
      name: 'country.name',
      isSort: true,
      render: item => {
        return <div>{item.country.name}</div>;
      },
    },
    {
      title: strings.businessUnit,
      name: 'businessUnit.name',
      isSort: true,
      render: item => {
        return <div>{item.businessUnit.name}</div>;
      },
    },
    {
      title: strings.stockController,
      name: 'firstName',
      isSort: true,
      render: item => {
        return <div>{item.warehouseActiveAddress[0].firstName} {item.warehouseActiveAddress[0].lastName}</div>;
      },
    },
    {
      title: strings.email,
      name: 'email',
      isSort: true,
      render: item => {
        return <div>{item.warehouseActiveAddress[0].email}</div>;
      },
    },
    {
      title: strings.permission,
      name: 'stockUserRole',
      isSort: false,
      render: item => {
        let role = get(item, 'allUserRole[0].role.role') || strings.none;
        if (role === 'Stock Controller' && selectedBusinessUnitType === 'GENERAL') {
          role = strings.stockController;
        }else if (role === 'Stock Controller' && selectedBusinessUnitType === 'SPECIAL') {
          role = strings.llManager
        }else if (role === 'Stock Controller' && selectedBusinessUnitType === 'RMS_ZMCC') {
          role = strings.zmccManager;
        } else if (role === 'Borrower' && (selectedBusinessUnitType === 'RMS_ZMCC' || selectedBusinessUnitType === 'SPECIAL')) {
          role = strings.stkRequestor;
        } else if (role === 'Dispatcher' && selectedBusinessUnitType === 'SPECIAL') {
          role = strings.llPartner;
        }
        const activeRoleId = get(item, 'allUserRole[0].role.id') || null;

        return (
          <div className='cursor-pointer'>
            <MenuCell
              roles={selectedBusinessUnitType === "RMS_ZMCC" ? getRMSZMCCStockRoles() : selectedBusinessUnitType === 'SPECIAL'? getllStockRoles() : rolesWithoutAdmin}
              activeRoleId={activeRoleId}
              id={item.id}
              updateItem={updatePermissionForStock}
            >
              <div className='d-flex justify-content-between'>
                <div>{role}</div>
                <Icon
                  name='arrow'
                  width={18}
                  height={18}
                  fill='#9a9b9c'
                  style={{ transform: 'rotate(90deg)' }}
                />
              </div>
            </MenuCell>
          </div>
        );
      },
    },
  ];

  const hasUserPermissions = permissionList && permissionList.length > 0;
  const tableCols = getTableCols();
  const isDisabledUpdates = !state.filters.user || !state.filters.businessUnit || !hasUserPermissions;
  const isDisabledSaveCancelButtons = !updatedRoles.length;

  return {
    state,
    tableCols,
    isShowFilter,
    hasUserPermissions,
    isDisabledUpdates,
    rolesWithoutAdmin,
    permissionList,
    isDisabledSaveCancelButtons,
    globalRole,
    loading,
    closeSaveChangesPopup,
    handleSavePermissionForStock,
    setGlobalRole,
    openSaveChangesPopup,
    handleCancelButton,
    toggleFilterVisibility,
    handleSort,
    handlePageChange,
    handleFilter,
    updatePage,
    handleCancelButtonOnPopup,
    selectedBusinessUnitType,
  };
}

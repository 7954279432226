import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import { connect } from 'react-redux';
import strings from 'resources/locales/Translate';
import { useMaintenanceCheckList } from './MaintenanceCheckList.hook';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import RadioButton from 'library/common/commonComponents/FormComponent/Optimized/RadioButton';
import { saveMantenanceCheckListData, saveMaintenanceNewCheckListForm, getActiveTransaction } from '../../TransactionDetailsActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import NetPromoterScore from './NetpromoterScore.component';
import moment from 'moment';
import CheckListOptionsPopup from '../CheckListOptionsPopup/CheckListOptionsPopup.component';
import ArrivalCheckListPopup from '../ArrivalCheckLIstPopup/ArrivalCheckListPopup.component';
import DemoCheckListPopup from '../DemoCheckListPopup/DemoCheckListPopup.component';
import DismantleCheckListPopup from '../DismantleCheckListPopup/DismantleCheckListPopup.component';

export const MaintenanceCheckList = props => {
  const {
    maintenanceCheckListData,
    usersList,
    saveMantenanceCheckListData,
    toggleActionMessage,
    transactionDetails,
    user,
    saveMaintenanceNewCheckListForm,
    getActiveTransaction,
    activeTranction
  } = props;
  const {
    arrivalAbnormalities,
    arrivalAbnormalitiesComment,
    arrivalCompleteness,
    arrivalCompletenessComment,
    arrivalInstallationAbnormalities,
    arrivalInstallationAbnormalitiesComment,
    unpackedFrom,
    setupBy,
    onTimeDelivery,
    demoAbnormalities,
    demoAbnormalitiesComment,
    demoIssues,
    demoIssuesComment,
    demoBackupOfTempCustFiles,
    demoBackupOfTempCustFilesComment,
    systemIsDemoReady,
    systemIsDemoReadyComment,
    netPromoterScore,
    dismantleBy,
    dismantleCompleteness,
    dismantleCompletenessComment,
    dismantleLaserSafety,
    dismantleLaserSafetyComment,
    dismantleElectronicSecurity,
    dismantleElectronicSecurityComment,
    dismantleFileDeleted,
    dismantleFileDeletedComment,
    dismantleDemoKitConfirmation,
    demoChecklistCreatedOn,
  } = maintenanceCheckListData;
  const {
    isArrivalCheckListEdit,
    isDemoCheckListEdit,
    isDismantelCheckListEdit,
    editCheckList,
    onChangeDropdown,
    onChangeInput,
    onChangeCheckbox,
    abnormalitiesComment,
    completenesComment,
    installationAbnormalitiesComment,
    unpackedBy,
    setUpBy,
    onChangeRadioButton,
    saveMaintenanceCheckListForm,
    isArrivalAbonormalities,
    isCompleteness,
    isInstallationAbnormalities,
    isDemoAbnormalitiesComment,
    isOnTimeDelivery,
    isDemoAbnormalities,
    isDemoIssuesComment,
    isDemoIssues,
    demoTemperoryFilesComment,
    isDemoTemperoryFile,
    demoSystemIsReadyComment,
    isSystemReadyDemo,
    isNps,
    isDismanteledBy,
    isDismantleCompleteness,
    isDismantleCompletenessComment,
    isLaserSafety,
    isLaserSafetyComment,
    isElectronicSecurity,
    isElectronicSecurityComment,
    isDeletedFiles,
    isDeletedFilesComment,
    isDismantleDemoKitConfirmation,
    onChangeNps,
    demoFormFilledUser,
    currentDate,
    loading,
    hasPermissionToEditCheckList,
    cancelMaintenanceCheckListForm,
    arrivalFormFilledUser,
    dismantleFormFilledUser,
    demochecklistFilledUser,
    openCheckListOptionsPopup,
    openArrivalCheckListPopUp,
    openDemoCheckListPopUp,
    openDismantleCheckListPopUp,
  } = useMaintenanceCheckList({
    maintenanceCheckListData,
    usersList,
    saveMantenanceCheckListData,
    toggleActionMessage,
    transactionDetails,
    user,
    getActiveTransaction,
  });

  const isCheckedDateAdded = transactionDetails && transactionDetails.checkedDate
  const showSubmitCheckListButton = ((arrivalAbnormalities === null || arrivalAbnormalities === undefined) || (demoAbnormalities === null || demoAbnormalities === undefined) || (dismantleCompleteness === null || dismantleCompleteness === undefined));
  return (
    <>
      <div>
        <div className='kit-title' style={{ marginTop: '2%', marginLeft: '1%' }}>
          {strings.maitenanceCheckList}
        </div>
        {(activeTranction && showSubmitCheckListButton) && (
          <div className='submit-maintainceCheckList-button'>
            <Button
              bgFill={false}
              value={strings.submitMaintenanceChecklist}
              onClick={openCheckListOptionsPopup}
              styleClass='col-4'
            />
          </div>
        )}
        <CheckListOptionsPopup
          id='checkList-options'
          maintenanceCheckListData={maintenanceCheckListData}
          openArrivalCheckListPopUp={openArrivalCheckListPopUp}
          openDemoCheckListPopUp={openDemoCheckListPopUp}
          openDismantleCheckListPopUp={openDismantleCheckListPopUp}
        />

        <ArrivalCheckListPopup
          id='arrival-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
        <DemoCheckListPopup
          id='demo-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
        <DismantleCheckListPopup
          id='dismantle-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
      </div>

      {maintenanceCheckListData ? (
        <div className='col-12 row container'>
          {arrivalAbnormalities !== null && (
            <div className='col-4'>
              <div className='maintenanceEdit'>
                <div className='section-title'>{strings.headerTextArrival}</div>
                {((arrivalFormFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded) && (
                  <div>
                    <Button
                      bgFill={false}
                      iconName='edit'
                      value={strings.edit}
                      onClick={() => editCheckList('arrival')}
                    />
                  </div>
                )}
              </div>
              <div className='font-bold'>
                <u>{strings.arrivalAndUnpacking}</u>
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.unpackedFrom}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <Dropdown
                      data={usersList ? usersList : []}
                      hasSection={false}
                      multiSelect={false}
                      filter={true}
                      idKey='userId'
                      field='unpackedBy'
                      displayValue={[
                        { key: 'lastName', separator: ', ' },
                        { key: 'firstName', separator: ' ' },
                      ]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      onChange={onChangeDropdown}
                      value={unpackedBy.value}
                      fullValue={unpackedBy.fullValue}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {unpackedBy.fullValue ? `${unpackedBy.fullValue.lastName}, ${unpackedBy.fullValue.firstName}` : ''}
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.abnormalities}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isArrivalAbonormalities}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='arrivalAbnormalities'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {arrivalAbnormalities === true
                      ? strings.yes
                      : arrivalAbnormalities === false
                      ? strings.no
                      : '' || ''}
                  </div>
                )}
              </div>
              {isArrivalAbonormalities === 'yes' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='arrivalAbnormalitiesComment'
                        placeholder=''
                        isRequired
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={abnormalitiesComment.value}
                        error={abnormalitiesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{arrivalAbnormalitiesComment || ''}</div>
                  )}
                </div>
              )}
                            <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.onTimeDelivery}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isOnTimeDelivery}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='onTimeDelivery'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {onTimeDelivery === true ? strings.yes : strings.no || ''}
                  </div>
                )}
              </div>
              <div className='font-bold mt-3'>
                <u>{strings.installation}</u>
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.setUpBy}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <Dropdown
                      data={usersList ? usersList : []}
                      hasSection={false}
                      multiSelect={false}
                      filter={true}
                      idKey='userId'
                      field='setUpBy'
                      displayValue={[
                        { key: 'lastName', separator: ', ' },
                        { key: 'firstName', separator: ' ' },
                      ]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      onChange={onChangeDropdown}
                      value={setUpBy.value}
                      fullValue={setUpBy.fullValue}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {setUpBy.fullValue ? `${setUpBy.fullValue.lastName}, ${setUpBy.fullValue.firstName}` : ''}
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.maintenanceCompleteness}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isCompleteness}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='arrivalCompleteness'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>{arrivalCompleteness === true ? strings.yes : strings.no || ''}</div>
                )}
              </div>
              {isCompleteness === 'no' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='arrivalCompletenessComment'
                        placeholder=''
                        isRequired
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={completenesComment.value}
                        error={completenesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{arrivalCompletenessComment || ''}</div>
                  )}
                </div>
              )}
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.abnormalities}</div>
                {isArrivalCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isInstallationAbnormalities}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='arrivalInstallationAbnormalities'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {arrivalInstallationAbnormalities === true ? strings.yes : strings.no || ''}
                  </div>
                )}
              </div>

              {isInstallationAbnormalities === 'yes' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='arrivalInstallationAbnormalitiesComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={installationAbnormalitiesComment.value}
                        error={installationAbnormalitiesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{arrivalInstallationAbnormalitiesComment || ''}</div>
                  )}
                </div>
              )}
              {isArrivalCheckListEdit && (
                <div className='row mt-3'>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='save'
                      value={strings.save}
                      loading={loading}
                      onClick={() => saveMaintenanceCheckListForm('arrival')}
                    />
                  </div>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='cross'
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('arrival')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {demoAbnormalities !== null && (
            <div className='col-4'>
              <div className='maintenanceEdit'>
                <div className='section-title'>Demo</div>
                {((demochecklistFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded) && (
                  <div>
                    <Button bgFill={false} iconName='edit' value={strings.edit} onClick={() => editCheckList('demo')} />
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.abnormalities}</div>
                {isDemoCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isDemoAbnormalities}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='demoAbnormalities'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {demoAbnormalities === true ? strings.yes : demoAbnormalities === false ? strings.no : '' || ''}
                  </div>
                )}
              </div>
              {isDemoAbnormalities === 'yes' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isDemoCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='demoAbnormalitiesComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={isDemoAbnormalitiesComment.value}
                        error={isDemoAbnormalitiesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{demoAbnormalitiesComment || ''}</div>
                  )}
                </div>
              )}
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.temporaryFilesBackup}</div>
                {isDemoCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isDemoTemperoryFile}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='demoBackupOfTempCustFiles'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {demoBackupOfTempCustFiles === true
                      ? strings.yes
                      : demoBackupOfTempCustFiles === false
                      ? strings.no
                      : '' || ''}
                  </div>
                )}
              </div>
              {isDemoTemperoryFile === 'yes' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isDemoCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='demoBackupOfTempCustFilesComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={demoTemperoryFilesComment.value}
                        error={demoTemperoryFilesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{demoBackupOfTempCustFilesComment || ''}</div>
                  )}
                </div>
              )}
              <div className='row mt-3'>
                {isDemoCheckListEdit ? (
                  <div className='col-4 font-bold'>{strings.systemDemoReady}</div>
                ) : (
                  <div className='col-6 font-bold'>{strings.systemDemoReady}</div>
                )}
                {isDemoCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isSystemReadyDemo}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'yesButRestricted',
                          value: 'yesButRestricted',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='systemIsDemoReady'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {systemIsDemoReady === 'YES'
                      ? strings.yes
                      : systemIsDemoReady === 'RESTRICTED'
                      ? strings.yesButRestricted
                      : systemIsDemoReady === 'NO'
                      ? strings.no
                      : '' || ''}
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                {isDemoCheckListEdit ? (
                  <div className='col'>
                    <InputField
                      field='systemIsDemoReadyComment'
                      placeholder=''
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      value={demoSystemIsReadyComment.value}
                      error={demoSystemIsReadyComment.error}
                      onChange={onChangeInput}
                    />
                  </div>
                ) : (
                  <div className='col'>{systemIsDemoReadyComment || ''}</div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.dateAndNameOfUser}</div>
                <div>{`${moment(demoChecklistCreatedOn).format('DD-MM-YYYY')}, ${
                  demoFormFilledUser && demoFormFilledUser.lastName
                } ${demoFormFilledUser && demoFormFilledUser.firstName}`}</div>
              </div>
              {isDemoCheckListEdit && (
                <div className='row mt-3'>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='save'
                      loading={loading}
                      value={strings.save}
                      onClick={() => saveMaintenanceCheckListForm('demo')}
                    />
                  </div>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='cross'
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('demo')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {dismantleCompleteness !== null && (
            <div className='col-4'>
              <div className='maintenanceEdit'>
                <div className='section-title'>{strings.dismantling}</div>
                {((dismantleFormFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded) && (
                  <div>
                    <Button
                      bgFill={false}
                      iconName='edit'
                      value={strings.edit}
                      onClick={() => editCheckList('dismantle')}
                    />
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.dismantlingBy}</div>
                {isDismantelCheckListEdit ? (
                  <div className='col'>
                    <Dropdown
                      data={usersList ? usersList : []}
                      hasSection={false}
                      multiSelect={false}
                      filter={true}
                      idKey='userId'
                      field='dismantleBy'
                      displayValue={[
                        { key: 'lastName', separator: ', ' },
                        { key: 'firstName', separator: ' ' },
                      ]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      onChange={onChangeDropdown}
                      value={isDismanteledBy.value}
                      fullValue={isDismanteledBy.fullValue}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {isDismanteledBy.fullValue
                      ? `${isDismanteledBy.fullValue.lastName}, ${isDismanteledBy.fullValue.firstName}`
                      : ''}
                  </div>
                )}
              </div>
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.maintenanceCompleteness}</div>
                {isDismantelCheckListEdit ? (
                  <div className='col-6'>
                    <RadioButton
                      type={'radio-button'}
                      value={isDismantleCompleteness}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='dismantleCompleteness'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {dismantleCompleteness === true
                      ? strings.yes
                      : dismantleCompleteness === false
                      ? strings.no
                      : '' || ''}
                  </div>
                )}
              </div>
              {isDismantleCompleteness === 'no' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isDismantelCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='dismantleCompletenessComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={isDismantleCompletenessComment.value}
                        error={isDismantleCompletenessComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{dismantleCompletenessComment || ''}</div>
                  )}
                </div>
              )}
              <div className='row mt-3'>
                <div className='col-6 font-bold'>{strings.filesFromPCDeleted}</div>
                {isDismantelCheckListEdit ? (
                  <div className='col'>
                    <RadioButton
                      type={'radio-button'}
                      value={isDeletedFiles}
                      placeholder={''}
                      data={[
                        {
                          id: 'yes',
                          value: 'yes',
                        },
                        {
                          id: 'no',
                          value: 'no',
                        },
                      ]}
                      field='dismantleFileDeleted'
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      onChange={onChangeRadioButton}
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {dismantleFileDeleted === true
                      ? strings.yes
                      : dismantleFileDeleted === false
                      ? strings.no
                      : '' || ''}
                  </div>
                )}
              </div>
              {isDeletedFiles === 'no' && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.maintenanceComment}</div>
                  {isDismantelCheckListEdit ? (
                    <div className='col'>
                      <InputField
                        field='dismantleFileDeletedComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={isDeletedFilesComment.value}
                        error={isDeletedFilesComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='col'>{dismantleFileDeletedComment || ''}</div>
                  )}
                </div>
              )}
              <div className='font-bold mt-2'>{strings.maintenanceDemoKit}</div>
              {isDismantelCheckListEdit ? (
                <div>
                  <Checkbox
                    label={strings.canBeShipped}
                    field='canBeShipped'
                    isRequired={true}
                    selected={isDismantleDemoKitConfirmation === 'CAN_BE_SHIPPED'}
                    onChange={onChangeCheckbox}
                  />
                  <Checkbox
                    label={strings.mustBeShipped}
                    field='mustBeShipped'
                    isRequired={true}
                    selected={isDismantleDemoKitConfirmation === 'MUST_BE_SHIPPED'}
                    onChange={onChangeCheckbox}
                  />
                  <Checkbox
                    label={strings.discussionWithProdCenter}
                    field='discussionWithProdCenter'
                    isRequired={true}
                    selected={isDismantleDemoKitConfirmation === 'DISCUSSION_WITH_PRODUCTION_CENTER'}
                    onChange={onChangeCheckbox}
                  />
                </div>
              ) : (
                <div>
                  {isDismantleDemoKitConfirmation === 'CAN_BE_SHIPPED' && strings.canBeShipped}
                  {isDismantleDemoKitConfirmation === 'MUST_BE_SHIPPED' && strings.mustBeShipped}
                  {isDismantleDemoKitConfirmation === 'DISCUSSION_WITH_PRODUCTION_CENTER' &&
                    strings.discussionWithProdCenter}
                </div>
              )}
              {isDismantelCheckListEdit && (
                <div className='row mt-3'>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='save'
                      // styleClass='show-all-messages-button'
                      loading={loading}
                      value={strings.save}
                      onClick={() => saveMaintenanceCheckListForm('dismantle')}
                    />
                  </div>
                  <div className='col-4'>
                    <Button
                      bgFill={true}
                      iconName='cross'
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('dismantle')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '3%' }}>{strings.noDataFoundText}</div>
      )}
    </>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, transactionDetailsReducer }) => ({
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  user: authReducer.user,
  usersList: masterDataReducer.usersList,
  maintenanceCheckListData: transactionDetailsReducer.maintenanceCheckListData,
  activeTranction: transactionDetailsReducer.activeTranction
});

export default connect(mapStateToProps, { saveMantenanceCheckListData, toggleActionMessage, saveMaintenanceNewCheckListForm, getActiveTransaction })(MaintenanceCheckList);

export const statisticsBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'statistics',
  },
];

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS = 'FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS';
export const FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION = 'STATISTICS/FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION';
export const FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY = 'STATISTICS/FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY';
export const FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA = 'STATISTICS/FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA';
export const FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY = 'STATISTICS/FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY';
export const FETCH_SYSTEM_CLASS_FOR_KIT_DATA = 'STATISTICS/FETCH_SYSTEM_CLASS_FOR_KIT_DATA';
export const FETCH_LOAN_REASONS_STATISTIC = 'STATISTICS/FETCH_LOAN_REASONS_STATISTIC';
export const FETCH_DEMOS_STATISTIC = 'STATISTICS/FETCH_DEMOS_STATISTIC';
export const FETCH_SATISFACTION_INDEX_STATISTIC = 'STATISTICS/FETCH_SATISFACTION_INDEX_STATISTIC';
export const CLEAR_STATISTIC = 'STATISTICS/CLEAR_STATISTIC';
export const FETCH_DEMOS_CHART_DATA = 'STATISTICS/FETCH_DEMOS_CHART_DATA';
export const FETCH_LATE_RETURNS_TRANSACTIONS = 'STATISTICS/FETCH_LATE_RETURNS_TRANSACTIONS';
export const CLEAR_LATE_RETURNS_TRANSACTIONS = 'STATISTICS/CLEAR_LATE_RETURNS_TRANSACTIONS';
export const FETCH_KIT_STATUS_CHART_STATISTICS = 'STATISTICS/FETCH_KIT_STATUS_CHART_STATISTICS';
export const CLEAR_KIT_STATUS_CHART_STATISTICS = 'STATISTICS/CLEAR_KIT_STATUS_CHART_STATISTICS';
export const FETCH_KIT_DATA_STATISTICS = 'STATISTICS/FETCH_KIT_DATA_STATISTICS';
export const CLEAR_KIT_DATA_STATISTICS = 'STATISTICS/CLEAR_KIT_DATA_STATISTICS';
export const CHANGE_KIT_STATUS_CHART_LOADER = 'STATISTICS/CHANGE_KIT_STATUS_CHART_LOADER';
export const CHANGE_KIT_DATA_LOADER = 'STATISTICS/CHANGE_KIT_DATA_LOADER';
export const FETCH_UTILIZED_KITS = 'STATISTICS/FETCH_UTILIZED_KITS';
export const FETCH_WORKING_CAPITAL_IN_STOCK = 'STATISTICS/FETCH_WORKING_CAPITAL_IN_STOCK';
export const CLEAR_WORKING_CAPITAL_IN_STOCK = 'STATISTICS/CLEAR_WORKING_CAPITAL_IN_STOCK';
export const NEW_REGISTRATIONS = 'NEW_REGISTRATIONS';
export const DMA_REPORTING_DATA = 'DMA_REPORTING_DATA';
export const SIX_MONTHS_TRAFFIC_DATA = 'SIX_MONTHS_TRAFFIC_DATA';
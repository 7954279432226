import { batchActions } from 'redux-batched-actions';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import { kitDetailsRequestJson } from 'library/api/endpoint-request-json';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  deleteKitService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  deleteKitDusdComment,
  fetchMasterDataService,
  postKitDetails,
  deleteMasterDataService
} from 'library/api/master-data.service';
import { logNetworkError } from 'library/utilities/logError';
import { scrollToTop } from 'library/utilities/scrollActions';
import { addNameKey } from 'library/utilities/fileHelperFunctions';
import zeissInstance from 'library/utilities/AxiosInstance';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { uploadKitAttachment } from 'modules/Kits/CreateKit/CreateKit.actions';

import * as actionTypes from './KitDetailsConstants';
import { getSystemAvailabiltySoltsList } from 'modules/Stock/CreateStock/Tabs/Workflows/Workflows.actions';

export const fetchKitDetail = id => async dispatch => {
  dispatch(changeLoader(true));

  try {
    const url = `${URLS.kit}/${id}`;
    const res = await postKitDetails(url);

    if (res.status === 200) {
      const attachments = res.data.kitAttachments;
      await dispatch(
        batchActions([
          dispatch({
            type: actionTypes.GET_KIT_DETAILS,
            payload: res.data,
          }),
          dispatch(getSystemAvailabiltySoltsList(res.data.stockInformation.id)),
          dispatch(fetchKitAttachmentsAndSave(attachments)),
          dispatch(fetchKitTransactionAttachments(id, actionTypes.FETCH_KIT_TRANSACTIONS_ATTACHMENTS)),
          dispatch(fetchWorkflowData(res.data.stockInformation.id)),
        ]),
      );
    }
    dispatch(changeLoader(false));
  } catch (err) {
    logNetworkError(err);
    dispatch(changeLoader(false));
  }
};

export const createSapErpLoanOrderForKit = id => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(URLS.createSapErpLoanOrderForKit(id));
    dispatch(fetchKitDetail(id));
    dispatch(changeLoader(false));
    return res;
  } catch (err) {
    dispatch(changeLoader(false));
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return;
  }
};

export const returnSapErpLoanOrderForKit = id => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(URLS.returnSapErpLoanOrderForKit(id));
    dispatch(fetchKitDetail(id));
    dispatch(changeLoader(false));
    return res;
  } catch(err) {
    dispatch(changeLoader(false));
    return;
  }
};

export const fetchKitAttachmentsAndSave = files => dispatch => {
  const kitDetailsAttachments = addNameKey(files);
  dispatch({
    type: actionTypes.FETCH_KIT_DETAILS_ATTACHMENTS,
    payload: kitDetailsAttachments,
  });
};

export const fetchKitTransactionAttachments = (kitId, actionType) => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.kitsTransactionsAttachments(kitId));
    const transactionAttachments = addNameKey(data);

    dispatch({
      type: actionType,
      payload: transactionAttachments,
    });
  } catch (err) {
    dispatch({
      type: actionType,
      payload: [],
    });
  }
};

export const updateKitAttachents = ({ kitAttachments }) => ({
  type: actionTypes.ADD_REMOVE_KIT_ATTACHMENTS,
  payload: kitAttachments,
});

export const saveKitAttachments = () => async (dispatch, getState) => {
  const { kitDetailsReducer } = getState();
  const { kitDetails, kitAttachments, kitDetailsAttachments } = kitDetailsReducer || {};

  if (kitAttachments.length === kitDetailsAttachments.length) return;

  await dispatch(uploadKitAttachment(kitAttachments, kitDetails.kitInformationId, true));
  dispatch(fetchKitDetail(kitDetails.kitInformationId));
};

export const deleteKitDetails = id => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(changeLoader(true));
    return deleteKitService(id)
      .then(res => {
        if (res.status === 200) {
          dispatch(
            batchActions([
              dispatch(changeLoader(false)),
              dispatch(toggleActionMessage(true, 'success', 'kitDeletedSuccessfully')),
            ]),
          );
        }
        resolve(res);
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(
          batchActions([
            dispatch(changeLoader(false)),
            dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
          ]),
        );
        reject(err);
      });
  });
};

export const saveCurrentTab = tab => dispatch => {
  dispatch({
    type: actionTypes.SAVE_CURRENT_TAB,
    payload: tab,
  });
};

export const clearKitDetailsData = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_KIT_DETAILS_DATA,
  });
  dispatch(fetchKitAttachmentsAndSave([]));
};

export const fetchKitStatistics = (kitId, dateRange) => () => {
  let url = URLS.getKitStatistics(kitId);
  if (dateRange) {
    url += `?from=${moment(dateRange[0]).format('YYYY-MM-DD')}&to=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
  }
  return zeissInstance
    .get(url)
    .then(({ status, data }) => {
      return { success: status === 200, data };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
};

export const fetchLLKitStatistics = (kitId, dateRange) => async () => {
  try {
    let url = URLS.llkitStatistic(kitId);

    if (dateRange) {
      url += `?from=${moment(dateRange[0]).format('YYYY-MM-DD')}&to=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
    }

    const { data } = await fetchMasterDataService(url);

    return { success: true, data };
  } catch (err) {
    return { success: false };
  }
};

export const handleDemoUnitComment = (dataToSend, id, request) => async dispatch => {
  try {
    const res = await request(URLS.demoUnitComment, dataToSend);
    const editionalMessage =
      dataToSend.status === 'NO_ISSUES' ? strings.demoUnitStatusOk : strings.demoUnitStatusDefective;

    if (res.status === 200) {
      await dispatch(fetchKitDetail(id));
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', `${strings.statusSubmittedSuccessfully} ${editionalMessage}`));
    }
  } catch (err) {
    logNetworkError(err);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const createDemoUnitComment = (dataToSend, id) => async dispatch => {
  await dispatch(handleDemoUnitComment(dataToSend, id, fetchMasterDataServicePost));
};

export const updateDemoUnitComment = (dataToSend, id) => async dispatch => {
  await dispatch(handleDemoUnitComment(dataToSend, id, fetchMasterDataServicePut));
};

export const changeDemoUnitStatusTask = (dataToSend, id) => async dispatch => {
  dispatch(changeLoader(true));
  try {
    const res = await fetchMasterDataServicePut(URLS.demoUnitTaskStatus, dataToSend);

    if (res.status === 200) {
      await dispatch(fetchKitDetail(id));
    }
    dispatch(changeLoader(false));
  } catch (err) {
    logNetworkError(err);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const createEditionalComment = (dataToSend, id) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.commentAnswer, dataToSend);

    if (res.status === 200) {
      await dispatch(fetchKitDetail(id));
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'commentSubmittedSuccessfully'));
    }
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};
export const deleteEditionalComment = (dataToSend, id) => async dispatch => {
  try {
    const res = await deleteKitDusdComment(URLS.demoUnitDeleteComment, dataToSend);
    const message = dataToSend.status ? 'statusDeletedSuccessfully' : 'commentDeletedSuccessfully';
    if (res.status === 200) {
      await dispatch(fetchKitDetail(id));
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', message));
    }
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const fetchWorkflowData = stockId => dispatch => {
  const url = URLS.stockWorkflow;
  dispatch(changeLoader(true));
  return fetchMasterDataService(url.replace('{id}', stockId))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: actionTypes.FETCH_WORKFLOW_QUESTIONS,
          payload: data,
        });
        dispatch(changeLoader(false));
      }
      dispatch(changeLoader(false));
      return { success: false };
    })
    .catch(err => {
      dispatch(changeLoader(false));
      return { success: false };
    });
};

export const checkActiveLoanorder = kitId => async dispatch => {
  try {
    const res = await fetchMasterDataService(URLS.activeLoanOrder(kitId));
    dispatch({
      type: actionTypes.FETCH_TRANSACTION_LOAN_ORDER,
      payload: res.data,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: actionTypes.FETCH_TRANSACTION_LOAN_ORDER,
      payload: {},
    });
    return { success: false };
  }
};


export const deletereparationSlotForKit = (kitReaparationId, reparationSlots, setReparationSlots) => async dispatch => {
  dispatch(changeLoader(true));
  try {
    const url = URLS.deleteKitReparation(kitReaparationId);
    const res = await deleteMasterDataService(url);
    dispatch(toggleActionMessage(true, 'success', 'kitReparationDeltedSuccessfully'));
    const filteredReparations = reparationSlots.filter(item => item.kitReparationId != kitReaparationId);
    setReparationSlots(filteredReparations);
    dispatch(changeLoader(false));
    scrollToTop(500);
    return { success: true };
  } catch (err) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
};

import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized'; 
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { validateForm } from 'library/utilities/ValidateForm.util';

export const CreateMessage = ({ createMessage, id, transactionsId, isZmcc = false, sendLogisticEmail, transactionNumber }) => {
  const [doValidate, setDoValidate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(1);
  const user = fetchFromStorage(identifiers.user);

  const [createMessageFormData, setCreateMessageFormData] = useState(
    {formData: {
      type:1
    }}
  );

  const handleForm = ({ values, field }) => {
    if (field === 'type') {
      handleTypeChange(values.type);
    }
    setCreateMessageFormData({
      ...createMessageFormData,
      formData: {
        ...createMessageFormData.formData,
        ...values,
      },
    });
  };

  const dropdownOptions = {
    1: { id: 1, value: 'comment', name: strings.commentText },
    2: { id: 2, value: 'damage-loss-report', name: strings.damageLossReport },
  };

  const dropdownOptionsForZmcc = {
    1: { id: 1, value: 'comment', name: strings.commentText },
    3: { id: 3, value: 'email', name: strings.email },
  };
  const formValue = { type: 1 };

  const model = [
    {
      dataTest: 'transaction-details-dropdown',
      label: '',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'type',
      validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
      required: true,
      styleClass: 'mt-3',
      options: isZmcc ? Object.values(dropdownOptionsForZmcc) : Object.values(dropdownOptions),
      initialValue: dropdownOptions[formValue.type],
      hasSection: false,
      multiSelect: false,
      filter: false,
      idKey: 'id',
      displayValue: [{ key: 'name', separator: '' }],
      clear: true,
      isClearable: true,
    },
  ];

  const [dynamicModel, setDynamicModel] = useState(model);

  useEffect(() => {
    updateModelBasedOnType();
  }, [selectedType]);


  const onCloseClick = () =>{
    resetForm();
    setSelectedType(1);
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
  }

  const resetForm = () => {
    setErrorMessage('');
    setDoValidate(false);
    setCreateMessageFormData({formData: {
      type:1
    }})
  };

  const onSaveClick = () => {
    const validForm = validateForm({
      form: createMessageFormData,
      model: dynamicModel
    });
        
    if (!validForm.isFormValid) {
      return setCreateMessageFormData(validForm);
    }

    if (validForm.isFormValid) {
      setLoading(true);
      if (selectedType === 1 || selectedType === 2) {
        const text = validForm.formData && validForm.formData.comment;
        const type = validForm.formData && dropdownOptions[validForm.formData.type]?.value;

        createMessage({ transactionsId, text, type }).then((res) => {
          setLoading(false);

          if (res.success) {
            onCloseClick();
          } else if (res.error) {
            setErrorMessage(res.error);
          }
        });
      } else if (selectedType === 3) {
        const allEmailAddresses = validForm.formData.emailAddress.split(",").map(email => email.trim());
        const dataToSend = {
          allEmailReciepients: allEmailAddresses,
          allEmailReciepientsInString: validForm.formData.emailAddress,
          toCcEmailAddress: user && [user.email],
          subject: `${validForm.formData.subject} (${strings.transactionNumber}: ${transactionNumber})`,
          emailText: validForm.formData.emailbody.replaceAll('\n', '<br>'),
          senderEmailAddress: user && user.email,
          emailBody: validForm.formData.emailbody,
          isZmcc: true,
        };

        sendLogisticEmail(dataToSend, transactionsId, 'emailFromZmcc').then((res) => {
          setLoading(false);

          if (res.success) {
            onCloseClick();
          } else if (res.error) {
            setErrorMessage(res.error);
          }
        });
      }
    } else {
      setDoValidate(true);
    }
  };

  const updateModelBasedOnType = () => {
    const updatedModel = [...model];
    if (selectedType === 3) {
      updatedModel.push({
        dataTest: 'transaction-details-email',
        label: 'email',
        value: '',
        type: 'email',
        placeholder: '',
        field: 'emailAddress',
        validators: [
          { check: Validators.required, message: strings.requiredErrorMessage },
          { check: Validators.email, message: strings.invalidEmailError },
        ],
        required: true,
        styleClass: 'mt-2',
        clear: true,
        isClearable: true,
        showExtraInfo: true,
        infoMessage: "canEnterMultipleEmails"
      });
      updatedModel.push({
        dataTest: 'transaction-details-subject',
        label: 'contactUsSubject',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'subject',
        validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
        required: true,
        styleClass: 'mt-2',
        clear: true,
        isClearable: true,
      });
      updatedModel.push({
        dataTest: 'transaction-details-email-body',
        label: 'contactUsMessage',
        value: '',
        type: 'textarea',
        placeholder: '',
        field: 'emailbody',
        validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
        required: true,
        styleClass: 'mt-2',
        clear: true,
        isClearable: true,
      });
      setDynamicModel(updatedModel);
    } else if (selectedType === 1 || selectedType === 2) {
      updatedModel.push({
        dataTest: 'transaction-details-textarea',
        label: 'commentText',
        value: '',
        type: 'textarea',
        placeholder: '',
        field: 'comment',
        validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
        required: true,
        styleClass: 'mt-2',
        clear: true,
        isClearable: true,
      });
      setDynamicModel(updatedModel);
    }
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
    updateModelBasedOnType();
    setDoValidate(false);
  };

  return (
    <Modal
      id={id}
      className='modal-lg'
      title={selectedType === 3 ? strings.email : strings.createMessage}
      confirmAction={onSaveClick}
      confirmDataTest='create-message-save'
      confirmTitle={selectedType === 3 ? strings.send : strings.save}
      loading={loading}
      onCloseClick={onCloseClick}
      titleOfCancel={strings.cancel}
    >
      <FormComponent
           model={dynamicModel}
           formName='createmMessageForm'
           formValue={createMessageFormData.formData}
           onChange={handleForm}
           className='d-flex'
           clear
           rowStyle='flex-column w-100 ml-0'
         />
      {!!errorMessage && <div className='error-message'>{errorMessage}</div>}
    </Modal>
  );
};

export default CreateMessage;

import React, { useState, useEffect } from 'react';
import Icon from "../../../library/common/commonComponents/Icon/Icon";
import strings from 'resources/locales/Translate';
import { object } from 'prop-types';
import { validateNameEmailAndFunctionInAttendees } from './SemBooking.actions';


const ZMCCTabs = (props) => {
  const {
    saveCurrentTab,
    toggleTab = true,
    tabs,
    currentTab,
    getTabId,
    isXbBooking,
    isSemBooking,
    isXRMBooking,
    isLMBooking,
    zmccXrmBooking,
    zmccLmBooking,
    zmccXbBooking,
    zmccSemBooking,
  } = props;
  const [activeTab, setActiveTab] = useState(currentTab || 1);
  const [tabCompletionStatus, setTabCompletionStatus] = useState({});
  const {formValue} = props;;
  const {
    generalInformationForm, schedulingInformationForm, projectDetailsForm,
    customerDetilsForm, primaryPointOfContactForm, customerBackgroundForm,
    mainApplicationChecklistForm, sampleDeatilsFrom, 
    demoCenterInformationForm, additionalInformationForm,
    logisticsAndCoordinationForm, 
    todosListForm,
    transactionsId,
    xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom,
    xrmDemoDetailsForm, 
    xrmApplicationInformationForm, 
    xrmSamplesDetailsForm, 
    xrmSampleDescriptionForm, 
    xrmSampleSpecsForm,
    lmApplicationAndSamplesForm
  } = formValue;
  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab])

  useEffect(() => {
    const updatedCompletionStatus = { ...tabCompletionStatus };
    updatedCompletionStatus[activeTab] = checkTabCompletion(activeTab);
    setTabCompletionStatus(updatedCompletionStatus);
  }, [activeTab, formValue]);

  const toggleTabFunc = (id) => () => {
    if (toggleTab && activeTab !== id) {
      setActiveTab(id);
      getTabId(id)
      saveCurrentTab && saveCurrentTab(id);
    }
  }
  const generalInformationMandatoryData = ['stockInformationId', 'intendedForId', 'requesterId','email', 'precallWithZmcc'];
  const schedulingInformationMandatoryData = ['suggestedDate','kitInformationId','alternateDate', 'dueDate','estimatedDays'];
  const projectDetailsMandatoryData = ['isItFirstDemo', 'isCustomerApplyingForFunding'];
  const intendedIdsForOpportunityMandatory =[1,4,5,6,14];
  if ( generalInformationForm.formData.intendedForId && intendedIdsForOpportunityMandatory.includes(generalInformationForm.formData.intendedForId)){
    projectDetailsMandatoryData.push('opportunityId');
  }
  if (schedulingInformationForm.formData.additionalInstrument && schedulingInformationForm.formData.additionalInstrument.includes(0)) {
    schedulingInformationMandatoryData.push('corrMicDetails');
  }
  
  const checkGeneralInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(generalInformationForm.formData), ...Object.keys(schedulingInformationForm.formData),  ...Object.keys(projectDetailsForm.formData)];
    generalInformationMandatoryData.map(key => {
        if (!keys.includes(key)){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        } else if(!generalInformationForm.formData[key]){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

    schedulingInformationMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
      // else if (key == 'additionalInstrument' && (schedulingInformationForm.formData.additionalInstrument === null || schedulingInformationForm.formData.additionalInstrument.length === 0)) {
      //   isMandatoryFilled = false;
      //   return isMandatoryFilled;
      // }
       else if(!schedulingInformationForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    projectDetailsMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if (key === 'opportunityId' && (projectDetailsForm.formData.opportunityIdError === "invalidNumberError" || projectDetailsForm.formData.opportunityIdError === 'correctOpportunityMessage' ) ) {
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
      else if(!projectDetailsForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    return isMandatoryFilled;
  }

  const generalInformationTabError = checkGeneralInformationMandatoryFilled();

  const customerDetilsMandatoryData = ['customerName', 'customerOrganization', 'customerDepartment', 'customerPointOfContact'];
  const customerBackgroundMandatoryData =['marketSegmentId', 'zeissCustomer','relationshipId'];
  let primaryPointOfContactMandatory = [];
  if (customerDetilsForm.formData.customerPointOfContact === 'no') {
    primaryPointOfContactMandatory.push('pocName', 'pocEmail');
  }
  const checkCustomerInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(customerDetilsForm.formData),  ...Object.keys(customerBackgroundForm.formData), ...Object.keys(primaryPointOfContactForm.formData)]
    customerDetilsMandatoryData.map(key => {
        if (!keys.includes(key) || customerDetilsForm.formData[key] == null ||  customerDetilsForm.formData[key] == ""){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        } else if(customerDetilsForm.formData[key] && !customerDetilsForm.formData[key].length >0 ){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

    primaryPointOfContactMandatory.map(key => {
      if (!keys.includes(key) || primaryPointOfContactForm.formData[key] == null ||  primaryPointOfContactForm.formData[key] == ""){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if (key === 'pocEmail' && (primaryPointOfContactForm.formData.pocEmailError === "invalidEmailError")) {
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
      else if(primaryPointOfContactForm.formData[key] && !primaryPointOfContactForm.formData[key].length >0 ){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    customerBackgroundMandatoryData.map(key => {
      if (!keys.includes(key) || customerBackgroundForm.formData[key] == null || customerBackgroundForm.formData[key] == ""){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if(!customerBackgroundForm.formData[key] && !customerBackgroundForm.formData[key].length >0){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    return isMandatoryFilled;
  }

  const customerInformationTabError = checkCustomerInformationMandatoryFilled();

  const mainApplicationMandatoryData = ['dataFromCustomerId'];
  const sampleDeatilsMandatoryData =['statusOfSampleId','totalNumberOfSamples', 'manipulateTheSamplesPriorToImaging'];
  const xbMainApplicationMandatoryData = ['xbDoYouHaveDataFromCustomer'];
  const xbSampleDeatilsMandatoryData = ['xbStatusOfSampleId', 'xbManipulateTheSamplesPriorToImaging', 'detailedDescription', 'sampleSpecificComments'];
  const xrmDemoDetailsMandatoryData = ['primaryGoalsOfDemo', 'customerSystemCompetition', 'xrmDoYouHaveDataFromCustomer'];
  const xrmApplicationInfoMandatoryData = ['detailedDescriptionOfCustomerFocus'];
  const xrmSamplesDetailsMandatoryData = ['totalNumberOfSamplesForXrm', 'statusOfSamplesForXRM', 'xrmManipulateTheSamplesPriorToImaging'];
  const xrmSampleDescriptionMadatoryData = ['describeOverallSamples'];
  const xrmSampleSpecMandatoryData = ['totalSampleSize', 'featureSizes', 'featureRegionOfInterest', 'chemicalCompositions', 'substrateOrSorroundingMaterial', 'contrastAgentBeenApplied'];
  const lmApplicationSamplesMandatoryData = ['primaryApplicationForThisCustomer', 'sampleGoingToProvideForThisDemo', 'learningAboutTheseSamples', 'justificationPoints', 'aboutCompetitionInThisSale', 'lmDoYouHaveDataFromCustomer', 'statusOfSamplesForLm', 'lmTotalNumberOfSamples']

  const checkApplicationAndSamplesMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(mainApplicationChecklistForm.formData), ...Object.keys(sampleDeatilsFrom.formData)]
    mainApplicationMandatoryData.map(key => {
        if (!keys.includes(key)){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        } else if(!mainApplicationChecklistForm.formData[key]){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

    sampleDeatilsMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if(!sampleDeatilsFrom.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    return isMandatoryFilled;
  }


  const checkXbApplicationAndSamplesMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(xbMainApplicationChecklistForm.formData), ...Object.keys(xbSampleDeatilsFrom.formData)]
    xbMainApplicationMandatoryData.map(key => {
        if (!keys.includes(key)){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        } else if(!xbMainApplicationChecklistForm.formData[key]){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

  xbSampleDeatilsMandatoryData.map(key => {
    if (!keys.includes(key)){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    } else if(!xbSampleDeatilsFrom.formData[key]){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    }
  });

  return isMandatoryFilled;
}

const checkLmApplicationAndSamplesMandatoryFilled  = () =>{
  let isMandatoryFilled = true;
  let keys = [...Object.keys(lmApplicationAndSamplesForm.formData)]
  lmApplicationSamplesMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if(!lmApplicationAndSamplesForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
  });
return isMandatoryFilled;
}

const checkXrmApplicationAndSamplesMandatoryFilled  = () =>{
  let isMandatoryFilled = true;
  let keys = [...Object.keys(xrmDemoDetailsForm.formData), ...Object.keys(xrmApplicationInformationForm.formData), ...Object.keys(xrmSamplesDetailsForm.formData), ...Object.keys(xrmSampleDescriptionForm.formData), ...Object.keys(xrmSampleSpecsForm.formData)]
  xrmDemoDetailsMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if(!xrmDemoDetailsForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
  });

xrmApplicationInfoMandatoryData.map(key => {
  if (!keys.includes(key)){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  } else if(!xrmApplicationInformationForm.formData[key]){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  }
});

xrmSamplesDetailsMandatoryData.map(key => {
  if (!keys.includes(key)){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  } else if(!xrmSamplesDetailsForm.formData[key]){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  }
});

xrmSampleDescriptionMadatoryData.map(key => {
  if (!keys.includes(key)){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  } else if(!xrmSampleDescriptionForm.formData[key]){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  }
});

xrmSampleSpecMandatoryData.map(key => {
  if (!keys.includes(key)){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  } else if(!xrmSampleSpecsForm.formData[key]){
    isMandatoryFilled = false;
    return isMandatoryFilled;
  }
});

return isMandatoryFilled;
}

  const applicationAndSamplesTabError = checkApplicationAndSamplesMandatoryFilled();
  const xbApplicationAndSamplesTabError = checkXbApplicationAndSamplesMandatoryFilled();
  const xrmApplicationAndSamplesTabError = checkXrmApplicationAndSamplesMandatoryFilled();
  const lmApplicationAndSamplesTabError = checkLmApplicationAndSamplesMandatoryFilled();

  const checkDemoCenterInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let data = demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList;
    if (data && !data.length >0){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    } else if(!demoCenterInformationForm.formData.hasOwnProperty('zmccHardwareSoftwareAccessoriesList')){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    }

    return isMandatoryFilled;
  }

  const demoCenterInformationTabError = checkDemoCenterInformationMandatoryFilled();

  const additionalInformationMandatoryData =['detailedCommentsAdditionalInformation'];

  const checkAdditionalInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(additionalInformationForm.formData)]
    additionalInformationMandatoryData.map(key => {
        if (keys.includes(key) && (additionalInformationForm.formData[key] == null || additionalInformationForm.formData[key] == '')){
          isMandatoryFilled = false;
        }
        else if (!keys.includes(key)){
          isMandatoryFilled = false;
        }
    });

    return isMandatoryFilled;
  }

  const additionalInformationMandatoryFilledTabError = checkAdditionalInformationMandatoryFilled();

  const checkLogisticsAndCoordination = () => {
    let isMandatoryFilled = true;
    let data = logisticsAndCoordinationForm.formData.zmccAttendeesList;
    if (data && !data.length >0){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    } else if(!logisticsAndCoordinationForm.formData.hasOwnProperty('zmccAttendeesList')){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    }

    return isMandatoryFilled;
  }


  const checkTodoListData = () => {
    let isMandatoryFilled = true;
    let data = todosListForm.formData.zmccTodosListDataList;
    if (data && !data.length >0){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    } else if(!todosListForm.formData.hasOwnProperty('zmccTodosListDataList')){
      isMandatoryFilled = false;
      return isMandatoryFilled;
    }

    return isMandatoryFilled;
  }

  const todosListTab = checkTodoListData();
  const zmccTabShowBlueTic = () =>{
    const data = logisticsAndCoordinationForm.formData;
    let isMandatoryFilledFilled  = false;
    if (data.accomodationNeeded === true &&  (data.accomodationNeededData === undefined || data.accomodationNeededData === '' || data.accomodationNeededData === null)) {
      return isMandatoryFilledFilled = false;
    }
    if (data.flightNeeded === true && (data.flightNeededData === undefined || data.flightNeededData === "" || data.flightNeededData === null)) {
      return isMandatoryFilledFilled = false;
    
    }
    if (data.visitMuseumOfOptics === true  && (data.visitMuseumOfOpticsData === undefined ||data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null)) {
      return isMandatoryFilledFilled = false;
    }
    if(((data.attendeeName && data.attendeeName !== "" && data.attendeeName !== null) || (data.attendeeEmail && data.attendeeEmail !== "" && data.attendeeEmail !== null ) || (data.function && data.function !== "" && data.function !== null) || (data.skillLevelId && data.skillLevelId !== "" && data.skillLevelId !== null)  || (data.descisionMaker && data.descisionMaker !=="" && data.descisionMaker !== null))){
      isMandatoryFilledFilled = true;
    }
      
      return isMandatoryFilledFilled;
  }
  
  const thirdTabMandatoryDataCheck = () => {
    if ((isSemBooking || zmccSemBooking) && applicationAndSamplesTabError) {
      return true;
    }else if ((isXbBooking || zmccXbBooking) && xbApplicationAndSamplesTabError) {
      return true;
    }else if ((isXRMBooking || zmccXrmBooking) && xrmApplicationAndSamplesTabError) {
      return true
    }else if ((isLMBooking || zmccLmBooking) && lmApplicationAndSamplesTabError) {
      return true
    }else{
      return false;
    }
  }

  const checkTabCompletion = (tabId) => {
    if (tabId == 1){
      return checkGeneralInformationMandatoryFilled();
    } else if(tabId == 2){
      return checkCustomerInformationMandatoryFilled()
    } else if(tabId == 3){
      if ((isSemBooking || zmccSemBooking)) {
        return checkApplicationAndSamplesMandatoryFilled();
      }else if ((isXbBooking || zmccXbBooking)) {
        return checkXbApplicationAndSamplesMandatoryFilled();
      }else if ((isXRMBooking || zmccXrmBooking)) {
        return checkXrmApplicationAndSamplesMandatoryFilled();
      }else if ((isLMBooking || zmccLmBooking)) {
        return checkLmApplicationAndSamplesMandatoryFilled();
      }else{
        return false;
      }
    } else if(tabId == 4){
      return checkDemoCenterInformationMandatoryFilled();
    } else if(tabId == 5){
      return checkAdditionalInformationMandatoryFilled();
    } else if(tabId == 6){
      return isLogisticTabValid();
    } else if(tabId == 7){
      return checkTodoListData();
    }
  }

  const isLogisticTabValid = () => {
    let valiations = validateNameEmailAndFunctionInAttendees(logisticsAndCoordinationForm);
    const data = logisticsAndCoordinationForm.formData;
    if (data.accomodationNeeded === true &&  (data.accomodationNeededData === undefined || data.accomodationNeededData === '' || data.accomodationNeededData === null)) {
      return false;
    }
    if (data.flightNeeded === true && (data.flightNeededData === undefined || data.flightNeededData === "" || data.flightNeededData === null)) {
      return false;
    
    }
    if (data.visitMuseumOfOptics === true  && (data.visitMuseumOfOpticsData === undefined ||data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null)) {
      return false;
    }

    if(valiations.allEmpty && logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length){
      return true
    }
    if(!valiations.allEmpty && valiations.isValid){
      return true
    }
    if(valiations.allEmpty && !logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length){
      return false;
    }
    return false;
  }

  const logisticsAndCoordinationTabError = isLogisticTabValid();
 
  const showDefaultBlueTick = generalInformationTabError && customerInformationTabError && thirdTabMandatoryDataCheck() && (demoCenterInformationTabError || demoCenterInformationForm.formData?.hardOrSoftwareAccessories?.length > 0) && logisticsAndCoordinationTabError;
  return (
    <div className='app-tabs'>
      <div className={`Zmcc-tab-header`}>
        <div className='d-flex'>
          {tabs.map((item, index, key) => (
            <div
              className={`tab cursor-pointer ${activeTab === item.id ? 'active active-tab-color' : ''} ${
                item.className
              }`}
              onClick={toggleTabFunc(item.id)}
            >
              <div className='tab-icon-and-name'>
                {item.tabTitle == 'generalInformation' &&
                  (generalInformationTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!generalInformationTabError  && transactionsId != null) || (!tabCompletionStatus[1] && !generalInformationTabError && activeTab !==1 )) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!generalInformationTabError && index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'customerInformation' &&
                  (customerInformationTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!customerInformationTabError && transactionsId != null) || (tabCompletionStatus.hasOwnProperty(2) && !tabCompletionStatus[2] && activeTab !==2 && !customerInformationTabError )) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!customerInformationTabError && index + 1}
                    </div>
                  ))}

                {(isSemBooking || zmccSemBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (applicationAndSamplesTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!applicationAndSamplesTabError && transactionsId != null) || ( tabCompletionStatus.hasOwnProperty(3) && !tabCompletionStatus[3] && activeTab !==3 && !applicationAndSamplesTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!applicationAndSamplesTabError && index + 1}
                    </div>
                  ))}
                {(isXbBooking || zmccXbBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (xbApplicationAndSamplesTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!xbApplicationAndSamplesTabError && transactionsId != null) || ( tabCompletionStatus.hasOwnProperty(3) && !tabCompletionStatus[3] && activeTab !==3 && !xbApplicationAndSamplesTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!xbApplicationAndSamplesTabError && index + 1}
                    </div>
                  ))}
                {(isXRMBooking || zmccXrmBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (xrmApplicationAndSamplesTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!xrmApplicationAndSamplesTabError && transactionsId != null) || (tabCompletionStatus.hasOwnProperty(3) &&!tabCompletionStatus[3] && activeTab !==3 && !xrmApplicationAndSamplesTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!xrmApplicationAndSamplesTabError && index + 1}
                    </div>
                  ))}

                {(isLMBooking || zmccLmBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (lmApplicationAndSamplesTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!lmApplicationAndSamplesTabError && transactionsId != null) || (tabCompletionStatus.hasOwnProperty(3) && !tabCompletionStatus[3] && activeTab !==3 && !lmApplicationAndSamplesTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!lmApplicationAndSamplesTabError && index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'demoCenterInformation' &&
                  ((demoCenterInformationTabError || demoCenterInformationForm.formData.hardOrSoftwareAccessories?.length > 0) ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!demoCenterInformationTabError && transactionsId != null) || ( tabCompletionStatus.hasOwnProperty(4) && !tabCompletionStatus[4] && activeTab !==4 && !demoCenterInformationTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!demoCenterInformationTabError && index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'additionalInformation' &&
                  ((additionalInformationMandatoryFilledTabError || showDefaultBlueTick) ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!additionalInformationMandatoryFilledTabError && index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'logisticsCoordination' &&
                  ((logisticsAndCoordinationTabError) ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!logisticsAndCoordinationTabError && transactionsId != null) || ( tabCompletionStatus.hasOwnProperty(6) && !tabCompletionStatus[6] && activeTab !=6 && !logisticsAndCoordinationTabError)) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ): (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'toDoList' &&
                  ((todosListTab || showDefaultBlueTick || todosListForm.formData.todoText?.length > 0 ) ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                <div>
                  {strings[item.tabTitle]} {!!item.count && `(${item.count})`}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='tab-content' id='nav-tabContent'>
        {tabs.map((item, key) => (
          <div
            className={`tab-pane fade ${activeTab === item.id ? 'show active' : ''}`}
            id='list-home'
            role='tabpanel'
            aria-labelledby='list-home-list'
            key={key}
          >
            {item.render(activeTab === item.id, { ...props })}
          </div>
        ))}
      </div>
    </div>
  );
}

ZMCCTabs.defaultProps = {
  toggleTab: true,
};

export default ZMCCTabs;

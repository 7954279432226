import React from 'react';

import strings from 'resources/locales/Translate';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';

const AttachmentsBlock = props => {
  const { title, downloadUrl, id, viewType, files } = props;

  return (
    <>
      {title && <h5 className='mb-3'>{title}</h5>}
      {files.length > 0 ? (
        <FileUploader
          mimeType='image/*'
          files={files}
          viewType={viewType}
          isDownloadable
          downloadStream
          downloadIdField={id}
          downloadUrl={downloadUrl}
          isUploadAllowed={false}
        />
      ) : (
        <div className='d-flex justify-content-center my-3'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}
    </>
  );
};

export default AttachmentsBlock;

import moment from 'moment';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import { fetchZmccKitsBookingAndOperatorSchedules } from 'modules/Calendar/calendar-services';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { createAlreadyBookedData } from 'modules/Calendar/CalendarActions';
import { createData as createKitsData } from 'modules/Calendar/CalendarActions';
import { createOperatorEvents } from 'modules/Calendar/CalendarActions';
import { timeSlotList } from 'library/utilities/constants';
import { fetchOperatorScheduleDetails } from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.actions';

const DATE_FORMAT = 'YYYY-MM-DD';

export const useOperatorSchedule = (
  trasanctionDetails,
  formType,
  updateZmccInformationTab,
  getDataToSendFromInformationTab,
  closeModal,
  editing,
  updateAssignOperatorsForZmcctranscation,
) => {
  const [selectedFromTime, setSelectedFromTime] = useState({ value: '', fullValue: '' });
  const [selectedToTime, setSelectedToTime] = useState({ value: '', fullValue: '' });
  const [selectedOperators, setSelectedOperators] = useState(trasanctionDetails?.operators || []);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectOperatorError, setSelectOperatorError] = useState('');
  const [selectedKits, setSelectedKits] = useState({});
  const [alreadyBooked, setAlreadyBooked] = useState([]);
  const [operatorScheduleAlternateDate, setOperatorScheduleAlternateDate] = useState(
    trasanctionDetails?.alternateDate ? moment(trasanctionDetails?.alternateDate).toDate() : new Date(),
  );
  const [operatorScheduleSuggestedDate, setOpertorScheduleSuggestedDate] = useState(null);
  const [operatorEvents, setOperatorEvents] = useState([]);
  const [allDay, setAllDay] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (trasanctionDetails) {
      setOpertorScheduleSuggestedDate(prev =>
        getValidDate(trasanctionDetails.operatorScheduleSuggestedDate, trasanctionDetails.suggestedDate),
      );
      setOperatorScheduleAlternateDate(prev =>
        getValidDate(trasanctionDetails.operatorScheduleAlternateDate, trasanctionDetails.alternateDate),
      );
      if (trasanctionDetails?.operatorScheduleSuggestedDate) {
        getScheduledDetailes();
      }
      let selectedOperatorsList = trasanctionDetails?.zmccOperatorsList;
      if (selectedOperatorsList?.length) {
        let finalList = selectedOperatorsList.map(operator => {
          return operator?.user?.accountId;
        });
        setSelectedOperators(finalList);
      } else {
        setSelectedOperators([]);
      }
    }
  }, [trasanctionDetails]);

  useEffect(() => {
    if (operatorScheduleSuggestedDate !== null || operatorScheduleSuggestedDate !== undefined) {
      getKitsAndSchedules(operatorScheduleSuggestedDate);
    }
  }, [operatorScheduleSuggestedDate]);

  const getScheduledDetailes = async () => {
    const response = await fetchOperatorScheduleDetails(
      moment(trasanctionDetails?.operatorScheduleSuggestedDate).format(DATE_FORMAT),
      trasanctionDetails?.transactions?.transactionsId,
    );
    if (response.success) {
      let info = response.data;
      setAllDay(info.allDay ? true : false);
      if (info?.startTime && info?.endTime) {
        setSelectedFromTime(prevState => ({
          value: moment(info.startTime, 'HH:mm'),
          fullValue: {
            id: moment(info.startTime, 'HH:mm'),
            value: moment(info.startTime, 'HH:mm').format('hh:mm A'),
          },
        }));
        setSelectedToTime(prevState => ({
          value: moment(info.endTime, 'HH:mm'),
          fullValue: {
            id: moment(info.endTime, 'HH:mm'),
            value: moment(info.endTime, 'HH:mm').format('hh:mm A'),
          },
        }));
      }else{
        setSelectedFromTime('');
        setSelectedToTime('');
      }
    }
  };
  const getKitsAndSchedules = async date => {
    if (date === null) {
      return;
    }
    let additionalInstrumentIds = trasanctionDetails.additionalInstruments?.length
      ? trasanctionDetails.additionalInstruments
          .filter(info => info?.kitInformation?.kitInformationId > 0)
          .map(info => info?.kitInformation?.kitInformationId)
      : [];
    setLoading(true);
    const primaryInstrument = trasanctionDetails.kitInformation?.kitInformationId;
    let zmccKits = [primaryInstrument, ...additionalInstrumentIds];
    let sendData = {
      filter: { multipleKits: zmccKits },
    };
    let requestDate = getStartAndEndOfWeek(date);
    let requestUrl = `${URLS.operatorsForZmccCalendar}?calendarSearch=true&fromDate=${requestDate.startOfWeek}&toDate=${requestDate.endOfWeek}`;
    const result = await fetchZmccKitsBookingAndOperatorSchedules(requestUrl, sendData);
    setSelectedKits(createKitsData(result.data));
    const alreadyBooked = createAlreadyBookedData(result.data.content, true);
    setAlreadyBooked(alreadyBooked);
    setOperatorEvents(createOperatorEvents(result.data.operatorSchedules));
    setLoading(false);
  };

  const getStartAndEndOfWeek = givenDate => {
    let date = moment(givenDate).toDate();
    let startOfWeek = moment(date).startOf('isoWeek').format(DATE_FORMAT);
    let endOfWeek = moment(date).endOf('isoWeek').format(DATE_FORMAT);
    return {
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    };
  };

  const getValidDate = (primaryDate, fallbackDate) => {
    if (primaryDate && moment(primaryDate).isValid()) {
      return moment(primaryDate).toDate();
    } else if(fallbackDate && moment(fallbackDate).isValid()) {
      return moment(fallbackDate).toDate();
    } else {
      return moment().toDate();
    }
  };

  const onChangeSuggestedDate = date => {
    setOpertorScheduleSuggestedDate(date);
    setErrors(prev => ({ ...prev, suggestedDateError: '' }));
  };

  const onChangeFromTime = (value, field, selectedFullValue) => {
    setSelectedFromTime({ value, fullValue: selectedFullValue });
    setErrors(prev => ({ ...prev, fromTimeError: '' }));
  };

  const onChangeToTime = (value, field, selectedFullValue) => {
    setSelectedToTime({ value, fullValue: selectedFullValue });
    setErrors(prev => ({ ...prev, toTimeError: '' }));
  };

  const onChangeAlternateDate = date => {
    setOperatorScheduleAlternateDate(date);
    setErrors(prev => ({ ...prev, alternateDateError: '' }));
  };

  const validateInputs = () => {
    let validations = {};
    if (!operatorScheduleSuggestedDate || !moment(operatorScheduleSuggestedDate).isValid()) {
      validations['suggestedDateError'] = 'requiredErrorMessage';
    }
    if (!operatorScheduleAlternateDate || !moment(operatorScheduleAlternateDate).isValid()) {
      validations['alternateDateError'] = 'requiredErrorMessage';
    }
    if (
      moment(operatorScheduleSuggestedDate).format(DATE_FORMAT) ===
      moment(operatorScheduleAlternateDate).format(DATE_FORMAT)
    ) {
      validations['alternateDateError'] = 'suggestedAndALternateCantBeSame';
    }
    if (!allDay) {
      if (!selectedFromTime.value) {
        validations['fromTimeError'] = 'requiredErrorMessage';
      }
      if (!selectedToTime.value) {
        validations['toTimeError'] = 'requiredErrorMessage';
      }
    } else {
      setSelectedFromTime('');
      setSelectedToTime('');
    }
    if (
      selectedFromTime.value &&
      selectedToTime.value &&
      moment(selectedFromTime.value, 'HH:mm').isAfter(moment(selectedToTime.value, 'HH:mm'))
    ) {
      validations['toTimeError'] = 'endTimeShouldBeLessThanStartTime';
    }
    return { isValid: !(Object.keys(validations).length > 0), validations };
  };

  const onClickAssign = async () => {
    setErrors(prev => ({ ...prev, operatorsAssignError: '' }));
    if (!validateInputs().isValid) {
      return setErrors({ ...validateInputs().validations });
    }
    const dataToSendFromInfoTab = await getDataToSendFromInformationTab();
    let selectedOperatorsList = trasanctionDetails?.zmccOperatorsList;
    let alreadySelectedOperators = [];
    if (selectedOperatorsList && selectedOperatorsList?.length) {
      alreadySelectedOperators = selectedOperatorsList.map(operator => {
        return operator?.user?.accountId;
      });
    }

    let deletedOperators = alreadySelectedOperators.filter(id => !selectedOperators.includes(id));

    const dataToSend = {
      ...dataToSendFromInfoTab,
      operators: selectedOperators,
      operatorScheduleDate: moment(operatorScheduleSuggestedDate).format(DATE_FORMAT),
      operatorScheduleStartTime: moment(selectedFromTime.value, 'HH:mm').format('HH:mm'),
      operatorScheduleEndTime: moment(selectedToTime.value, 'HH:mm').format('HH:mm'),
      transactionId: trasanctionDetails?.transactions?.transactionsId,
      deleteOperatorIds: deletedOperators,
      allDay: allDay,
      operatorScheduleAlternateDate: moment(operatorScheduleAlternateDate).format(DATE_FORMAT),
    };

    setButtonLoading(true);
    setSelectOperatorError('');
    try {
      let success = false;
      setSelectOperatorError('');
      if (editing) {
        const response = await updateZmccInformationTab(dataToSend, formType, { showErrorMessageOnPopup: true });
        if (response?.success) {
          success = true;
        } else {
          setErrors(prev => ({
            ...prev,
            operatorsAssignError: strings[response.message] || response.message || strings.somethingWentWrongMessage,
          }));
        }
      } else {
        const response = await updateAssignOperatorsForZmcctranscation(dataToSend, formType, {
          showErrorMessageOnPopup: true,
        });
        if (response?.success) {
          success = true;
        } else {
          setErrors(prev => ({
            ...prev,
            operatorsAssignError: strings[response.message] || response.message || strings.somethingWentWrongMessage,
          }));
        }
      }
      if (success) {
        closeModal();
        getScheduledDetailes();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setButtonLoading(false);
    }
  };

  const getSelectedOperatorsCallback = accountId => {
    setSelectedOperators(prev => {
      if (prev && prev.length && prev.includes(accountId)) {
        return prev.filter(id => id !== accountId);
      } else {
        return [...prev, accountId];
      }
    });
  };

  const handleAllDay = (val) => {
    setAllDay(val);
    setSelectedFromTime('')
    setSelectedToTime('')
    setErrors(prev => ({...prev, fromTimeError: '', toTimeError: ''}))
  }

  return {
    selectedFromTime,
    selectedToTime,
    timeSlotList,
    onChangeSuggestedDate,
    onChangeFromTime,
    onChangeToTime,
    selectedOperators,
    loading,
    setLoading,
    onClickAssign,
    buttonLoading,
    selectOperatorError,
    selectedKits,
    alreadyBooked,
    operatorEvents,
    allDay,
    handleAllDay,
    operatorScheduleSuggestedDate,
    operatorScheduleAlternateDate,
    getSelectedOperatorsCallback,
    onChangeAlternateDate,
    errors,
    selectedOperators,
  };
};

export const ADD_BOOKING = '@@CALENDAR/ADD_BOOKING';
export const FETCH_AVAILABLE_KIT_LIST = '@@CALENDAR/FETCH_AVAILABLE_KIT_LIST';
export const FETCH_STOCKS_FOR_USERS = '@@CALENDAR/FETCH_STOCKS_FOR_USERS';
export const UPDATE_FILTERS = '@@CALENDAR/UPDATE_FILTERS';
export const UPDATE_LABS_LOCATION_FILTERS = '@@CALENDAR/UPDATE_LABS_LOCATION_FILTERS';
export const ICON_URL = 'http://chart.googleapis.com/chart?chst=d_map_spin&chld=1.15|0|0091ff|40|_|%E2%80%A2';
export const UPDATE_ZMCC_FILTERS = '@@CALENDAR/UPDATE_ZMCC_FILTERS';
export const FETCH_OPERATORS_LIST = '@@FETCH_OPERATORS_LIST';
export const UPDATE_ZMCC_DATE_FILTERS = 'CALENDAR/UPDATE_ZMCC_DATE_FILTERS'
export const CLEAR_ZMCC_EXISTING_DATA = "CALENDAR/CLEAR_ZMCC_EXISTING_DATA"

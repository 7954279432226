import React, { useState } from 'react';
import Icon from 'library/common/commonComponents/Icon/Icon';
// import EditStockAddressPopUp from '../Information/EditStockAddress/EditStockAddress.component';
import { EditStockAddressPopUp } from '../../Tabs/Information/EditStockAddress/EditStockAddress.component';
import $ from 'jquery';

export const AddressList = ({ addressList, editAddress, stockId, deleteAddress, canEditStock, isRMSZMCCStock, allProvinces, isLLStock }) => {
  const [editAddressList, setEditAddresList] = useState({});
  const onEdit = item => {
    setEditAddresList(item);
    $('#edit-modal').modal('show');
  };
  const onDelete = async id => {
    await deleteAddress(id, stockId);
  };
  return (
    <div className='row' style={{ overflowWrap: 'break-word' }}>
      {addressList &&
        addressList.length > 0 &&
        addressList.map(item => (
          <>
            <div className='col-4 mb-5'>
              <b>
                <div>
                  {item.addressType} {item.activeAddress && '(Default)'}
                </div>
              </b>
              <div>
                {item.firstName} {item.lastName}
              </div>
              <div>{item.organization}</div>
              <div>{item.department || ''}</div>
              <div>
                {item.street} {item.houseNumber ? item.houseNumber : ''}
              </div>
              <div>
                {item.postalCode} {item.city}
              </div>
              <div>{(item.state && allProvinces) ?  allProvinces.find(s => s.stateId  === item.state).stateName : item.stateName || ''}</div>
              <div>{item.country && item.country.name}</div>
              <div>{item.phone}</div>
              <div>{item.email}</div>
              {canEditStock && (
                <div className='mt-3'>
                  <button onClick={() => onEdit(item)} style={{ border: '1px solid #0088D0', backgroundColor: '#fff' }}>
                    <Icon name={'edit'} width={24} height={30} fill={'#0088d0'} />
                  </button>

                  {!item.activeAddress && (
                    <button
                      onClick={() => onDelete(item.addressId)}
                      disabled={item.activeAddress}
                      style={{ border: '1px solid #0088D0', marginLeft: '10px', backgroundColor: '#fff' }}
                    >
                      <Icon name={'delete'} width={24} height={30} fill={'#0088d0'} />
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        ))}
      <EditStockAddressPopUp id={'edit-modal'} address={editAddressList} editAddress={editAddress} stockId={stockId} isRMSZMCCStock={isRMSZMCCStock}  allProvinces={allProvinces} isLLStock={isLLStock}/>
    </div>
  );
};

export default AddressList;

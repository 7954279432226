import {connect} from "react-redux";

import QuickStart from "../QuickStart";

const mapStateToProps = state => {
  return {
    language: state.languageReducer
  }
};

export default connect(mapStateToProps)(QuickStart);

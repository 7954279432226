import React from 'react';
import cn from 'classnames';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';

import { useStockDashboardFilterHook } from './StockDashboardFilter.hook';

const StockDashboardFilter = ({
  isShowFilter,
  filters,
  setFilters,
  businessUnits,
  countries,
  stocks,
  getStockByCountry,
  getStatistics,
}) => {
  const { handleFilter } = useStockDashboardFilterHook({
    filters,
    businessUnits,
    countries,
    stocks,
    setFilters,
    getStockByCountry,
    getStatistics,
  });

  return (
    <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <Dropdown
            label={strings.businessGroup}
            data={businessUnits}
            field='businessGroup'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.businessGroup}
            fullValue={filters.businessGroupFullValue}
          />
          <Dropdown
            label={strings.countryLand}
            data={countries}
            filter
            field='country'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.country}
            fullValue={filters.countryFullValue}
          />
          <Dropdown
            label={strings.stock}
            data={stocks}
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            idKey='id'
            field='stock'
            displayValue={[{ key: 'locationName', separator: '' }]}
            isRequired={true}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.stock}
            fullValue={filters.stockFullValue}
          />
        </div>
      </div>
    </div>
  );
}

export default StockDashboardFilter;

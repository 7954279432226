import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import InRefurbishment from '../../../CreateKit/Tabs/Information/InRefurbishmentPopup';
import { useInformationTab } from './InformationTab.hook';

const CreateZmccKitInformation = props => {
  const {
    informationForm,
    kitStatusForm,
    cancelCreateKit,
    saveKit,
    handleForm,
    conditions,
    updateForm,
  } = props;
  const {
    informationModel,
    kitConditionModel,
  } = useInformationTab(props);

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.information}</h3>
            <FormComponent
              model={informationModel}
              formName='informationForm'
              formValue={informationForm.formData}
              onChange={handleForm}
            />
          </div>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.kitStatus}</h3>
            <FormComponent
              model={kitConditionModel}
              formName='kitStatusForm'
              formValue={kitStatusForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} />
      </div>
      <InRefurbishment
        id='inRefurbishmentStatus'
        conditions={conditions}
        kitStatusForm={kitStatusForm}
        updateFormCreateKit={updateForm}
      />
    </div>
  );
};

export default CreateZmccKitInformation;

import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import CreateKit from './CreateKit.component';
import { fetchKitDetail, updateFormCreateKit, resetCreateKit, saveCreateKit } from './CreateKit.actions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { deletereparationSlotForKit } from '../KitDetails/KitDetailsAction';

const mapStateToProps = ({ appActionsReducer, authReducer, createKitReducer, languageReducer, masterDataReducer }) => ({
  appActions: appActionsReducer,
  formValue: {
    generalInformationForm: createKitReducer.generalInformationForm,
    generalInformationKitForSaleForm: createKitReducer.generalInformationKitForSaleForm,
    furtherImportantComponentForm: createKitReducer.furtherImportantComponentForm,
    salesComponentForm: createKitReducer.salesComponentForm,
    freeAccessoriesForm: createKitReducer.freeAccessoriesForm,
    kitImages: createKitReducer.kitImages,
    kitImagesToDelete: createKitReducer.kitImagesToDelete,
    kitAttachments: createKitReducer.kitAttachments,
    kitAttachmentsToDelete: createKitReducer.kitAttachmentsToDelete,
    vrDetalisForm: createKitReducer.vrDetalisForm,
  },
  kitDetails: createKitReducer.kitDetails,
  sellOffCurrency: masterDataReducer.sellOffCurrency,
  kitImages: createKitReducer.kitImages,
  kitInformationId: createKitReducer.kitInformationId,
  language: languageReducer,
  user: authReducer.user,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchKitDetail,
  updateFormCreateKit,
  saveCreateKit,
  resetCreateKit,
  toggleActionMessage,
  deletereparationSlotForKit
})(CreateKit);

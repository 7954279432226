import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import { scrollToTop } from 'library/utilities/scrollActions';

import {
  furtherImportantComponentModel,
  salesComponentModel,
  optionalSalesComponentModel,
} from './Components.constants';
import './Components.style.scss';

const TAB_ID = 2;

export const CreateKitComponents = props => {
  const { formValue, saveKit, cancelCreateKit, handleForm, isOptional, toggleActionMessage } = props;
  const { furtherImportantComponentForm, salesComponentForm } = formValue;

  const componentModel = isOptional ? optionalSalesComponentModel : salesComponentModel;
  const regex = /[><]/;
  const specialCharactersEntry = regex.test(salesComponentForm.formData.salesComponents);

  const handleSaveKit = () => {
    if (!specialCharactersEntry) {
      saveKit(TAB_ID);
    } else {
      toggleActionMessage(true, 'error', 'restrictSpecialCharacters');
      scrollToTop(500);
    }
  };

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.salesComponentLabel}</h3>
            <FormComponent
              model={componentModel}
              formName='salesComponentForm'
              formValue={salesComponentForm.formData}
              onChange={handleForm}
            />
          </div>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.furtherImportantComponentLabel}</h3>
            <FormComponent
              model={furtherImportantComponentModel}
              formName='furtherImportantComponentForm'
              formValue={furtherImportantComponentForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={handleSaveKit} onCancel={cancelCreateKit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateKitComponents;

import { connect } from 'react-redux';

import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';

import CreateStock from './CreateStock.component';
import { fetchStockDetails, cancelStockCreation, setStockId, getPendingKitsListByWarehouseWhereSlotIsNotAvailable, getAllActiveProvinceCountries } from './CreateStock.actions';
import { fetchMasterDataOnLanguageChange } from 'modules/Booking/BookingActions';

const mapStateToProps = state => {
  return {
    masterData: state.masterDataReducer,
    createStockReducer: state.createStockReducer,
    language: state.languageReducer,
    appActions: state.appActionsReducer,
  };
};

export default connect(mapStateToProps, {
  fetchMasterData,
  setStockId,
  cancelStockCreation,
  fetchStockDetails,
  fetchMasterDataPost,
  fetchMasterDataOnLanguageChange,
  getPendingKitsListByWarehouseWhereSlotIsNotAvailable,
  getAllActiveProvinceCountries
})(CreateStock);

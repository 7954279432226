import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Loader from 'library/common/commonComponents/Loader';
import { getChangeHistory } from '../../TransactionDetailsActions';

export const ChangeHistory = ({ changeLog, getChangeHistory, transactionDetails, transactionsId , language, match }) => {
  const [loading, setLoading] = useState(false);
  const isZmccTrans = match && match.url.includes('isZmcc');
  const zmccTransactionId = match && match.params.id;
  useEffect(() => {
    setLoading(true);
   
    if (isZmccTrans) {
      getChangeHistory({ page: 1, transactionsId: zmccTransactionId }).then(res => {
        setLoading(false);
      });
    }else{
      getChangeHistory({ page: 1, transactionsId }).then(res => {
        setLoading(false);
      });
    }
    
  }, [getChangeHistory, transactionsId , language, match]);

  const calculateDate = date => {
    let diff = '';
    if (moment(date).isValid) {
      diff = `${moment.duration(moment().diff(moment(date))).humanize()} ago`;
    }
    return diff;
  };

  const onLoadMore = () => {
    setLoading(true);
    getChangeHistory({ transactionsId, page: changeLog.number + 1 }).then(res => {
      setLoading(false);
    });
  };

  const makeBoldToHeaders = (text) => {
    text = text.replace(/([^,]+?):/g, '<b>$1:</b>');
    return text;
  }

  const renderChangeLog = () => {
    return (
      <>
        <div className='section-title mt-0'>{strings.changeHistory}</div>
        {changeLog.content && changeLog.content.length ? (
          <>
            <div>
              {changeLog.content.map((item, i) => (
                <div
                  key={item.id}
                  className={changeLog.content.length === i + 1 ? 'change-log-item no-border' : 'change-log-item'}
                  data-test='change-log-item'
                >
                  <div>
                    {item.createdBy ? 
                    <span>
                      <span className='font-weight-bold'>{item.createdBy && item.createdBy.firstName}</span>
                      <span className='font-weight-bold'>
                        {item.createdBy && item.createdBy.firstName && item.createdBy.firstName && ' '}
                        {item.createdBy && item.createdBy.lastName}
                      </span>
                    </span>
                    :
                    <span className='font-weight-bold'>{item.type == 3 && strings.updatedBySystem}</span>
                    }
                    <span className='change-history-time-text'> {calculateDate(item.createdAt)}</span>
                  </div>
                  <div className='d-flex flex-column flex-lg-row justify-content-start align-items-start'>
                    {item.createdBy &&
                      item.createdBy.userAddresses &&
                      item.createdBy.userAddresses[0] &&
                      item.createdBy.userAddresses[0].phone && (
                        <div className='d-flex flex-row align-items-center mr-3'>
                          <Icon name='phone' width={20} height={20} fill='#0088d0' />
                          <span className='phone-email-text mt-1 ml-1'>{item.createdBy.userAddresses[0].phone}</span>
                        </div>
                      )}
                    <div className='d-flex flex-row align-items-center'>
                      {item.createdBy && <Icon name='message' width={22} height={20} fill='#0088d0' />}
                      <span className='phone-email-text mt-1 ml-1'>{item.createdBy && item.createdBy.email}</span>
                    </div>
                  </div>
                  <div>{item.displayMessage.includes('\n') ? item.displayMessage.split("\n").map((t,key) => {
                      return <p key={key} dangerouslySetInnerHTML={{ __html: makeBoldToHeaders(t) }}></p>;
                     }):item.displayMessage}</div>
                </div>
              ))}
            </div>
            {!changeLog.last && (
              <div className='d-flex flex-row justify-content-center mt-3'>
                <Button
                  dataTest='change-history-load-more'
                  bgFill={false}
                  iconName='refresh'
                  loading={loading}
                  value={strings.loadMore}
                  styleClass='btn-lighter message-load-more-button'
                  onClick={onLoadMore}
                />
              </div>
            )}
          </>
        ) : (
          <p>{strings.noDataFoundText}</p>
        )}
      </>
    );
  };

  return (
    <>
      {transactionDetails && transactionDetails.c2cLoan && (
        <div className={'blue-bg-message'}>
          <div className='container-fluid'>{strings.c2cLoanProcessText(transactionDetails.c2cId)}</div>
        </div>
      )}
      <div className='container-fluid pt-3 pb-3'>{changeLog.content || !loading ? renderChangeLog() : <Loader />}</div>
    </>
  );
};

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, transactionDetailsReducer }) => ({
  changeLog: transactionDetailsReducer.changeLog,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  transactionsId:
    transactionDetailsReducer.transactionDetails && transactionDetailsReducer.transactionDetails.transactionsId,
});

export default connect(
  mapStateToProps,
  { getChangeHistory },
)(ChangeHistory);

import React from 'react';

import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';

import { useAttachments } from './Attachments.hook';
import './attachments.style.scss';

export default function TransactionAttachments({
  user,
  attachments,
  deletedAttachmentIds,
  deleteAttachments,
  addAttachments,
  editing,
  transactionDetails,
  uploadLoanAttachment,
  deleteLoansAttachments,
  fetchTransactionDetails,
  clearAttachments,
  toggleActionMessage,
}) {
  const {
    hasPermissions,
    viewType,
    isLoading,
    handleSaveAttachments,
    handleDeleteAttachments,
    addAttachmentFile,
    deleteAttachmentFile,
    toggleToListViewType,
    toggleToGridViewType,
  } = useAttachments({
    user,
    attachments,
    deletedAttachmentIds,
    deleteAttachments,
    addAttachments,
    transactionDetails,
    uploadLoanAttachment,
    deleteLoansAttachments,
    fetchTransactionDetails,
    clearAttachments,
    toggleActionMessage,
  });

  return (
    <div className='transaction-attachments-container'>
      <div className='container-fluid form-container'>
        <div className='toggle-container d-flex align-items-center justify-content-between'>
          <h3 className='form-section-title mb-0'>{strings.attachments}</h3>
          <div className='buttons'>
            <button onClick={toggleToListViewType}>
              <Icon
                name='list_view'
                width={32}
                height={32}
                fill={viewType === 'list' ? '#000' : '#e0e1dd'}
              />
            </button>
            <button onClick={toggleToGridViewType} data-test="test-button-grid">
              <Icon
                name='grid_view'
                width={32}
                height={32}
                fill={viewType === 'grid' ? '#000' : '#e0e1dd'}
              />
            </button>
          </div>
        </div>

        <FileUploader
          maxSize={10}
          maxFiles={30}
          mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxm'
          files={attachments}
          viewType={viewType}
          isDownloadable
          downloadStream
          downloadIdField='transactionAttachmentsId'
          downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadLoanAttachment}
          isUploader={!editing && hasPermissions}
          errorMessage={strings.allowedFormats}
          onFileAdd={addAttachmentFile}
          onRemove={deleteAttachmentFile}
          isNotRemoved={!editing}
        />
        {((hasPermissions && !editing) || ((attachments.length > 0 || deletedAttachmentIds.length > 0) && editing)) && (
          <Button
            dataTest='edit-transaction-button'
            bgFill={false}
            iconName='save'
            onClick={editing ? handleDeleteAttachments : handleSaveAttachments}
            value={strings.save}
            styleClass='save-button '
            loading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

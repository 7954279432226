import SmallButton from 'library/common/commonComponents/SmallButton';
import { getIconByCondition, getIconByLLTransactionStatus, getIconByZmccTransactionStatus } from 'library/utilities/getStatusAndConditionIcons';
import { get } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import strings from 'resources/locales/Translate';
import CancellationRequestPopup from '../CancellationRequestPopup/CancellationRequestPopup.component';

const ZmccDataExpandableTable = ({
  data,
  match,
  row,
  setRejectCancelRequest,
  rejectCancelRequest,
  saveCancellationRequest,
  user,
}) => {
  const { transactionKit, transactionStatus, transactionPosition, isCancellationRequest, transactionsId } = row || {};
  const kitCondition = get(transactionKit, 'kitInformation.kitCondition.kitConditionTranslated');
  const hasKitCondition = kitCondition && kitCondition.length > 0;
  const status = get(transactionStatus, 'transactionStatusId') || '';
  const notCancelledStatus = status !== 5;
  const isExpandable = true;
  const hasPermissions = () => {
    let isAllowed = false;
    if (!isAllowed && user && user.stockRoles && user.stockRoles.length) {
      const demoLoanStockInformation = get(transactionKit, 'kitInformation.stockInformation') || {};
      const demoLoanStockId = demoLoanStockInformation.id;

      if (demoLoanStockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'stock controller' || role.roleName.toLowerCase() === 'dispatcher') &&
            demoLoanStockId === role.stockId
          ) {
            isAllowed = true;
          }
        });
      }
    }
    if (user && user.admin) {
      isAllowed = true;
    }
    return isAllowed;
  };
  return (
    <>
      <td />
      <td />
      <td colSpan='12'>
        <div className='d-flex flex-wrap mb-3'>
          {status && getIconByZmccTransactionStatus(status, isExpandable)}
          {hasKitCondition && getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}
        </div>
        <div className='mb-2'>
          <div className='d-flex'>
            <NavLink
              to={{
                pathname: `${match.url}/${transactionsId}/isZmcc?detailPage=${1}`,
              }}
              exact={true}
            >
              <SmallButton iconName='visibility' value={strings.showDetails} />
            </NavLink>
            {notCancelledStatus && isCancellationRequest && (
              <div className='d-flex'>
                <span className='pt-1 pl-4'>{strings.cancelRequestRaised}</span>
                {hasPermissions() && (
                  <>
                    <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                      <SmallButton bgFill={false} iconName='' value={strings.cancelBooking} className='ml-3' />
                    </div>
                    <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                      <SmallButton
                        bgFill={false}
                        iconName=''
                        value={strings.rejectCancelRequest}
                        className='ml-3'
                        onClick={() => setRejectCancelRequest(true)}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            {!isCancellationRequest && notCancelledStatus && (
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                <SmallButton
                  bgFill={false}
                  iconName=''
                  value={strings.requestCancel}
                  className='ml-3'
                  onClick={() => setRejectCancelRequest(false)}
                />
              </div>
            )}
          </div>
        </div>
      </td>
      <CancellationRequestPopup
        id={`cancellationRequestPopup${row.transactionsId}`}
        transactionsId={row.transactionsId}
        saveCancellationRequest={saveCancellationRequest}
        isFinalCancel={isCancellationRequest}
        isRejectCancel={rejectCancelRequest}
        setRejectCancelRequest={setRejectCancelRequest}
      />
    </>
  );
}; 

export default ZmccDataExpandableTable;
import React, { Component } from 'react';

import strings from 'resources/locales/Translate';

import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { scrollToTop } from 'library/utilities/scrollActions';
import { getllStockRolesForMessages, getRMSZMCCStockRolesForMessages } from 'library/common/commonConstants/roles';

import './EmailMessages.style.scss';

export default class EmailMessages extends Component {
  state = {
    emailMessages: null,
    loading: false,
  };

  componentDidMount() {
    this.fetchEmailMessages();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.emailMessages && this.props.isActive && !prevProps.isActive) {
      this.fetchEmailMessages();
    }
    if (prevProps.language && this.props.language && prevProps.language.language !== this.props.language.language) {
      this.fetchEmailMessages();
    }
  }

  fetchEmailMessages = async () => {
    if (this.props.stockId && !this.state.loading) {
      this.setState({ loading: true }, async () => {
        let { success, data } = await this.props.fetchEmailMessages(this.props.stockId);
        let updatedData = [...data];
        const isLabsLocation = this.props.stockDetails && this.props.stockDetails.businessUnitFullValue.designation === 'RMS_L_L';
        const isRMSZMCC = this.props.stockDetails && this.props.stockDetails.businessUnitFullValue.designation === 'RMS_ZMCC';

        let orderConfirmationEnglish = data && data.filter(item => item.messageName == 'Order confirmation (Send when status: "Deliver or Confirmed" (MED US))');
        if ((isLabsLocation || isRMSZMCC) && orderConfirmationEnglish.length>0){
          updatedData = updatedData.filter(item => item.messageName !== 'Order confirmation (Send when status: "Deliver or Confirmed" (MED US))');
          orderConfirmationEnglish[0].messageName = 'Order confirmation (Send when status: "Deliver")';
          updatedData = [...updatedData, ...orderConfirmationEnglish];
        }
        if (success) {
          this.setState({ emailMessages: data, loading: false });
        }
      });
    }
  };

  onScrollTable = $event => {
    document.getElementById('fix-column-tbody').scrollTop = $event.target.scrollTop;
    document.getElementById('rest-columns-thead').scrollLeft = $event.target.scrollLeft;
  };

  saveEmailMessages = () => {
    const { emailMessages } = this.state;
    const { businessUnitFullValue, crmAccountId } = this.props;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    const arrayToSend = [];
    if (isRMSLL) {
      if (!crmAccountId) {
        scrollToTop(500);
        this.props.toggleActionMessage(true, 'error', 'crmAccountIdError');
        return;
      }
  }
    if (emailMessages.length) {
      emailMessages.forEach(emailMessage => {
        emailMessage.roleAndStatus.forEach(roleStatus => {
          if (roleStatus.isActive) {
            const roleAndStatusToSend = {
              messagesId: emailMessage.messagesId,
              roleId: roleStatus.roleId,
              status: roleStatus.status,
            };
            arrayToSend.push(roleAndStatusToSend);
          }
        });
      });
      this.props.saveUpdateEmailMessages(arrayToSend, this.props.stockId);
    } else {
      this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
    }
    scrollToTop(500);
  };

  onChange = (messageId, roleAndStatusId) => checked => {
    const { emailMessages } = this.state;
    const messageIndex = emailMessages.findIndex(item => item.messagesId === messageId);
    if (messageIndex > -1) {
      const roleStatusIndex = emailMessages[messageIndex].roleAndStatus.findIndex(
        item => item.roleId === roleAndStatusId,
      );
      if (roleStatusIndex > -1) {
        if (emailMessages[messageIndex].roleAndStatus[roleStatusIndex].isActive) {
          emailMessages[messageIndex].roleAndStatus[roleStatusIndex].status = checked;
        }
      }
    }
    this.setState({ emailMessages: emailMessages });
  };

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  render() {
    const { canEditStock, rolesList, stockDetails } = this.props;
    const { emailMessages } = this.state;
    const roles = getllStockRolesForMessages();
    const zmccRoles = getRMSZMCCStockRolesForMessages();
    const isLabsLocation =
    stockDetails && stockDetails.businessUnitFullValue.designation === 'RMS_L_L';
    const isRMSZMCC = stockDetails && stockDetails.businessUnitFullValue.designation === 'RMS_ZMCC';
    return (
      <div className='message-container message-container--no-border'>
        <div className='container-fluid form-container'>
          <div className='row'>
            <div className='col-sm-12'>
              <h4 className='form-title'>{strings.messages}</h4>
              <div></div>
              {!emailMessages ||
                (!emailMessages.length && <div className='text-center'> {strings.noDataFoundText}</div>)}
              {emailMessages && !!emailMessages.length && (
                <div className='total-wrapper'>
                  <div className='fix-column'>
                    <div className='thead'>
                      <span></span>
                    </div>
                    <div className='tbody' id='fix-column-tbody'>
                      {emailMessages.map((emailMessage, index) => (
                        <>
                          {index === 0 && (
                            <div className='categoryText'>
                              <span>{emailMessage.categoryName}</span>
                            </div>
                          )}
                          {emailMessages[index - 1] &&
                            emailMessages[index - 1].categoryName !== emailMessages[index].categoryName && (
                              <div className='categoryText'>
                                <span>{emailMessage.categoryName}</span>
                              </div>
                            )}
                          <div className='trow text-truncate' key={index} title={emailMessage.messageName}>
                            <span className='text-truncate'>{emailMessage.messageName}</span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className='rest-columns'>
                    <div className='thead' id='rest-columns-thead'>
                      {!isLabsLocation && !isRMSZMCC && rolesList && rolesList.map(role => <span key={role.id}>{role.role}</span>)}
                      {(isLabsLocation && !isRMSZMCC) && roles && roles.map(role => <span key={role.id}>{role.role}</span>)}
                      {(!isLabsLocation && isRMSZMCC) && zmccRoles && zmccRoles.map(role => <span key={role.id}>{role.role}</span>)}
                    </div>
                    <div className='tbody' id='rest-columns-tbody' onScroll={this.onScrollTable}>
                      {emailMessages.map((emailMessage, index) => (
                        <>
                          {index === 0 && (
                            <div className='categoryText'>
                              <span></span>
                            </div>
                          )}
                          {emailMessages[index - 1] &&
                            emailMessages[index - 1].categoryName !== emailMessages[index].categoryName && (
                              <div className='categoryText'>
                                <span></span>
                              </div>
                            )}
                          <div className='trow' key={index}>
                            {(isLabsLocation && !isRMSZMCC) &&
                              emailMessage.roleAndStatus.map(status =>
                                roles.map(
                                  role =>
                                    status.roleId === role.id && (
                                      <span className='email-messages-checkbox' key={status.roleId}>
                                        <Checkbox
                                          selected={status.status}
                                          key={status.roleId}
                                          disabled={!status.isActive || !canEditStock}
                                          onChange={this.onChange(emailMessage.messagesId, status.roleId)}
                                          data-test={`email-messages-checkbox${index}`}
                                        />
                                      </span>
                                    ),
                                ),
                              )}
                            {(!isLabsLocation && !isRMSZMCC) &&
                              emailMessage.roleAndStatus.map(status => (
                                <span className='email-messages-checkbox' key={status.roleId}>
                                  <Checkbox
                                    selected={status.status}
                                    key={status.roleId}
                                    disabled={!status.isActive || !canEditStock}
                                    onChange={this.onChange(emailMessage.messagesId, status.roleId)}
                                    data-test={`email-messages-checkbox${index}`}
                                  />
                                </span>
                              ))}
                            {(!isLabsLocation && isRMSZMCC) &&
                              emailMessage.roleAndStatus.map(status =>
                                zmccRoles.map(
                                  role =>
                                    status.roleId === role.id && (
                                      <span className='email-messages-checkbox' key={status.roleId}>
                                        <Checkbox
                                          selected={status.status}
                                          key={status.roleId}
                                          disabled={!status.isActive || !canEditStock}
                                          onChange={this.onChange(emailMessage.messagesId, status.roleId)}
                                          data-test={`email-messages-checkbox${index}`}
                                        />
                                      </span>
                                    ),
                                ),
                              )}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {emailMessages && !!emailMessages.length && canEditStock && (
            <CreateStockActionButtons onSave={this.saveEmailMessages} onCancel={this.cancelStockCreation} />
          )}
        </div>
      </div>
    );
  }
}

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import { useEditStockAddres } from './EditStockAddress.hook';
export const EditStockAddressPopUp = ({ id, address, editAddress, stockId, isRMSZMCCStock, allProvinces, isLLStock}) => {
  const { addressModel, handleForm, editAddressForm, onCloseClick, onSaveClick, error, loading } = useEditStockAddres({
    id,
    address,
    editAddress,
    stockId,
    isRMSZMCCStock,
    allProvinces,
    isLLStock
  });
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      title={strings.editAddress}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={addressModel()}
        formName='editAressForm'
        formValue={editAddressForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default EditStockAddressPopUp;

import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';

import { exportTransactionListModel } from './ExportTransactionList.constants';

export default function ExportTransactionList({
  countries,
  categoriesAndSystems,
  loanTypes,
  filters,
  id,
  exportTransactionList,
  searchType,
  transactionType,
  language,
  changeLoader
}) {
  const [loading, setLoading] = useState(false);
  const [model, updateModel] = useState([]);
  const [exportTransactionListForm, setExportTransactionListForm] = useState({ formData: {}, isFormValid: false });

  useEffect(() => {
    const transactionListModel = exportTransactionListModel({ countries, loanTypes, categoriesAndSystems, transactionType });
    updateModel(transactionListModel);
  }, [countries, loanTypes, categoriesAndSystems, transactionType]);


  useEffect(() => {
    if (countries && countries.length) {
      const isError = filters.stock && filters.stock.length > 20 ? 'maxLengthErrorMessage' : false;
      setExportTransactionListForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          stock: filters.stock,
          stockFullValue: filters.stockFullValue,
          stockError: isError,
        },
      }));
    }
  }, [countries, filters.stock, filters.stockFullValue]);

  useEffect(() => {
    if (loanTypes && loanTypes.length) {
      const loanTypeIds = [1,2,3];
      const isSelectedLoanTypes = loanTypeIds.includes(filters.loanType);
      setExportTransactionListForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          loanType: isSelectedLoanTypes ? filters.loanType : null,
          loanTypeFullValue: isSelectedLoanTypes ? filters.loanTypeFullValue : null,
          loanTypeError: null,
        },
      }));
    }
  }, [loanTypes, filters.loanType, filters.loanTypeFullValue]);

  useEffect(() => {
    if (categoriesAndSystems && categoriesAndSystems.length) {
      setExportTransactionListForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          systemClass: filters.systemClass,
          systemClassFullValue: filters.systemClassFullValue,
          systemClassError: null,
        },
      }));
    }
  }, [categoriesAndSystems, filters.systemClass, filters.systemClassFullValue]);

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: exportTransactionListForm,
      model,
    });
    if (!validForm.isFormValid) {
      setExportTransactionListForm(validForm);
      return;
    } else {
      const loanTypes = { 1: 'kit loan', 2: 'single loan', 3: 'course loan' };
      const stockIds = validForm.formData.stock;
      const transactionType = validForm.formData.loanType ? loanTypes[validForm.formData.loanType] : null;
      const systemMainComponentIds = validForm.formData.systemClass && validForm.formData.systemClass.length ? validForm.formData.systemClass : null;
      let dataToSend = {
        searchType,
        stockIds,
        transactionType,
        systemMainComponentIds,
      };
      if (validForm.formData.loanStartDate && validForm.formData.loanEndDate) {
        dataToSend = {
          ...dataToSend,
          loanStartDate: new Date(validForm.formData.loanStartDate).getTime(),
          loanEndDate: new Date(validForm.formData.loanEndDate).getTime(),
        }
      }
      setLoading(true);
      changeLoader(true)
      openContinueUseModal();
      onCloseClick();
      await exportTransactionList(dataToSend, language);
      setLoading(false);
      closeContinueUseModal();
    }
  };

   const openContinueUseModal = () =>{
    $('#continue-use-app').modal('show');
  }
  const closeContinueUseModal = () =>{
    $(`#continue-use-app`).modal('hide');
    changeLoader(false)
}
  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setExportTransactionListForm({
      ...exportTransactionListForm,
      formData: {
        stock: filters.stock,
        stockFullValue: filters.stockFullValue,
        stockError: exportTransactionListForm.formData.stockError === 'maxLengthErrorMessage' && 'maxLengthErrorMessage',
        systemClass: filters.systemClass,
        systemClassFullValue: filters.systemClassFullValue,
        systemClassError: null,
        loanType: filters.loanType,
        loanTypeError: null,
      },
    });
  };

  const checkDates = ({ field, values }) => {
    if (field === 'loanEndDate') {
      const newModel = model;
      model[3].maxDate = values.loanEndDate;
      updateModel(newModel);
    }
    if (field === 'loanStartDate') {
      const newModel = model;
      model[4].minDate = values.loanStartDate;
      updateModel(newModel);
    }
  };

  const handleForm = ({ field, values }) => {
    checkDates({ field, values });
    if (field === 'stock' && values.stock && values.stock.length > 20) {
      setExportTransactionListForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
          stockError: 'maxLengthErrorMessage',
        },
      }));
    } else {
      setExportTransactionListForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  };

  return (
    <Modal
      id={id}
      title={strings.exportList}
      confirmAction={onSaveClick}
      confirmDataTest='export-transaction-list-save'
      confirmTitle={strings.save}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={model}
        formName='exportTransactionListForm'
        formValue={exportTransactionListForm.formData}
        onChange={handleForm}
      />
    </Modal>
  );
};

import Validators from 'library/utilities/Validators';

export const validSystemClassValue = ['Miscellaneous', 'Other', 'Office accessories'];

export const assignmentFormModel = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformation',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'storageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
  },
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'firstSalesOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstSalesOrder',
    required: false,
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'kitLoanOrderText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapERP',
    required: false,
    styleClass: 'col-sm-12',
  },
  {
    label: 'walkOffSalesOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'walkOffSalesOrder',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    isDisplayed: true,
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClass',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
    disabled: false,
  },
];

export const mainComponentModel = [
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponent',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'materialNumberOfTheStand',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'materialNumberOfStand',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    isDisplayed: true,
  },
  {
    label: 'ibaseText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'ibase',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
  },
  {
    label: 'marketingMaterialText',
    selected: false,
    type: 'checkbox',
    field: 'marketingMaterial',
    styleClass: 'col-sm-6 mt-4',
  },
  {
    label: 'supportingAsset',
    selected: false,
    type: 'checkbox',
    field: 'supportingAsset',
    styleClass: 'col-sm-12',
  },
  {
    label: 'division',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'divisionId',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'divisionsId',
    displayValue: [
      { key: 'name', separator: ' , ' },
      { key: 'id', separator: ' ' },
    ],
    isDisplayed: true,
  },
  {
    label: 'itemNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'itemNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    isDisplayed: true,
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    dateFormat: 'MM/YYYY',
  },
  {
    label: 'countryOfOrigin',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    clearOption: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    showExtraInfo: true,
    infoMessage: 'createKitCountryAndCommodityExtraMessage',
    selectAllOption: false,
  },
  {
    label: 'commodityCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'commodityCode',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    showExtraInfo: true,
    infoMessage: 'createKitCountryAndCommodityExtraMessage',
  },
];

export const defaultPeriodsModel = [
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true,
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true,
  },
  {
    label: 'reconditioning',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'reconditioningDeliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true,
  },
  {
    label: 'maxLoanDuration',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'maxLoanDuration',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: false,
    styleClass: 'col-sm-5',
    inputGroupText: 'days',
    isAppend: true,
  },
];

export const availabilityFormModel = [
  {
    label: 'availableFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    disabled: false,
    dateFormat: 'DD.MM.YYYY',
    momentDateFormat: true
  },
  {
    label: 'availableTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityTo',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    momentDateFormat: true
  },
];

export const packageDimensionsModel = [
  {
    label: 'packageDimensions',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'packageDimensions',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
];

export const kitStatusModel = [
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    field: 'kitCondition',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'kitStatusId',
    displayValue: [{ key: 'translatedStatus', separator: '' }],
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
];

export const refurbishmentPeriodModel = [
  {
    label: 'from',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'refurbishmentFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-sm-6',
    dateFormat: 'DD.MM.YYYY',
  },
  {
    label: 'to',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'refurbishmentTo',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
   {
    label: 'reparationComment',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'reparationComment',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
   }
];

export const kitForSaleModel = [
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'condition',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'loanOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'loanOrder',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: false,
    styleClass: 'col-sm-6',
  },

  {
    label: 'visibility',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'visibility',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClass',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
    disabled: false,
  },
  {
    label: 'grossListPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'localSalePrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currency',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'transferPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'transferPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
  },
  {
    label: 'currency',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'currencyForTransferPrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true,
  },
  {
    label: 'salesDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'saleDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
  {
    label: 'deliveryTimeInWeeksNumberField',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'deliveryTimeInWeeks',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'salesComment',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'saleComment',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];

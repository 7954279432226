import React from 'react';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import { useLogisticAndCordination } from './LogiscticAndCordination.hook';
import Validators from 'library/utilities/Validators';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import EditAddttendeePopup from '../EditAttendePopup/EditAttendeePopup.component';
import classNames from 'classnames';

export const LogiscticAndCordination = props => {
  const { formValue, shippingAddress, transactionsAddress, isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction } = props;
  const { logisticsAndCoordinationForm, generalInformationForm, transactionsId, todosListForm, deleteTodoIds } = formValue;

  const {
    attendeeName,
    attendeeEmail,
    attendeesList,
    functionData,
    descisionMaker,
    skillLevel,
    addIntoAttendeesListList,
    handleNonFormComponent,
    onChangeInput,
    onChangeCheckbox,
    accomodationNeeded,
    flightNeeded,
    visitMuseumOfOptics,
    accomodationNeededData,
    flightNeededData,
    visitMuseumOfOpticsData,
    isEditng,
    onEditClick,
    saveEditAttendee,
    onChangeExistingValue,
    handleEditNonFormComponent,
    editingFiledId,
    handleEditNonFormComponentForTodoList,
    deleteAttendeById,
    showCheckboxes
  } = useLogisticAndCordination(props);
  return (
    <>
      {transactionsAddress && Object.keys(transactionsAddress).length > 0 && transactionsAddress.firstName != null ? (
        <>
          <h2 className='mt-3 zmcc-title-heading'>{strings.shippingSamples}</h2>
          <div className='mt-3'>
            <div>
              {transactionsAddress.firstName} {transactionsAddress.lastName}
            </div>
            <div>
              {transactionsAddress.organization} {transactionsAddress.department}
            </div>
            <div>
              {transactionsAddress.street} {transactionsAddress.houseNumber}
            </div>
            <div>
              {transactionsAddress.postalCode} {transactionsAddress.city} {transactionsAddress.stateName}{' '}
            </div>
            <div>{transactionsAddress.phone && `Cell: ${transactionsAddress.phone}`}</div>
          </div>
        </>
      ) : (
        generalInformationForm.formData.stockInformationId &&
        Object.keys(shippingAddress).length > 0 && (
          <>
            <h2 className='mt-3 zmcc-title-heading'>{strings.shippingSamples}</h2>
            <div className='mt-3'>
              <div>
                {shippingAddress.firstName} {shippingAddress.lastName}
              </div>
              <div>
                {shippingAddress.organization} {shippingAddress.department}
              </div>
              <div>
                {shippingAddress.street} {shippingAddress.houseNumber}
              </div>
              <div>
                {shippingAddress.postalCode} {shippingAddress.city} {shippingAddress.stateName}{' '}
              </div>
              <div>Cell: {shippingAddress.phone}</div>
            </div>
          </>
        )
      )}

      <h2 className='mt-3 zmcc-title-heading'>{strings.demoAttendees}</h2>
      {logisticsAndCoordinationForm.formData.zmccAttendeesList &&
        logisticsAndCoordinationForm.formData.zmccAttendeesList.map((item, index) => (
              <>
                <h3 className='mt-2 zmcc-title-heading'> {strings.attendeeName} {index + 1} </h3>

                <div className='row col-sm-12 mt-3 p-0' key={item.zmccAttendeesId}>
                  <div className='col-sm-12'>
                    <div className='zmcc-add-edit'>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>{strings.name} : </span>
                        {item.attendeeName}{' '}
                      </p>
                      <div className='d-flex'>
                        <Button
                          iconName='edit'
                          iconPosition='left'
                          bgFill={false}
                          isGhost={true}
                          // value={strings.editAddress}
                          onClick={() => onEditClick(index)}
                          styleClass='font-weight-normal'
                        />
                        <Button
                          iconName='delete'
                          iconPosition='left'
                          bgFill={false}
                          isGhost={true}
                          onClick={() => deleteAttendeById(item)}
                          styleClass='font-weight-normal'
                        />
                      </div>
                    </div>

                    <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.email} : </span>
                      {item.attendeeEmail}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.function} : </span>
                      {item.functionData}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.descisionMaker} : </span>
                      {item.decisionMaker ? 'yes' : item.decisionMaker === false ? 'no' : '-'}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.skillLevel} : </span>
                      {logisticsAndCoordinationForm.formData.zmccAttendeesList &&
                      logisticsAndCoordinationForm.formData.zmccAttendeesList[index]['skillLevel']
                        ? logisticsAndCoordinationForm.formData.zmccAttendeesList[index]['skillLevel'][
                            'skillLevelTranslated'
                          ][0]['name']
                        : props.skillLevel &&
                          props.skillLevel.find(
                            item =>
                              item.id === logisticsAndCoordinationForm.formData.zmccAttendeesList[index].skillLevelId,
                          ) &&
                          props.skillLevel.find(
                            item =>
                              item.id === logisticsAndCoordinationForm.formData.zmccAttendeesList[index].skillLevelId,
                          ).name}
                    </p>
                    {showCheckboxes && <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.accomodationNeeded} : </span>
                      {item.accomodationNeededData}
                    </p>}
                    {showCheckboxes && <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.flightNeeded} : </span>
                      {item.flightNeededData}
                    </p>}
                    {showCheckboxes && <p>
                      <span style={{ fontWeight: 'bold' }}>{strings.visitMuseum} : </span>
                      {item.visitMuseumOfOpticsData}
                    </p>}
                  </div>
                </div>
                <EditAddttendeePopup
                  id='edit-Attendee-modal'
                  data={logisticsAndCoordinationForm.formData.zmccAttendeesList[editingFiledId]}
                  skillLevelData={props.skillLevel}
                  handleEditNonFormComponent={handleEditNonFormComponent}
                  todosListForm={todosListForm}
                  user={props.user}
                  handleNonFormComponent={handleNonFormComponent}
                  editingFiledId={editingFiledId}
                  handleEditNonFormComponentForTodoList={handleEditNonFormComponentForTodoList}
                  saveZmccSemData={props.saveZmccSemData}
                  deleteTodoIds={deleteTodoIds}
                  isZmcc= {isZmcc}
                  zmccXrmBooking={zmccXrmBooking}
                  zmccLmBooking={zmccLmBooking}
                  zmccXbBooking={zmccXbBooking}
                  zmccSemBooking={zmccSemBooking}
                  updateZmccTransaction={updateZmccTransaction}
                  showCheckBoxes={showCheckboxes}
                />
              </>
        ))}
      <div className='row mt-3'>
        <div className='col-sm-3'>
          <InputField
            label={strings.name}
            field='attendeeName'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[]}
            value={logisticsAndCoordinationForm.formData.attendeeName}
            error={logisticsAndCoordinationForm.formData.attendeeNameError}
            onChange={onChangeInput}
            isRequired={true}
            hideOptionalText={true}
            showRedAsterik={true}
          />
        </div>
        <div className='col-sm-3'>
          <InputField
            label={strings.email}
            field='attendeeEmail'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[{ check: Validators.email, message: 'invalidEmailError' }]}
            value={logisticsAndCoordinationForm.formData.attendeeEmail}
            error={logisticsAndCoordinationForm.formData.attendeeEmailError}
            onChange={onChangeInput}
            isRequired={true}
            hideOptionalText={true}
            showRedAsterik={true}
          />
        </div>
        <div className='col-sm-3'>
          <InputField
            label={strings.function}
            field='function'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[]}
            value={logisticsAndCoordinationForm.formData.function}
            error={logisticsAndCoordinationForm.formData.functionError}
            onChange={onChangeInput}
            isRequired={true}
            hideOptionalText={true}
            showRedAsterik={true}
          />
        </div>
        <div className='col-sm-3'>
          <Dropdown
            label={strings.skillLevel}
            placeholder='Please select'
            idKey='id'
            field='skillLevelId'
            data={props.skillLevel || []}
            hasSection={false}
            multiSelect={false}
            filter={false}
            isRequired={false}
            validators={[]}
            displayValue={[{ key: 'name', separator: '' }]}
            onChange={onChangeInput}
            value={skillLevel && skillLevel.value}
            fullValue={skillLevel && skillLevel.fullValue}
            hideOptionalText={true}
          />
        </div>
        <div className={classNames('col-md-12', {'d-none': !showCheckboxes})}>
        <div className='col-sm-12 d-flex'>
          <div className='col-sm-3 p-0 mt-3'>
            <Checkbox
              label={strings.accomodationNeeded}
              field='accomodationNeeded'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.accomodationNeeded}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
            />
          </div>
          {(accomodationNeeded || logisticsAndCoordinationForm.formData.accomodationNeeded) && (
            <div className='col-sm-3 mt-2 mb-2 p-0 ml-2'>
              <InputField
                label={strings.specifyDetails}
                field='accomodationNeededData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.accomodationNeededData}
                error={logisticsAndCoordinationForm.formData.accomodationNeededDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                showRedAsterik={true}
              />
            </div>
          )}
        </div>
        <div className='col-sm-12 d-flex'>
          <div className='col-sm-3 p-0 mt-2'>
            <Checkbox
              label={strings.flightNeeded}
              field='flightNeeded'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.flightNeeded}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
            />
          </div>
          {(flightNeeded  || logisticsAndCoordinationForm.formData.flightNeeded) && (
            <div className='col-sm-3 mb-2 p-0 ml-2'>
              <InputField
                label={strings.specifyDetails}
                field='flightNeededData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.flightNeededData}
                error={logisticsAndCoordinationForm.formData.flightNeededDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                showRedAsterik={true}
              />
            </div>
          )}
        </div>
        <div className='col-sm-12 d-flex'>
          <div className='col-sm-3 p-0 mt-2'>
            <Checkbox
              label={strings.visitMuseum}
              field='visitMuseumOfOptics'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.visitMuseumOfOptics}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
            />
          </div>
          {(visitMuseumOfOptics ||  logisticsAndCoordinationForm.formData?.visitMuseumOfOptics) && (
            <div className='col-sm-3 mb-2 p-0 ml-2'>
              <InputField
                label={strings.specifyDetails}
                field='visitMuseumOfOpticsData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.visitMuseumOfOpticsData}
                error={logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                showRedAsterik={true}
              />
            </div>
          )}
        </div>
        </div>
        <div className='col-sm-3 mt-4'>
          <ZmccRadioButton
            label={strings.descisionMaker}
            value={logisticsAndCoordinationForm.formData.descisionMaker}
            // error={}
            placeholder=''
            formName='logisticsAndCoordinationForm'
            field='descisionMaker'
            validators={[]}
            required={false}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={onChangeInput}
            hideOptionalText={true}
          />
        </div>
      </div>
      <div class='row'>
        <div className='col-auto mt-4'>
            <Button
              value={strings.addMoreButtonText}
              onClick={() =>
                addIntoAttendeesListList(
                  attendeeName,
                  attendeeEmail,
                  functionData,
                  descisionMaker,
                  skillLevel ? skillLevel.value : null,
                  accomodationNeededData,
                  flightNeededData,
                  visitMuseumOfOpticsData,
                )
              }
              bgFill={false}
              iconName='plus'
              disabled={
                logisticsAndCoordinationForm.formData.zmccAttendeesList &&
                logisticsAndCoordinationForm.formData.zmccAttendeesList.length === 5
              }
            />
          </div>
        </div>
    </>
  );
};

export default LogiscticAndCordination;

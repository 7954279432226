import React, { Component } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';

import './inputFieldStyles.scss';
import Icon from '../Icon/Icon';

export class InputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      error: false,
      errorMessage: null,
      isInfoOpen: false,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.clear) {
      if (prevProps.clear !== this.props.clear) {
        this.setState({ value: '' });
      }
    }
    if (this.props.value && this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value }, () => this.validateValue());
    }

    if (!this.props.value && this.props.value !== prevProps.value) {
      if(this.props.value !== 0) {
        this.setState({ value: '' });
      }
      else {
        this.setState({value: this.props.value})
      }

    }

    if (this.props.doValidateForm && this.props.doValidateForm !== prevProps.doValidateForm) {
      this.validateValue();
    }

    if (this.props.resetValidation && this.props.resetValidation !== prevProps.resetValidation) {
      this.setState({ error: false, errorMessage: null });
    }

    if (this.props.isRequired !== prevProps.isRequired) {
      this.setState({ error: false, errorMessage: null });
    }
  }

  getData = () => {
    let error = null;
    let valid = true;
    error = this.validate(this.state.value);
    if (error && error.error) {
      this.setState({ error: true, errorMessage: error.message });
      valid = true;
      if (this.props.onError) {
        this.props.onError(error, this.props.field);
      }
    } else {
      valid = false;
      this.setState({ error: false });
      if (this.props.onError) {
        this.props.onError({ error: false, message: null }, this.props.field);
      }
    }
    return { value: this.state.value, error: valid };
  };

  setError = error => {
    if (error.error) {
      this.setState({ error: true, errorMessage: error.message });
      if (this.props.onError) {
        this.props.onError(error, this.props.field);
      }
    } else {
      this.setState({ error: false });
      if (this.props.onError) {
        this.props.onError({ error: false, message: null }, this.props.field);
      }
    }
  };

  validate = value => {
    const { validators } = this.props;
    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message, validators[i].minDate, validators[i].length);
      if (error) {
        return error;
      }
    }
    return false;
  };

  validateValue = () => {
    this.setError(this.validate(this.state.value));
    if (this.props.onChange) {
      this.props.onChange(this.state.value, this.props.field);
    }
  };

  onBlur = () => {
    if (this.props.isRequired) {
      this.validateValue();
    }
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value, this.props.field);
    }
  };

  handleOnChange = event => {
    const value = event.target.value;
    let error = this.validate(value);
    if (error && error.error) {
      this.setState({ value, error: true, errorMessage: error.message });
    } else {
      this.setState(
        { value, error: false, errorMessage: '' },
        () => {
          if (this.props.onChange) {
            this.props.onChange(value, this.props.field);
          }
        }
      );
    }
  };

  render() {
    const {
      dataTest,
      label,
      isRequired,
      type,
      extraProps,
      placeholder,
      icon,
      infoMessage,
      showExtraInfo,
      disabled,
    } = this.props;
    const { error, errorMessage, value } = this.state;
    return (
      <div className='input-field' data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label}</p>
            {showExtraInfo && <p className='input-info cursor-pointer'>i</p>}
            {showExtraInfo && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
          {!isRequired && <span className='optional-text'>{strings.optionalField}</span>}
        </div>

        <input
          type={type}
          value={value !== null && value !== undefined ? value : ''}
          className={'form-control rounded-0 position-relative ' + (error && 'input-error')}
          placeholder={placeholder}
          {...extraProps}
          onChange={this.handleOnChange}
          onBlur={this.onBlur}
          disabled={disabled}
        />

        {icon && <Icon name={icon} width={24} height={24} fill='#9a9b9c' className='search-icon' />}

        {error && <span className='text-danger error-block'>{strings[errorMessage]}</span>}
      </div>
    );
  }
}

InputField.defaultProps = {
  label: '',
  type: 'text',
  field: '',
  clear: false,
  isRequired: false,
  validators: [],
  placeholder: '',
  icon: null,
  showExtraInfo: false,
  infoMessage: '',
  disabled: false,
  onError: () => {},
  onChange: () => {},
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(InputField);

import React, { useState } from 'react';
import * as $ from 'jquery';

import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';

import './informationStyles.scss';
import { refurbishmentPeriodModel } from './Information.constants';

export default ({ id, conditions, kitStatusForm, saveKit, updateFormCreateKit }) => {
  const [periodForm, setPeriodForm] = useState({ formData: {} });

  const closeModal = () => {
    $(`#${id}`).modal('hide');
  };

  const refurbishmentModel = () => {
    const model = [
      { ...refurbishmentPeriodModel[0], minDate: new Date() },
      {
        ...refurbishmentPeriodModel[1],
        minDate: periodForm.formData.refurbishmentFrom ? periodForm.formData.refurbishmentFrom : new Date(),
      },
      {
        ...refurbishmentPeriodModel[2],
        refurbishmentComment: periodForm.formData.refurbishmentComment ? periodForm.formData.refurbishmentComment : "",
      }
    ];
    return model;
  };

  const handleForm = ({ values, field }) => {
    setPeriodForm({
      ...periodForm,
      formData: {
        ...((periodForm && periodForm.formData) || {}),
        ...values,
      },
    });
  };

  const handleSave = () => {
    const form = validateForm({ form: periodForm, model: refurbishmentModel() });
    const kitConditionFullValue = conditions && conditions.length && conditions.find(item => item.kitStatusId === 5);
    if (form.isFormValid) {
      updateFormCreateKit({
        kitStatusForm: {
          ...kitStatusForm,
          formData: {
            ...((kitStatusForm && kitStatusForm.formData) || {}),
            kitCondition: 5,
            kitConditionFullValue,
            refurbishmentFrom: periodForm.formData.refurbishmentFrom,
            refurbishmentTo: periodForm.formData.refurbishmentTo,
            reparationComment: periodForm.formData.reparationComment
          },
        },
      });

      if (saveKit) saveKit(false);
      closeModal();
    } else {
      setPeriodForm(form);
    }
  };

  return (
    <Modal
      id={id}
      className='modal-lg'
      confirmAction={handleSave}
      onCloseClick={closeModal}
      confirmTitle={strings.save}
    >
      <div className='refurbishment-popup'>
        <h5 className='mb-3'>{strings.pleaseSelectPeriodForRefurbishment}</h5>
        <FormComponent
          model={refurbishmentModel()}
          formName='refurbishmentPeriodForm'
          formValue={periodForm.formData}
          onChange={handleForm}
        />
      </div>
    </Modal>
  );
};

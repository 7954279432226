import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import { ReactComponent as Logo } from 'resources/images/zeiss-logo.svg';

const UnloggedMenu = ({ language, cart, handleLanguageChange }) => {

  return (
    <div className='full-screen-menu'>
      <div className='top-header d-flex justify-content-between align-content-start'>
        <Logo />
        <div className='top-menu'>
          <ul className='nav'>
            <li className='nav-item'>
              <p className='cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                {strings.document}
              </p>
            </li>
            <li className='nav-item cursor-pointer'>{strings.contact}</li>
            <li className='nav-item cursor-pointer' onClick={handleLanguageChange}>
              {strings[language]}
            </li>
          </ul>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center bg-white' id='main-nav'>
        <nav className='navbar navbar-expand-lg pl-0 pb-0'>
          <Logo
            width={32}
            height={32}
            id='sticky-logo'
            style={{ display: 'none', marginRight: '24px', marginLeft: '20px' }}
          />

          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav mr-auto'>
              <li className='nav-item'>
                <NavLink to={'/home'} exact={true} activeClassName='active' className='nav-link'>
                  {strings.homepage}
                </NavLink>
              </li>
              <li className='nav-item'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.calendar}
                </p>
              </li>
              <li className='nav-item'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.homeBooking}
                </p>
              </li>
              <li className='nav-item loan-menu dropdown'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.transaction}
                </p>
              </li>
              <li className='nav-item'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.kits}
                </p>
              </li>
              <li className='nav-item'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.stocks}
                </p>
              </li>
              <li className='nav-item loan-menu dropdown'>
                <p className='nav-link cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                  {strings.demoSale}
                </p>
              </li>
            </ul>
          </div>
        </nav>

        <div className='user-menu'>
          <ul className='nav'>
            <li className='nav-item dropdown user'>
              <p className='cursor-pointer' data-toggle='modal' data-target='#loginModal'>
                <Icon name='person' width={32} height={32} fill={'#0088d0'} />
              </p>
            </li>
            <li className='nav-item dropdown cart'>
              <div className='cursor-pointer position-relative'>
                <Icon name='cart' width={32} height={32} fill={'#0088d0'} />
                <div className='cart-badge'>{cart.length}</div>
              </div>
            </li>
            <li className='nav-item'>
              <div className='cursor-pointer position-relative'>
                <Icon name='bell' width={24} height={24} viewBox='0 0 24 24' fill={'#0088d0'} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnloggedMenu;

import React, { useState } from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';

import { dataTable } from '../../dataTable';
import dataExpandableTable from '../../dataExpandableTable';
import Filter from '../Filter';
import { itemsPerPage } from '../../BookingConstants';
import BulkConfirmationPopup from '../BulkConfirmationPopup/BulkConfirmationPopup.component';
import BulkCancelTwentyFourHourReservation from '../BulkCancelTwentyFourHourReservation/BulkCancelTwentyFourHourReservation.component';

const DLATransactions = props => {
  const {
    transactionsList,
    dateTypes,
    categoriesAndSystems,
    isShowFilter,
    countries,
    language,
    conditions,
    data,
    transactionStatuses,
    transactionPositions,
    transactionType,
    handleFilterChange,
    resetFilters,
    history,
    toggleActionMessage,
    addItemToCart,
    match,
    cartItems,
    setAdjustDatesModalVisible,
    handleSort,
    saveCancellationRequest,
    user,
    loanTypes,
    downloadTransactionList,
    handlePageChange,
    handleItemsPerPage,
    typeTransactions,
    bundleLoans,
    fetchConnectTransactionData,
    cancelBulkResevations,
    removeItemFromCartForBulk,
  } = props;
  const checkTransactionsList = transactionsList && transactionsList.content && transactionsList.content.length;
  const [rejectCancelRequest, setRejectCancelRequest] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showBulkConfirmPopup, setShowBulkConfirmPopup] = useState(false);
  const [showBulkCancelPopup, setShowBulkCancelPopup] = useState(false);
  const isDlaOrLab = props.typeTransactions
  const filterDataToSend = data;
  return (
    <>
      <Filter
        isShowFilter={isShowFilter}
        countries={countries}
        language={language}
        categoriesAndSystems={categoriesAndSystems}
        conditions={conditions}
        loanTypes={loanTypes}
        selected={data.filter}
        transactionStatuses={transactionStatuses}
        transactionPositions={transactionPositions}
        transactionType={transactionType}
        handleFilterChange={handleFilterChange}
        resetFilters={resetFilters}
        dateTypes={dateTypes}
      />
      <div className='booking-table'>
        {checkTransactionsList ? (
          <div className='container-fluid mb-3'>
            <ExpandableTable
              cols={dataTable(data.filter.loanType, transactionType)}
              rows={transactionsList.content}
              sortFields={data.sort}
              dataExpandableTable={dataExpandableTable(
                history,
                toggleActionMessage,
                addItemToCart,
                match.url,
                cartItems,
                transactionType,
                data,
                setAdjustDatesModalVisible,
                setRejectCancelRequest,
                rejectCancelRequest,
                isDlaOrLab,
                bundleLoans,
                fetchConnectTransactionData,
                setSelectedData,
                cancelBulkResevations,
                filterDataToSend,
                setShowBulkConfirmPopup,
                setShowBulkCancelPopup,
                removeItemFromCartForBulk
              )}
              handleSort={handleSort}
              saveCancellationRequest={saveCancellationRequest}
              user={user}
              isDlaOrLab={isDlaOrLab}
            />
            {showBulkConfirmPopup && <BulkConfirmationPopup 
                    id={'bulk-confirmation-modal'}
                    data={selectedData}
                    bundleLoans={bundleLoans}
                    cartItems={cartItems}
                    history={history}
                    setSelectedData={(e) =>setSelectedData(e)}
                    setShowBulkConfirmPopup={(e) => setShowBulkConfirmPopup(e)}
                  />}
            {showBulkCancelPopup && 
              <BulkCancelTwentyFourHourReservation 
                  id={'bulk-cancel-modal'}
                  data={selectedData}
                  bundleLoans={bundleLoans}
                  cancelBulkResevations={cancelBulkResevations}
                  fetchTransListData={data}
                  transactionType={transactionType}
                  setSelectedData={(e) =>setSelectedData(e)}
                  setShowBulkCancelPopup={(e) => setShowBulkCancelPopup(e)}
                  removeItemFromCartForBulk={removeItemFromCartForBulk}
            />}
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-md-auto mb-3'>
            <Button
              bgFill={false}
              iconName='download'
              value={strings.exportCurrentList}
              styleClass='mt-0'
              onClick={downloadTransactionList}
            />
          </div>
          {checkTransactionsList ? (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={transactionsList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DLATransactions;

import { useEffect, useCallback } from 'react';
import get from 'lodash/get';

import { refurbishmentPeriodModel } from '../../../CreateKit/Tabs/Information/Information.constants';
import { informationFormModel, kitConditionFormModel } from './InformationTab.constants';
export const useInformationTab = props => {
  const {
    informationForm,
    kitStatusForm,
    stocks,
    systemClasses,
    systemMainComponent,
    fetchSystemMainComponent,
    conditions,
    activeUntil,
    kitDetails,
    fetchActiveUnitDate,
  } = props;

  useEffect(() => {
    if (informationForm.formData.systemClass) {
      fetchSystemMainComponent(informationForm.formData.systemClass);
    }
  }, [informationForm.formData.systemClass, fetchSystemMainComponent]);

  useEffect(() => {
    if (kitDetails && kitDetails.stockInformation) {
      fetchActiveUnitDate(kitDetails && kitDetails.stockInformation.id);
    }
  }, [kitDetails && kitDetails.kitInformationId]);

  const getInformationModel = useCallback(() => {
    const model = [...informationFormModel];
    const date = activeUntil ? new Date(activeUntil) : null;
    model[0].options = stocks;
    model[2].options = systemClasses;
    model[3].options = systemMainComponent;

    model[8].maxDate = date;
    model[8].minDate = informationForm.formData.availabilityFrom;
    model[7].maxDate = informationForm.formData.availabilityTo || date;

    return model;
  }, [informationForm, activeUntil, stocks, systemClasses, systemMainComponent]);

  const getKitConditionsModel = useCallback(() => {
    const model = [...kitConditionFormModel];

    if (conditions.length) {
      const unusedСonditionIds = [6, 10, 11, 12, 1, 3, 4, 7, 8, 9, 13, 14];
      const newOptions = conditions.filter(condition => !unusedСonditionIds.includes(condition.kitStatusId));
      const okCondition = conditions.find(condition => condition.kitStatusId === 6);

      newOptions.unshift(okCondition);
      model[0].options = newOptions;
    }

    if (get(kitStatusForm, 'formData.kitCondition') === 5) {
      model.splice(1, 0, ...refurbishmentPeriodModel);
      model[1].minDate = new Date();
      model[2].minDate = kitStatusForm.formData.refurbishmentFrom;
    }

    return model;
  }, [conditions, kitStatusForm]);

  const informationModel = getInformationModel();
  const kitConditionModel = getKitConditionsModel();

  return {
    informationModel,
    kitConditionModel,
    getInformationModel,
    getKitConditionsModel,
  };
};

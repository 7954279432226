import { useEffect, useState, useCallback } from 'react';
import { regex } from 'library/common/commonConstants/RegexConstants';
import { get, values } from 'lodash';
import { scrollToTop } from 'library/utilities/scrollActions';
import strings from 'resources/locales/Translate';

export const useInformation = ({
  onClickEdit,
  zmmctransactionDetails,
  user,
  getDemoSiteName,
  toggleActionMessage,
  updateZmccTransaction,
  fetchConnectedDemoLoans,
  connectedTransactionArray,
  editing,
  updateZmccTransactionStatus,
  formType,
  updateZmccInformationTab,
}) => {
  const {
    intendedFor,
    opportunityId,
    customerOrganization,
    samplesAt,
    isCorrelative,
    stockInformation,
    travelLog,
    customerName,
    requestor,
    zmccOperatorsList,
    zmccSubscribersList,
    zmccPriority,
    dataOfConnectedLoans,
    transactions,
    comment,
    zmccSystemCategories,
    travelTime,
    portalOrganization
  } = zmmctransactionDetails;
  const [category, setCategory] = useState({
    value: '',
    fullValue: {},
  });
  const [crmOpportunityId, setCrmOpportunityId] = useState({ value: opportunityId, error: null });
  const [zmccRequestor, setZmccRequestor] = useState({ value: '', fullValue: {} });

  const [organization, setOrganization] = useState({ value: portalOrganization ? portalOrganization : user ? user.portalOrganization : '', error: null });
  const [customer, setCustomer] = useState({ value: customerOrganization, error: null });

  const [operator, setOperator] = useState({ value: '', fullValue: '' });
  const [subscriber, setSubscriber] = useState({ value: '', fullValue: '' });
  const [priority, setPriority] = useState({ value: '', fullValue: '' });
  const [samplesAtValue, setSamplesAtValue] = useState({ value: samplesAt, error: null });
  const [travelTimeValue, setTravelTimeValue] = useState({value:travelTime, error: null })
  const [correlative, setCorrelative] = useState('');
  const [systemCategory, setSystemCategory] = useState({
    value: '',
    fullValue: {},
  });

  const [demoTime, setDemoTime] = useState();
  const [transactionStastusValue, setTransactionStastusValue] = useState({ value: '', fullValue: '' });
  const warehouseId = get(stockInformation, 'id');
  const getName = get(zmmctransactionDetails, 'transactions.name');
  const getKitName = get(zmmctransactionDetails, 'kitInformation.kitName');
  const [demoSite, setDemoSite] = useState({
    value: '',
    error: null,
  });

  const [name, setName] = useState({
    value: '',
    error: null,
  });

  const [commentValue, setCommentValue] = useState({
    value: '',
    error: null,
  });

  const [connectedTransactionValues, setConnectedTransactionValues] = useState({ value: '', fullValue: '' });

  const transactionId = get(zmmctransactionDetails, 'transactions.transactionsId');
  const filteredConnectedTransaction =
    dataOfConnectedLoans && dataOfConnectedLoans.filter(item => item != transactionId);
  const getCommentValue = get(zmmctransactionDetails, 'transactions.comment');

  const [travelLogValue, setTravelLogValue] = useState({
    value: '',
    error: null,
  });

  const [customerNameValue, setCustomerNameValue] = useState({
    value: '',
    error: null,
  });

  const modifiedConnectTransactionArray =
    connectedTransactionArray &&
    connectedTransactionArray.map(item => ({
      transactionsId: item.transactionsId,
      name: item.name !== null ? item.name : '',
      transactionNumber: item.transactionNumber,
    }));

  const intendedIdsForOpportunityMandatory = [1, 4, 5, 6, 14];

  useEffect(() => {
    if (warehouseId) {
      fetchConnectedDemoLoans(warehouseId);
    }
  }, [warehouseId]);

  useEffect(() => {
    setDemoSite({ value: getDemoSiteName, error: null });
    setCategory({
      value: intendedFor ? intendedFor.intendedForId : '',
      fullValue: intendedFor ? { id: intendedFor.intendedForId, name: intendedFor.intendedForTranslated[0].name } : '',
    });
    setSystemCategory({
      value: zmccSystemCategories ? zmccSystemCategories.zmccSystemCategoryId : '',
      fullValue: zmccSystemCategories ? { id: zmccSystemCategories.zmccSystemCategoryId, name: zmccSystemCategories.name }: ''
    })
    setCustomer({ value: customerOrganization, error: null });
    setName({
      value: getName ? getName : `${getKitName} ${customerName !== null ? customerName : '' }`,
      error: null,
    });
    
    setZmccRequestor({ value: requestor && requestor.accountId, fullValue: requestor });
    if (zmccOperatorsList && zmccOperatorsList.length > 0) {
      const listOfZmccOperatorForFullValue = zmccOperatorsList.map(item => item.user);
      const listOfOperatorForValue = zmccOperatorsList.map(item => item.user.accountId);
      setOperator({ value: listOfOperatorForValue, fullValue: listOfZmccOperatorForFullValue });
    }
    if (zmccSubscribersList && zmccSubscribersList.length > 0) {
      const listOfSubscriberForFullValue = zmccSubscribersList.map(item => item.user);
      const listOfSubscriberForValue = zmccSubscribersList.map(item => item.user.accountId);
      setSubscriber({ value: listOfSubscriberForValue, fullValue: listOfSubscriberForFullValue });
    }
    if (zmccPriority) {
      setPriority({
        value: zmccPriority.zmccPriorityId,
        fullValue: { id: zmccPriority.zmccPriorityId, name: zmccPriority.zmccPriorityTranslated[0].name },
      });
    }
    if (filteredConnectedTransaction && filteredConnectedTransaction.length > 0) {
      const values = filteredConnectedTransaction.map(item => ({ transactionNumber: item, transactionsId: item }));
      setConnectedTransactionValues({ value: filteredConnectedTransaction, fullValue: values });
    }
    if (transactions && transactions.transactionStatus) {
      let updatedTransactionValue;
      let statusId = transactions.transactionStatus.transactionStatusId;
      if (transactions.transactionStatus.transactionStatusId === 1) {
        updatedTransactionValue = strings.waitingForConfirmation;
      } else if (transactions.transactionStatus.transactionStatusId === 6) {
        updatedTransactionValue = strings.doneStatus;
      }
      setTransactionStastusValue({
        value: transactions.transactionStatus.transactionStatusId,
        fullValue: {
          id: transactions.transactionStatus.transactionStatusId,
          name:
            statusId === 1 || statusId === 6
              ? updatedTransactionValue
              : transactions.transactionStatus.transactionStatusTranslated[0].name,
        },
      });
    }
    setCommentValue({ value: getCommentValue, error: null });
    setCrmOpportunityId({ value: opportunityId, error: null });
    setTravelLogValue({ value: travelLog });
    setCustomerNameValue({ value: customerName, error: null });
    setCorrelative(isCorrelative);
    setTravelTimeValue({value: travelTime, error: null});
    setSamplesAtValue({value : samplesAt , error :null});
    setOrganization({value : portalOrganization , error :null});
  }, [getDemoSiteName, zmccPriority, customerName, travelTime]);

  
  const onChangeDropdown = (value, field, fullValue) => {
    if (field === 'category') {
      savedInformationTab();
      return setCategory({ value, fullValue });
    }
    if (field === 'operator') {
      savedInformationTab();
      return setOperator({ value, fullValue });
    }
    if (field === 'requestor') {
      savedInformationTab();
      return setZmccRequestor({ value, fullValue });
    }
    if (field === 'subscriber') {
      savedInformationTab();
      return setSubscriber({ value, fullValue });
    }

    if (field === 'priority') {
      savedInformationTab();
      return setPriority({ value, fullValue });
    }

    if (field === 'transactionStatus') {
      savedInformationTab();
      return setTransactionStastusValue({ value, fullValue });
    }

    if (field === 'connectedTransaction') {
      savedInformationTab();
      return setConnectedTransactionValues({ value, fullValue });
    }
    if (field === 'zmccSystemCategoriesId') {
      savedInformationTab();
      return setSystemCategory({ value, fullValue });
    }
  };

  const onChangeInput = (value, field) => {
    if (field === 'opportunityId') {
      savedInformationTab();
      return setCrmOpportunityId({
        value,
        error: null,
      });
    }
    if (field === 'organization') {
      savedInformationTab();
      return setOrganization({
        value,
        error: null,
      });
    }
    if (field === 'zmccCustomerInstitute') {
      savedInformationTab();
      return setCustomer({
        value,
        error: null,
      });
    }
    if (field === 'demoSite') {
      savedInformationTab();
      return setDemoSite({
        value,
        error: null,
      });
    }
    if (field === 'samplesAt') {
      savedInformationTab();
      return setSamplesAtValue({
        value,
        error: null,
      });
    }
    if (field === 'travelTime') {
      savedInformationTab();
      return setTravelTimeValue({
        value,
        error: null,
      });
    }
    if (field === 'travelLog') {
      savedInformationTab();
      return setTravelLogValue({ value, error: null });
    }
    if (field === 'name') {
      return setName({
        value,
        error: null,
      });
    }

    if (field === 'comment') {
      savedInformationTab();
      return setCommentValue({
        value,
        error: null,
      });
    }
    if (field === 'customerName') {
      savedInformationTab();
      return setCustomerNameValue({
        value,
        error: null,
      });
    }
  };

  const onChangeCheckbox = value => {
    savedInformationTab();
    setCorrelative(value);
  };

  const onChangeDemoTime = (id, value) => {
    const inputValue = value.trim();

    if (inputValue && !regex.numberWithDecimals.test(inputValue)) return;
    if (inputValue && inputValue[0] === '.') return;
    if (inputValue && inputValue.split('.')[1] && inputValue.split('.')[1].length > 2) return;
    if (inputValue && inputValue > 99.99) return;
    if (inputValue && inputValue < 0) return;
    if (inputValue.includes('000')) return;

    setDemoTime(prevState => ({ ...prevState, [id]: inputValue }));
    savedInformationTab();
  };

  
  const validateFields = () => {
    let isValid = true;

    if (!organization.value) {
      isValid = false;
      setOrganization(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }

    if (category.value) {
      if (intendedIdsForOpportunityMandatory.includes(category.value)) {
        if (!crmOpportunityId.value) {
          isValid = false;
          setCrmOpportunityId(prevState => ({
            ...prevState,
            error: 'requiredErrorMessage',
          }));
        }
        }
      }
      if (crmOpportunityId.value !== null && crmOpportunityId.value !== "") {
        if (crmOpportunityId.value.length !== 18 && user.crmSystemId === 'ngcrm') {
          isValid = false;
          setCrmOpportunityId(prevState => ({
            ...prevState,
            error: strings.pleaseMindThatFieldNeedsToHave(18),
          }));
        } else if (crmOpportunityId.value.length !== 9 && user.crmSystemId !== 'ngcrm') {
          isValid = false;
          setCrmOpportunityId(prevState => ({
            ...prevState,
            error: strings.pleaseMindThatFieldNeedsToHave(9),
          }));
        } else if (crmOpportunityId.value.length === 9 && user.crmSystemId !== 'ngcrm') {
          if (!regex.number.test(crmOpportunityId.value)) {
            isValid = false;
            setCrmOpportunityId(prevState => ({
              ...prevState,
              error: 'invalidNumberError',
            }));
          } else if (!regex.validCrmCheck.test(crmOpportunityId.value)) {
            isValid = false;
            setCrmOpportunityId(prevState => ({
              ...prevState,
              error: 'correctOpportunityMessage',
            }));
          }
      }
       
    }
    return isValid;
  };

  const savedInformationTab = () => {
    
    const data = {
      name: name.value,
      transactionOrganization: organization.value,
      transactionStatusId: transactionStastusValue.value,
      operators: operator.value || [],
      subscribers: subscriber.value || [],
      priorityId: priority.value,
      samplesAt: samplesAtValue.value,
      isCorrelative: correlative,
      travelLog: travelLogValue.value,
      comment: commentValue.value,
      requestor: zmccRequestor.value,
      indentedFor: category.value,
      transactionId: transactionId,
      connectedDemoLoans: connectedTransactionValues.value || [],
      customerOrganization: customer.value,
      opportunityIdValue: crmOpportunityId.value,
      travelTime: travelTimeValue.value
    };
  };

  const onSave = async (sendDataForOperatorsModel) => {
    const isValidFields = validateFields();
    if (!isValidFields && editing) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'formIsInvalid');
    }
    if (!priority.value && editing) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'priorityEroor');
    }
   
    const dataToSend = {
      name: name.value,
      transactionOrganization: organization.value,
      transactionStatusId: transactionStastusValue.value,
      operators: operator.value || [],
      subscribers: subscriber.value || [],
      priorityId: priority.value,
      samplesAt: samplesAtValue.value,
      isCorrelative: correlative,
      travelLog: travelLogValue.value,
      comment: commentValue.value,
      requesterId: zmccRequestor.value,
      intendedForId: category.value,
      transactionId: transactionId,
      connectedDemoLoans: connectedTransactionValues.value || [],
      customerOrganization: customer.value,
      opportunityId: crmOpportunityId.value,
      zmccSystemCategoriesId: systemCategory.value !== '' ? systemCategory.value : null,
      travelTime: travelTimeValue.value,
      customerName : customerNameValue.value
    };
    if(sendDataForOperatorsModel){
      return dataToSend
    }
    const res = await (!editing
      ? updateZmccTransactionStatus(
          transactionId,
          transactionStastusValue.value === 13
            ? 'in_progress'
            : transactionStastusValue.value === 1
            ? 'waiting_for_confirmation'
            : transactionStastusValue.value === 10
            ? 'on_hold'
            : transactionStastusValue.value === 11
            ? 'waiting_for_samples'
            : transactionStastusValue.value === 12
            ? 'waiting_for_processing' 
            : transactionStastusValue.value === 6
            ? 'done'
            : transactionStastusValue.value === 5
            ? 'cancelled'
            : ''
        ): updateZmccInformationTab(dataToSend, formType));
    if (res.success && editing) {
      onClickEdit();
    }
  };

  const getDataToSendFromInformationTab = () => {
    return onSave(true)
  }

  return {
    category,
    crmOpportunityId,
    zmccRequestor,
    organization,
    customer,
    operator,
    subscriber,
    priority,
    samplesAtValue,
    correlative,
    demoTime,
    demoSite,
    travelLogValue,
    transactionStastusValue,
    name,
    commentValue,
    connectedTransactionValues,
    filteredConnectedTransaction,
    getCommentValue,
    transactionId,
    modifiedConnectTransactionArray,
    intendedIdsForOpportunityMandatory,
    customerNameValue,
    onChangeDropdown,
    onChangeInput,
    onChangeCheckbox,
    onChangeDemoTime,
    onSave,
    systemCategory,
    getDataToSendFromInformationTab,
    travelTimeValue,
  };
};

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';
import _ from 'lodash';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import TextArea from 'library/common/commonComponents/FormComponent/Optimized/TextArea';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import OpportunityForm from 'library/common/commonComponents/OpportunityForm';
import Button from 'library/common/commonComponents/Button/Button';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import Validators from 'library/utilities/Validators';
import { FETCH_LABS_LOCATION_COUNTRIES } from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import TypesOfJobsBlock from '../../Components/TypesOfJobsBlock';
import { useLLInformation } from './LLInformation.hook';
import Modal from 'library/common/commonComponents/Modal';

const LLInformation = props => {
  const {
    transactionDetails,
    editing,
    usersList,
    labsLocationCountries,
    setCurrentTab,
    toggleActionMessage,
    onEditPress,
    updateLabsLocationTransactionDetails,
    typesOfJobsList,
    user,
    language,
    fetchMasterData,
    newTypesOfJobsList,
  } = props;
  const {
    name,
    targetReservationFrom,
    targetReservationTo,
    transactionOpportunities,
    createdAt,
    comment,
    firstName,
    lastName,
    organization,
    department,
    street,
    houseNumber,
    postalCode,
    city,
    state,
    country,
    phone,
    email,
    showHelpForDismantling,
    operator,
    customer,
    customerInstitute,
    transactionTypesOfJobs,
    llPartnerName,
    llPartnerEmail
  } = transactionDetails;
  const kitName = get(transactionDetails, 'transactionKit.kitInformation.kitName', '');
  const ibase = get(transactionDetails, 'transactionKit.kitInformation.ibase', '');
  const serialNumber = get(transactionDetails, 'transactionKit.kitInformation.serialNumber', '');
  const kitId = get(transactionDetails, 'transactionKit.kitInformation.kitInformationId', '');
  const reasonForLoanName = get(transactionDetails, 'reasonForLoan.name', '');
  const period = targetReservationFrom && getDateInUTCFormat('DD.MM.YYYY', targetReservationFrom, targetReservationTo);
  const opportunityStatus = transactionOpportunities.find(item => item.crmStatus);
  const opportunityStatusName = get(opportunityStatus, 'crmStatus.crmStatusTranslated[0].name', '');
  const borrowerName = `${get(transactionDetails, 'borrower.firstName', '')} ${get(
    transactionDetails,
    'borrower.lastName', '',
  )}`;
  const borrowerEmail = get(transactionDetails, 'borrower.email', '');
  const borrowerPhone = get(transactionDetails, 'borrower.userAddresses[0].phone', '');
  const partnerSite = get(transactionDetails, 'transactionKit.stockInformation.locationName', '');
  const userName = `${get(transactionDetails, 'user.firstName', '')} ${get(transactionDetails, 'user.lastName', '')}`;
  const dateOfCreation = createdAt && getDateInUTCFormat('DD.MM.YYYY', createdAt);
  const selectedTypesOfJobsIds = transactionTypesOfJobs.map(item => item.typesOfJobsId);
  const selectedTypesOfJobs = newTypesOfJobsList ? newTypesOfJobsList.filter(item => selectedTypesOfJobsIds.includes(item.tyesOfJobsId)) : [];
  const nameOfOperator = `${get(transactionDetails, 'operatorUser.firstName', '')} ${get(
    transactionDetails,
    'operatorUser.lastName', '',
  )}`;

  const {
    demoSiteForm,
    shortDescription,
    requestor,
    transactionStatusField,
    commentField,
    transactionStatusOptions,
    helpForDismantlingCheckbox,
    customerField,
    customerInstituteField,
    operatorField,
    operatorModel,
    addressModel,
    opportunities,
    enteredOpportunityId,
    isOpportunityIdCrmError,
    typesOfJobs,
    demoTime,
    isLoader,
    hasEditingPermissions,
    isBorrower,
    hasSavePermission,
    transactionStatusFullValue,
    nameOfOperatorfield,
    onChangeInput,
    onChangeDropdown,
    onChangeCheckbox,
    onChangeForm,
    setOpportunity,
    removeOpportunity,
    updateOpportunities,
    handleEnteredOpportunityId,
    onSave,
    handleOpportunityIdCrmError,
    onChangeDemoTime,
    onChangeTypeOfJobs,
    llPartnerEmailFeild,
    llPartnerNameFeild,
    onSaveClick,
    onCloseClick
  } = useLLInformation({
    transactionDetails,
    labsLocationCountries,
    toggleActionMessage,
    onEditPress,
    updateLabsLocationTransactionDetails,
    editing,
    user,
  });

  return (
    <div className='container-fluid mt-3'>
      <div className='row'>
        <div className='col col-lg-6 col-12'>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.period}</div>
            <div className='col-6'>{period}</div>
          </div>
          {kitName && (
            <div className='row small-height'>
              <div className='col-6 font-bold'>{strings.labsAndLocationSystem}</div>
              <div className='col-6'>
                <NavLink to={`/kit_details/${kitId}`}>{kitName}</NavLink>
              </div>
            </div>
          )}
          {ibase && (
            <div className='row small-height'>
              <div className='col-6 font-bold'>{strings.crmiBase}</div>
              <div className='col-6'>{ibase}</div>
            </div>
          )}
          {serialNumber && (
            <div className='row small-height'>
              <div className='col-6 font-bold'>{strings.serialNumber}</div>
              <div className='col-6'>{serialNumber}</div>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.nameShortDescription}</div>
            <div className='col-6'>
              {editing && hasEditingPermissions ? (
                <InputField
                  label={strings.nameShortDescription}
                  field='shortDescription'
                  placeholder=''
                  isRequired
                  validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                  value={shortDescription.value}
                  error={shortDescription.error}
                  onChange={onChangeInput}
                />
              ) : (
                name
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.labsAndLocationReasonForLoan}</div>
            <div className='col-6'>{reasonForLoanName}</div>
          </div>
          {showHelpForDismantling && hasEditingPermissions && (
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.helpForBuildingAndDismantling}</div>
              <div className='col-6'>
                <Checkbox
                  label=''
                  field='helpForBuildingAndDismantling'
                  isRequired={false}
                  selected={helpForDismantlingCheckbox}
                  onClick={onChangeCheckbox}
                  disabled={!editing}
                />
              </div>
            </div>
          )}
          <div className='row small-height mb-3'>
            <div className='col-6 font-bold'>{strings.crmOpportunityId}</div>
            <div className='col-6'>
              {editing && hasEditingPermissions ? (
                <OpportunityForm
                  setOpportunity={setOpportunity}
                  removeOpportunity={removeOpportunity}
                  updateOpportunities={updateOpportunities}
                  opportunities={opportunities}
                  isEditableField
                  isRequired={false}
                  setIsOpportunityIdCrmError={handleOpportunityIdCrmError}
                  isOpportunityIdCrmError={isOpportunityIdCrmError}
                  state={enteredOpportunityId}
                  handleEnteredOpportunityId={handleEnteredOpportunityId}
                  isBusinessUnitMed={() =>{}}
                  usCountryStock={() =>{}}
                  user={user}
                />
              ) : (
                <>
                  {transactionOpportunities.map(item => (
                    <div>{item.opportunityNumber}</div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className='row small-height mb-3'>
            <div className='col-6 font-bold'>{strings.crmOpportunityStatus}</div>
            <div className='col-6'>{opportunityStatusName}</div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 column-vertical-top font-bold'>{strings.requestor}</div>
            {editing && hasEditingPermissions ? (
              <div className='col-6'>
                <Dropdown
                  data={usersList}
                  hasSection={false}
                  multiSelect={false}
                  filter={true}
                  idKey='accountId'
                  field='requestor'
                  displayValue={[
                    { key: 'lastName', separator: ', ' },
                    { key: 'firstName', separator: ' (' },
                    { key: 'email', separator: ')' },
                  ]}
                  isRequired
                  validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                  placeholder={strings.pleaseSelect}
                  onChange={onChangeDropdown}
                  value={requestor.value}
                  fullValue={requestor.fullValue}
                />
              </div>
            ) : (
              <div className='col-6 mb-3'>
                {borrowerName && <div>{borrowerName}</div>}
                {borrowerEmail && <div>{borrowerEmail}</div>}
                {borrowerPhone && <div>{borrowerPhone}</div>}
              </div>
            )}
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.customer}</div>
            <div className='col-6'>
              {editing && hasEditingPermissions ? (
                <InputField
                  label={strings.customer}
                  field='customerField'
                  placeholder=''
                  isRequired
                  validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                  value={customerField.value}
                  error={customerField.error}
                  onChange={onChangeInput}
                />
              ) : (
                customer
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.customerInstitute}</div>
            <div className='col-6'>
              {editing && hasEditingPermissions ? (
                <InputField
                  label={strings.customerInstitute}
                  field='customerInstituteField'
                  placeholder=''
                  isRequired
                  validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                  value={customerInstituteField.value}
                  error={customerInstituteField.error}
                  onChange={onChangeInput}
                />
              ) : (
                customerInstitute
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.typesOfJobs}</div>
            <div className='col-6'>
              {editing && hasSavePermission ? (
                <TypesOfJobsBlock
                  typesOfJobs={typesOfJobs}
                  demoTime={demoTime}
                  typesOfJobsList={newTypesOfJobsList}
                  onChangeDemoTime={onChangeDemoTime}
                  onChangeTypeOfJobs={onChangeTypeOfJobs}
                />
              ) : (
                <ul className='ml-3'>
                  {selectedTypesOfJobs.map(item => (
                    <li title={item.toolTipValue !== null ? item.toolTipValue : ''} style={{cursor:'pointer'}}>{item.name}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.operator}</div>
            <div className='col-6'>
              {editing && hasEditingPermissions ? (
                <Dropdown
                  placeholder=''
                  idKey='id'
                  field='operatorField'
                  data={operatorModel}
                  hasSection={false}
                  multiSelect={false}
                  filter={false}
                  isRequired={false}
                  validators={[]}
                  displayValue={[{ key: 'value', separator: '' }]}
                  onChange={onChangeDropdown}
                  value={operatorField.value}
                  fullValue={operatorField.fullValue}
                />
              ) : (
                operator
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 column-vertical-top font-bold'>{strings.nameOfOperator}</div>
            {editing && hasEditingPermissions ? (
              (operatorField.value == 'PARTNER' || operatorField.value == 'Partner') ?
              <div className='col-6'>
                <InputField
                  label={strings.name}
                  field='llPartnerNameFeild'
                  placeholder=''
                  isRequired
                  type='text'
                  validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                  value={llPartnerNameFeild.value}
                  error={llPartnerNameFeild.error}
                  onChange={onChangeInput}
                  required={true}
                  className={'mb-3'}
                />
                <InputField
                  label={strings.email}
                  field='llPartnerEmailFeild'
                  placeholder=''
                  isRequired
                  type='text'
                  validators={[
                    { check: Validators.email, message: 'invalidEmailError' },
                    {
                      check: Validators.required,
                      message: 'requiredErrorMessage',
                    },
                  ]}
                  value={llPartnerEmailFeild.value}
                  error={llPartnerEmailFeild.error}
                  onChange={onChangeInput}
                  required={true}
                  maxLength={150}
                />
              </div>
              :
              <div className='col-6'>
                <Dropdown
                  data={usersList}
                  hasSection={false}
                  multiSelect={false}
                  filter={true}
                  idKey='accountId'
                  field='nameOfOperator'
                  displayValue={[
                    { key: 'lastName', separator: ', ' },
                    { key: 'firstName', separator: '' },
                  ]}
                  isRequired={false}
                  validators={[]}
                  placeholder={strings.pleaseSelect}
                  onChange={onChangeDropdown}
                  value={nameOfOperatorfield.value}
                  fullValue={nameOfOperatorfield.fullValue}
                  clearOption={true}
                  selectAllOption
                  selectAllPlaceholder='clear'
                />
              </div>
            ) : (
              
                operator === 'PARTNER' ? 
                <>
                <div className='col-3 mb-3'>{llPartnerName && <div>{llPartnerName}</div>}</div>
                <div className='col-3 mb-3'>{llPartnerEmail && <div>{llPartnerEmail}</div>}</div>
                </>
                :
                <div className='col-6 mb-3'>{nameOfOperator && <div>{nameOfOperator}</div>}</div>
              
            )}
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold vertical-middle'>{strings.transactionStatus}</div>
            <div className='col-6'>
              {hasEditingPermissions ? (
                <Dropdown
                  data={transactionStatusOptions}
                  hasSection={false}
                  multiSelect={false}
                  filter={false}
                  idKey='id'
                  field='transactionStatus'
                  displayValue={[{ key: 'name', separator: '' }]}
                  isRequired={true}
                  placeholder={strings.requested}
                  onChange={onChangeDropdown}
                  value={transactionStatusField.value}
                  fullValue={transactionStatusField.fullValue}
                />
              ) : (
                transactionStatusFullValue.name || strings.requested
              )}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.partnerSite}</div>
            <div className='col-6'>{partnerSite}</div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.requestCreatedBy}</div>
            <div className='col-6'>
              {userName}, {dateOfCreation}
            </div>
          </div>
          {(hasEditingPermissions || isBorrower || editing) && (
            <div className='save-dates-button-container mt-3'>
              <Button
                disabled={!hasSavePermission}
                bgFill={false}
                value={strings.save}
                iconName='save'
                onClick={onSave}
                loading={isLoader}
              />
            </div>
          )}
        </div>
        <div className='col col-lg-6 col-12'>
          {editing && hasEditingPermissions ? (
            <FormComponent model={addressModel} formValue={demoSiteForm.formData} onChange={onChangeForm} />
          ) : (
            <div className='row'>
              <div className='col-6 font-bold'>{strings.demoSite}</div>
              <div className='col-6'>
                <div>
                  {firstName} {lastName}
                </div>
                <div>{organization}</div>
                <div>{department}</div>
                <div>
                  {street} {houseNumber}
                </div>
                <div>
                  {postalCode} {city}
                </div>
                <div>{state}</div>
                <div>{country.name}</div>
                <div>
                  {strings.phone}: {phone}
                </div>
                <div>
                  {strings.email}: {email}
                </div>
              </div>
            </div>
          )}
          <>
            <div className='font-bold'>{strings.commentText}</div>
            {editing && hasEditingPermissions ? (
              <TextArea
                label={strings.commentText}
                type='textarea'
                field='commentField'
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                isRequired={true}
                doValidateForm
                value={commentField.value}
                error={commentField.error}
                placeholder=''
                onChange={onChangeInput}
              />
            ) : (
              <div>{comment}</div>
            )}
          </>
          <Button
            isGhost={true}
            bgFill={false}
            dataTest='transaction-details-show-messages'
            styleClass='show-all-messages-button'
            iconName='notification'
            iconPosition='left'
            onClick={() => setCurrentTab(2)}
            value={strings.showAllMessages}
          />
          <Modal
            id='confirm-partner'
            confirmTitle={strings.ok}
            confirmAction={() => onSaveClick(operatorField.value, operatorField.fullValue )}
            onCloseClick={() => onCloseClick(operatorField.value, operatorField.fullValue )}
          >
            <p>{strings.operatorChangeWarningMessage}</p>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default LLInformation;

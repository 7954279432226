import Validators from 'library/utilities/Validators';
import React from 'react';
import strings from 'resources/locales/Translate';
import Icon from '../../../../../../library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';

export const AddKitLoanDismantleEmail = ({
  kitDismantleEmail,
  kitDismantleEmailError,
  newKitDismantleEmailArray,
  onChange,
  handleOnChangeExstingValue,
  onAdd,
  deleteEmail,
}) => {
  const disabled = newKitDismantleEmailArray && newKitDismantleEmailArray.length >= 2;
  return (
    <div className='col-4 p-1'>
      {newKitDismantleEmailArray &&
        newKitDismantleEmailArray.length > 0 &&
        newKitDismantleEmailArray.map((item, index) => (
          <>
            <div className='row' style={{ marginBottom: '1%' }}>
              <div className='col-md-10'>
                <InputField
                  onChange={e => handleOnChangeExstingValue(e, index, 'kitDismantleEmail')}
                  value={`${item.email}`}
                  className='mb-2'
                  label={strings.email}
                />
              </div>

              <button
                type='button'
                className='delete-email-button '
                onClick={() => deleteEmail(index, 'kitDismantleEmail')}
              >
                <Icon name='delete' width={24} height={24} fill='#0088d0' />
              </button>
            </div>
          </>
        ))}
      <div className='row'>
        <div className='col-md-10'>
          <InputField
            onChange={e => onChange(e, 'kitDismantleEmail')}
            value={kitDismantleEmail}
            disabled={disabled}
            isRequired={false}
            validators={[{ check: Validators.email, message: strings.invalidEmailError }]}
            error={kitDismantleEmailError}
            label={strings.email}
          />
        </div>
        <button
          type='button'
          className='add-email-button '
          onClick={() => onAdd()}
          disabled={disabled}
        >
          <Icon name='plus' width={30} height={24} fill='#fff' />
        </button>
      </div>
    </div>
  );
};

export default AddKitLoanDismantleEmail;

export const getValues = ({ field, fullValue, type, validatedValue }) => {
  if (type === 'dropdown') {
    return {
      [field]: validatedValue.value,
      [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
      [`${field}FullValue`]: fullValue,
    };
  }
  return {
    [field]: validatedValue.value,
    [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
  };
};

export const validate = (value, field, validators) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message, validators[i].minDate, validators[i].strLength);
      if (error) {
        return { value, error };
      }
    }
    return { value, error: null };
  }
  return { value, error: null };
};

export const validateForm = ({ form, model }) => {
  const formValue = (form && form.formData) || {};
  let isValid = true;
  let newFormData = {
    ...form.formData,
  };
  model.forEach(({ field, validators, type }) => {
    const stateValue = formValue && formValue.hasOwnProperty(field) ? formValue[field] : '';
    const stateFullValue = formValue && formValue.hasOwnProperty(field) ? formValue[`${field}FullValue`] : '';
    let validatedValue = { value: stateValue, error: null };
    validatedValue = validate(stateValue, field, validators);
    if (validatedValue.error) {
      isValid = false;
    }
    newFormData = {
      ...newFormData,
      ...getValues({ field, fullValue: stateFullValue, type, validatedValue }),
    };
  });
  newFormData = { formData: newFormData, isFormValid: isValid };
  return newFormData;
};

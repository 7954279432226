import React, { useCallback } from "react";
import { NavLink } from 'react-router-dom';
import Validators from 'library/utilities/Validators';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import { get } from 'lodash';
import { useInformation } from './Information.hook';
import Button from 'library/common/commonComponents/Button/Button';
import $ from 'jquery'
import OperatorsModal from "./AssignOperator/OperatorsModal.component";

export const Information = ({
  editing,
  onClickEdit,
  toggleActionMessage,
  zmmctransactionDetails,
  user,
  loansIntended,
  rmsZmccUser,
  zmccPrioritesArray,
  updateZmccTransaction,
  zmccTransactionDetailsStatuses,
  fetchConnectedDemoLoans,
  connectedTransactionArray,
  hasPermission,
  updateZmccTransactionStatus,
  formType,
  updateZmccInformationTab,
  zmccStockOperators,
  zmccSubscriberUsers,
  systemCategoriesList,
  fetchOperatorScheduleOfStockOnGivenDate,
  changeLoader,
  showAllButton
}) => {
  const getDemoSiteName = get(zmmctransactionDetails, 'stockInformation.locationName');
  const {
    category,
    crmOpportunityId,
    zmccRequestor,
    organization,
    customer,
    operator,
    subscriber,
    priority,
    samplesAtValue,
    correlative,
    demoTime,
    demoSite,
    travelLogValue,
    transactionStastusValue,
    name,
    commentValue,
    connectedTransactionValues,
    filteredConnectedTransaction,
    getCommentValue,
    transactionId,
    modifiedConnectTransactionArray,
    intendedIdsForOpportunityMandatory,
    customerNameValue,
    onChangeDropdown,
    onChangeInput,
    onChangeCheckbox,
    onSave,
    getDataToSendFromInformationTab,
    systemCategory,
    travelTimeValue
  } = useInformation({
    onClickEdit,
    zmmctransactionDetails,
    user,
    getDemoSiteName,
    toggleActionMessage,
    updateZmccTransaction,
    fetchConnectedDemoLoans,
    connectedTransactionArray,
    editing,
    updateZmccTransactionStatus,
    formType,
    updateZmccInformationTab
  });
  const {
    suggestedDate,
    kitInformation,
    additionalInstruments,
    opportunityId,
    requestor,
    customerName,
    customerOrganization,
    zmccPriority,
    transactions,
    samplesAt,
    travelLog,
    zmccOperatorsList,
    zmccSubscribersList,
    eventTime,
    eventEndTime,
    travelTime
  } = zmmctransactionDetails;
  const getIntendedForValue = get(zmmctransactionDetails, 'intendedFor.intendedForTranslated[0].name');
  const getZmccSystemCategory = get(zmmctransactionDetails, 'zmccSystemCategories.name')
  const requestedCreatedBy = transactions && transactions.user;
  const getZmccPriorityName = get(zmccPriority, 'zmccPriorityTranslated[0].name');

  const trimmedTime =  eventTime?.substring(0, eventTime.length - 3);
  const trimedEndTime  = eventEndTime?.substring(0, eventEndTime.length - 3);
  return (
    <>
      {showAllButton && <OperatorsModal 
        id={"operators-availability-schedule-modal"}
        changeLoader={changeLoader}
        transactionDetails={zmmctransactionDetails} 
        stockOperators={zmccStockOperators} 
        fetchOperatorScheduleOfStockOnGivenDate={fetchOperatorScheduleOfStockOnGivenDate}
        formType={formType}
        getDataToSendFromInformationTab={getDataToSendFromInformationTab}
        onClickEdit={onClickEdit}
        editing={editing}
      />}
      <div className='container-fluid mt-3'>
        <div className='row align-items-start'>
          <div className='col col-lg-6 col-12'>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.demoDateAndTime}</div>
              <div className='col-6'>{`${moment(suggestedDate).format('YYYY-MM-DD')}  ${(eventTime != null && eventTime != undefined && eventEndTime != null && eventEndTime !== undefined) ? `${trimmedTime}-${trimedEndTime}` : '00:00'}`}</div>
            </div>
            <div className='row mb-3'>
            <div className='col-6 font-bold'>{strings.systemCategory}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={systemCategoriesList}
                    hasSection={false}
                    multiSelect={false}
                    filter={false}
                    idKey='id'
                    field='zmccSystemCategoriesId'
                    displayValue={[{ key: 'name', separator: ' ' }]}
                    isRequired={false}
                    validators={[]}
                    placeholder={strings.pleaseSelect}
                    onChange={onChangeDropdown}
                    value={systemCategory.value}
                    fullValue={systemCategory.fullValue}
                  />
                ) : (
                  getZmccSystemCategory
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.zmccSystems}</div>
              {kitInformation && (
                <div className='col-6 d-flex flex-wrap'>
                  {
                    <NavLink
                      to={`/kit_details/${kitInformation.kitInformationId}`}
                    >{`${kitInformation.kitName}`}</NavLink>
                  }
                  {additionalInstruments &&
                    additionalInstruments.length > 0 &&
                    additionalInstruments.map((item, index) => (
                      <>
                        {item.kitInformation !== null && (
                          <><div style={{paddingRight: '3px'}}>{`, `}</ div>
                          <NavLink to={`/kit_details/${item.kitInformation.kitInformationId}`}>
                            {` ${item.kitInformation.kitName}`}
                          </NavLink>
                          </>
                        )}
                        </>
                    ))}
                </div>
              )}
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.nameShortDescription}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.nameShortDescription}
                    field='name'
                    placeholder=''
                    isRequired
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    value={name.value}
                    error={name.error}
                    onChange={onChangeInput}
                  />
                ) : name.value ? (
                  name.value
                ) : (
                  `${kitInformation && kitInformation.kitName} ${(customerName !== null && customerName !== undefined) ? customerName : ''}`
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.category}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={loansIntended}
                    hasSection={false}
                    multiSelect={false}
                    filter={true}
                    idKey='id'
                    field='category'
                    displayValue={[{ key: 'name', separator: ' ' }]}
                    isRequired
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    placeholder={strings.pleaseSelect}
                    onChange={onChangeDropdown}
                    value={category.value}
                    fullValue={category.fullValue}
                  />
                ) : (
                  getIntendedForValue
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.crmOpportunityId}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.crmOpportunityId}
                    field='opportunityId'
                    placeholder=''
                    isRequired={intendedIdsForOpportunityMandatory.includes(category.value)}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    value={crmOpportunityId.value}
                    error={crmOpportunityId.error}
                    onChange={onChangeInput}
                    maxLength={user && user.crmSystemId === 'ngcrm' ? 18 : 9}
                  />
                ) : (
                  opportunityId
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.crmOpportunityStatus}</div>
              <div className='col-6'></div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.crmDemoRequestID}</div>
              <div className='col-6'></div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.zmccNameOFRequestor}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={rmsZmccUser}
                    hasSection={false}
                    multiSelect={false}
                    filter={true}
                    idKey='accountId'
                    field='requestor'
                    displayValue={[
                      { key: 'firstName', separator: ', ' },
                      { key: 'lastName', separator: ' ' },
                    ]}
                    isRequired={true}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    placeholder={strings.pleaseSelect}
                    onChange={onChangeDropdown}
                    value={zmccRequestor.value}
                    fullValue={zmccRequestor.fullValue}
                  />
                ) : (
                  requestor && `${requestor.firstName} ${requestor.lastName}`
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.organization}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.organization}
                    field='organization'
                    placeholder=''
                    isRequired={true}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    value={organization.value}
                    error={organization.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  organization ? organization.value : user && user.portalOrganization
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.customerName}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.customerName}
                    field='customerName'
                    placeholder=''
                    isRequired={false}
                    validators={[]}
                    value={customerNameValue.value}
                    error={customerNameValue.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  customerName
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.zmccCustomerInstitute}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.zmccCustomerInstitute}
                    field='zmccCustomerInstitute'
                    placeholder=''
                    isRequired={true}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    value={customer.value}
                    error={customer.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  customerOrganization
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.connectedTransaction}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={modifiedConnectTransactionArray.filter(item => item.transactionsId != transactionId) || []}
                    hasSection={false}
                    multiSelect={true}
                    filter={true}
                    clearMultiOption={true}
                    idKey='transactionsId'
                    displayValue={[
                      { key: 'transactionNumber', separator: ' ' },
                      // { key: 'name', separator: ' ' },
                    ]}
                    isRequired={false}
                    validators={[]}
                    placeholder={strings.pleaseSelect + '...'}
                    label={strings.connectedTransaction}
                    field='connectedTransaction'
                    onChange={onChangeDropdown}
                    value={connectedTransactionValues.value}
                    fullValue={connectedTransactionValues.fullValue}
                  />
                ) : filteredConnectedTransaction ? (
                  filteredConnectedTransaction.map(
                    (item, index) => `${item} ${filteredConnectedTransaction.length - 1 != index ? ',' : ''} `,
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.operator}</div>
              <div className='col-6'>
              {zmccOperatorsList &&
                  zmccOperatorsList.map(
                    (item, index) =>
                      `${item.user.firstName} ${item.user.lastName}${
                        zmccOperatorsList.length - 1 !== index ? ',' : ''
                      } `,
                  )
              }
                <Button
                  disabled={!hasPermission}
                  bgFill={false}
                  value={zmccOperatorsList && zmccOperatorsList?.length > 0 ? strings.reassignOperator: strings.assignOperator }
                  iconName='plus'
                  onClick={() => $('#operators-availability-schedule-modal').modal('show')}
                  styleClass = 'w-50'
                >
                </Button>
                {/* {editing ? (
                  <>  
                    <Dropdown
                      data={zmccStockOperators}
                      hasSection={false}
                      multiSelect={true}
                      filter={true}
                      clearMultiOption={false}
                      idKey='accountId'
                      displayValue={[
                        { key: 'firstName', separator: ', ' },
                        { key: 'lastName', separator: ' ' },
                      ]}
                      isRequired={false}
                      validators={[]}
                      placeholder={strings.pleaseSelect + '...'}
                      label={strings.operator}
                      field='operator'
                      onChange={onChangeDropdown}
                      value={operator.value}
                      fullValue={operator.fullValue}
                      selectAllOption={false}
                    />
                  </>

                ) : (
                  zmccOperatorsList &&
                  zmccOperatorsList.map(
                    (item, index) =>
                      `${item.user.firstName} ${item.user.lastName}${
                        zmccOperatorsList.length - 1 !== index ? ',' : ''
                      } `,
                  )
                )} */}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.subscribers}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={zmccSubscriberUsers}
                    hasSection={false}
                    multiSelect={true}
                    filter={true}
                    clearMultiOption={true}
                    idKey='accountId'
                    displayValue={[
                      { key: 'firstName', separator: ', ' },
                      { key: 'lastName', separator: ' ' },
                    ]}
                    isRequired={false}
                    validators={[]}
                    placeholder={strings.pleaseSelect + '...'}
                    label={strings.subscribers}
                    field='subscriber'
                    onChange={onChangeDropdown}
                    value={subscriber.value}
                    fullValue={subscriber.fullValue}
                  />
                ) : (
                  zmccSubscribersList &&
                  zmccSubscribersList.map(
                    (item, index) =>
                      `${item.user.firstName} ${item.user.lastName}${
                        zmccSubscribersList.length - 1 != index ? ',' : ''
                      } `,
                  )
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.priority}</div>
              <div className='col-6'>
                {editing ? (
                  <Dropdown
                    data={zmccPrioritesArray}
                    hasSection={false}
                    multiSelect={false}
                    filter={false}
                    clearMultiOption={true}
                    idKey='id'
                    displayValue={[{ key: 'name', separator: ' ' }]}
                    isRequired={true}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    placeholder={strings.pleaseSelect + '...'}
                    label={strings.priority}
                    field='priority'
                    onChange={onChangeDropdown}
                    value={priority.value}
                    fullValue={priority.fullValue}
                  />
                ) : (
                  getZmccPriorityName
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.transactionStatus}</div>
              <div className='col-6'>
                {hasPermission ? (
                  <Dropdown
                    data={zmccTransactionDetailsStatuses}
                    hasSection={false}
                    multiSelect={false}
                    filter={false}
                    clearMultiOption={false}
                    idKey='transactionStatusId'
                    displayValue={[{ key: 'name', separator: ' ' }]}
                    isRequired={false}
                    validators={[]}
                    placeholder={strings.pleaseSelect + '...'}
                    field='transactionStatus'
                    onChange={onChangeDropdown}
                    value={transactionStastusValue.value}
                    fullValue={transactionStastusValue.fullValue}
                  />
                ) : (
                  transactionStastusValue.fullValue.name || ''
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.samplesAt}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.samplesAt}
                    field='samplesAt'
                    placeholder=''
                    isRequired={false}
                    validators={[]}
                    value={samplesAtValue.value}
                    error={samplesAtValue.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  samplesAt
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.isCorrelative}</div>
              <div className='col-6'>
                <Checkbox
                  label=''
                  field='isCorrelative'
                  isRequired={false}
                  selected={correlative}
                  onChange={onChangeCheckbox}
                  disabled={!editing}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.requestCreatedBy}</div>
              <div className='col-6'>
                {requestedCreatedBy &&
                  `${requestedCreatedBy.firstName}  ${requestedCreatedBy.lastName} ${moment(
                    transactions.editedAt,
                  ).format('DD-MM-YYYY')}`}
              </div>
            </div>
            <div className='save-dates-button-container mt-3'>
              <Button
                disabled={!hasPermission || !showAllButton}
                bgFill={false}
                value={strings.save}
                iconName='save'
                onClick={() => onSave(false)}
                // loading={isLoader}
              />
            </div>
          </div>
          <div className='col col-lg-6 col-12'>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.zmccDemoSite}</div>
              <div className='col-6'>{getDemoSiteName}</div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.timeTravel}</div>
              <div className='col-6'>
              {editing ? (
                  <InputField
                    label={strings.timeTravel}
                    field='travelTime'
                    placeholder=''
                    isRequired={false}
                    validators={[]}
                    value={travelTimeValue.value}
                    error={travelTimeValue.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  travelTime
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.travelLog}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.travelLog}
                    field='travelLog'
                    placeholder=''
                    isRequired={false}
                    validators={[]}
                    value={travelLogValue.value}
                    error={travelLogValue.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  travelLog
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6 font-bold'>{strings.comment}</div>
              <div className='col-6'>
                {editing ? (
                  <InputField
                    label={strings.comment}
                    field='comment'
                    placeholder=''
                    isRequired={false}
                    validators={[]}
                    value={commentValue.value}
                    error={commentValue.error}
                    onChange={onChangeInput}
                  />
                ) : (
                  getCommentValue
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Information;

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import {
  CAN_EDIT_STOCK,
  NEW_STOCK_ROLES,
  ADDRESS_LIST,
  CAN_ADD_MULTIPLE_ADDRESS,
  ROOMS_LIST,
  INTERNAL_CRM_ACCOUNT_ID,
  KITS_LIST_BY_WAREHOUSE_ID,
  AVAILABILITY_SLOTS_LIST,
} from 'modules/Stock/CreateStock/CreateStock.constants';

import { WORKFLOW_CATEGORY_QUESTION } from './Tabs/Workflows/workflows.constants';
const initialState = {
  stockDetails: { stockUserRole: [] },
  stockId: null,
  stockDetailsToDelete: {},
  canEditStock: true,
  entitlements: null,
  newStockRoles: [],
  addressList: [],
  canAddMultipleAddress: false,
  roomsList: [],
  crmAccountId: '',
  kitsListByWarehouse: [],
  availabilitySlotList: [],
  stockWorkflows: {},
  stockAddressesList: []
};

const CreateStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STOCK_CREATED:
      return {
        ...state,
        stockId: action.payload,
      };
    case actionTypes.FETCH_STOCK_DETAILS:
      return {
        ...state,
        stockDetails: action.payload,
      };
    case CAN_EDIT_STOCK:
      return { ...state, canEditStock: action.payload };
    case actionTypes.RESET_CREATE_STOCK:
      return initialState;
    case actionTypes.FETCH_STOCK_ENTITLEMENTS:
      return {
        ...state,
        entitlements: action.payload,
      };
    case NEW_STOCK_ROLES:
      return {
        ...state,
        newStockRoles: action.payload,
      };
    case ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
      };
    case CAN_ADD_MULTIPLE_ADDRESS:
      return {
        ...state,
        canAddMultipleAddress: action.payload,
      };
    case ROOMS_LIST:
      return {
        ...state,
        roomsList: action.payload,
      };
    case INTERNAL_CRM_ACCOUNT_ID:
      return {
        ...state,
        crmAccountId: action.payload,
      };
    case KITS_LIST_BY_WAREHOUSE_ID:
      return {
        ...state,
        kitsListByWarehouse: action.payload,
      };
    case AVAILABILITY_SLOTS_LIST:
      return {
        ...state,
        availabilitySlotList: action.payload,
      };
    case WORKFLOW_CATEGORY_QUESTION:
      return { ...state, stockWorkflows: action.payload };
    default:
      return state;
  }
};

export default CreateStockReducer;

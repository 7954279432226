import Validators from 'library/utilities/Validators';

export const FETCH_CREATE_KIT_DETAILS = 'FETCH_CREATE_KIT_DETAILS';
export const UPDATE_CREATE_KIT_FORM = 'UPDATE_CREATE_KIT_FORM';
export const RESET_CREATE_KIT = 'RESET_CREATE_KIT';
export const DIVISION_LIST = 'DIVISION_LIST';
export const ERP_STATUS = 'ERP_STATUS';
export const REFURBISHMENTFEES_STATUS = 'REFURBISHMENTFEES_STATUS';

export const createKitBreadcrumbs = isEditView => [
  { name: 'homepage', url: '/home' },
  { name: 'kits', url: '/kits' },
  { name: isEditView ? 'maintainKits' : 'createKit', url: '' },
];

export const generalInformationModel = [
  {
    label: 'kitName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',

    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-7',
  },
  {
    label: 'kitInformationId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitInformationId',
    validators: [],
    required: false,
    styleClass: 'col-sm-5',
    disabled: true,
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'systemValue',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'systemValue',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    inputGroupText: 'euroSymbol',
    isAppend: true,
  },
  {
    label: 'hideKitText',
    selected: false,
    type: 'checkbox',
    field: 'hideKit',
    styleClass: 'col-sm-6 mt-4',
  },
];

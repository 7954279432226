import { connect } from 'react-redux';

import DemoActivityAndKitDataFilter from './DemoActivityAndKitDataFilter.component';

const mapStateToProps = ({ languageReducer, statisticsReducer }) => ({
  language: languageReducer,
  businessUnits: statisticsReducer.businessUnits,
  countries: statisticsReducer.countries,
  businessUnitsForkits: statisticsReducer.businessUnitsForkits
});

export default connect(mapStateToProps)(DemoActivityAndKitDataFilter);

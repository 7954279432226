import { useState } from 'react';

export const useAdditionalInformation = (props) => {


  const handleNonFormComponent = (values, field, formName ) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
        },
      },
    });
    props.setUnsavedChanges(true);
  };


  return {
    handleNonFormComponent
  };

};

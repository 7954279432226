import React, { Component } from 'react';

import strings from 'resources/locales/Translate';

import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { scrollToTop } from 'library/utilities/scrollActions';

import './StockImages.style.scss';

class StockImages extends Component {
  state = {
    files: null,
    loading: false,
    viewType: 'list',
  };

  componentDidMount() {
    this.fetchImages();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.files && this.props.isActive && !prevProps.isActive) {
      this.fetchImages();
    }
  }

  fetchImages = async () => {
    if (this.props.stockId && !this.state.loading) {
      this.setState({ loading: true }, async () => {
        const { success, data } = await this.props.fetchStockImages(this.props.stockId);
        if (success) {
          this.setState({ files: data, loading: false });
        }
      });
    }
  };

  toggleViewType = viewType => () => {
    this.setState({ viewType });
  };

  cancelStockCreation = () => {
    this.props.history.replace('/stocks');
  };

  addStockImageFile = newFiles => {
    const { files } = this.state;
    files.unshift(...newFiles);
    this.setState({ files });
  };

  removeStockImageFile = async fileIndex => {
    const { files } = this.state;
    if (files[fileIndex].stockImagesId) {
      const { success } = await this.props.removeStockImageFile(files[fileIndex].stockImagesId);
      if (success) {
        files.splice(fileIndex, 1);
      }
    } else {
      files.splice(fileIndex, 1);
    }
    this.setState({ files });
  };

  generateDataForAPI = async () => {
    const { files } = this.state;
    const filesArray = files.filter(file => !file.hasOwnProperty('stockImagesId'));
    const { businessUnitFullValue, crmAccountId } = this.props;
    const { designation } = businessUnitFullValue || {};
    const isRMSLL = designation === 'RMS_L_L';
    if (filesArray.length) {
      if (isRMSLL) {
        if (!crmAccountId) {
          scrollToTop(500);
          this.props.toggleActionMessage(true, 'error', 'crmAccountIdError');
          return;
        }
    }
      const { success, data: files } = await this.props.uploadStockImages(filesArray, this.props.stockId);
      if (success) {
        this.setState({ files });
      }
    } else {
      this.props.toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage');
      scrollToTop(500);
    }
  };

  render() {
    const { files, viewType } = this.state;
    const { canEditStock } = this.props;

    return (
      <div className='image-container'>
        <div className='container-fluid form-container'>
          <div className='toggle-container d-flex align-items-center justify-content-between'>
            <h3 className='form-section-title mb-0'>{strings.attachmentsStock}</h3>
            <div className='buttons'>
              <button onClick={this.toggleViewType('list')}>
                <Icon name='list_view' width={32} height={32} fill={viewType === 'list' ? '#000' : '#e0e1dd'} />
              </button>
              <button onClick={this.toggleViewType('grid')}>
                <Icon name='grid_view' width={32} height={32} fill={viewType === 'grid' ? '#000' : '#e0e1dd'} />
              </button>
            </div>
          </div>

          {files && (
            <FileUploader
              mimeType='image/*'
              files={files}
              maxSize={4}
              viewType={viewType}
              onRemove={this.removeStockImageFile}
              onFileAdd={this.addStockImageFile}
              isUploadAllowed={canEditStock}
            />
          )}

          {canEditStock && (
            <div className='row'>
              <div className='col-sm-12 mt-3'>
                <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StockImages;

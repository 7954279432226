import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { fetchBookingsForZmccKit } from 'modules/Calendar/calendar-services';
import AppScheduler from 'library/common/commonComponents/AppSchedular/AppSchedular';
import moment from 'moment';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { useZmccAdjustDatesPopup } from './ZmccAdjustDatesPopup.hook';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Validators from 'library/utilities/Validators';

export const ZmccAdjustDates = ({ id, zmccTransactionDetails, saveAdjustDates, transactionId }) => {
  const {
    bookedKits,
    handleDateCange,
    dateValue,
    showOnlyBlockedDates,
    onSave,
    onClose,
    loading,
    timeSlotList,
    startTime,
    endTime,
    startTimeError,
    endTimeError,
    onChangeDropdown,
    timeError,
  } = useZmccAdjustDatesPopup({
    id,
    zmccTransactionDetails,
    saveAdjustDates,
    transactionId,
  });
  const disabledDates = showOnlyBlockedDates()
  return (
    <Modal
      id={id}
      className='modal-lg'
      title={strings.adjustDates}
      confirmAction={onSave}
      confirmDataTest='adjust-dates-button-save'
      loading={loading}
      onCloseClick={onClose}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
    >
      <div className='d-flex'>
        <DateInput
          label={strings.dateRange}
          field='dateRange'
          placeholder={strings.pleaseSelectDate + '...'}
          onChange={handleDateCange}
          selectedDate={dateValue}
          isRequired={true}
          mainContainerStyle='col-sm-6'
          disabledDates={disabledDates}
          disabled={disabledDates.length === 0}
        />
        <div className='col-sm-3'>
          <Dropdown
            data={timeSlotList}
            hasSection={false}
            multiSelect={false}
            filter={false}
            idKey='id'
            field='startTime'
            displayValue={[{ key: 'value', separator: ' ' }]}
            label={strings.startTime}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeDropdown}
            value={startTime.value}
            fullValue={startTime.fullValue}
            error={startTimeError}
          />
        </div>
        <div className='col-sm-3'>
          <Dropdown
            data={timeSlotList}
            hasSection={false}
            multiSelect={false}
            filter={false}
            idKey='id'
            field='endTime'
            displayValue={[{ key: 'value', separator: ' ' }]}
            label={strings.endTime}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeDropdown}
            value={endTime.value}
            fullValue={endTime.fullValue}
            error={endTimeError}
          />
        </div>
      </div>
      
      <AppScheduler
        resources={[
          {
            id: 'r0',
            name: 'Resource0',
            groupOnly: true,
          },
        ]}
        updateEventsOnChange
        disableClick
        resizeable={false}
        dragable={false}
        schedulerWrapperClassName='mr-3'
        hideOptions
        startDate={zmccTransactionDetails && moment(zmccTransactionDetails.suggestedDate).format('YYYY-MM-DD')}
        alreadyBooked={bookedKits}
        isLabsLocation={false}
        isCalenderFromAdjustDates={true}
        events={[]}
        cart={[]}
      />  

      {timeError && <div className='error-block'>{timeError}</div>}
    </Modal>
  );
};

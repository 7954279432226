import { useEffect, useState } from 'react';
import moment from 'moment';
import { fetchBookingsForZmccKit } from 'modules/Calendar/calendar-services';
import $ from 'jquery';
import strings from 'resources/locales/Translate';

export const useZmccAdjustDatesPopup = ({ id, zmccTransactionDetails, saveAdjustDates, transactionId }) => {
  const [bookedKits, setBookedKits] = useState([]);
  const [copyBookedKitsForRest, setCopyBookedKitsForRest] = useState([]);
  const [value, onChange] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState({ value: null, fullValue: null });
  const [endTime, setEndTime] = useState({ value: null, fullValue: null });
  const [timeError, setTimeError] = useState('');
  useEffect(() => {
    if (zmccTransactionDetails && zmccTransactionDetails.suggestedDate) {
      const date = new Date(zmccTransactionDetails.suggestedDate);
      setDateValue(date);
      const modifiedStartTime = moment(zmccTransactionDetails.eventTime, "HH:mm:ss").format("HH:mm");
      const modifiedEndTime = moment(zmccTransactionDetails.eventEndTime, "HH:mm:ss").format("HH:mm")
      setStartTime({value:modifiedStartTime});
      setEndTime({value:modifiedEndTime});
    }
    getBookedKits();
  }, [zmccTransactionDetails]);
  const getBookedKits = async () => {
    const { data } = await fetchBookingsForZmccKit({
      filter: { kitId: zmccTransactionDetails.kitInformation.kitInformationId },
    });
    const alreadyBooked = [];
    if (data.content && data.content.length > 0 && data.content[0].zmccCalendarBooked) {
      data.content[0].zmccCalendarBooked.forEach((transaction, index) => {
        const { from, to, id, transactionsId, zmccCalendarBlockId } = transaction;

        const startDate = moment.utc(from).format('YYYY-MM-DD') + ' 00:00:00';
        const endDate = moment.utc(to).format('YYYY-MM-DD') + ' 23:59:59';
        const transactionObject = {
          kit: { kitInformationId: 1, kitName: '' },
          resourceId: 'r0',
          start: startDate,
          end: endDate,
          isBooked: true,
          isSelected: true,
          isLabsLocation: false,
          isZMCCCalendar: true,
          transactionsId: transactionsId,
          id: zmccCalendarBlockId,
          title: '',
        };

        alreadyBooked.push(transactionObject);
      });
    }

    if (data.content && data.content.length > 0 && data.content[0].zmccCalendarBlocked) {
      data.content[0].zmccCalendarBlocked.forEach((transaction, index) => {
        const { from, to, id } = transaction;

        const startDate = moment.utc(from).format('YYYY-MM-DD') + ' 00:00:00';
        const endDate = moment.utc(to).format('YYYY-MM-DD') + ' 23:59:59';

        const transactionObject = {
          kit: { kitInformationId: 1, kitName: '' },
          resourceId: 'r0',
          start: startDate,
          end: endDate,
          isZMCCSlotAvailibility: true,
          isZMCCSlotBooked: false,
          isSelected: true,
          isAvailabilityChecked: false,
          isLabsLocation: false,
          isZMCCCalendar: true,
          transactionsId: 0,
          id: id,
          title: '',
        };

        alreadyBooked.push(transactionObject);
      });
    }
    setBookedKits(alreadyBooked);
    setCopyBookedKitsForRest(alreadyBooked);
  };

  const handleDateCange = date => {
    setDateValue(date);
  };

  const showOnlyBlockedDates = () => {
    let displayDates = [];
    if (bookedKits && bookedKits.length > 0) {
      const filterDates = bookedKits.filter(item => item.isZMCCSlotBooked === false);
      filterDates.forEach(element => {
        let finalDate = new Date(element.start);
        displayDates.push(`${finalDate.getFullYear()}-${finalDate.getMonth()}-${finalDate.getDate()}`);
      });
    }
    return displayDates;
  };
  const closePopup = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
  };
  const resetBookedKits = () => {
    setBookedKits(copyBookedKitsForRest);
  };

  const onClose = () => {
    closePopup();
    if (zmccTransactionDetails) {
      const date = new Date(zmccTransactionDetails.suggestedDate);
      setDateValue(date);
      if (zmccTransactionDetails.eventTime && zmccTransactionDetails.eventEndTime) {
        const modifiedStartTime = moment(zmccTransactionDetails.eventTime, "HH:mm:ss").format("HH:mm");
      const modifiedEndTime = moment(zmccTransactionDetails.eventEndTime, "HH:mm:ss").format("HH:mm")
      const getAmORPMStartTime = moment(zmccTransactionDetails.eventTime, 'HH:mm');
      const getEndTimeAmOrPm = moment(zmccTransactionDetails.eventEndTime, 'HH:mm');
      setStartTime({value:modifiedStartTime, fullValue:{id: modifiedStartTime, value: `${modifiedStartTime} ${getAmORPMStartTime.format('A')}`}});
      setEndTime({value:modifiedEndTime, fullValue:{id: modifiedEndTime, value:`${modifiedEndTime} ${getEndTimeAmOrPm.format('A')}`}});
      }else{
        setStartTime({value: null, fullValue: null});
        setEndTime({value: null, fullValue: null});
      }
    }
    setTimeError('')
    resetBookedKits();
  };

  const onSave = async () => {
    const dataToSend = {
      suggestedDate: moment(dateValue).format('YYYY-MM-DD'),
      eventTime: startTime.value,
      eventEndTime: endTime.value
    };
    if (endTime.value <= startTime.value) {
      setTimeError(strings.endTimeShouldBeLessThanStartTime);
      return;
    }
    setLoading(true);
    const res = await saveAdjustDates(dataToSend, transactionId);
    setLoading(false);
    if (res.success) {
      onClose();
    }
  };

  const timeSlotList = [
    { id: '08:00', value: '8:00 AM' },
    { id: '08:30', value: '8:30 AM' },
    { id: '09:00', value: '9:00 AM' },
    { id: '09:30', value: '9:30 AM' },
    { id: '10:00', value: '10:00 AM' },
    { id: '10:30', value: '10:30 AM' },
    { id: '11:00', value: '11:00 AM' },
    { id: '11:30', value: '11:30 AM' },
    { id: '12:00', value: '12:00 PM' },
    { id: '12:30', value: '12:30 PM' },
    { id: '13:00', value: '1:00 PM' },
    { id: '13:30', value: '1:30 PM' },
    { id: '14:00', value: '2:00 PM' },
    { id: '14:30', value: '2:30 PM' },
    { id: '15:00', value: '3:00 PM' },
    { id: '15:30', value: '3:30 PM' },
    { id: '16:00', value: '4:00 PM' },
    { id: '16:30', value: '4:30 PM' },
    { id: '17:00', value: '5:00 PM' },
    { id: '17:30', value: '5:30 PM' },
    { id: '18:00', value: '6:00 PM' },
    { id: '18:30', value: '6:30 PM' },
    { id: '19:00', value: '7:00 PM' },
    { id: '19:30', value: '7:30 PM' },
    { id: '20:00', value: '8:00 PM' },
  ];

  const onChangeDropdown = (value, field, fullValue) => {
    if (field === 'startTime') {
      setTimeError('');
      return setStartTime({ value, fullValue });
    }
    if (field === 'endTime') {
      setTimeError('');
      return setEndTime({ value, fullValue });
    }
  };
  return {
    value,
    bookedKits,
    handleDateCange,
    dateValue,
    showOnlyBlockedDates,
    onSave,
    onClose,
    loading,
    timeSlotList,
    startTime,
    endTime,
    onChangeDropdown,
    timeError,
  };
};

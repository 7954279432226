import React from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import strings from 'resources/locales/Translate';
import EditButton from 'library/common/commonComponents/Table/EditButton';
import DeleteButton from 'library/common/commonComponents/Table/DeleteButton';

export const breadcrumbsData = ({ transactionType, transactionsType, transactionsTypeString, transactionsId, edit, view }) => {
  const breadcrumbs = [
    {
      name: 'homepage',
      url: '/home',
    },
    {
      name: transactionsTypeString ? transactionsTypeString : 'c2c-loans',
      action: 'goBack',
      url: transactionsType ? `/${transactionsType}` : '/c2c-loans',
    },
  ];
  if (transactionsId && transactionsTypeString !== 'c2cLoans') {
    breadcrumbs.push({
      name: `${strings[transactionType] ? strings[transactionType] : ''} (${transactionsId})`,
      action: 'goBack',
      url: transactionsType ? `/${transactionsType}/${transactionsId}` : `/c2c/${transactionsId}`,
    });
  }
  if (!view) {
    breadcrumbs.push({
      name: edit ? strings.maintainC2CLoan : strings.createC2CLoan,
      url: '',
    });
  }
  return breadcrumbs;
};

export const dataTable = (user, onEditClick, tableData, view) => [
  {
    title: strings.kitLoanId,
    name: 'transactionNumber',
    isSort: false,
    render: item => (
      <NavLink to={`/c2c/${item.transactionsId}`} data-test={'c2c-transaction-details-link'}>
        <span>{item.transactionNumber}</span>
      </NavLink>
    )
  },
  {
    title: strings.shipped,
    name: 'targetDeliveryFrom',
    isSort: false,
    render: item => <span>{item.targetDeliveryFrom ? dayjs(item.targetDeliveryFrom).format('DD.MM.YYYY') : null}</span>,
  },
  {
    title: strings.receiptAtTheCustomer,
    name: 'targetReservationFrom',
    isSort: false,
    render: item => (
      <span>{item.targetReservationFrom ? dayjs(item.targetReservationFrom).format('DD.MM.YYYY') : null}</span>
    ),
  },
  {
    title: strings.checkBeforeReturn,
    name: 'targetReservationTo',
    isSort: false,
    render: item => (
      <span>{item.targetCheckBeforeReturnDate ? dayjs(item.targetCheckBeforeReturnDate).format('DD.MM.YYYY') : (item.targetReturnDeliveryFrom ? dayjs(item.targetReturnDeliveryFrom).subtract(1,'day').format('DD.MM.YYYY') : null)}</span>
    ),
  },
  {
    title: strings.returnDelivery,
    name: 'targetReturnDeliveryFrom',
    isSort: false,
    render: item => (
      <span>
        {item.targetReturnDeliveryFrom
          ? dayjs(item.targetReturnDeliveryFrom).format('DD.MM.YYYY')
          : null}
      </span>
    ),
  },
  {
    title: `${strings.receivedNextCustomer} / ${strings.atStock.toLowerCase()}`,
    name: 'receivedNextCustomer',
    isSort: false,
    render: item => (
      <span>
        {item.targetReturnDeliveryTo
          ? dayjs(item.targetReturnDeliveryTo).format('DD.MM.YYYY') 
          : dayjs(item.targetReconditioningFrom).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: strings.checked,
    name: 'targetReconditioningTo',
    isSort: false,
    render: item => (
      <span>
        {item.allNextC2c && item.allNextC2c.length > 0 
        ? ''
        : item.targetReconditioningTo &&
        dayjs(item.targetReconditioningTo).format('DD.MM.YYYY')}
      </span>
    ),
  },
  {
    title: '',
    name: 'edit',
    render: item => {
      if (user && !view) {
        return (
          <EditButton dataTest='create-c2c-loan-edit-button' text={strings.changeDates} onClick={onEditClick(item)} />
        );
      }
    },
  },
  {
    title: '',
    name: 'remove',
    render: (item, handleDelete) => {
      if (user && (item.oldLoan || item.first || item.last) && !view) {
        const { prevTransaction, nextTransaction } = findPrevNext(tableData, item);
        return (
          <DeleteButton
            dataTest='create-c2c-loan-delete-button'
            itemId={item.transactionsId}
            label='remove'
            showExtraInformation={!item.last}
            confirmMessage={item.first || item.last ? 'deleteC2C' : 'deleteC2CRemove'}
            extraModalInformation={extraModalInformation(prevTransaction, nextTransaction, item.transactionsId, item.first)}
            onConfirm={handleDelete(item, prevTransaction, nextTransaction)}
          />
        );
      }
    },
  },
];

const findPrevNext = (tableData, item) => {
  let prevTransaction = null;
  let nextTransaction = null;
  let selected = false;
  let itemPosition = tableData &&
    tableData.length &&
    tableData.findIndex(current => current.transactionsId === item.transactionsId);
    if (itemPosition == 0){
        prevTransaction =null; 
        nextTransaction = tableData && tableData.length > itemPosition+1 && tableData[itemPosition+1] ? tableData[itemPosition+1] : null;
    }
    else {
      prevTransaction = tableData[itemPosition-1] ? tableData[itemPosition-1] : null; 
      nextTransaction = tableData && tableData.length > itemPosition+1 && tableData[itemPosition+1] ? tableData[itemPosition+1] : null;
    }
  return { nextTransaction, prevTransaction };
};

const extraModalInformation = (prevTransaction, nextTransaction, currentTransactionsId, firstC2cTransaction) => () => {
  if (nextTransaction) {
    return (
      <>
        <div className='extra-message'>
          {firstC2cTransaction ?
            strings.c2cFirstTransactionWillBeChanged(currentTransactionsId, nextTransaction.transactionsId)
            : strings.c2cMiddleTransactionWillBeChanged(nextTransaction.transactionsId)
          }
        </div>
      </>
    );
  }
  return null;
};

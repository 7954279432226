import React, {PureComponent} from 'react';
import {connect} from "react-redux";

import strings from "resources/locales/Translate";
import Icon from '../../commonComponents/Icon/Icon'
import classNames from 'classnames';

import './stylesheet.scss';

export class RadioButton extends PureComponent {

  state = {
    selectedOption: this.props.value,
    isError: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({selectedOption: this.props.value});
    }

    if(this.props.doValidateForm && this.props.doValidateForm !== prevProps.doValidateForm) {
      this.validateValue(this.state.selectedOption);
    }

    if(this.props.resetValidation && this.props.resetValidation !== prevProps.resetValidation) {
      this.setState({isError: false});
    }
  }

  setError = (error) => {
    const {field, onError} = this.props;
    if (error.error) {
      this.setState({isError: true});
      onError(error, field);
    } else {
      this.setState({isError: false});
      onError({error: false, type: null}, field);
    }
  };

  validate = (value) => {
    const {validators} = this.props;

    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }

    return false;
  };

  validateValue = (value) => {
    this.setError(this.validate(value));
  };

  handleSelection(selectedOption) {
    this.setState({selectedOption}, () => {
      this.validateValue(selectedOption);
      this.props.onChange(selectedOption, this.props.field);
    });
  };

  handleClearSelection(){
    this.setState({selectedOption: ""}, () => {
      this.props.onChange("", this.props.field);
    });
  }

  render() {
    const {data, dataTest, label, isRequired, disabled, showClearIcon} = this.props;
    const {selectedOption, isError} = this.state;

    return (
      <div className="app-radio-button" data-test={dataTest}>
        <div className="label-container d-flex justify-content-between align-items-center">
          <span>{label}</span>
          {!isRequired && <span className='optional-text'>{strings.optionalField}</span>}
        </div>
        <div className="btn-group d-flex" role="group" aria-label="Basic example">
          {data.map((item, key) => (
            <button
              disabled={disabled}
              key={key}
              type="button"
              className={classNames(`btn text-left rounded-0 ${selectedOption === item.id ? 'active' : ''} ${isError && 'input-error'}`, {
                'd-flex align-items-center': selectedOption !== item.id,
                'd-flex align-items-center justify-content-between': selectedOption === item.id
              })}
              onClick={() => this.handleSelection(item.id)}
            >
              {strings[item.value]}
              {showClearIcon && <span className={classNames({'d-none': selectedOption !== item.id})} onClick={(e) => {
                e.stopPropagation()
                this.handleClearSelection()
              }}>
              <Icon name='clear' width='15' height='15' viewBox='0 0 48 48'  fill={'#fff'} />
            </span>}
            </button>
          ))}
        </div>
      </div>
    )
  }
}

RadioButton.defaultProps = {
  value: '',
  doValidateForm: false,
  disabled: false,
  showClearIcon: false,
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer
  }
};

export default connect(mapStateToProps)(RadioButton);

import Validators from 'library/utilities/Validators';

export const UPDATE_L_L_KIT_FORM = 'UPDATE_L_L_KIT_FORM';
export const FETCH_STOCKS_FOR_LAB_LOCATION_BU = 'FETCH_STOCKS_FOR_LAB_LOCATION_BU';
export const FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU = 'FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU';
export const FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU = 'FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU';
export const FETCH_LABS_LOCATION_CONDITIONS = 'FETCH_LABS_LOCATION_CONDITIONS';
export const FETCH_ACTIVE_UNTIL_DATE = 'FETCH_ACTIVE_UNTIL_DATE';
export const FETCH_LAB_LOCATION_KIT_DETAILS = 'FETCH_LAB_LOCATION_KIT_DETAILS';
export const RESET_LAB_LOCATION_KIT_FORM = 'RESET_LAB_LOCATION_KIT_FORM';

export const generalInformationModel = [
  {
    label: 'kitName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',

    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-7'
  },
  {
    label: 'kitInformationId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitInformationId',
    validators: [],
    required: true,
    styleClass: 'col-sm-5',
    disabled: true
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12'
  },
];
  
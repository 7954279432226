import React, { useEffect } from 'react';
import { get } from 'lodash';
import * as $ from 'jquery';

import Button from 'library/common/commonComponents/Button/Button';
import { connect } from 'react-redux';
import { getIconByCondition, getIconByStatus } from 'library/utilities/getStatusAndConditionIcons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { createSapErpLoanOrderForKit, returnSapErpLoanOrderForKit } from '../KitDetailsAction';

import { NavLink } from 'react-router-dom';
import CreateLoanOrderDetailsPopup from './CreateLoanOrderDetailsPopUp.component';

const Information = ({
  kitDetail,
  history,
  isEditable,
  user,
  toggleActionMessage,
  createSapErpLoanOrderForKit,
  returnSapErpLoanOrderForKit,
  workFlowQuestions,
  activeTransactionLoanOrderData,
}) => {
  const transactionLoanOrder = activeTransactionLoanOrderData && activeTransactionLoanOrderData;
  const showTransactionLoanOder = transactionLoanOrder && transactionLoanOrder !== undefined && transactionLoanOrder.transactionsId !== 0 && (transactionLoanOrder.sapErp !== "" && transactionLoanOrder.sapeErp !== null);
  const istransactionLoanOderisCompleted = transactionLoanOrder && transactionLoanOrder !== undefined && transactionLoanOrder.transactionsId !== 0 && (transactionLoanOrder.sapErp !== "" && transactionLoanOrder.sapeErp !== null) && (transactionLoanOrder.sapReturnLoanNumber !== null && transactionLoanOrder.sapLoanOrderStatus === "RETURNED");
  const currentPosition = get(kitDetail, 'transactionPosition.transactionPositionTranslated[0].positionText');
  const businessUnit = get(kitDetail, 'businessUnit.name');
  const conditionString = get(
    kitDetail,
    'kitCondition.kitConditionTranslations[0].translatedCondition',
    '',
  ).toLowerCase();
  const organizationName = get(kitDetail, 'organizationName');
  const showedOrganizationName = organizationName ? `[${organizationName}]` : '';
  const division = get(kitDetail, 'divisions.divisionsTranslated[0].name');
  const kitReparation =
    kitDetail.kitReparation &&
    kitDetail.kitReparation.length &&
    kitDetail.kitReparation.find(item => item.isReparationTimeActive);

  const isHavingRole = (roles, stockId) => {
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
      });
    }
    return false;
  };
  const RolesKitLevelLoanOrder = ['Stock Controller', 'Inbound staff', 'Outbound staff', 'Dispatcher'];
  const isAdminOrHasLoanOrderAccess =
    (user && user.admin) ||
    isHavingRole(RolesKitLevelLoanOrder, kitDetail && kitDetail.stockInformation && kitDetail.stockInformation.id);

  const hasERPActiavted = () => {
    const workFlowCategoryData =
      workFlowQuestions &&
      workFlowQuestions.stockWorkflowDTOS &&
      workFlowQuestions.stockWorkflowDTOS[4] &&
      workFlowQuestions.stockWorkflowDTOS[4]['stockWorkflowSettingDTO'];
    const isWorkflowErp = workFlowCategoryData && workFlowCategoryData.find(item => item.categoryQuestionId === 21);
    const isWorkflowExternal =
      workFlowCategoryData && workFlowCategoryData.find(item => item.categoryQuestionId === 26);
    const finalValue =
      isWorkflowErp != null && isWorkflowExternal != null
        ? isWorkflowErp.categoryAnswer === 'yes' && isWorkflowExternal.categoryAnswer === 'yes'
        : false;
    return finalValue;
  };

  const validateLoanOrder = loanOrderNumber => {
    if (
      loanOrderNumber &&
      loanOrderNumber.length == 10 &&
      loanOrderNumber.startsWith('1') &&
      /^\d+$/.test(loanOrderNumber)
    ) {
      return true;
    }
    return false;
  };

  const hasLoanOrderValue = kitDetail && !!kitDetail.sapERP && kitDetail.loanOrderAddedAs !== "FROM_TRANSACTION_MANUALLY";
  const isloanOrderNumberValid = kitDetail && validateLoanOrder(kitDetail.sapERP);

  const showCreateLoanOrderButton =
    kitDetail &&
    kitDetail.stockInformation &&
    hasERPActiavted(kitDetail) &&
    isAdminOrHasLoanOrderAccess &&
    (!hasLoanOrderValue || kitDetail.sapLoanOrderStatus === 'RETURNED');
  const showReturnLoanOrderButton =
    kitDetail &&
    kitDetail.stockInformation &&
    hasERPActiavted(kitDetail) &&
    ((isloanOrderNumberValid && kitDetail.sapLoanOrderStatus === null) || kitDetail.sapLoanOrderStatus === 'CREATED');
  const hidingCreateLoanOrder =
    (kitDetail && kitDetail.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE') ||
    kitDetail.sapLoanOrderStatus === 'CREATED';

  const createLoanOrder = () => {
    const divisionsMissing = kitDetail && !!kitDetail.divisions;
    const itemNumberMissing = kitDetail && !!kitDetail.itemNumber;
    if (!divisionsMissing || !itemNumberMissing) {
      toggleActionMessage(true, 'error', 'divisionOrItemNumberMissing');
      scrollToTop(500);
      return;
    }
    const kitId = kitDetail && kitDetail.kitInformationId;
    createSapErpLoanOrderForKit(kitId);
  };

  const returnLoanOrder = () => {
    const divisionsMissing = kitDetail && !!kitDetail.divisions;
    const itemNumberMissing = kitDetail && !!kitDetail.itemNumber;
    if (!divisionsMissing || !itemNumberMissing) {
      toggleActionMessage(true, 'error', 'returnLoanOrderDivisonMissing');
      scrollToTop(500);
      return;
    }
    const kitId = kitDetail && kitDetail.kitInformationId;
    returnSapErpLoanOrderForKit(kitId);
  };

  const onResendRequest = () => {
    $('#load-order-modal').modal('show');
  };
  const showDisvisionAndItemNumber =  workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[4] && workFlowQuestions.stockWorkflowDTOS[4]['stockWorkflowSettingDTO'][0].categoryAnswer === 'yes';
  const isRefusmentActivated = workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[1] && workFlowQuestions.stockWorkflowDTOS[1]['stockWorkflowSettingDTO'][8].categoryAnswer === 'yes'
  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='row mb-3'>
          <div className='col-12 col-md-6'>
            <div className='section-title'>{strings.information}</div>

            <div className='subtitle'>{`${strings.packageDimensions}`}</div>
            <div className='description-container mb-3'>
              {kitDetail.packageDimensions || 'No package dimensions available'}
            </div>

            <div className='subtitle'>{strings.mainComponent}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.systemMainComponent}:`}</div>
              <div className='col-6'>
                {(kitDetail.systemMainComponent && kitDetail.systemMainComponent.systemMainComponentName) || '-'}
              </div>
              <div className='col-6'>{`${strings.serialNumber}:`}</div>
              <div className='col-6'>{kitDetail.serialNumber || '-'}</div>
              <div className='col-6'>{`${strings.yearOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-'}
              </div>
              <div className='col-6'>{`${strings.monthOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-'}
              </div>
              <div className='col-6'>{`${strings.countryOfOrigin}:`}</div>
              <div className='col-6'>{(kitDetail.country && kitDetail.country.name) || '-'}</div>
              <div className='col-6'>{`${strings.commodityCode}:`}</div>
              <div className='col-6'>{kitDetail.commodityCode || '-'}</div>
              <div className='col-6'>{`${strings.ibaseText}:`}</div>
              <div className='col-6'>{kitDetail.ibase || '-'}</div>
              <div className='col-6'>{`${strings.marketingMaterialText}:`}</div>
              <div className='col-6'>
                <Checkbox
                  dataTest='marketingMaterial-checkbox-test'
                  selected={kitDetail.marketingMaterial}
                  disabled={true}
                />
              </div>
              <div className='col-6'>{`${strings.supportingAsset}:`}</div>
              <div className='col-6 mt-1'>
                <Checkbox
                  dataTest='marketingMaterial-checkbox-test'
                  selected={kitDetail.supportingAsset}
                  disabled={true}
                />
              </div>
              {showDisvisionAndItemNumber && <div className='col-6'>{`${strings.division}:`}</div>}
              {showDisvisionAndItemNumber && <div className='col-6'>{division || '-'}</div>}
              {showDisvisionAndItemNumber && <div className='col-6'>{`${strings.itemNumber}:`}</div>}
              {showDisvisionAndItemNumber && <div className='col-6'>{kitDetail.itemNumber || '-'}</div>}
              {isRefusmentActivated &&<div className='col-6'>{`${strings.materialNumberOfTheStand}:`}</div>}
              {isRefusmentActivated &&<div className='col-6'>{kitDetail.materialNumberOfStand || '-'}</div>}
              <div className='col-6'>{`${strings.dateCreated}:`}</div>
              <div className='col-6'>
                {kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by+ " "+ kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName  : ""}` : '-'}
              </div>
              <div className='col-6'>{`${strings.dateModified}:`}</div>
              <div className='col-6'>
                {kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName  : ""} ` : '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.defaultPeriods}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.delivery}:`}</div>
              <div className='col-6'>
                {kitDetail.deliveryDays === 0 ? kitDetail.deliveryDays : kitDetail.deliveryDays || '-'}
              </div>
              <div className='col-6'>{`${strings.returnDelivery}:`}</div>
              <div className='col-6'>
                {kitDetail.returnDeliveryDays === 0
                  ? kitDetail.returnDeliveryDays
                  : kitDetail.returnDeliveryDays || '-'}
              </div>
              <div className='col-6'>{`${strings.reconditioning}:`}</div>
              <div className='col-6'>
                {kitDetail.reconditioningDeliveryDays === 0
                  ? kitDetail.reconditioningDeliveryDays
                  : kitDetail.reconditioningDeliveryDays || '-'}
              </div>
              <div className='col-6'>{`${strings.maxLoanDuration}:`}</div>
              <div className='col-6'>
                {kitDetail.maxLoanDuration === 0 ? kitDetail.maxLoanDuration : kitDetail.maxLoanDuration || '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.availability}</div>
            <div className='row mb-3'>
              {kitDetail.availabilityFrom && kitDetail.availabilityTo && (
                <div className='col-6'>{`${strings.availabilityPeriod}:`}</div>
              )}
              {!kitDetail.availabilityTo && <div className='col-6'>{`${strings.availableFrom}:`}</div>}
              <div className='col-6'>
                {kitDetail.availabilityFrom
                  ? getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom, kitDetail.availabilityTo)
                  : '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.assignment}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.countryLand}:`}</div>
              <div className='col-6'>
                {(kitDetail.stockInformation && kitDetail.stockInformation.country.name) || '-'}
              </div>
              <div className='col-6'>{strings.location}:</div>
              <div className='col-6'>
                {(kitDetail.stockInformation && kitDetail.stockInformation.locationName) || '-'}
              </div>
              <div className='col-6'>{`${strings.storageLocation}:`}</div>
              <div className='col-6'>{kitDetail.storageLocation || '-'}</div>
              <div className='col-6'>{strings.firstSalesOrder}:</div>
              <div className='col-6'>{kitDetail.firstSalesOrder || '-'}</div>
              <div className='col-6 mt-2'>{strings.kitLoanOrderTextUpdated}:</div>
              {showTransactionLoanOder && (
                <div className='col-3 mt-2'>
                  {
                    (transactionLoanOrder.sapLoanOrderStatus === 'CREATED' || transactionLoanOrder.sapLoanOrderStatus === 'WAITING_FOR_ZLA') ? 'ZLEI: ' : ((transactionLoanOrder.sapLoanOrderStatus === 'RETURNED') ? 'ZLA:' : 'ZLEI:')
                  }
                  {`${(kitDetail.sapERP || '')} ${kitDetail.sapERP && "("+strings.transactionId+":"}`}
                  <NavLink to={`/transactions/${transactionLoanOrder.transactionsId}`}>
                    {` ${kitDetail.sapERP && transactionLoanOrder.transactionsId || ''}`}
                  </NavLink>
                  {kitDetail.sapERP && ")"}
                </div>
              )}
              {!showTransactionLoanOder && <div className='col-2 mt-2'>{kitDetail.sapERP || '-'}</div>}
              {showCreateLoanOrderButton && !hidingCreateLoanOrder && istransactionLoanOderisCompleted && (
                <Button
                  styleClass='col-3 mt-2'
                  bgFill={false}
                  value={strings.createLoanOrder}
                  iconName='save'
                  onClick={() => createLoanOrder()}
                />
              )}
              {kitDetail && kitDetail.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' && (
                <div>
                  <p className='mt-2'>{strings.waitingForLoanOrder}</p>
                  <Button
                     styleClass='mt-2'
                    bgFill={false}
                    value={strings.viewDetails}
                    iconName='save'
                    onClick={onResendRequest}
                  />
                  <CreateLoanOrderDetailsPopup id='load-order-modal' workFlowQuestions={workFlowQuestions} createSapErpLoanOrderForKit={createSapErpLoanOrderForKit} kitDetail={kitDetail} />
                </div>
              )}
              {kitDetail && kitDetail.sapLoanOrderStatus === 'WAITING_FOR_ZLA' && <p>WAITING_FOR_ZLA</p>}
              {showReturnLoanOrderButton  && !showTransactionLoanOder && (
                <Button
                  styleClass='col-4'
                  bgFill={false}
                  value={strings.returnLoanOrder}
                  iconName='save'
                  onClick={() => returnLoanOrder()}
                />
              )}
              <div className='col-6'>{strings.walkOffSalesOrder}:</div>
              <div className='col-6'>{kitDetail.walkOffSalesOrder || '-'}</div>
              <div className='col-6'>{strings.businessUnit}:</div>
              <div className='col-6'>{businessUnit}</div>
              <div className='col-6'>{`${strings.categorySystem}:`}</div>
              <div className='col-6'>{(kitDetail.systemClass && kitDetail.systemClass.systemClassName) || '-'}</div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='section-title'>Kit-Status</div>
            <div className='subtitle'>{strings.status}</div>
            <div className='d-flex mb-3 flex-wrap'>
              {kitDetail.kitStatuses && kitDetail.kitStatuses.length ? (
                <>
                  {kitDetail.kitStatuses.map(item => {
                    if (item.kitStatusesTranslated && item.kitStatusId !== 15) {
                      return (
                        <div key={item.kitStatusId} className='mr-4 mb-2'>
                          {getIconByStatus(item, true, 24)}
                        </div>
                      );
                    }

                    return null;
                  })}
                </>
              ) : null}
              {conditionString ? (
                <>
                  <div key={kitDetail && kitDetail.kitCondition && kitDetail.kitCondition.kitConditionId} className='mr-4 mb-2 '>
                    {kitDetail && kitDetail.kitCondition && kitDetail.kitCondition.kitConditionId === 5 && kitReparation && kitReparation.reparationComment && (
                      <Icon
                        name='info'
                        width={18}
                        height={18}
                        fill='#9a9b9c'
                        className='cursor-pointer'
                        data-tooltip={kitReparation && kitReparation.reparationComment || ''}
                        data-tooltip-config={JSON.stringify({ direction: 'bottom' })}
                      />
                    )}
                    {getIconByCondition(kitDetail.kitCondition, true, 24)}
                  </div>
                </>
              ) : null}
            </div>
            <div className='subtitle'>Dispatch Status</div>
            <div className='mb-3'>-</div>
            <div className='subtitle'>{strings.currentPosition}</div>
            <div className='mb-3'>
              {currentPosition ? currentPosition : '-'} {showedOrganizationName}
            </div>
            <div className='comment-title'>{strings.commentText}</div>
            <div className='description-container word-wrap mb-3'>
              {kitDetail.comment || strings.noCommentAvailable}
            </div>
          </div>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-kit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, kitDetailsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  activeTransactionLoanOrderData: kitDetailsReducer.activeTransactionLoanOrderData,
  workFlowQuestions: kitDetailsReducer.workFlowQuestions
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  createSapErpLoanOrderForKit,
  returnSapErpLoanOrderForKit,
})(Information);

import { useEffect, useState, useCallback } from 'react';
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';
import { addressFormModel, stateDropdown } from '../Information.constants';

export const useEditStockAddres = ({ id, address, editAddress, stockId, isRMSZMCCStock , allProvinces, isLLStock}) => {
  // commented this because of new check box for zmcc integration
  // const [model, setModel] = useState(addressFormModel);
  const [editAddressForm, setEditAddressForm] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const addressModel = () => {
    const model = [...addressFormModel];
    if (allProvinces && !isRMSZMCCStock && !isLLStock){
      model[9] = stateDropdown;
      model[9].options = allProvinces;

      if (address.stateName && !allProvinces.some(option => option.stateName === address.stateName)) {
        model[9].options.push({ stateName: address.stateName });
      }
    }
    if(isRMSZMCCStock){
      model[14].disabled = false;
    } else{
      model[14].disabled = true;
    }
    return model;
  };

  const selectedData = () => {
    setEditAddressForm(prevState => ({
      formData: {
        ...prevState,
        addressType: address.addressType,
        salutation: address.salutation ? address.salutation : '',
        firstName: address.firstName,
        lastName: address.lastName,
        organization: address.organization,
        department: address.department ? address.department : '',
        street: address.street,
        number: address.houseNumber ? address.houseNumber : '',
        postalCode: address.postalCode,
        stateName: (address.state && allProvinces) ? {stateId: allProvinces.find(s => s.stateId ===  address.state).stateId , stateName:allProvinces.find(s => s.stateId ===  address.state).stateName } : address.stateName,
        stateNameFullValue: (address.state && allProvinces) && {stateId: allProvinces.find(s => s.stateId ===  address.state).stateId , stateName:allProvinces.find(s => s.stateId ===  address.state).stateName },
        city: address.city,
        phone: address.phone,
        email: address.email,
        activeAddress: address.activeAddress,
        activeShippingAddressForZmcc: address.activeShippingAddressForZmcc ? address.activeShippingAddressForZmcc : null
      },
    }));
  };
  useEffect(() => {
    if (address !== {}) {
      selectedData();
    }
  }, [address, allProvinces]);

  const handleForm = ({ values, field }) => {
    setEditAddressForm({
      ...editAddressForm,
      formData: {
        ...editAddressForm.formData,
        ...values,
      },
    });
  };
  const onSaveClick = async () => {
    let model = addressModel();
    const validForm = validateForm({
      form: editAddressForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setEditAddressForm(validForm);
    }
    const {
      addressType,
      salutation,
      firstName,
      lastName,
      organization,
      department,
      street,
      number,
      postalCode,
      stateName,
      city,
      phone,
      email,
      activeAddress,
      activeShippingAddressForZmcc
    } = validForm.formData;
    const dataToSend = {
      addressType: addressType,
      salutation: salutation ? salutation : '',
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      department: department ? department : '',
      street: street,
      houseNumber: number ? number : '',
      postalCode: postalCode,
      stateName: stateName,
      city: city,
      phone: phone,
      email: email,
      activeAddress: activeAddress,
      activeShippingAddressForZmcc: activeShippingAddressForZmcc
    };
    setLoading(true);
    const { success, err } = await editAddress(dataToSend, address.addressId, stockId);
    if (!success) {
      if (err && err.response && err.response.data.status === 422) {
        setError(err.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
      setLoading(false);
    } else {
      setLoading(false);
      onCloseClick();
    }
  };

  const onCloseClick = () => {
    selectedData();
    setError('');
    closePopup();
  };
  const closePopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);
  return {
    addressModel,
    handleForm,
    editAddressForm,
    onCloseClick,
    onSaveClick,
    error,
    loading,
  };
};

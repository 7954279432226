import React from 'react';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

export const ZmccAttachements = ({formValues,updateSEMForm, updateZmccTransaction, formType, showAllButton}) =>{
  const onSaveClick = () =>{
    updateZmccTransaction(formType);
  }
  return(
    <>
      <Attachments
    text='Attach File'
    id='kitAttachmentsId'
    formValue={formValues}
    hasVideo={true}
    handleForm={updateSEMForm}
    actionFrom='zmccAttachement'
    isDownloadable
    downloadStream
    downloadIdField='kitAttachmentsId'
    isUploadAllowed={false}
  />
    <div className='col-sm-2 mt-4'>
        <Button
          value={strings.save}
          onClick={onSaveClick}
          bgFill={false}
          iconName='save'
          disabled={!showAllButton}
        />
      </div>
    </>
    

  
  ) 
}

export default ZmccAttachements;
import React, { Component } from 'react';
import cn from 'classnames';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import DateInput from 'library/common/commonComponents/DateInput/DateInput';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { getAlltransactionStatus } from 'library/common/commonConstants/roles';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.dateRange = null;
    this.state = {
      dateObj: null,
      dateTypes: props.dateTypes,
    };
  }

  componentDidMount() {
    if (!this.state.dateObj && this.props.selected && this.props.selected.dateRange) {
      this.dateRange.setState({ date: this.props.selected.dateRange });
      this.setState({ dateObj: this.props.selected.dateRange });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      const dateTypes = [
        {
          id: 1,
          name: this.props.language !== 'de' ? 'Delivery date' : 'Liefertermin',
        },
        {
          id: 2,
          name: this.props.language !== 'de' ? 'Begin of Loan' : 'Beginn der Ausleihe',
        },
        {
          id: 3,
          name: this.props.language !== 'de' ? 'End of loan' : 'Ende der Ausleihe',
        },
      ];
      this.setState({dateTypes})
    }
  }

  handleFilter = key => (value, field, fullValue) => {
    if (key === 'dateType') {
      if (value !== this.props.dateType) {
        this.dateRange.setState({ date: '' });
        this.setState({ dateObj: null });
        this.props.handleFilterChange(key, value, fullValue);
      }
    } else {
      this.props.handleFilterChange(key, value, fullValue);
    }
  };

  handleChange = dateRange => {
    let newDateRange = null;
    if (dateRange) {
      newDateRange = [...dateRange];
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      newDateRange[0] = dateRangeStart;
      newDateRange[1] = dateRangeEnd;
    }
    this.setState({ dateObj: dateRange });
    this.props.handleFilterChange('dateRange', newDateRange);
  };

  getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    if (selectedValues && selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }
    return displayPlaceholder;
  };

  resetFilter = key => () => {
    if (key === 'dateRange') {
      this.dateRange.setState({ date: '' });
      this.setState({ dateObj: null });
    }
    this.props.handleFilterChange(key, null, null);
  };

  resetFilters = () => {
    if (this.dateRange) {
      this.dateRange.setState({ date: '' });
      this.setState({ dateObj: null });
    }
    this.props.resetFilters();
  };

  renderFilters = () => {
    const newFilters = [];
    const filters = {
      loanType: { displayValue: [{ key: 'name', separator: '' }], multiple: false },
      stock: { displayValue: [{ key: 'locationName', separator: '' }], multiple: true },
      systemClass: {
        displayValue: [{ key: 'systemMainComponentName', separator: '', optionsArrayKey: 'systemMainComponent' }],
        multiple: true,
      },
      currentPosition: { displayValue: [{ key: 'positionText', separator: '' }], multiple: true },
      transactionStatuses: { displayValue: [{ key: 'name', separator: '' }], multiple: true },
      condition: { displayValue: [{ key: 'translatedStatus', separator: '' }], multiple: false },
      dateType: { displayValue: [{ key: 'name', separator: '' }], multiple: false },
    };
    Object.keys(filters).forEach(key => {
      if (
        this.props.selected[key] &&
        (typeof this.props.selected[key] !== 'object' || this.props.selected[key].length) &&
        this.props.selected[`${key}FullValue`]
      ) {
        newFilters.push(
          <div className='filter-chips-container mt-2'>
            {this.getPlaceholder(
              this.props.selected[`${key}FullValue`],
              filters[key].displayValue,
              filters[key].multiple,
            )}
            <span onClick={this.resetFilter(key)}>&times;</span>
          </div>,
        );
      }
    });
    if (this.props.selected && this.props.selected.dateRange) {
      newFilters.push(
        <div className='filter-chips-container mt-2'>
          {moment(this.props.selected.dateRange[0]).format('DD.MM.YYYY') +
          ' - ' +
          moment(this.props.selected.dateRange[1]).format('DD.MM.YYYY')}
          <span onClick={this.resetFilter('dateRange')}>&times;</span>
        </div>,
      );
    }
    return newFilters;
  };

  render() {
    const {
      selected,
      isShowFilter,
      countries,
      categoriesAndSystems,
      conditions,
      loanTypes,
      transactionPositions,
      transactionStatuses,
    } = this.props;
    const { dateObj, dateTypes } = this.state;
    const filters = this.renderFilters();
    const transactionStatus = getAlltransactionStatus()
    return (
      <>
        <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
          <div className='container-fluid'>
            <div className='row'>
              <Dropdown
                data={loanTypes}
                hasSection={false}
                multiSelect={false}
                filter={false}
                clearOption={true}
                idKey='id'
                displayValue={[{ key: 'name', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                disabled={false}
                label={strings.loanProcess}
                field='loanType'
                onChange={this.handleFilter('loanType')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.loanType}
                fullValue={selected.loanTypeFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={countries}
                hasSection={true}
                multiSelect={true}
                filter={true}
                clearMultiOption={true}
                idKey='id'
                displayValue={[{ key: 'locationName', separator: '' }]}
                optionsArrayKey='stockInformation'
                titleDisplay={[
                  { key: 'shortName', separator: ' ' },
                  { key: 'name', separator: '' },
                ]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.location}
                field='stock'
                onChange={this.handleFilter('stock')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.stock}
                fullValue={selected.stockFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={categoriesAndSystems}
                hasSection={true}
                multiSelect={true}
                filter={true}
                clearMultiOption={true}
                idKey='systemMainComponentId'
                childArrayKey='systemMainComponent'
                displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                optionsArrayKey='systemMainComponent'
                titleDisplay={[{ key: 'systemClassName', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.categorySystem}
                field='systemClass'
                onChange={this.handleFilter('systemClass')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.systemClass}
                fullValue={selected.systemClassFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={transactionPositions}
                hasSection={false}
                multiSelect={true}
                filter={false}
                clearMultiOption={true}
                idKey='transactionPositionId'
                displayValue={[{ key: 'positionText', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.currentPosition}
                field='currentPosition'
                onChange={this.handleFilter('currentPosition')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.currentPosition}
                fullValue={selected.currentPositionFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={transactionStatus}
                hasSection={false}
                multiSelect={true}
                filter={false}
                clearMultiOption={true}
                idKey='transactionStatusId'
                displayValue={[{ key: 'name', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.transactionStatus}
                field='transactionStatuses'
                onChange={this.handleFilter('transactionStatuses')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.transactionStatuses}
                fullValue={selected.transactionStatusesFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={conditions}
                hasSection={false}
                multiSelect={false}
                filter={false}
                clearOption={true}
                idKey='kitStatusId'
                displayValue={[{ key: 'translatedStatus', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.conditionText}
                field='condition'
                onChange={this.handleFilter('condition')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.condition}
                fullValue={selected.conditionFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <Dropdown
                data={dateTypes}
                hasSection={false}
                multiSelect={false}
                filter={false}
                clearOption={true}
                idKey='id'
                displayValue={[{ key: 'name', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.dateType}
                field='dateType'
                onChange={this.handleFilter('dateType')}
                mainContainerStyle='col-lg-3 mb-3'
                value={selected.dateType}
                fullValue={selected.dateTypeFullValue}
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
              />
              <DateInput
                ref={ref => {
                  this.dateRange = ref;
                }}
                disabled={!selected.dateType}
                label={strings.dateRange}
                placeholder={strings.pleaseSelectDate + '...'}
                onChangeDate={this.handleChange}
                selectedDate={dateObj}
                isRequired={true}
                mainContainerStyle='col-lg-3 mb-3'
                isRange={true}
              />
            </div>
          </div>
        </div>
        {filters && !!filters.length && (
          <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center flex-wrap'>
            <div className='mr-2 mt-2'>
              <strong>Filters:</strong>
            </div>
            {filters}
            <div className='filter-chips-delete-all mt-2' onClick={this.resetFilters}>
              <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Filter;

export const freeAccessoriesModel = [
  {
    label: 'freeAccessoriesLabel',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'freeAccessories',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      className: 'form-control rounded-0 position-relative components-textarea'
    }
  }
];

import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useZmccTodoList } from './ZmccTodoList.hook';
import moment from 'moment';

export const ZmccTodoList = ({ formValues, updateSEMForm, user, zmccInformationTabData, updateZmccTransaction, formType,isClickedOnAdInTodoTab, showAllButton }) => {
  const {
    handleInputChange,
    inputText,
    removeATodoFromList,
    addIntoTodosList,
    onChangeCheckbox,
    isCheckboxSelected,
    onSaveClick,
    todosList,
    errorMsg
  } = useZmccTodoList({ formValues, updateSEMForm, user, zmccInformationTabData, updateZmccTransaction, formType, isClickedOnAdInTodoTab });
  return (
    <>
      <h1 className='mt-4 title-heading'>{strings.toDoList}</h1>
      <div className='p-0 mt-3'>
        {formValues.todosListForm.formData.zmccTodosListDataList &&
          formValues.todosListForm.formData.zmccTodosListDataList.map((item, index) => (
            <>
              <div className={`row m-0 ${item.checkedAt ? '':' mb-4'}`} key={index}>
                <Checkbox
                  label=''
                  field='isEditedBy'
                  isRequired={false}
                  selected={item.isChecked}
                  onChange={i => onChangeCheckbox(i, item, index)}
                  //   disabled={!editing}
                />
                <div className='col-sm-5 border-for-todo-list'>{item.zmccTodosText}</div>
                <div className='col-sm-1 border-for-todo-list'>
                  <button
                    type='button'
                    className='delete-button delete-icon-name'
                    onClick={() => removeATodoFromList(index)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' className='mr-2' />
                    {strings.delete}
                  </button>
                </div>
              </div>
              {item.checkedAt &&<div className='mb-4  ml-5 mt-1'>{`${strings.editedBy}: ${item.checkedUser ? item.checkedUser.firstName: '' } ${item.checkedUser ? item.checkedUser.lastName: ''}  ${moment.utc(item.checkedAt).format('YYYY-MM-DD')}`}</div>}
            </>
          ))}
      </div>

      <div className='row mt-4'>
        <div className='col-sm-4 mt-2'>
          <InputField
            label={''}
            field='todoText'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={inputText}
            error={errorMsg}
            onChange={handleInputChange}
            // className={'mb-3'}
          />
        </div>
        <div className='col-sm-2 mt-4'>
          <Button
            value={strings.add}
            onClick={() => addIntoTodosList(inputText)}
            bgFill={false}
            iconName='plus'
            disabled={
              formValues.todosListForm.formData.zmccTodosListDataList &&
              formValues.todosListForm.formData.zmccTodosListDataList.length === 20
            }
          />
        </div>
      </div>

      <div className='col-sm-2 mt-4'>
        <Button value={strings.save} onClick={onSaveClick} bgFill={false} iconName='save' disabled={!showAllButton} />
      </div>
    </>
  );
};

export default ZmccTodoList;

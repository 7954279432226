import Validators from 'library/utilities/Validators';

export const exportTransactionListModel = ({ countries, loanTypes, categoriesAndSystems, transactionType }) => {
  const model = [
    {
      label: 'stockMaxThree',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'stock',
      validators: [
        { check: Validators.required, message: 'requiredErrorMessage' },
        { check: Validators.maxLength, message: 'maxLengthErrorMessage', strLength: 20 }
      ],
      required: true,
      styleClass: 'col-12',
      options: countries,
      hasSection: true,
      multiSelect: true,
      filter: true,
      selectAllOption: false,
      idKey: 'id',
      displayValue: [{ key: 'locationName', separator: '' }],
      optionsArrayKey: 'stockInformation',
      titleDisplay: [
        { key: 'shortName', separator: ' ' },
        { key: 'name', separator: '' },
      ],
    },
    {
      label: 'loanProcess',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'loanType',
      validators: [],
      required: true,
      styleClass: 'col-12',
      options: loanTypes,
      hasSection: false,
      multiSelect: false,
      filter: false,
      selectAllOption: true,
      selectAllPlaceholder: 'selectAll',
      idKey: 'id',
      displayValue: [{ key: 'name', separator: '' }],
    },
    {
      label: 'categorySystem',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'systemClass',
      validators: [],
      required: false,
      styleClass: 'col-12',
      options: categoriesAndSystems,
      hasSection: true,
      multiSelect: true,
      filter: true,
      selectAllOption: true,
      idKey: 'systemMainComponentId',
      displayValue: [{ key: 'systemMainComponentName', separator: '' }],
      optionsArrayKey: 'systemMainComponent',
      titleDisplay: [{ key: 'systemClassName', separator: '' }]
    },
  ];

  if (transactionType === 'archiveProcesses') {
    const loanDateModel = [
      {
        label: 'start',
        value: '',
        type: 'date',
        placeholder: 'pleaseSelect',
        field: 'loanStartDate',
        validators: [
          { check: Validators.required, message: 'requiredErrorMessage' },
        ],
        required: true,
        styleClass: 'col-12 col-sm-6',
        dateFormat: 'DD.MM.YYYY',
        validateOnChange: true,
      },
      {
        label: 'end',
        value: '',
        type: 'date',
        placeholder: 'pleaseSelect',
        field: 'loanEndDate',
        validators: [
          { check: Validators.required, message: 'requiredErrorMessage' },
        ],
        required: true,
        styleClass: 'col-12 col-sm-6',
        dateFormat: 'DD.MM.YYYY',
        activeStartDate: null,
        validateOnChange: true,
      },
    ];
    return model.concat(loanDateModel);
  } else {
    return model;
  }
};

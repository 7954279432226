import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';

import strings from 'resources/locales/Translate';

const Accessories = ({ kitDetail, history, isEditable }) => {
  const editingKitURL = kitDetail.isLabsLocationPartner ? `/edit-llkit/${kitDetail.kitInformationId}` : kitDetail.isZMCCKit ? `/edit-zmcc-kit/${kitDetail.kitInformationId}` :`/edit-kit/${kitDetail.kitInformationId}`;

  return (
    <div className='components-container'>
      <div className='container-fluid'>
        {
          kitDetail.freeAccessories ?
            <div className='row mb-4'>
              <div className='col-12 col-lg-6'>
                <div className='section-title'>{strings.freeAccessoriesLabel}</div>
                <div className='description-container word-wrap'>{kitDetail.freeAccessories}</div>
              </div>
            </div> :
            <div className='dummy-data'>
              <span>{strings.noFreeAccessoriesMessage}</span>
            </div>
        }

        {isEditable &&
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() => history.push(
              {
                pathname: editingKitURL,
                state: { edit: true, tab: 'accessories' }
              })
            }
          />
        }
      </div>
    </div>
  );
};

export default Accessories;

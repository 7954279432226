import React, { PureComponent } from 'react';

import Uploader from '../Uploader/Uploader';
import SingleFileGridView from './SingleFileGridView';
import './fileListStyles.scss';

export default class FilesGridView extends PureComponent {

  handleRemove(index) {
    this.props.onRemove(index);
  }

  render() {
    const {
      onFileAdd,
      viewType,
      mimeType,
      maxSize,
      files,
      isUploadAllowed,
      isDownloadable,
      downloadUrl,
      downloadIdField,
      onReject,
      maxFiles,
      onMaxFileReached,
      downloadStream,
      disableOnlyUpload,
      availableTypes,
      errorMessage,
      isNotRemoved,
      isUploader = true,
    } = this.props;

    return (
      <div className="file-list-container">
        <div className="row">

          {files.length > 0 && files.map((file, key) => (
            <div className="col-sm-3 mb-4" key={key}>
              <SingleFileGridView
                file={file}
                key={key}
                index={key}
                downloadUrl={downloadUrl}
                isDownloadable={isDownloadable}
                downloadIdField={downloadIdField}
                isUploadAllowed={isUploadAllowed}
                downloadStream={downloadStream}
                onRemove={(index) => this.handleRemove(index)}
                isNotRemoved={isNotRemoved}
              />
            </div>
          ))}

          {isUploadAllowed && disableOnlyUpload && isUploader ? (
            <div className="col-sm-3">
              <Uploader
                allFiles={files}
                mimeType={mimeType}
                maxSize={maxSize}
                maxFiles={maxFiles}
                onReject={onReject}
                onFileAdd={onFileAdd}
                onMaxFileReached={onMaxFileReached}
                viewType={viewType}
                availableTypes={availableTypes}
                errorMessage={errorMessage}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

import React, { useEffect } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';

export const NoStockAccessPopup = ({ chnageTestValue, history, user }) => {
  const firstName = user && user.firstName;
  const lastName = user && user.lastName;
  const isLabLocationPartner = user && user.labLocationPartner;
  useEffect(() => {
    $('#confirm-partner').modal('show');
  }, []);

  const onClickOk = () => {
    $(`#${'confirm-partner'}`).modal('show');  
    history.push('/stocks');
    window.location.reload();      
  };

  const onCloseClick = () => {
    chnageTestValue();
    history.push('/');
    window.location.reload();
    $(`#${'confirm-partner'}`).modal('hide');
  };

  const onClickOkButton = () => {
    chnageTestValue();
    history.push('/');
    window.location.reload();
    $(`#${'confirm-partner'}`).modal('hide');
  };

  const navigateToDocuments = () => {
    $(`#${'confirm-partner'}`).modal('hide');
    window.location.reload();
    history.push('documents/');
  }
  return (
    <div
      className='modal fade modal-container'
      id={'confirm-partner'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'confirm-partner'}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog stock-popup' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
              <button
                type='button'
                className='close modal-close position-absolute'
                data-dismiss='modal'
                aria-label='Close'
                onClick={onCloseClick}
              >
                <Icon name='cross' width={24} height={24} />
              </button>
              {!isLabLocationPartner && (
                <>
                  <p className='description mt-4'>{strings.stockWelcomeMessage(firstName, lastName)}</p>
                  <p>
                    {strings.stockWelcomeMessageDesc}
                    <button type='button' class='btn btn-link p-0' onClick={navigateToDocuments}>
                      {strings.stockPopupDocument}
                    </button>
                  </p>
                  <p>{strings.yourDemoLoanAppTeam}</p>
                  <div className='d-flex justify-content-center align-items-center mt-3'>
                    <Button
                      styleClass='btn-max-width-300'
                      value={strings.stockPopupRequestionPermission}
                      bgFill={true}
                      onClick={onClickOk}
                    />
                  </div>
                </>
              )}
              {isLabLocationPartner && (
                <>
                  <p className='description mt-4'>{strings.labsLocationUserWelcomeMessage(firstName, lastName)}</p>
                  <div className='d-flex justify-content-center align-items-center mt-3'>
                    <Button styleClass='btn-max-width-300' value={strings.ok} bgFill={true} onClick={onClickOkButton} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoStockAccessPopup;

import React, { useState, useEffect } from 'react';

import strings from 'resources/locales/Translate';

import './tabsStyle.scss';

const Tabs = (props) => {
  const { saveCurrentTab, toggleTab = true, tabs, currentTab, getTabId, zmmcNoTypeModal } = props;
  const [activeTab, setActiveTab] = useState(currentTab || 1);

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab])

  const toggleTabFunc = (id) => () => {
    if (toggleTab && activeTab !== id) {
      setActiveTab(id);
      getTabId(id)
      saveCurrentTab && saveCurrentTab(id);
    }
    if(id === 2){
      // dont use zmmcNoTypeModal prop in anyOther tab because it is used to show modal in zmcc transaction details
      zmmcNoTypeModal(true);
    }
  }

  return (
    <div className='app-tabs'>
      <div className='tab-header'>
        <div className='container-fluid d-flex'>
          {tabs.map((item, key) => (
            <div
              className={`tab cursor-pointer ${activeTab === item.id ? 'active' : ''} ${
                item.className
              }`}
              key={key}
              onClick={toggleTabFunc(item.id)}
            >
              {strings[item.tabTitle]} {!!item.count && `(${item.count})`}
            </div>
          ))}
        </div>
      </div>
      <div className='tab-content' id='nav-tabContent'>
        {tabs.map((item, key) => (
          <div
            className={`tab-pane fade ${activeTab === item.id ? 'show active' : ''}`}
            id='list-home'
            role='tabpanel'
            aria-labelledby='list-home-list'
            key={key}
          >
            {item.render(activeTab === item.id)}
          </div>
        ))}
      </div>
    </div>
  );
}

Tabs.defaultProps = {
  toggleTab: true,
  getTabId: () => {},
  zmmcNoTypeModal: () => {}
};

export default Tabs;

import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Icon from 'library/common/commonComponents/Icon/Icon';

import { useTasksFilter } from './TasksFilter.hook';
import { statusOptions } from '../../DusdTask.constants';

const TasksFilter = ({
  isShowFilter,
  systemsClass,
  resetFilters,
  handleFilterChange,
  selected,
}) => {
  const {
    filters,
    optionsForMainComponent,
    handleFilter,
  } = useTasksFilter({
    handleFilterChange,
    selected,
    systemsClass,
  });

  return (
    <>
      <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              data={systemsClass}
              filter
              field='systemClass'
              idKey='systemClassId'
              displayValue={[{ key: 'systemClassName', separator: '' }]}
              titleDisplay={[{ key: 'systemClassName', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.categorySystem}
              onChange={handleFilter('systemClass')}
              mainContainerStyle='col-lg-4 mt-3 mt-md-0'
              value={selected.systemClass}
              fullValue={selected.systemClassFullValue}
            />
            <Dropdown
              data={optionsForMainComponent}
              multiSelect
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              field='systemMainComponent'
              idKey='systemMainComponentId'
              displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
              titleDisplay={[{ key: 'systemMainComponentName', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.systemMain}
              onChange={handleFilter('systemMainComponent')}
              mainContainerStyle='col-lg-4 mt-3 mt-md-0'
              value={selected.systemMainComponent}
              fullValue={selected.systemMainComponentFullValue}
            />
            <Dropdown
              data={statusOptions}
              idKey='id'
              field='status'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.status}
              onChange={handleFilter('status')}
              mainContainerStyle='col-lg-4 mt-3 mt-md-0'
              value={selected.status}
              fullValue={selected.statusFullValue}
            />
          </div>
        </div>
      </div>
      {filters && !!filters.length && (
        <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center'>
          <div className='mr-2'>
            <strong>Filters:</strong>
          </div>
          {filters}
          <div className='filter-chips-delete-all' onClick={resetFilters}>
            <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
          </div>
        </div>
      )}
    </>
  );
};

export default TasksFilter;

import React, { useState } from 'react';
import { get } from 'lodash';

import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import strings from 'resources/locales/Translate';

const SalesDetails = ({ kitDetail, history, isEditable, editingKitURL }) => {
  const internalNotes = get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.internalNotes', '-');
  const isInternalNotesLengthMoreThanLimit = internalNotes && internalNotes.length > 1650;
  const shortInternalNotes = isInternalNotesLengthMoreThanLimit ? `${internalNotes.substring(0, 1650)}...` : internalNotes;
  const [isShowMoreButton, setIsShowMoreButton] = useState(isInternalNotesLengthMoreThanLimit);
  const internalNotesText = isShowMoreButton ? shortInternalNotes : internalNotes;
  const firstName = get(kitDetail, 'kitReservation.createdBy.firstName');
  const lastName = get(kitDetail, 'kitReservation.createdBy.lastName');
  const customer = firstName && lastName ? `${firstName} ${lastName}` : '-';
  const completenessCheckFirstName = get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.completenessCheckUser.firstName');
  const completenessCheckLastName = get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.completenessCheckUser.lastName');
  const completenessCheckUserName = completenessCheckFirstName && completenessCheckLastName ? `${strings.editedBy}: ${completenessCheckFirstName} ${completenessCheckLastName}` : '';
  const technicalControlFirstName = get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.technicalControlUser.firstName');
  const technicalControlLastName = get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.technicalControlUser.lastName');
  const technicalControlUserName = technicalControlFirstName && technicalControlLastName ? `${strings.editedBy}: ${technicalControlFirstName} ${technicalControlLastName}` : '';

  const handleClickOnShowMore = () => {
    setIsShowMoreButton(false)
  };

  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='w-75 mb-3'>
          <div className='section-title'>{strings.salesDetails}</div>
          <div className='row mb-3'>
            <div className='col-3 mb-2'>{`${strings.internalNotes}:`}</div>
            <div className='col-9 mb-2'>
              {internalNotesText}
              {isShowMoreButton && (
                <Button
                  dataTest='show-more-button'
                  bgFill={false}
                  value={strings.showMore}
                  iconName='visibility'
                  iconPosition='left'
                  onClick={handleClickOnShowMore}
                  styleClass='w-auto showMore-button mb-3'
                />
              )}
            </div>
            <div className='col-3 mb-2'>{`${strings.crmQuoteID}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'kitReservation.crmQuoteId') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.customer}:`}</div>
            <div className='col-9 mb-2'>{customer}</div>
            <div className='col-3 mb-2'>{`${strings.deliveryAddress}:`}</div>
            <div className='col-9 mb-2 text-break'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.deliveryAddress') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.purchaseOrder}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.purchaseOrder') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.salesOrder}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.salesOrder') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.technicalControl}:`}</div>
            <div className='col-9'>
              <Checkbox
                label={technicalControlUserName}
                selected={get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.technicalControl')}
                disabled={true}
                className='text-italic'
              />
            </div>
            <div className='col-3 mb-2'>{`${strings.internalOrder}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.internalOrder') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.desinfection}:`}</div>
            <div className='col-9 mb-2'>
              <Checkbox
                selected={get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.desinfection')}
                disabled={true}
                className='text-italic'
              />
            </div>
            <div className='col-3 mb-2'>{`${strings.completenessCheck}:`}</div>
            <div className='col-9 mb-2'>
              <Checkbox
                label={completenessCheckUserName}
                selected={get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.completenessCheck')}
                disabled={true}
                className='text-italic'
              />
            </div>
            <div className='col-3 mb-2'>{`${strings.productionOrderReparationOrder}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.productionOrder') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.internalStockOrder}:`}</div>
            <div className='col-9 mb-2'>
              {get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.internalStockOrder') || '-'}
            </div>
            <div className='col-3 mb-2'>{`${strings.deliveryDate}:`}</div>
            <div className='col-9 mb-2'>
              {
                get(kitDetail, 'sellOffPlatformInformation.kitSalesDetails.deliveryDate') ?
                getDateInFormat('DD.MM.YYYY', kitDetail.dateOfManufacture) :
                '-'
              }
            </div>
          </div>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: editingKitURL,
                state: { edit: true, tab: 'salesDetails' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default SalesDetails;

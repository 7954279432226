import React from "react";
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Button from 'library/common/commonComponents/Button/Button';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import { NavLink } from "react-router-dom";
import { useDemoCenterInformation } from "./DemoCenterInformation.hook";
import Icon from 'library/common/commonComponents/Icon/Icon';

export const DemoCenterInformation = (props) =>{
  const {language, formValue} = props;
  const {  demoCenterInformationForm  } = formValue;
  const enText = `Please provide a list of software and hardware/accessories that are required for the demo and/or have been offered to or discussed with the customer. System and accessories are listed here:`
  const entext2 = 'Always consult in advance with the ZMCC.';
  const deText1 = `Bitte geben Sie eine Liste der Software und der Hardware/des Zubehörs an, die für die Demo erforderlich sind und/oder dem Kunden angeboten oder mit ihm besprochen wurden. System und Zubehör sind hier aufgelistet:`;
  const detext2 = 'Bitte immer vorher mit dem ZMCC absprechen.'
  const {
    hardOrSoftwareAccessories,
    hardOrSoftwareAccessoriesList,
    addIntoHardOrSoftwareAccessoriesList,
    handleNonFormComponent,
    onChangeInput,
    deleteItem
  } = useDemoCenterInformation(props);

   return (
     <>
       <div className='mt-4'>{strings.systemAccessoriesText}</div>
       {
        demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList && demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList.map((item, index) =>
           
          <div className="row col-sm-12 mt-3" key={index}>
            <div className="col-sm-12 row">
              <p className="col-sm-4">{item.hardwareAccessoriesText}</p>
              <button
                    type='button'
                    className='delete-button delete-icon-name'
                    onClick={() => deleteItem(index)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' className='mr-2' />
                    {strings.delete}
                  </button>
            </div>
          </div>
        
        )
       }
       <div className="row col-sm-12 mt-3 p-0">
         <div className="col-sm-4">
         <InputField
          label={strings.hardOrSoftwareAccessories}
          field='hardOrSoftwareAccessories'
          formName='demoCenterInformationForm'
          placeholder=''
          isRequired={true}
          type='text'
          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
          value={demoCenterInformationForm.formData.hardOrSoftwareAccessories}
          error={demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError}
          onChange={onChangeInput}
          bigSizeInput={'bigger-input-field'}
          showRedAsterik={true}
        />
         </div>
         <div className="ml-4 mt-3">
         <Button
          value={strings.addMoreButtonText}
          onClick={() => addIntoHardOrSoftwareAccessoriesList(hardOrSoftwareAccessories)}
          bgFill={false}
          iconName='plus'
          disabled={demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList && demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList.length === 10}
        />
         </div>
       </div>
     </>
   ); 
}

export default DemoCenterInformation
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { CellUnits, ViewTypes } from './index';
import moment from 'moment';

class HeaderView extends Component {

  static propTypes = {
    schedulerData: PropTypes.object.isRequired,
    nonAgendaCellHeaderTemplateResolver: PropTypes.func,
  };

  renderWeekCustomHeader = (headerList = []) => {
    let mainHeaderList = []
    let count = 0;
    let sublist = []
    headerList.forEach((header, index) => {
      count++;
      if(index%2 != 0){
        sublist.push(header)
      }
      if(count === 22){
        mainHeaderList.push(sublist)
        sublist = []
        count = 0
      }

    })

    const getDateFromHeader = (header) => {
      if(header && header.length){
        let singleHeader = header[0];
        let currentDate = singleHeader.key
        currentDate = moment(currentDate).format('D ddd')
        return currentDate
      }
    }

    const getTodayStyles = (header) => {
      if(header && header.length){
        let singleHeader = header[0];
        let currentDate = singleHeader.key
        let clone = currentDate
        currentDate = moment(currentDate).format('dddd')
        const styles = {color: "#fff", fontWeight: "bold", backgroundColor: "rgb(0,136,208)"}
        return moment(clone).isValid() && moment(clone).isSame(new Date(), "day") ? styles: {};
      }
    }


    return <tr className='d-flex' style={{overflow: "hidden"}}>
      {
        mainHeaderList.map((subHeaderTimesList, index) => {
          return <div className='d-flex' style={{borderBottomWidth: 0 }}>
              <div className='d-flex flex-column'>
                <td rowSpan={10} style={getTodayStyles(subHeaderTimesList)}>
                  <p>{getDateFromHeader(subHeaderTimesList)}</p>
                </td>
                <td>{subHeaderTimesList}</td>
              </div>
           </div>
        })
      }
    </tr>

  }

  render() {
    const { schedulerData, nonAgendaCellHeaderTemplateResolver } = this.props;
    const { headers, cellUnit, config, localeMoment } = schedulerData;
    let headerHeight = schedulerData.getTableHeaderHeight();
    let cellWidth = schedulerData.getContentCellWidth();
    let minuteStepsInHour = schedulerData.getMinuteStepsInHour();

    let headerList = [];
    let style = {};
    if (cellUnit ===  CellUnits.Hour) {
      headers.forEach((item, index) => {
        if (index % minuteStepsInHour ===  0) {
          let datetime = localeMoment(item.time);

          style = !!item.nonWorkingTime
            ? {
                width: cellWidth * 2,
                color: config.nonWorkingTimeHeadColor,
                backgroundColor: config.nonWorkingTimeHeadBgColor,
              }
            : { width: cellWidth * 2 };

          if (index ===  headers.length - minuteStepsInHour)
            style = !!item.nonWorkingTime
              ? {
                  color: config.nonWorkingTimeHeadColor,
                  backgroundColor: config.nonWorkingTimeHeadBgColor,
                }
              : {width: cellWidth * 2};

          let pFormattedList = config.nonAgendaDayCellHeaderFormat
            .split('|')
            .map(item => datetime.format(item));
          let element;

          if (typeof nonAgendaCellHeaderTemplateResolver ===  'function') {
            element = nonAgendaCellHeaderTemplateResolver(
              schedulerData,
              item,
              pFormattedList,
              style,
            );
          } else {
            const pList = pFormattedList.map((item, index) => <div key={index}>{item}</div>);

            element = (
              <th key={item.time} className='header3-text' style={style}>
                <div>{pList}</div>
              </th>
            );
          }

          headerList.push(element);
        }
      });
    } else {
      headerList = headers.map((item, index) => {
        let datetime = localeMoment(item.time);
        style = !!item.nonWorkingTime
          ? {
              width: cellWidth,
              color: config.nonWorkingTimeHeadColor,
              backgroundColor: config.nonWorkingTimeHeadBgColor,
            }
          : { width: cellWidth };
        if (index ===  headers.length - 1)
          style = !!item.nonWorkingTime
            ? {
                color: config.nonWorkingTimeHeadColor,
                backgroundColor: config.nonWorkingTimeHeadBgColor,
              }
            : {};

        let pFormattedList = config.nonAgendaOtherCellHeaderFormat
          .split('|')
          .map(item => datetime.format(item));

        if (typeof nonAgendaCellHeaderTemplateResolver ===  'function') {
          return nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
        }

        const pList = pFormattedList.map((item, index) => <div key={index}>{item}</div>);

        return (
          <th key={item.time} className='header3-text' style={style}>
            <div>{pList}</div>
          </th>
        );
      });
    }

    return (
      <thead>
        {(schedulerData.viewType === ViewTypes.WeekDay || schedulerData.viewType === ViewTypes.Day) ? this.renderWeekCustomHeader(headerList): <tr style={{ height: headerHeight }}>{headerList}</tr>}
      </thead>
    );
  }
}

export default HeaderView;

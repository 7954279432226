import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';

import Icon from 'library/common/commonComponents/Icon/Icon';
import { images } from 'library/common/commonConstants/ImageConstants';

export const getIconByStatus = (status, isExpandable, size = 16) => {
  const { kitStatusId } = status;
  switch (kitStatusId) {
    case 1:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='home' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.readyForLoan}</span>}
        </div>
      );
    case 2:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='beforeDelivery' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.beforeDelivery}</span>}
        </div>
      );
    case 3:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='deliveryCustomer' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.deliveryCustomer}</span>}
        </div>
      );
    case 4:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='atDemo' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.atDemo}</span>}
        </div>
      );
    case 5:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='atDemoUnconfirmed' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.atDemoUnconfirmed}</span>}
        </div>
      );
    case 6:
      return (
        <div className='d-flex align-items-center mb-1'>
          <img width={size} height={size} src={images.returnToStock} alt={strings.returnToStock} />
          {isExpandable && <span className='mr-3 ml-1'>{strings.returnToStock}</span>}
        </div>
      );
    case 7:
      return (
        <div className='d-flex align-items-center mb-1'>
          <img
            width={size}
            className='mr-1'
            height={size}
            src={images.returnToStockUnconfirmed}
            alt={strings.returnToStockUnconfirmed}
          />
          {isExpandable && <span className='mr-3'>{strings.returnToStockUnconfirmed}</span>}
        </div>
      );
    case 8:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='visibility' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.visible}</span>}
        </div>
      );
    case 9:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='invisible' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.invisible}</span>}
        </div>
      );
    case 10:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'blue-round-max': size === 24, 'blue-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.available}</span>}
        </div>
      );
    case 11:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.notAvailable}</span>}
        </div>
      );
    case 12:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='file' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.fileAttached}</span>}
        </div>
      );
    case 13:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'green-round-max': size === 24, 'green-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.ok}</span>}
        </div>
      );
    case 14:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.incompleteMaintained}</span>}
        </div>
      );
    default:
      break;
  }
};

export const getIconByCondition = (status, isExpandable, size = 16) => {
  const { kitConditionId } = status;
  switch (kitConditionId) {
    case 1:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.incomplete}</span>}
        </div>
      );
    case 2:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.defective}</span>}
        </div>
      );
    case 3:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='toSale' width={16} height={16} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.toSell}</span>}
        </div>
      );
    case 4:
      return (
        <div className='d-flex align-items-center mb-1'>
          <Icon name='sold' width={16} height={16} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.sold}</span>}
        </div>
      );
    case 5:
      return (
        <div className='align-items-center mb-1' style={{display:'inline-block', marginLeft:'5px'}}>
          <Icon name='inRefurbishment' width={16} height={16} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.inRefurbishment}</span>}
        </div>
      );
    case 7:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.lost}</span>}
        </div>
      );
    case 8:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.scrapped}</span>}
        </div>
      );
    case 9:
      return (
        <div className='d-flex align-items-center mb-1'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.dismantled}</span>}
        </div>
      );
    case 12:
      return (
        <div className='d-flex align-items-center mb-1'>
           <Icon name='toSale' width={16} height={16} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.forSaleReserved}</span>}
        </div>
      );
    case 13:
        return (
          <div className='d-flex align-items-center mb-1'>
             <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
            {isExpandable && <span className='mr-3'>{strings.limitedDemoAbility}</span>}
          </div>
        );
    case 14:
        return (
          <div className='d-flex align-items-center mb-1'>
             <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
            {isExpandable && <span className='mr-3'>{strings.malfunction}</span>}
          </div>
        );
    default:
      break;
  }
};

export const getIconByCurrentPosition = (
  position,
  isExpandable,
  twentyFourHourReservation,
  returnDelivery,
  size = 16,
) => {
  const { transactionPositionId } = position;

  switch (transactionPositionId) {
    case 6:
      return (
        <div className='d-flex align-items-center'>
          <Icon name='home' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.readyForLoan}</span>}
        </div>
      );
    case 2:
      return (
        <div className='d-flex align-items-center'>
          <Icon name='beforeDelivery' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.beforeDelivery}</span>}
        </div>
      );
    case 3:
      return (
        <div className='d-flex align-items-center'>
          <Icon name='deliveryCustomer' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.deliveryCustomer}</span>}
        </div>
      );
    case 1:
      if (twentyFourHourReservation) {
        return null;
      }
      if (returnDelivery) {
        return (
          <div className='d-flex align-items-center'>
            <Icon name='beforeDelivery' width={size} height={size} fill='#000' className='mr-1' />
            {isExpandable && <span className='mr-3'>{strings.beforeDelivery}</span>}
          </div>
        );
      }
      return (
        <div className='d-flex align-items-center'>
          <Icon name='home' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.readyForLoan}</span>}
        </div>
      );
    case 'Demo in':
      if (twentyFourHourReservation) {
        return null;
      }
      return (
        <div className='d-flex align-items-center'>
          <Icon name='atDemo' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.atDemo}</span>}
        </div>
      );
    case 'Demo in unconfirmed':
      return (
        <div className='d-flex align-items-center'>
          <Icon name='atDemoUnconfirmed' width={size} height={size} fill='#000' className='mr-1' />
          {isExpandable && <span className='mr-3'>{strings.atDemoUnconfirmed}</span>}
        </div>
      );
    case 'Return Delivery':
      return (
        <div className='d-flex align-items-center'>
          <img width={size} className='mr-1' height={size} src={images.returnToStock} alt={strings.returnToStock} />
          {isExpandable && <span className='mr-3'>{strings.returnToStock}</span>}
        </div>
      );
    case 'Return delivery unconfirmed':
      return (
        <div className='d-flex align-items-center'>
          <img
            width={size}
            className='mr-1'
            height={size}
            src={images.returnToStockUnconfirmed}
            alt={strings.returnToStockUnconfirmed}
          />
          {isExpandable && <span className='mr-3'>{strings.returnToStockUnconfirmed}</span>}
        </div>
      );
    default:
      break;
  }
};

export const getIconByTransactionStatus = (status, isExpandable, size = 16) => {
  switch (status) {
    case 1:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.toApprove}</span>}
        </div>
      );
    case 2:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'green-round-max': size === 24, 'green-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.approved}</span>}
        </div>
      );
    case 5:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.cancelled}</span>}
        </div>
      );
    default:
      break;
  }
};

export const getIconByLLTransactionStatus = (status, isExpandable, size = 16) => {
  switch (status) {
    case 1:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'orange-round-max': size === 24, 'orange-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.toBeApprovedByLLManager}</span>}
        </div>
      );
    case 7:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'yellow-round-max': size === 24, 'yellow-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.toBeApprovedByLLPartner}</span>}
        </div>
      );
    case 8:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'green-round-max': size === 24, 'green-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.demoConfirmed}</span>}
        </div>
      );
    case 5:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.cancelled}</span>}
        </div>
      );
    default:
      break;
  }
};

export const getIconByZmccTransactionStatus = (status, isExpandable, size = 16) => {
  switch (status) {
    case 1:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'orange-round-max-zmcc': size === 24, 'orange-round-zmcc': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.waitingForConfirmation}</span>}
        </div>
      );
      case 10:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'gray-round-max': size === 24, 'gray-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.onHold}</span>}
        </div>
      );
      case 13:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'blue-round-max': size === 24, 'blue-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.inProgress}</span>}
        </div>
      );
      case 11:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'red-round-max': size === 24, 'red-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.waitingForSamples}</span>}
        </div>
      );
      case 12:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'red-light-round-max': size === 24, 'red-light-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.waitingForProcessing}</span>}
        </div>
      );
      case 6:
      return (
        <div className='d-flex align-items-center'>
          <div className={cn('mr-1', { 'green-round-max': size === 24, 'green-round': size === 16 })} />
          {isExpandable && <span className='mr-3'>{strings.doneStatus}</span>}
        </div>
      );
    default:
      break;
  }
};

export const getIconFor24HourReservation = (isExpandable, size = 20) => {
  return (
    <div className='d-flex align-items-center'>
      <Icon name='twentyFourCircle' width={size} height={size} fill='#000' className='mr-1' />
      {isExpandable && <span className='mr-3'>{strings.reservedKitFor24Hours}</span>}
    </div>
  );
};

export const getNameOfReturnGoodsBookingCondition = status => {
  switch (status) {
    case '1':
      return strings.incomplete;
    case '2':
      return strings.defective;
    case '6':
      return strings.ok;
    case '10':
      return strings.tracesOfUse;
    default:
      break;
  }
};

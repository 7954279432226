import React from 'react';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { getIconByCondition, getIconByStatus } from 'library/utilities/getStatusAndConditionIcons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { user } from '../../__mocks__/kit-details-mock-data';

const LLInformation = ({ kitDetail, history }) => {
  const businessUnit = get(kitDetail, 'businessUnit.name');
  const conditionString = get(kitDetail, 'kitCondition.kitConditionTranslations[0].translatedCondition', '').toLowerCase();
  const isEditable = (user && user.admin) || (user && user.labLocationPartner);

  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='row mb-3'>
          <div className='col-12 col-md-6'>
            <div className='section-title'>{strings.information}</div>

            <div className='subtitle'>{strings.mainComponent}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.systemMainComponent}:`}</div>
              <div className='col-6'>
                {(kitDetail.systemMainComponent && kitDetail.systemMainComponent.systemMainComponentName) || '-'}
              </div>
              <div className='col-6'>{`${strings.serialNumber}:`}</div>
              <div className='col-6'>{kitDetail.serialNumber || '-'}</div>
              <div className='col-6'>{`${strings.ibaseText}:`}</div>
              <div className='col-6'>{kitDetail.ibase || '-'}</div>
              <div className='col-6'>{`${strings.yearOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-'}
              </div>
              <div className='col-6'>{`${strings.monthOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-'}
              </div>
              <div className='col-6'>{`${strings.dateCreated}:`}</div>
              <div className='col-6'>
                {kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by + " " +kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName  : ""}` : '-'}
              </div>
              <div className='col-6'>{`${strings.dateModified}:`}</div>
              <div className='col-6'>
                {kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName  : ""} ` : '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.availability}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.availableKitDetails}:`}</div>
              <div className='col-6'>
                {kitDetail.availabilityFrom
                  ? getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom, kitDetail.availabilityTo)
                  : '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.assignment}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.countryLand}:`}</div>
              <div className='col-6'>
                {(kitDetail.stockInformation && kitDetail.stockInformation.country.name) || '-'}
              </div>
              <div className='col-6'>{strings.partnerSite}:</div>
              <div className='col-6'>
                {(kitDetail.stockInformation && kitDetail.stockInformation.locationName) || '-'}
              </div>
              <div className='col-6'>{`${strings.buildingAndRoom}:`}</div>
              <div className='col-6'>{kitDetail.storageLocation || '-'}</div>
              <div className='col-6'>{strings.businessUnit}:</div>
              <div className='col-6'>{businessUnit}</div>
              <div className='col-6'>{`${strings.categorySystem}:`}</div>
              <div className='col-6'>{(kitDetail.systemClass && kitDetail.systemClass.systemClassName) || '-'}</div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='section-title'>Kit-Status</div>
            <div className='subtitle'>{strings.status}</div>
            <div className='d-flex mb-3 flex-wrap'>
              {kitDetail.kitStatuses && kitDetail.kitStatuses.length ? (
                <>
                  {kitDetail.kitStatuses.map(item => {
                    if (item.kitStatusesTranslated && item.kitStatusId !== 15) {
                      return (
                        <div key={item.kitStatusId} className='mr-4 mb-2'>
                          {getIconByStatus(item, true, 24)}
                        </div>
                      );
                    }

                    return null;
                  })}
                </>
              ) : null}
              {conditionString ? (
                <div key={kitDetail.kitCondition.kitConditionId} className='mr-4 mb-2'>
                  {getIconByCondition(kitDetail.kitCondition, true, 24)}
                </div>
              ) : null}
            </div>
            <div className='comment-title'>{strings.function}</div>
            <div className='description-container word-wrap mb-3'>
              {kitDetail.comment || strings.noCommentAvailable}
            </div>
          </div>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-llkit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default LLInformation;

import React from 'react';
import { NavLink } from 'react-router-dom';

import strings from 'resources/locales/Translate';

import SmallButton from 'library/common/commonComponents/SmallButton/SmallButton.component';
import CreateServiceTicketPopup from 'library/common/commonComponents/CreateServiceTicketPopup';

import DeleteButton from '../DeleteButton/DeleteButton';

const ExpandableRowOfKitListTable = ({ fetchKitsList, row, handleDelete, user }) => {
  const { stockInformation, isTransactionAssociated, isLabsLocationPartner, isZMCCKit } = row;
  let isEditable = user.admin ? user.admin : false;
  let isDeletable = user.admin ? user.admin : false;
  let isServiceTicketCreatable = user.admin ? user.admin : false;

  if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0) {
    isEditable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      user.admin ||
      !isLabsLocationPartner && (stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning') ||
      (stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher');

    isDeletable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
    stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher' || user.admin;

    isServiceTicketCreatable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' || user.admin
      || stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher'
      || stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning';
  }

  return (
    <>
      <td />
      <td />
      <td colSpan='9'>
        <strong>{(isLabsLocationPartner || isZMCCKit) ? strings.function : strings.commentText}</strong>
        <p className='mb-3'>{row.comment}</p>
        <div className='row no-gutters mb-2'>
          <NavLink to={{ pathname: `/kit_details/${row.kitInformationId}` }} exact={true}>
            <SmallButton iconName='visibility' value={strings.showDetails} />
          </NavLink>
          {isEditable && (
            <div className='ml-2'>
              <NavLink
                to={{
                  pathname: isLabsLocationPartner
                    ? `/edit-llkit/${row.kitInformationId}`
                    : isZMCCKit 
                    ? `/edit-zmcc-kit/${row.kitInformationId}`
                    : `/edit-kit/${row.kitInformationId}`,
                  state: { edit: true },
                }}
              >
                <SmallButton bgFill={false} iconName='edit' value='Edit' />
              </NavLink>
            </div>
          )}

          {isServiceTicketCreatable && row.ibase && (
            <div className='ml-2' data-toggle='modal' data-target={`#createServiceTicket${row.kitInformationId}`}>
              <SmallButton iconName='file' value={strings.createServiceTicket} />
            </div>
          )}

          {!isTransactionAssociated && isDeletable && (
            <div className='ml-1'>
              <DeleteButton
                iconName='delete'
                value={strings.delete}
                id={row.kitInformationId}
                handleDelete={handleDelete}
              />
            </div>
          )}
        </div>
      </td>
      <CreateServiceTicketPopup
        id={`createServiceTicket${row.kitInformationId}`}
        kitDetails={row}
        user={user}
        handleFetch={fetchKitsList}
      />
    </>
  );
};

export default ExpandableRowOfKitListTable;

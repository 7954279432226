import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import strings from 'resources/locales/Translate';

import CreatingOrEditingEditionalComment from '../CreatingOrEditingEditionalComment';
import EditionalComment from '../EditionalComment';
import { useComment } from './Comment.hook';
import Icon from 'library/common/commonComponents/Icon/Icon';


const Comment = ({
  comment,
  userId,
  user,
  handleEdit,
  createEditionalComment,
  kitDetail,
  updateDemoUnitComment,
  deleteEditionalComment,
}) => {
  const getColorIcon = (status) => {
    switch(status) {
      case 'NO_ISSUES':
        return 'green-round';
      case 'SYSTEM_RESTRICTED':
        return 'yellow-round';
      case 'SYSTEM_DOWN':
        return 'red-round';
      default:
        break;
    }
  };

  const handleClick = () => handleEdit(comment);

  const { createdBy, updatedOn, kitDUSDCommentStatus, text, childComments } = comment;
  const name = `${createdBy.firstName} ${createdBy.lastName}`;
  const date = getDateInFormat('DD.MM.YYYY', updatedOn);
  const colorIcon = getColorIcon(kitDUSDCommentStatus.code);
  const createdById = createdBy ? createdBy.id : null;
  const isShowEditButton = (userId === createdById) && kitDetail.systemClassActive;
  const hasChildComments = childComments && childComments.length > 0;
  const {
    isCreationMode,
    editionalCommentForm,
    isLoader,
    currentEditionalComment,
    hasPermissions,
    toggleCreationMode,
    handleEditionalCommentForm,
    handleSaveEditionalComment,
    handleEditEditionalComment,
    handleEditComment,
    resetCurrentEditionalComment,
    handleDeleteComment,
  } = useComment({
    user,
    comment,
    deleteEditionalComment,
    createEditionalComment,
    kitDetail,
    updateDemoUnitComment,
  });

  return (
    <div>
      <div className='d-flex align-items-center mb-4'>
        <div className='w-50'>
          <div className='d-flex align-items-center mb-2'>
            <div className={`${colorIcon} mr-2`} />
            <p className='createdBy-text'>
              <span>{name}, </span>
              <span>{date}</span>
            </p>
          </div>
          <div className='description-container'>{text}</div>
        </div>
        <div className='w-50'>
          <div className='d-flex'>
            {isShowEditButton &&
              <div className='d-flex  flex-direction-row justify-content-between align-items-center'>
              <Button
                bgFill={false}
                iconName='edit'
                value={strings.edit}
                onClick={handleClick}
                styleClass='w-auto mr-3'
              />
                            <div className='btn-no-border pr-2 cursor-pointer' onClick={() => handleDeleteComment({deleteStatus: true})}>
                                                   <Icon name='delete' width={24} height={24} fill='#ff1a00' />
                                                 </div>

              </div>
            }
            {!isCreationMode && hasPermissions &&
              <Button
                dataTest='add-comment'
                value={strings.addComment}
                onClick={toggleCreationMode}
                bgFill={false}
                isGhost={true}
                styleClass='w-auto btn-no-border'
              />
            }
          </div>
        </div>
      </div>
      {isCreationMode &&
        <CreatingOrEditingEditionalComment
          form={editionalCommentForm}
          handleForm={handleEditionalCommentForm}
          handleCancel={toggleCreationMode}
          handleSave={handleSaveEditionalComment}
          isLoading={isLoader}
        />
      }
      {hasChildComments && childComments.map(editionalComment =>
        <EditionalComment
          key={editionalComment.id}
          editionalComment={editionalComment}
          userId={userId}
          form={editionalCommentForm}
          handleEdit={handleEditEditionalComment}
          handleUpdate={handleEditComment}
          handleForm={handleEditionalCommentForm}
          currentEditionalComment={currentEditionalComment}
          handleDelete={handleDeleteComment}
          resetCurrentEditionalComment={resetCurrentEditionalComment}
          isLoading={isLoader}
          kitDetail={kitDetail}
        />
      )}
    </div>
  );
};

export default Comment;

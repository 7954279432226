import React, { useState, useEffect } from 'react';
import Modal from 'library/common/commonComponents/Modal';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import strings from 'resources/locales/Translate';
import IconButton from 'library/common/commonComponents/IconButton';
import Icon from '../../../../../library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import { useEditMaterialNumberAndDescriptionPopup } from './EditMaterialNumberAndDescriptionPopup.hook';
import Validators from 'library/utilities/Validators';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';

export const EditMaterialNumberAndDescriptionPopup = props => {
  const {
    id,
    transactionMaterialNumber,
    saveMaterialNumberAndDescription,
    transactionDetails,
    editMaterialNumberPermission,
    transactionsType,
    detailPage,
  } = props;

  const {
    dispalyInput,
    dispaly,
    deleteTransactionMaterialNumber,
    handleOnChange,
    newTransactionMaterialValue,
    addTransactionMaterialNumber,
    transactionMaterials,
    handleOnChangeExstingValue,
    saveMaterialNumber,
    onCloseClick,
    isLoading,
    error,
  } = useEditMaterialNumberAndDescriptionPopup(props);
  return (
    <div>
      <Modal
        id={id}
        title={strings.edit}
        confirmAction={() => saveMaterialNumber(transactionMaterials)}
        confirmTitle={strings.save}
        titleOfCancel={strings.cancel}
        loading={isLoading}
        disableBackgroundClose
        onCloseClick={onCloseClick}
        className='modal-width-for-editMaterial'
      >
        <div>
          <div className='my-2'>
            <div className='row'>
              <div className='col-3'>{strings.materialNumber}</div>
              {!editMaterialNumberPermission && <div className='col-4'>{strings.singleLoanDescription}</div>}
              {editMaterialNumberPermission && <div className='col-4'>{strings.singleLoanDescription}</div>}
              <div className='col-1'>{strings.quantity}</div>
              <div className='col-1'>{strings.checked}</div>
              <div className='col-1'>{strings.sold}</div>
              <div className='col-1'>{strings.returned}</div>
            </div>
            {transactionMaterials &&
              transactionMaterials.length > 0 &&
              transactionMaterials.map((item, index) => (
                <>
                  <div className='row d-flex align-items-end'>
                    <InputField
                      isRequired
                      onChange={e => handleOnChangeExstingValue(e, index, 'materialNumber')}
                      value={`${item.materialNumber}`}
                      className='col-3'
                    />

                    <InputField
                      isRequired={false}
                      onChange={e => handleOnChangeExstingValue(e, index, 'decription')}
                      value={`${item.description}`}
                      className='col-4'
                    />
                    <InputField
                      isRequired
                      onChange={e => handleOnChangeExstingValue(e, index, 'quantity')}
                      value={`${item.quantity ? item.quantity : ''}`}
                      className='col-1'
                      maxLength={4}
                    />
                    <div className='col-1'>
                      <Checkbox
                        selected={item.checked}
                        onChange={e => handleOnChangeExstingValue(e, index, 'checked')}
                        className='return-checkbox'
                      />
                    </div>
                    <div className='col-1'>
                      <Checkbox
                        selected={item.sold}
                        onChange={e => handleOnChangeExstingValue(e, index, 'sold')}
                        className='return-checkbox'
                      />
                    </div>
                    <div className='col-1'>
                      <Checkbox
                        selected={item.sapReturnable}
                        onChange={e => handleOnChangeExstingValue(e, index, 'returned')}
                        className='return-checkbox'
                      />
                    </div>
                    {editMaterialNumberPermission && (
                      <div className='col-1'>
                        <button
                          type='button'
                          className='delete-button'
                          onClick={() => deleteTransactionMaterialNumber(index)}
                        >
                          <Icon name='delete' width={24} height={24} fill='#0088d0' className='fix-delete' />
                          {/* {strings.delete} */}
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
          {dispaly && (
            <>
            <div className='row d-flex align-items-end'>
              <InputField
                isRequired
                onChange={e => handleOnChange(e, 'materialNumber')}
                value={newTransactionMaterialValue.materialNumber}
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                doValidateForm={true}
                className={error ? 'col-3 mb-4' : 'col-3'}
              />
              <InputField
                isRequired={false}
                onChange={e => handleOnChange(e, 'decription')}
                value={newTransactionMaterialValue.description}
                className={error ? 'col-4 mb-4' : 'col-4'}
                validators={[]}
              />
              <InputField
                isRequired
                onChange={e => handleOnChange(e, 'quantity')}
                value={newTransactionMaterialValue.quantity}
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage },{ check: Validators.numberOrDecimal, message: 'invalidNumberError' },]}
                doValidateForm={true}
                className={(error === strings.invalidNumberError || error === strings.qunatityFieldIsRequired )? 'col-1 mt-2' : ((error === strings.materialnumberWarning || error === strings.materialNumberFormatError || error === strings.addMaterialNumberInfo || error === `${strings.materialnumberWarning} and ${strings.materialNumberFormatError}` || error === 'Quantity fields are required.' || error === `${strings.qunatityFieldIsRequired}`) ? 'col-1 mb-4' : 'col-1')}
                maxLength={4}
                error={(error === strings.invalidNumberError || error === strings.qunatityFieldIsRequired) && 'error'}
              />
              <div className={error ? 'col mb-4': 'col'}>
                <Checkbox
                  selected={newTransactionMaterialValue.checked}
                  onChange={e => handleOnChange(e, 'checked')}
                  className='return-checkbox '
                />
              </div>
              <div className={error ? 'col mb-4': 'col'}>
                <Checkbox
                  selected={newTransactionMaterialValue.sold}
                  onChange={e => handleOnChange(e, 'sold')}
                  className='return-checkbox '
                />
              </div>
              <div className={error ? 'col mb-4': 'col'}>
                <Checkbox
                  selected={newTransactionMaterialValue.sapReturnable}
                  onChange={e => handleOnChange(e, 'returned')}
                  className='return-checkbox '
                />
              </div>

              {editMaterialNumberPermission && (
                <div className={error ? 'col-1 mb-4': 'col-1'}>
                  <button
                    type='button'
                    className='delete-button'
                    onClick={() =>
                      addTransactionMaterialNumber(
                        newTransactionMaterialValue.materialNumber,
                        newTransactionMaterialValue.description,
                        newTransactionMaterialValue.sapReturnable,
                        newTransactionMaterialValue.quantity,
                        newTransactionMaterialValue.checked,
                        newTransactionMaterialValue.sold
                      )
                    }
                  >
                    <Icon name='plus' width={24} height={24} fill='#0088d0' className='fix-delete' />
                  </button>
                </div>
              )}
            </div>
            <div className='mt-2'>{!error && (strings.addMaterialNumberInfo)}</div>
            </>
          )}
          {error && <div className='error-block'>{error}</div>}
          {editMaterialNumberPermission && !dispaly && (
            <Button
              styleClass='col-3 mt-3'
              bgFill={false}
              value={strings.add}
              iconName='plus'
              onClick={dispalyInput}
              disabled={transactionMaterials.length >= 20}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditMaterialNumberAndDescriptionPopup;

import React, { PureComponent } from 'react';

import strings from 'resources/locales/Translate';

import Button from '../Button/Button';

export default class CreateStockActionButtons extends PureComponent {
  render() {
    const { onSave, onCancel, isLoading } = this.props;

    return (
      <div className='row mt-3 ml-0 mr-0'>
        {onCancel &&
          <div className='mr-3'>
            <Button value={strings.cancel} iconName='cross' bgFill={false} onClick={onCancel} />
          </div>
        }
        <div className='col-md-2 p-0'>
          <Button
            dataTest='create-kit-save-button'
            value={strings.save}
            iconName='save'
            onClick={onSave}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
}

CreateStockActionButtons.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
};

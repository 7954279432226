import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { ReactComponent as Logo } from 'resources/images/zeiss-logo.svg';
import strings from 'resources/locales/Translate';

import CartItem from './Components/CartItem';
import UnloggedMenu from './Components/UnloggedMenu';
import Navigation from './Components/Navigation';
import './fullScreenMenu.styles.scss';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export default class FullScreenMenu extends Component {
  async componentDidMount() {
    const { user} = this.props.authentication;
    const isZMCCEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';

    window.onscroll = () => {
      this.handleScroll();
    };
    const res =  user && isZMCCEnabled && await this.props.getListOfUnexecutedSemTransaction();
    this.props.fetchCartDataFromLocalStorage();
    const loggedInUser = fetchFromStorage(identifiers.user);
    if (loggedInUser) {
    this.props.getAllScheduledEventList(loggedInUser.accountId);
    this.props.checkIsOperator(loggedInUser.accountId);
      
    }
  }

  handleScroll = () => {
    const navbar = document.getElementById('main-nav');
    if (navbar) {
      const sticky = navbar.offsetTop;
      const logo = document.getElementById('sticky-logo');

      if (window.pageYOffset >= sticky) {
        navbar.classList.add('sticky');
        logo.style.display = 'block';
      } else {
        navbar.classList.remove('sticky');
        logo.style.display = 'none';
      }

      if (window.scrollY < 40) {
        navbar.classList.remove('sticky');
        logo.style.display = 'none';
      }
    }
  };

  onContactClick = () => {
    this.props.onContactChange();
  };

  handleLanguageChange = () => {
    const { language } = this.props.language;
    if (language === 'en') {
      this.props.setLanguage('de');
    } else {
      this.props.setLanguage('en');
    }
  };

  goToLoanList = () => {
    const { cart } = this.props.loanList;
    const updatedCart = cart.filter(item => !item.isZMCCCalendar)
    const { history } = this.props;
    let sikpToBooking = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('labs-location-calendar');
    let skipToKitLoanBooking = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('calendar');
    let url
    if (sikpToBooking && updatedCart.length === 1 ) {
      url = '/labs-location-kit-loan'
    } else {
      url = sikpToBooking ? '/labs-location-loan-list' : '/loan-list';
    }
    let demoLoans = updatedCart.filter(item => !item.isLabsLocation)
    if(skipToKitLoanBooking && demoLoans.length === 1){
      url = '/kit-loan'
    }
    history.push(url);
    
  };

  openNotificationsBar = () => {
    const { openOrHideNotificationsBar } = this.props;

    openOrHideNotificationsBar(true);
  };

  render() {
    const { language } = this.props.language;
    const { isLoggedIn, user ,} = this.props.authentication;
    const { cart } = this.props.loanList;
    const { removeItemFromCart, history, notifications, location, refurbishmentStockList, allUnexecutedtransactions, removeLoanFromCart } = this.props;
    const isShowNotificationsCount = isLoggedIn && notifications.length > 0;
    const notificationsCount = notifications.filter(notification => notification.status === 'UNREAD').length;
    let displayLanguage = language.includes('de') ? 'en' : 'de';
    const isLabsLocation = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('labs-location-calendar');
    const isLoanList = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('loan-list');
    const isZMCCCalendar = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('zmcc-calendar');
    let cartForUpdatedIssue = cart.filter((item,key) => 
      item.isZMCCCalendar === false
    );
    const updatedCartListForZmcc = [];

    if (allUnexecutedtransactions && allUnexecutedtransactions.length > 0){
      allUnexecutedtransactions.forEach(item => {
        item = JSON.parse(item);
        updatedCartListForZmcc.push({
          transactionsId : item.transactions.transactionsId,
          start: item.suggestedDate,
          end: item.alternateDate,
          kit: item['kitInformation'],
          customerName: item.customerName,
          isZMCCCalendar: true,
          isZmccLmBooking: item.transactions.zmccLmBooking,
          isZmccSemBooking: item.transactions.zmccSemBooking,
          isZmccXbBooking: item.transactions.zmccXbBooking,
          isZmccXrmBooking: item.transactions.zmccXrmBooking,
        });
      })
    }

    cartForUpdatedIssue = [...cartForUpdatedIssue, ...updatedCartListForZmcc];
    if (!isLoggedIn) {
      return <UnloggedMenu language={displayLanguage} cart={cart} handleLanguageChange={this.handleLanguageChange} />
    }

    const loansSubMenu = fetchFromStorage(identifiers.zmccSubLoanMenus);
    let indexForZMCC=0;
    return (
      <div className='full-screen-menu'>
        <div className='top-header d-flex justify-content-between align-content-start'>
          <Logo />
          <div className='top-menu'>
            <ul className='nav'>
              <li className='nav-item'>
                <NavLink to='/documents' exact={true}>
                  {strings.document}
                </NavLink>
              </li>
              <li className='nav-item cursor-pointer' onClick={this.onContactClick}>
                {strings.contact}
              </li>
              <li className='nav-item cursor-pointer' onClick={this.handleLanguageChange}>
                {strings[displayLanguage]}
              </li>
            </ul>
          </div>
        </div>

        <div className='d-flex justify-content-between align-items-center bg-white' id='main-nav'>
          <nav className='navbar navbar-expand-lg pl-0 pb-0'>
            <Logo
              width={32}
              height={32}
              id='sticky-logo'
              style={{ display: 'none', marginRight: '24px', marginLeft: '20px' }}
            />
            <Navigation
              user={user}
              pathname={history.location.pathname}
              location={location}
              refurbishmentFessStockList={refurbishmentStockList}
              loansSubMenu={loansSubMenu}
            />
          </nav>

          <div className='user-menu'>
            <ul className='nav'>
              {user && (
                <li className='nav-item p-0'>
                  <span className='nav-link pl-0'>{`${user.firstName} ${user.lastName}`}</span>
                </li>
              )}
              <li className='nav-item dropdown user'>
                <p
                  className='cursor-pointer dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <Icon name='person' width={32} height={32} fill={'#0088d0'} />
                </p>

                {user && (
                  <div className='dropdown-menu shadow rounded-0' aria-labelledby='dropdownMenuButton'>
                    <a
                      href={process.env.REACT_APP_ZEISS_PROFILE_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='dropdown-item'
                    >
                      {strings.zeissIdPortal}
                    </a>
                    <NavLink exact={true} to={'/delivery-addresses'} className='dropdown-item'>
                      {strings.deliveryAddresses}
                    </NavLink>
                    {this.props.isOperatorUser &&<NavLink exact={true} to={'/my-schedule'} className='dropdown-item'>
                      {strings.mySchedule}
                    </NavLink>}
                    <Button
                      bgFill={false}
                      value={strings.logout}
                      styleClass='mt-3'
                      onClick={() => this.props.logoutUser(this.props.history)}
                    />
                  </div>
                )}
              </li>
              <li className='nav-item dropdown cart'>
                <div
                  className='cursor-pointer dropdown-toggle position-relative'
                  id='cartDropdown'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <Icon name='cart' width={32} height={32} fill={'#0088d0'} />
                  <div className='cart-badge'>{cartForUpdatedIssue.length}</div>
                </div>

                <div className='dropdown-menu shadow rounded-0' aria-labelledby='cartDropdown'>
                  <div className='align-items-center p-3'>
                    {!isZMCCCalendar && cartForUpdatedIssue.length > 0 ? (
                      <div className='row'>
                        <div className='col-sm-12 cart-container'>
                          {cartForUpdatedIssue.map((item, key) => {
                            if (item.isZMCCCalendar){
                              indexForZMCC+=1;
                            }
                            return <CartItem 
                            key={key} 
                            item={item} 
                            deleteItem={item.isZMCCCalendar ? removeLoanFromCart : removeItemFromCart} 
                            history={history}
                            indexForZMCC={indexForZMCC}
                          />
                          }

                           )}
                        </div>

                        <div className='col-md-12 mt-3'>
                          <Button
                            value={isLabsLocation ? strings.continueToBooking : ( !isLabsLocation && cart.filter(item => !item?.isLabsLocation && !item?.isZMCCCalendar).length === 1  && !isLoanList ? strings.continueToBooking:  strings.continueToLoanListText)}
                            onClick={this.goToLoanList}
                          />
                        </div>
                      </div>
                    ) : (
                      <p className='no-cart-item text-center'>No items added in cart</p>
                    )}
                  </div>
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className='cursor-pointer position-relative'
                  onClick={isLoggedIn ? this.openNotificationsBar : null}
                >
                  <Icon name='bell' width={24} height={24} viewBox='0 0 24 24' fill={'#0088d0'} />
                  {isShowNotificationsCount && <div className='notifications-badge'>{notificationsCount}</div>}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import strings from 'resources/locales/Translate';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { useViewAndEditSchedule } from './ViewAndEditSchedule.hook';
import AddSchedulePupup from '../AddSchedulePopup/AddSchedulePopup.componet';
import { deleteSchedule } from 'modules/Homepage/HomepageActions';

export const ViewAndEditSchedule = ({ id, onEditClick, scheduledDetails, deleteSchedule }) => {
  const { onDeleteClick, onCloseClick, displayDays } = useViewAndEditSchedule({ id, scheduledDetails, deleteSchedule });
  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'view-edit-schedule'}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content rounded-0'>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
              <div className='view-Title-adjustment'>
              <h3 className='modal-title'>{strings.viewSchedule}</h3>
              <div className='edit-delete-buttton-adjustment'>
                <button className='edit-delete-icon-without-border' onClick={() => onEditClick(true)}>
                  <Icon name={'edit'} width={24} height={30} fill={'#0088d0'} />
                </button>
                <button className='ml-2 edit-delete-icon-without-border' onClick={onDeleteClick}>
                  <Icon name={'delete'} width={24} height={30} fill={'#0088d0'} />
                </button>
              </div>
              </div>
             
             
              {scheduledDetails !== null && (
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-6 font-bold'>{strings.scheduleInputTitle}:</div>
                    <div className='col-6'>{scheduledDetails.title}</div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.scheduleType}:</div>
                    <div className='col-6'>
                      {scheduledDetails.zmccScheduleType.zmccScheduledTypesTranslated[0].name}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.listLabelRecurring}</div>
                    <div className='col-6'>{scheduledDetails.recurring ? displayDays(scheduledDetails.days): strings.no}</div>
                  </div>
                  {!scheduledDetails.recurring && <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.date}:</div>
                    <div className='col-6'>{moment(scheduledDetails.scheduledDate).format('YYYY-MM-DD')}</div>
                  </div>}
                  
                  {scheduledDetails.recurring &&<div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.startDate}:</div>
                    <div className='col-6'>{moment(scheduledDetails.startDate).format('YYYY-MM-DD')}</div>
                  </div>}
                  {scheduledDetails.recurring &&<div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.endDate}:</div>
                    <div className='col-6'>{moment(scheduledDetails.endDate).format('YYYY-MM-DD')}</div>
                  </div>}
                  <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.startTime}:</div>
                    <div className='col-6'>{scheduledDetails.startTime}</div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.endTime}:</div>
                    <div className='col-6'>{scheduledDetails.endTime}</div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6 font-bold'>{strings.allDay}:</div>
                    <div className='col-6'>{scheduledDetails.allDayEvent ? strings.yes : strings.no}</div>
                  </div>
                </div>
              )}
              <div className='d-flex justify-content-center align-content-center mt-4'>
                <Button
                  styleClass='btn-max-width-300'
                  value={strings.close}
                  bgFill={true}
                  onClick={onCloseClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, homepage, languageReducer, appActionsReducer }) => ({
  user: authReducer.user,
  scheduledEventsList: homepage.scheduledEventsList,
  appActions: appActionsReducer,
  language: languageReducer.language,
  scheduledDetails: homepage.scheduledDetails,
});

export default connect(mapStateToProps, { deleteSchedule })(ViewAndEditSchedule);

import React from 'react';

import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

import { useOrderPopup } from './OrderPopup.hook'

const OrderPopup = ({
  id,
  handleClickOnConfirmation,
  handleClickOnReserve,
  updateList,
  kitId,
  isReservedKit,
}) => {
  const MAX_LENGTH = 10;
  const {
    state,
    isReservationLoading,
    isOrderLoading,
    isDisabledReservation,
    error,
    handleConfirmation,
    handleClosePopup,
    handleReservation,
    handleInput,
  } = useOrderPopup({
    id,
    handleClickOnConfirmation,
    handleClickOnReserve,
    updateList,
    kitId,
    isReservedKit,
  });
  
  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={id}
      aria-hidden='true'
    >
      <div className='modal-background' />
      <div className='modal-dialog' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content'>
              <button
                type='button'
                className='close modal-close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={handleClosePopup}
                data-test={`${id}-close-button`}
              >
                <Icon name='cross' width={24} height={24} />
              </button>
              <h5>{strings.youHaveOrderDemoKit}</h5>
              <div className='my-5'>
                <InputField
                  isRequired
                  label={strings.enterCRMQuoteID}
                  onChange={handleInput}
                  maxLength={MAX_LENGTH}
                  value={state.value}
                  error={state.error}
                />
              </div>
              {error && <div className='error-block'>{strings[error]}</div>}
              <div className='row mt-3'>
                <div className='col-md-4'>
                  <Button
                    data-test='modal-close-button'
                    iconName='cross'
                    bgFill={false}
                    value={strings.cancel}
                    onClick={handleClosePopup}
                  />
                </div>
                <div className='col-md-8'>
                  <Button
                    iconName='save'
                    value={strings.confirm}
                    onClick={handleConfirmation}
                    loading={isOrderLoading}
                  />
                </div>
                <div className='col-md-12 mt-2'>
                  <Button
                    bgFill={false}
                    value={strings.createQuoteFirst}
                    onClick={handleReservation}
                    loading={isReservationLoading}
                    disabled={isDisabledReservation}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPopup;

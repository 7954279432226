import React, { useEffect, useState } from 'react';
import * as $ from 'jquery';
import { isEqual, get } from 'lodash';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import {
  FETCH_CONDITIONS,
  FETCH_SELL_OFF_CONDITION,
  FETCH_SELL_OFF_VISIBILITY,
  FETCH_SELL_OFF_CURRENCY,
  FETCH_COUNTRY_LIST
} from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { scrollToTop } from 'library/utilities/scrollActions';

import services from '../../CreateKit.service';
import { validSystemClassValue } from './Information.constants';
import InRefurbishment from './InRefurbishmentPopup';
import KitForSalePopup from './Components/KitForSalePopup';

import {
  assignmentFormModel,
  availabilityFormModel,
  defaultPeriodsModel,
  kitStatusModel,
  mainComponentModel,
  packageDimensionsModel,
  refurbishmentPeriodModel,
} from './Information.constants';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import moment from 'moment';

const CreateKitInformation = ({
  formValue,
  fetchSystemClassData,
  fetchSystemMainComponentData,
  fetchUniqueIBaseValue,
  fetchMasterData,
  history,
  isCopyKit,
  kitInformationId,
  kitDetails,
  language,
  masterData,
  saveCreateKit,
  updateFormCreateKit,
  saveSaleKit,
  user,
  toggleActionMessage,
  fetchdivisionList,
  checkErpStatus,
  divisionsList,
  erpStatus,
  refurbishmentStatus,
  refurbishmentFessStatus,
  fetchKitDetail,
  activeTransactionLoanOrderData
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [conditions, setConditions] = useState(null);
  const [systemClassData, setSystemClassData] = useState(null);
  const [systemMainComponentData, setSystemMainComponentData] = useState(null);
  const [initialSellStatus, setInitialSellStatus] = useState(false);
  const [sellStatus, setSellStatus] = useState(false);
  const [stockId, setStockId] = useState(null);

  useEffect(() => {
    fetchMasterData(URLS.sellOffCondition, FETCH_SELL_OFF_CONDITION);
    fetchMasterData(URLS.sellOffVisibility, FETCH_SELL_OFF_VISIBILITY);
    fetchMasterData(URLS.sellOffCurrency, FETCH_SELL_OFF_CURRENCY);
    fetchdivisionList();
    if (kitDetails && kitDetails.stockInformation.id !== null) {
      checkErpStatus(kitDetails.stockInformation.id);
    } else {
      checkErpStatus(stockId);
    }
    if (kitDetails && kitDetails.stockInformation.id !== null) {
      refurbishmentFessStatus(kitDetails.stockInformation.id);
    } else {
      refurbishmentFessStatus(stockId);
    }
  }, [fetchMasterData, stockId]);
  useEffect(() => {
    const fetchInitialData = async updateCondition => {
      if (kitDetails && kitDetails.businessUnit && kitDetails.businessUnit.id) {
        const systemClassData = await fetchSystemClassData(kitDetails.businessUnit.id);
        setSystemClassData(systemClassData);
      }
      if (kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassId) {
        const systemMainComponentData = await fetchSystemMainComponentData(kitDetails.systemClass.systemClassId);
        setSystemMainComponentData(systemMainComponentData);
      }
    };
    if (!systemClassData) {
      fetchInitialData();
    }
  }, [formValue.kitStatusForm, systemClassData, fetchSystemClassData, fetchSystemMainComponentData, kitDetails]);

  useEffect(() => {
    const getSellStatusRequest = async () => {
      const response = await services.getSellStatus(formValue.assignmentForm.formData.stockInformation);
      setSellStatus(response);
    };

    const getInitialSellStatusRequest = async stockId => {
      const response = await services.getSellStatus(kitDetails.stockInformation.id);
      setInitialSellStatus(response);
      setSellStatus(response);
    };

    if (
      kitDetails &&
      kitDetails.stockInformation &&
      kitDetails.stockInformation.id === formValue.assignmentForm.formData.stockInformation
    ) {
      getInitialSellStatusRequest();
    } else if (formValue.assignmentForm.formData.stockInformation && !!kitInformationId) {
      getSellStatusRequest();
    }
  }, [formValue.assignmentForm.formData.stockInformation, kitDetails, kitInformationId]);

  /**
   * IF LANGUAGE HAS CHANGED
   * call to fetch new masterData.conditions
   * update kitConditionFullValue because of the language change
   */
  useEffect(() => {
    if (language !== currentLanguage) {
      setCurrentLanguage(language);
      fetchMasterData(`${URLS.countryList}?loantype=dla-stocks`, FETCH_COUNTRY_LIST);
      fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
      fetchdivisionList();
    }
    if (masterData.conditions && masterData.conditions.length && !isEqual(conditions, masterData.conditions)) {
      if (
        formValue.kitStatusForm &&
        formValue.kitStatusForm.formData &&
        formValue.kitStatusForm.formData.kitConditionFullValue
      ) {
        setConditions(masterData.conditions);
        const kitConditionFullValue = masterData.conditions.find(
          item => item.kitStatusId === formValue.kitStatusForm.formData.kitConditionFullValue.kitConditionId,
        );
        if (kitConditionFullValue) {
          updateFormCreateKit({
            kitStatusForm: {
              ...formValue.kitStatusForm,
              formData: {
                ...((formValue.kitStatusForm && formValue.kitStatusForm.formData) || {}),
                ...{
                  kitCondition: formValue.kitStatusForm.formData.kitCondition,
                  kitConditionFullValue: {
                    ...kitConditionFullValue,
                    kitConditionId: kitConditionFullValue.kitStatusId,
                  },
                  kitConditionError: null,
                },
              },
            },
          });
        }
      }
    }
  }, [
    masterData.conditions,
    conditions,
    language,
    currentLanguage,
    formValue.kitStatusForm,
    updateFormCreateKit,
    fetchMasterData,
  ]);

  const fetchSystemClass = async businessUnitId => {
    if (businessUnitId) {
      const systemClassData = await fetchSystemClassData(businessUnitId);
      setSystemClassData(systemClassData);
      setSystemMainComponentData([]);
    }
  };

  const fetchSystemMainComponent = async systemClassId => {
    if (systemClassId) {
      const systemMainComponentData = await fetchSystemMainComponentData(systemClassId);
      setSystemMainComponentData(systemMainComponentData);
    }
  };

  const openKitForSalePopup = () => {
    $('#kitForSale').modal('show');
  };

  const openInRefurbishmentPopup = () => {
    $('#inRefurbishmentStatus').modal('show');
  };

  const handleBusinessUnit = ({ values, field, formName }) => {
    if (field === 'businessUnit' && formValue.assignmentForm.formData.businessUnit !== values.businessUnit) {
      fetchSystemClass(values.businessUnit);
    }
    if (field === 'systemClass' && formValue.assignmentForm.formData.systemClass !== values.systemClass) {
      fetchSystemMainComponent(values.systemClass);
    }
    const newAssignmentForm = {
      ...formValue.assignmentForm,
      formData: {
        ...formValue.assignmentForm.formData,
        ...values,
      },
    };
    const newMainComponentForm = {
      ...formValue.mainComponentForm,
      formData: {
        ...formValue.mainComponentForm.formData,
        systemMainComponent: null,
        systemMainComponentFullValue: null,
      },
    };
    if (field === 'businessUnit') {
      newAssignmentForm.formData = {
        ...newAssignmentForm.formData,
        systemClass: null,
        systemClassFullValue: null,
      };
    }
    updateFormCreateKit({ assignmentForm: newAssignmentForm, mainComponentForm: newMainComponentForm });
  };

  const handleMarketingMaterial = async ({ values, formName }) => {
    const newData = {
      ...formValue[formName],
      formData: {
        ...((formValue[formName] && formValue[formName].formData) || {}),
        ...values,
      },
    };
    updateFormCreateKit({ [formName]: newData });
    let iBaseValue = '';
    if (formValue.mainComponentForm.formData.ibase) {
      iBaseValue = formValue.mainComponentForm.formData.ibase.trim();
    }
    if (values.marketingMaterial && !iBaseValue) {
      const res = await fetchUniqueIBaseValue(kitInformationId);
      const kitId = res.kitInformationId || res.kitId;
      const ibase = res.ibase || res.iBase;
      if (kitId && kitId !== kitInformationId) {
        updateFormCreateKit({ kitInformationId: kitId });
      }
      newData.formData = {
        ...newData.formData,
        ibase,
        ibaseError: null,
      };
    }
    updateFormCreateKit({ [formName]: newData });
  };

  const handleKitCondition = ({ values, formName }) => {
    const isAlreadyInShop = kitDetails && kitDetails.kitCondition && kitDetails.kitCondition.kitConditionId === 3;
    const isShowForSalePopup = sellStatus;
    const isEditingKit = !!kitInformationId;
    const isHavingRole = user.admin || checkUserRole();
    const isKitForSale = values && values.kitCondition === 3;
    const isInRefurbishment = values && values.kitCondition === 5;
    const isNotInRefurbishment = values && values.kitCondition !== 5;
    const isEmptyKitStatusForm = !formValue.kitStatusForm.formData;
    const isEmptyRefurbishmentFrom = !formValue.kitStatusForm.formData.refurbishmentFrom;
    const isInRefurbishmentSetInForm =
      formValue.kitStatusForm.formData && formValue.kitStatusForm.formData.kitCondition === 5;
    let refurbishmentFrom = get(formValue, 'kitStatusForm.formData.refurbishmentFrom');
    let refurbishmentTo = get(formValue, 'kitStatusForm.formData.refurbishmentTo');

    if (isInRefurbishment && (isEmptyKitStatusForm || isEmptyRefurbishmentFrom)) {
      return openInRefurbishmentPopup();
    }

    if (isKitForSale && isEditingKit && isHavingRole) {
      if (isAlreadyInShop && initialSellStatus) {
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'kitIsAlreadyDisplayedInShop');
      } else if (isShowForSalePopup) {
        return openKitForSalePopup();
      }
    }

    if (isNotInRefurbishment && refurbishmentFrom && refurbishmentTo && isInRefurbishmentSetInForm) {
      refurbishmentFrom = null;
      refurbishmentTo = null;
    }

    updateFormCreateKit({
      [formName]: {
        ...formValue[formName],
        formData: { ...formValue[formName].formData, ...values, refurbishmentFrom, refurbishmentTo },
      },
    });
  };

  const handleForm = async ({ values, field, formName }) => {
    switch (field) {
      case 'businessUnit':
      case 'systemClass':
        return handleBusinessUnit({ values, field, formName });
      case 'marketingMaterial':
        return handleMarketingMaterial({ values, formName });
      case 'kitCondition':
        return handleKitCondition({ values, formName });
      default:
        break;
    }
    updateFormCreateKit({
      [formName]: {
        ...formValue[formName],
        formData: {
          ...((formValue[formName] && formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
    if (field === 'stockInformation'){
      setStockId(values.stockInformation);
    }
  };

  const currentAssignmentFormModel = () => {
    const model = [...assignmentFormModel];
    const { assignmentForm } = formValue;
    model[0].options = masterData.countries || [];
    const excludeDlaKitBusinessUnits = ["RMS_L_L","RMS_ZMCC"];
    model[2].options = masterData.businessUnit.filter(item => !excludeDlaKitBusinessUnits.includes(item.designation)) || [];
    model[6].options = systemClassData || [];
    if(kitInformationId!==null && activeTransactionLoanOrderData && activeTransactionLoanOrderData.hasOwnProperty('transactionsId') && activeTransactionLoanOrderData.transactionsId !== 0 && (activeTransactionLoanOrderData.sapErp != "" || activeTransactionLoanOrderData.sapeErp != null)){
      model[4].disabled = true;
    }
    else{
      model[4].disabled = false;
    }
    if (
      assignmentForm.formData &&
      assignmentForm.formData.stockInformationFullValue &&
      (assignmentForm.formData.stockInformationFullValue.businessUnitName === 'MED' ||
        assignmentForm.formData.stockInformationFullValue.businessUnitName === 'MED-SUR' ||
        assignmentForm.formData.stockInformationFullValue.businessUnitName === 'MED-OPT')
    ) {
      model[5].isDisplayed = false;
    } else if (
      (stockId === null || stockId === undefined) &&  kitDetails &&
      kitDetails.stockInformation &&
      kitDetails.stockInformation.businessUnit && (kitDetails.stockInformation.businessUnit.name === 'MED' ||
        kitDetails.stockInformation.businessUnit.name === 'MED-OPT' ||
        kitDetails.stockInformation.businessUnit.name === 'MED-SUR')
    ) {
      model[5].isDisplayed = false;
    } else {
      model[5].isDisplayed = true;
    }

    return model;
  };

  const currentMainComponentModel = () => {
    const model = [...mainComponentModel];
    model[0].options = systemMainComponentData || [];
    if (
      (formValue.mainComponentForm.formData &&
        formValue.mainComponentForm.formData.marketingMaterial &&
        formValue.mainComponentForm.formData.ibase) ||
      (formValue.assignmentForm.formData &&
        formValue.assignmentForm.formData.systemClassFullValue &&
        validSystemClassValue.includes(formValue.assignmentForm.formData.systemClassFullValue.systemClassName))
    ) {
      model[1].required = false;
    } else {
      model[1].required = true;
    }
    model[9].options = masterData.countriesOfOrigin || [];
    model[9].selectAllOption = masterData.countriesOfOrigin && !!masterData.countriesOfOrigin.length;
    model[6].options = divisionsList;
    if (refurbishmentStatus) {
      model[2].isDisplayed = false;
      model[6].isDisplayed = false;
      model[7].isDisplayed = false;
    } else if (erpStatus) {
      model[2].isDisplayed = true;
      model[6].isDisplayed = false;
      model[7].isDisplayed = false;
    } else if (!erpStatus) {
      model[2].isDisplayed = true;
      model[6].isDisplayed = true;
      model[7].isDisplayed = true;
    }
    return model;
  };

  const checkUserRole = () => {
    const roles = ['Stock Controller', 'Shop Manager'];
    if (formValue.assignmentForm.formData.stockInformation) {
      return user.stockRoles.find(
        role => role.stockId === formValue.assignmentForm.formData.stockInformation && roles.includes(role.roleName),
      );
    }
    return false;
  };

  const currentKitStatusModel = () => {
    const model = [...kitStatusModel];
    if (masterData.conditions && masterData.conditions.length) {
      const kitCondition = get(kitDetails, 'kitCondition.kitConditionId');
      const reservedId = 12;
      const unusedСonditionIds = kitCondition === reservedId ? [6, 10, 11, 13, 14] : [6, 10, 11, 12, 13, 14];
      const newOptions = masterData.conditions.filter(condition => !unusedСonditionIds.includes(condition.kitStatusId));
      const okCondition = masterData.conditions.find(condition => condition.kitStatusId === 6);
      newOptions.unshift(okCondition);
      model[0].options = newOptions;
    }
    if (
      formValue.kitStatusForm.formData &&
      formValue.kitStatusForm.formData.refurbishmentFrom &&
      formValue.kitStatusForm.formData.refurbishmentTo &&
      formValue.kitStatusForm.formData.kitCondition === 5
    ) {
      model.splice(1, 0, ...refurbishmentPeriodModel);
      model[1].minDate = new Date();
      model[2].minDate = formValue.kitStatusForm.formData.refurbishmentFrom;
    }
    return model;
  };

  const currentAvailabilityFormModel = () => {
    const { availabilityForm } = formValue;
    const model = [...availabilityFormModel];

    if (availabilityForm.formData && availabilityForm.formData.availabilityFrom) {
      model[1].minDate = availabilityForm.formData.availabilityFrom;
    }
    if (availabilityForm.formData && availabilityForm.formData.availabilityTo) {
      model[0].maxDate = availabilityForm.formData.availabilityTo;
    }

    if (kitDetails && kitDetails.kitLoanAssociated !== true && disablingAvailableFrom()) {
      model[0].disabled = false;
    } else if (kitDetails && kitDetails.kitInformationId && kitDetails.kitLoanAssociated === true) {
      model[0].disabled = true;
    } else if (
      kitDetails &&
      kitDetails.kitLoanAssociated !== true &&
      (moment(kitDetails.availabilityFrom).isBefore(moment().format('YYYY-MM-DD')) ||
        moment(kitDetails.availabilityFrom).isAfter(moment().format('YYYY-MM-DD'))) &&
      !disablingAvailableFrom()
    ) {
      model[0].disabled = true;
    } else {
      model[0].disabled = false;
    }
    return model;
  };

  const disablingAvailableFrom = () => {
    let hasPermission = false;
    const roles = ['Stock Controller', 'Dispatcher'];
    const currentDate = moment();
    const availabilityFromDate = kitDetails.availabilityFrom;
    const getStockId = kitDetails && kitDetails.stockInformation.id;
    if (user && user.admin) {
      return true;
    }

    if (user && user.stockRoles && user.stockRoles.length > 0) {
      user.stockRoles.forEach(item => {
        if (
          roles.includes(item.roleName) &&
          item.stockId === getStockId &&
          moment(availabilityFromDate).isAfter(currentDate)
        ) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  };

  const saveKit = async (load = true, isKitForSale) => {
    const isExchangeStockForKitPossible = kitDetails && (kitDetails.sapLoanOrderStatus === 'CREATED' || kitDetails.sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE') && stockId && kitDetails.stockInformation.id !== stockId;
    if (isExchangeStockForKitPossible) {
      changeLoader(true);
      fetchKitDetail(kitDetails.kitInformationId, isCopyKit);
      changeLoader(false);
      scrollToTop(500);
      setStockId(null);
      toggleActionMessage(true, 'error', 'exchangeStockForKitErrorMessage');
      return;
    }
    else {
      const { success, kitInformationId } = await saveCreateKit(1, isCopyKit, isKitForSale);
      if (load && success) {
        history.replace(`/kit_details/${kitInformationId}`);
      }
    }
  };

  const cancelCreateKit = () => {
    history.replace('/kits');
  };

  const {
    assignmentForm,
    mainComponentForm,
    availabilityForm,
    defaultPeriodsForm,
    packageDimensionsForm,
    kitStatusForm,
  } = formValue;
  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.information}</h3>

            <h4 className='form-title sub-title'>{strings.assignment}</h4>
            <FormComponent
              model={currentAssignmentFormModel()}
              formName='assignmentForm'
              formValue={assignmentForm.formData}
              onChange={handleForm}
            />

            <h4 className='form-title sub-title'>{strings.mainComponent}</h4>
            <FormComponent
              model={currentMainComponentModel()}
              formName='mainComponentForm'
              formValue={mainComponentForm.formData}
              onChange={handleForm}
            />

            <h4 className='form-title sub-title'>{strings.defaultPeriods}</h4>
            <FormComponent
              model={defaultPeriodsModel}
              formName='defaultPeriodsForm'
              formValue={defaultPeriodsForm.formData}
              onChange={handleForm}
            />

            <h4 className='form-title sub-title'>{strings.availability}</h4>
            <FormComponent
              model={currentAvailabilityFormModel()}
              formName='availabilityForm'
              formValue={availabilityForm.formData}
              onChange={handleForm}
            />

            <h4 className='form-title sub-title'>{strings.packageDimensions}</h4>
            <FormComponent
              model={packageDimensionsModel}
              formName='packageDimensionsForm'
              formValue={packageDimensionsForm.formData}
              onChange={handleForm}
            />
          </div>

          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.kitStatus}</h3>
            <FormComponent
              model={currentKitStatusModel()}
              formName='kitStatusForm'
              formValue={kitStatusForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} />
          </div>
        </div>
        <InRefurbishment
          id='inRefurbishmentStatus'
          conditions={masterData.conditions}
          kitStatusForm={formValue.kitStatusForm}
          updateFormCreateKit={updateFormCreateKit}
          saveKit={saveKit}
        />
        <KitForSalePopup
          id='kitForSale'
          sellOffConditions={masterData.sellOffConditions}
          sellOffVisibility={masterData.sellOffVisibility}
          sellOffCurrency={masterData.sellOffCurrency}
          updateFormCreateKit={updateFormCreateKit}
          saveSaleKit={saveSaleKit}
          saveKit={saveKit}
          kitInformationId={kitInformationId}
          kitStatusForm={formValue.kitStatusForm}
          conditions={masterData.conditions}
          availabilityTo={availabilityForm.formData && availabilityForm.formData.availabilityTo}
          systemClassData={systemClassData || []}
          assignmentForm={assignmentForm.formData}
          mainComponentForm={mainComponentForm.formData}
        />
      </div>
    </div>
  );
};

export default CreateKitInformation;

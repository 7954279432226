import React, { Fragment } from 'react';
import moment from 'moment';

import Icon from 'library/common/commonComponents/Icon/Icon';
import SmallButton from 'library/common/commonComponents/SmallButton/SmallButton.component';
import {
  getIconByCondition,
  getIconByTransactionStatus,
  getIconByCurrentPosition,
  getIconFor24HourReservation,
} from 'library/utilities/getStatusAndConditionIcons';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';

import CancellationRequestPopup from './Components/CancellationRequestPopup';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import BulkConfirmationPopup from './Components/BulkConfirmationPopup/BulkConfirmationPopup.component';

const dataExpandableTable = (
  history,
  toggleActionMessage,
  addItemToCart,
  transactionType,
  cartItems,
  urlType,
  paginationData,
  setAdjustDatesModalVisible,
  setRejectCancelRequest,
  rejectCancelRequest,
  isDlaOrLab,
  bundleLoans,
  fetchConnectTransactionData,
  setSelectedData,
  cancelBulkResevations,
  filterDataToSend,
  setShowBulkConfirmPopup,
  setShowBulkCancelPopup,
  removeItemFromCartForBulk,
) => (data, handleDelete, user, saveCancellationRequest, index) => {
  const {
    transactionKit,
    transactionStatus,
    transactionPosition,
    stockInformation,
    isCancellationRequest,
    twentyFourHourReservation,
    returnDelivery,
    transactionsId
  } = data;
  let phone = '';
  let email = '';
  const checkCondition =
    transactionKit &&
    transactionKit.kitInformation &&
    transactionKit.kitInformation.kitCondition &&
    transactionKit.kitInformation.kitCondition.kitConditionTranslated.length;
  const checkStatus = transactionStatus && transactionStatus.name;
  const checkCurrentPosition = transactionPosition && transactionPosition.transactionPositionTranslated.length;
  const isExpandable = true;
  const disableButtons24 = data && data.twentyFourHourReservation;
  
  if (data.type === 'kit loan' && transactionKit && transactionKit.kitInformation) {
    const { stockInformation } = transactionKit;
    const warehouseAddresses = stockInformation && stockInformation.warehouseAddresses;
    const activeWarehouseAddress = warehouseAddresses && warehouseAddresses.find((address)=>address.activeAddress);
    phone = activeWarehouseAddress ? activeWarehouseAddress.phone : '***';
    email = activeWarehouseAddress ? activeWarehouseAddress.email : '***';

  } else {
    phone = stockInformation ? stockInformation.warehouseActiveAddress
    [0].phone : '***';
    email = stockInformation ? stockInformation.warehouseActiveAddress
    [0].email : '***';

  }
  const note = strings.pleaseCall.replace('[phone]', phone).replace('[email]', email);

  const detailPageNumber = (paginationData.page - 1) * paginationData.size + index + 1;

  const onShowDetailsClick = () => {
    history.push(`${transactionType}/${data.transactionsId}?detailPage=${detailPageNumber}?islabordla=${isDlaOrLab}`);
  };
  const onConfirmBooking = async() => {
    setSelectedData(data);
   const res = await fetchConnectTransactionData(transactionsId);
   const filterData = res && res.data && res.data.length > 0 && res.data.filter(item => item.twentyFourHoursReservation);
    if (filterData && filterData.length > 0) {
      setShowBulkConfirmPopup(true);
        $('#bulk-confirmation-modal').modal('show');
    }else{
      const item = {};
    let index = -1;
    index =
      cartItems && cartItems.length ? cartItems.findIndex(item => item.transactionsId === data.transactionsId) : -1;
    if (index === -1) {
      item.id = Math.floor(Math.random() * 20);
      item.title = '';
      item.isSelected = true;
      item.isBooked = false;
      item.hideOnCalendar = true;
      item.start = moment(data.targetReservationFrom).format('YYYY-MM-DD 00:00:00');
      item.end = moment(data.targetReservationTo).format('YYYY-MM-DD 23:59:59');
      item.resourceId = data.transactionKit.kitInformation.kitInformationId;
      item.kit = {
        id: data.transactionKit.kitInformation.kitInformationId,
        kitImages: [],
        kitInformationId: data.transactionKit.kitInformation.kitInformationId,
        kitName: data.transactionKit.kitInformation.kitName,
        name: data.transactionKit.kitInformation.kitName,
        stockInformation: data.transactionKit.stockInformation,
        transactionKit: [
          {
            transactions: {
              targetReservationFrom: data.targetReservationFrom,
              targetReservationTo: data.targetReservationTo,
            },
          },
        ],
        twentyFourHourReservation: true,
      };
      item.transactionsId = data.transactionsId;
      item.isAvailabilityChecked = true;
      item.isLabsLocation = false;
      addItemToCart([item]);
    }
    history.push('/kit-loan');
    }
    // 
    
  };
  
  const cancelTwentyForHoursReservation = async () => {
    setSelectedData(data);
    const res = await fetchConnectTransactionData(transactionsId);
    const filterData = res && res.data && res.data.length > 0 && res.data.filter(item => item.twentyFourHoursReservation);
     if (filterData && filterData.length > 0) {
      setShowBulkCancelPopup(true);
         $('#bulk-cancel-modal').modal('show');
     }else{
      const dataToSend = {transactionIds: [transactionsId]}
      await cancelBulkResevations(dataToSend, filterDataToSend, transactionType);
      removeItemFromCartForBulk([{transactionsId: transactionsId}])
     }
  }
  const onShowActionMessage = () => {
    toggleActionMessage(true, 'info', strings.orderIsShortlyBeforeDelivery(phone, email));
    scrollToTop(500);
  };
  const renderButton = () => {
    let field = 'targetDeliveryDate';
    if (data.deliveryDate) {
      field = 'deliveryDate';
    }
    if (
      moment(data[field]).isValid() &&
      moment(data[field]).isBefore(moment(`${moment().add(4, 'days').format('YYYY-MM-DD')} 00:00`)) &&
      checkStatus &&
      checkStatus.toLowerCase() !== 'cancelled'
    ) {
      return (
        <div className='d-flex'>
          {isCancellationRequest && enableAdjustDateButton? (
            <>
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${data.transactionsId}`}>
                <div className='d-flex'>
                  <SmallButton bgFill={false} iconName='' value={strings.cancelBooking} className='ml-3' />
                </div>
              </div>
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${data.transactionsId}`}>
                <div className='d-flex'>
                  <SmallButton
                    bgFill={false}
                    iconName=''
                    value={strings.rejectCancelRequest}
                    className='ml-3'
                    onClick={() => setRejectCancelRequest(true)}
                  />
                </div>
              </div>
            </>
          ) : (
            <SmallButton
              bgFill={false}
              iconName=''
              value={strings.requestCancel}
              className='ml-3'
              onClick={onShowActionMessage}
            />
          )}
        </div>
      );
    }
    if (checkStatus && checkStatus.toLowerCase() !== 'cancelled' && isCancellationRequest) {
      return (
        <div className='d-flex'>
          <span className='pt-1 pl-4'>{strings.cancelRequestRaised}</span>
          {enableAdjustDateButton() && (
            <>
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${data.transactionsId}`}>
                <div className='d-flex'>
                  <SmallButton bgFill={false} iconName='' value={strings.cancelBooking} className='ml-3' />
                </div>
              </div>
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${data.transactionsId}`}>
                <div className='d-flex'>
                  <SmallButton
                    bgFill={false}
                    iconName=''
                    value={strings.rejectCancelRequest}
                    className='ml-3'
                    onClick={() => setRejectCancelRequest(true)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      );
    }

    if (checkStatus && checkStatus.toLowerCase() !== 'cancelled' && !isCancellationRequest) {
      return (
        <div data-toggle='modal' data-target={`#cancellationRequestPopup${data.transactionsId}`}>
          <SmallButton bgFill={false} iconName='' value={strings.requestCancel} className='ml-3' onClick={() => setRejectCancelRequest(false)} />
        </div>
      );
    }
  };

  const enableAdjustDateButton = () => {
    let isAllowed = false;
    if (!isAllowed && user && user.stockRoles && user.stockRoles.length) {
      const { transactionKit } = data;
      let stockId = stockInformation && stockInformation.id;
      const demoLoanStockId = transactionKit && transactionKit.stockInformation && transactionKit.stockInformation.id;

      if (stockId || demoLoanStockId) {
        let businessGroup = transactionKit ? transactionKit.stockInformation && transactionKit.stockInformation.businessUnit && transactionKit.stockInformation.businessUnit.name : stockInformation && stockInformation.businessUnit && stockInformation.businessUnit.designation;
        let country = transactionKit ? transactionKit.stockInformation && transactionKit.stockInformation.country && transactionKit.stockInformation.country.shortName : stockInformation && stockInformation.country && stockInformation.country.shortName;

        const rolesForAdjustButton = ['stock controller', 'dispatcher', 'outbound staff'];
        const rolesForAdjustButtonForMedUs = ['stock controller', 'dispatcher', 'outbound staff','borrower mcs','borrower odx','borrower sur','borrower'];

        const roles = (businessGroup === 'MED' && country == 'US') ? rolesForAdjustButtonForMedUs : rolesForAdjustButton;
        user.stockRoles.forEach(role => {
          if (roles.includes(role.roleName.toLowerCase()) && (stockId === role.stockId || demoLoanStockId === role.stockId)) {
            isAllowed = true;
          }
        })
      }
    }
    if (user && user.admin) {
      isAllowed = true;
    }
    return isAllowed;
  };

  return (
    <>
      <td />
      <td />
      <td colSpan='12'>
        <div className='d-flex flex-wrap mb-3'>
          {checkStatus ? getIconByTransactionStatus(transactionStatus.transactionStatusId, isExpandable) : null}
          {checkCondition ? getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable) : null}
          {checkCurrentPosition ? (
            <div className='d-flex align-items-center'>
              {transactionPosition.transactionPositionTranslated.map(position => (
                <Fragment key={position.positionText}>
                  {getIconByCurrentPosition(position, isExpandable, twentyFourHourReservation, returnDelivery)}
                </Fragment>
              ))}
            </div>
          ) : null}
          {twentyFourHourReservation ? <>{getIconFor24HourReservation(isExpandable)}</> : null}
        </div>

        <div className='d-flex flex-wrap mb-3'>
          <div>
            <strong>{strings.deliveryCommentText}</strong>
            <p className='mb-3'>{data.deliveryComment || 'No comment'}</p>
          </div>

          <div className='ml-3'>
            <strong>{strings.commentText}</strong>
            <p className='mb-3'>{data.comment || 'No comment'}</p>
          </div>
        </div>
        {(urlType === 'cancellationApproval' || urlType === 'cancelledProcesses') && (
          <>
            <strong>{strings.cancellationComment}</strong>
            <p className='mb-3'>{data.reasonForCancellation || '-'}</p>
          </>
        )}

        <div className='mb-2'>
          <div className='d-flex'>
            <NavLink
              to={{
                pathname: `${transactionType}/${data.transactionsId}?detailPage=${detailPageNumber}?islabordla=${isDlaOrLab}`,
              }}
              exact={true}
            >
              <SmallButton iconName='visibility' value={strings.showDetails} />
            </NavLink>
            {twentyFourHourReservation &&
              checkStatus &&
              checkStatus.toLowerCase() !== 'cancelled' &&
              !isCancellationRequest && (
                <>
                <div className='ml-3'>
                  <SmallButton bgFill={false} value={strings.confirmBooking} onClick={onConfirmBooking} />
                </div>
                <div className='ml-3'>
                <SmallButton bgFill={false} value={strings.requestCancel} onClick={cancelTwentyForHoursReservation} />
              </div>
              </>
              )}
            {!twentyFourHourReservation && renderButton()}
            {enableAdjustDateButton() && (
              <div
                className='btn-no-border btn-lighter mx-3 pl-0 cursor-pointer'
                data-toggle='modal'
                data-target={!disableButtons24 ? `adjustDatesModal${data.transactionsId}` : ''}
                data-test='transaction-details-adjust-dates-button'
                onClick={() => setAdjustDatesModalVisible(data)}
              >
                <SmallButton bgFill={false} iconName='calendar' value={strings.adjustDates} />
              </div>
            )}
            <CancellationRequestPopup
              id={`cancellationRequestPopup${data.transactionsId}`}
              transactionsId={data.transactionsId}
              saveCancellationRequest={saveCancellationRequest}
              isFinalCancel={isCancellationRequest}
              isRejectCancel={rejectCancelRequest}
              setRejectCancelRequest={setRejectCancelRequest}
            />
          </div>
          <div className='d-flex align-items-center mt-3 mb-2'>
            <Icon name='info' width={24} height={24} className='mr-2' />
            <div>
              <strong>{strings.pleaseNote}</strong>
              <p>{note}</p>
            </div>
          </div>
        </div>
      </td>
    </>
  );
};
export default dataExpandableTable;

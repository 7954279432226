import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { editionalCommentModel } from './CreatingOrEditingEditionalComment.constants';

const CreatingOrEditingEditionalComment = ({
  form,
  isLoading,
  handleCancel,
  handleSave,
  handleForm,
}) => {
  return (
    <div className='mb-3 ml-3'>
      <FormComponent
        model={editionalCommentModel}
        formName='commentForm'
        formValue={form.formData}
        onChange={handleForm}
      />
      <CreateStockActionButtons onSave={handleSave} onCancel={handleCancel} isLoading={isLoading} />
    </div>
  );
};

export default CreatingOrEditingEditionalComment;

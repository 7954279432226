import React from 'react';

import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';

const TypesOfJobsBlock = props => {
  const { typesOfJobs, demoTime, typesOfJobsList, onChangeDemoTime, onChangeTypeOfJobs } = props;
  return (
    <div>
      {typesOfJobsList.map(item => (
        <div className='mb-3' key={item.tyesOfJobsId} title={item.toolTipValue !== null ? item.toolTipValue : ''}>
          <Checkbox
            label={item.name}
            selected={typesOfJobs.includes(item.tyesOfJobsId)}
            field={item.tyesOfJobsId}
            onChange={onChangeTypeOfJobs}
          />
          {typesOfJobs.includes(item.tyesOfJobsId) && <InputField
            label={item.tyesOfJobsId === 19 ? strings.pages : strings.demoTime}
            field='demoTime'
            placeholder='00.00'
            isRequired={false}
            validators={[]}
            value={demoTime[item.tyesOfJobsId]}
            onChange={value => onChangeDemoTime(item.tyesOfJobsId, value)}
          />}
        </div>
      ))}
    </div>
  );
};

export default TypesOfJobsBlock;

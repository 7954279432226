import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import CreateServiceTicketPopup from 'library/common/commonComponents/CreateServiceTicketPopup';

import Comment from '../Comment';
import CreatingOrEditingComment from '../CreatingOrEditingComment';
import Task from '../Task';
import { useDemoUnitStatus } from './DemoUnitStatus.hook';

const DemoUnitStatus = ({
  kitDetail,
  user,
  problemsList,
  createDemoUnitComment,
  updateDemoUnitComment,
  changeDemoUnitStatusTask,
  fetchProblemsList,
  createEditionalComment,
  language,
  fetchKitDetail,
  deleteEditionalComment,
}) => {
  const { demoUnitTaskToKitInformationSorted, kitDUSDComments } = kitDetail;
  const {
    creatingCommentForm,
    isCreationMode,
    currentEditableComment,
    editingCommentForm,
    isCreatLoader,
    isEditLoader,
    isDisabledUncheckedTasks,
    isDisableCreationgComment,
    userId,
    hasIBase,
    toggleCreationMode,
    cancelEditingComment,
    showEditingComment,
    handleCreatingForm,
    handleTaskStatus,
    handleCreateComment,
    handleEditingCommentForm,
    handleEditComment,
    showCreateServiceTicketPopup,
    handleFetchKitDetails,
  } = useDemoUnitStatus({
    kitDetail,
    user,
    problemsList,
    fetchProblemsList,
    createDemoUnitComment,
    updateDemoUnitComment,
    changeDemoUnitStatusTask,
    fetchKitDetail,
  });
  const hasTasks = demoUnitTaskToKitInformationSorted && demoUnitTaskToKitInformationSorted.length > 0;

  return (
    <div className='container-fluid'>
      <div className='section-title'>{strings.demoUnitStatus}</div>

      <div className='subtitle'>{strings.task}</div>
      <div className='mb-5'>
        {hasTasks ? demoUnitTaskToKitInformationSorted.map(task => (
          <Task
            key={task.demoUnitTask.id}
            userId={userId}
            task={task}
            isDisabledUncheckedTasks={isDisabledUncheckedTasks}
            handleTaskStatus={handleTaskStatus}
          />
        )) : (
          <span>{strings.noTaskAvailable}</span>
        )}
      </div>

      <div className='subtitle'>{strings.commentText}</div>
      <div className='mb-5'>
        {kitDUSDComments && kitDUSDComments.length > 0 ? kitDUSDComments.map(comment => {
          if (comment.id === (currentEditableComment && currentEditableComment.id)) {
            return (
              <CreatingOrEditingComment
                key={comment.id}
                commentForm={editingCommentForm}
                handleCommentForm={handleEditingCommentForm}
                handleCancel={cancelEditingComment}
                handleSave={handleEditComment}
                isLoading={isEditLoader}
                language={language}
              />
            );
          }
          return (
            <Comment
              key={comment.id}
              comment={comment}
              userId={userId}
              user={user}
              handleEdit={showEditingComment}
              createEditionalComment={createEditionalComment}
              kitDetail={kitDetail}
              updateDemoUnitComment={updateDemoUnitComment}
              deleteEditionalComment={deleteEditionalComment}
            />
          );
        }) : (
          <span>{strings.noCommentAvailable}</span>
        )}
      </div>

      {isCreationMode && (
        <CreatingOrEditingComment
          commentForm={creatingCommentForm}
          handleCommentForm={handleCreatingForm}
          handleCancel={toggleCreationMode}
          handleSave={handleCreateComment}
          isLoading={isCreatLoader}
          language={language}
        />
      )}
      <div className='d-flex'>
        {!isCreationMode && !isDisableCreationgComment && (
          <Button
            bgFill={false}
            iconName='plus'
            value={strings.addDemoUnitStatus}
            onClick={toggleCreationMode}
            styleClass='w-auto mr-3'
            disabled={isDisableCreationgComment}
          />
        )}
        {hasIBase && (
          <Button
            bgFill={false}
            iconName='plus'
            value={strings.createCustomerCareTicket}
            onClick={showCreateServiceTicketPopup}
            styleClass='w-auto'
          />
        )}
      </div>
      <CreateServiceTicketPopup
        id={`createServiceTicket${kitDetail.kitInformationId}`}
        kitDetails={kitDetail}
        user={user}
        handleFetch={handleFetchKitDetails}
      />
    </div>
  );
};

export default DemoUnitStatus;

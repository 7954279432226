import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';

import strings from 'resources/locales/Translate';

const Components = ({ kitDetail, history, isEditable }) => {
  const editingKitURL = kitDetail.isLabsLocationPartner ? `/edit-llkit/${kitDetail.kitInformationId}` : kitDetail.isZMCCKit ? `/edit-zmcc-kit/${kitDetail.kitInformationId}`:`/edit-kit/${kitDetail.kitInformationId}`;

  return (
    <div className='components-container'>
      <div className='container-fluid'>
      {
        kitDetail.salesComponents || kitDetail.furtherImportantComponents ?
        <div className='row mb-4'>
          <div className='col-12 col-lg-6'>
            <div className='section-title'>{strings.salesComponentLabel}</div>
            <div className='description-container word-wrap'>{kitDetail.salesComponents || 'No components available'}</div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='section-title'>{strings.furtherImportantComponentLabel}</div>
            <div className='description-container word-wrap'>{kitDetail.furtherImportantComponents || 'No components available'}</div>
          </div>
        </div> :
        <div className='dummy-data'>
          <span>{strings.noComponentsAvailableMessage}</span>
        </div>
      }

        {isEditable && <Button
          data-test='edit-kit-button'
          bgFill={false}
          iconName='edit'
          value={strings.edit}
          styleClass='w-auto'
          onClick={() => history.push(
            {
              pathname: editingKitURL,
              state: { edit: true, tab: 'components' }
            })
          }
        />}
      </div>
    </div>
  );
}

export default Components;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { IoIosPrint } from 'react-icons/io';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import ImageGallery from 'library/common/commonComponents/ImageGallery/ImageGallery';
import Print from 'library/common/commonComponents/KitPrint';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import { getPriceBasedonLanguage } from 'library/utilities/getCurrencyFormat';
import { images } from 'library/common/commonConstants/ImageConstants';
import { DUST_MANAGER, DUST_USER } from 'library/common/commonConstants/roles';

import { kitsDetailsBreadcrumbData, dustUserKitsDetailsBreadcrumbData } from './KitDetailsConstants';
import { kitDetailsTabs } from './tabsData';
import moment from 'moment';

class KitDetails extends Component {
  constructor(props) {
    super(props);
    const hasOnlyDustUserRole = props.user && props.user.dustUser;
    let currentTab = hasOnlyDustUserRole ? 5 : 1;
    if (props.history.location.state && props.history.location.state.tab) {
      if (props.history.location.state.tab === 'messages') {
        currentTab = 4;
      }
      if (props.history.location.state.tab === 'demoUnitStatus') {
        currentTab = 5;
      }
      if (props.history.location.state.tab === 'serviceTicket') {
        currentTab = 8;
      }
    }
    this.state = {
      isFullDescriptionShown: false,
      currentTab,
      isAttachmentsLoading: false,
    };
  }

  async componentDidMount() {
    const { fetchKitDetail, fetchProblemsList, match, language , checkActiveLoanorder} = this.props;
    document.title = `Kit #${match.params.id}`;
    fetchKitDetail(match.params.id);
    await checkActiveLoanorder(match.params.id);
    fetchProblemsList();
  }

  componentDidUpdate() {
    const { language } = this.props;
  }

  componentWillUnmount() {
    const { saveCurrentTab, clearKitDetailsData } = this.props;
    clearKitDetailsData();
    if (saveCurrentTab) saveCurrentTab(null);
  }

  handleDelete = () => {
    const { deleteKitDetails, kitDetails } = this.props;
    deleteKitDetails(kitDetails.kitInformationId).then(res => {
      setTimeout(() => {
        this.props.history.replace('/kits');
      }, 1000);
    });
  };

  goToCopyKit = () => {
    const { resetCreateKit, history, kitDetails, match } = this.props;
    const url = kitDetails.isLabsLocationPartner ? `/copy-llkit/${match.params.id}` : kitDetails.isZMCCKit ? `/copy-zmcc-kit/${match.params.id}` :`/copy-kit/${match.params.id}`;

    resetCreateKit();
    history.push(url);
  };

  checkForShowingVRDetailsTab = () => {
    const { user } = this.props;
    const { stockInformation, isLabsLocationPartner, isZMCCKit } = this.props.kitDetails;
    const stockId= stockInformation && stockInformation.id;
    const roles=['Stock Controller','Dispatcher'];
      if (user && user.admin){
        return true;
      }
      if (user && user.stockRoles && user.stockRoles.length > 0) {
        return roles.some(role => {
          return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
        });
      }
      return false;
    };

  checkRoleAndPermission() {
    const { user } = this.props;
    const { stockInformation, isLabsLocationPartner } = this.props.kitDetails;

    const isServiceManager = user && user.globalRoles && user.globalRoles.includes(DUST_MANAGER);
    const isServiceTechnician = user && user.globalRoles && user.globalRoles.includes(DUST_USER);
    const isServiceManagerOrServiceTechnician = isServiceManager || isServiceTechnician;

    let isEditable = user && user.admin ? user.admin : false;
    let isDeletableAndCanCopied = user && user.admin ? user.admin : false;
    let isBookingAndReserveAllowed = user && user.admin ? user.admin : false;

    if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0) {
      isEditable =
        stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
        (user && user.admin) ||
        (!isLabsLocationPartner && stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning') ||
        (stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher');
      isDeletableAndCanCopied =
        stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
        (user && user.admin) ||
        stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher';

      isBookingAndReserveAllowed =
        stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
        (user && user.admin) ||
        stockInformation.userRole[0].role.role.toLowerCase() === 'borrower' ||
        stockInformation.userRole[0].role.role.toLowerCase() === 'external borrower' ||
        stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher';
    }
    return { isEditable, isDeletableAndCanCopied, isBookingAndReserveAllowed, isServiceManagerOrServiceTechnician };
  }

  toggleFullDescription = isLimitMoreThan => () => {
    if (isLimitMoreThan) {
      this.setState(prevState => {
        return {
          isFullDescriptionShown: !prevState.isFullDescriptionShown,
        };
      });
    }
  };

  fetchKit = () => {
    const { fetchKitDetail, match } = this.props;
    fetchKitDetail(match.params.id);
  };

  getErrorMessageForIncompleteMaintainedKit = kitDetails => {
    const arrayMandatoryFilds = [
      {
        field: 'stockInformation',
        label: strings.stock,
      },
      {
        field: 'systemClass',
        label: strings.systemClassText,
      },
      {
        field: 'systemMainComponent',
        label: strings.systemMainComponent,
      },
      {
        field: 'dateOfManufacture',
        label: strings.dateOfManufacture,
      },
      {
        field: 'deliveryDays',
        label: strings.delivery,
      },
      {
        field: 'returnDeliveryDays',
        label: strings.returnDelivery,
      },
      {
        field: 'reconditioningDeliveryDays',
        label: strings.reconditioning,
      },
      {
        field: 'availabilityFrom',
        label: strings.availableFrom,
      },
      {
        field: 'salesComponents',
        label: strings.salesComponentLabel,
      },
    ];

    const emptyFields = [];
    arrayMandatoryFilds.forEach(item => {
      if (!kitDetails[item.field]) {
        emptyFields.push(item.label);
      }
    });

    if (!kitDetails.stockInformation || (kitDetails.stockInformation && !kitDetails.stockInformation.businessUnit)) {
      emptyFields.push(strings.businessUnit);
    }

    if (!kitDetails.serialNumber && !kitDetails.marketingMaterial) {
      emptyFields.push(strings.serialNumber);
    }

    if (emptyFields.length === 1) {
      return `${strings.mandatoryFieldIsMissing} ${emptyFields.join()}.`;
    } else if (emptyFields.length > 1) {
      return `${strings.mandatoryFieldsAreMissing} ${emptyFields.join().replaceAll(',', ', ')}.`;
    }

    return '';
  };

  checkAvailabilityToDate = availabilityTo => {
    const curentDtae = new Date();
    const finalCurrentDate = moment(curentDtae).format('YYYY-MM-DD');
    if (availabilityTo === 'null') {
      return false;
    } else if (finalCurrentDate > availabilityTo) {
      return true;
    }
  };

  checkAvailabilityKit = (kitStatuses, kitCondition, availabilityTo, isZMCCKit) => {
    let isIncompleteMaintained = false;
    let isInvisible = false;
    let isNotAvailable = false;
    let isOk = false;

    if (kitStatuses) {
      kitStatuses.forEach(status => {
        if (status.kitStatusId === 9) {
          isInvisible = true;
        }
        if (!isZMCCKit && status.kitStatusId === 11 && this.checkAvailabilityToDate(availabilityTo)) {
          isNotAvailable = true;
        }
        if (isZMCCKit && status.kitStatusId === 11) {
            isNotAvailable = true;
        }
        if (status.kitStatusId === 13) {
          isOk = true;
        }
        if (status.kitStatusId === 14) {
          isIncompleteMaintained = true;
        }
      });
    }

    if (kitCondition) {
      isOk = isOk && (kitCondition.kitConditionId !== 1 || kitCondition.kitConditionId !== 2);
    }

    return { isIncompleteMaintained, isInvisible, isNotAvailable, isOk };
  };

  handleSaveAttachmentsKit = async () => {
    const { saveKitAttachments } = this.props;

    this.setState({ isAttachmentsLoading: true });
    await saveKitAttachments();
    this.setState({ isAttachmentsLoading: false });
  };

  render() {
    const { isFullDescriptionShown, currentTab, isAttachmentsLoading } = this.state;
    const {
      kitDetails,
      isLoading,
      history,
      displayActionMessage,
      type,
      message,
      saveCurrentTab,
      damageLossReportData,
      commentsData,
      bookNowHandler,
      cart,
      kitDetailsAttachments,
      user,
      updateKitAttachents,
      formValue,
      transactionAttachments,
    } = this.props;
    const isLabsLocationPartner = kitDetails.isLabsLocationPartner;
    const isZMCCKitType = kitDetails.isZMCCKit;
    const hasOnlyDustUserRole = user && user.dustUser;
    const { isBookingAndReserveAllowed, isEditable, isDeletableAndCanCopied, isServiceManagerOrServiceTechnician } =
      this.checkRoleAndPermission();
    const kitImages = get(kitDetails, 'kitImages', []);
    const genericImages = get(kitDetails, 'kitGenericDevicePicture', []);
    const checkKitImage = kitImages.length || genericImages.length;
    const desplayedImages = (kitImages.length) ? kitImages : genericImages;
    const dustUserBreadcrumbs = hasOnlyDustUserRole ? dustUserKitsDetailsBreadcrumbData : kitsDetailsBreadcrumbData;
    const breadcrumbs = [...dustUserBreadcrumbs, { name: kitDetails.kitName }];
    let count = 0;
    if (damageLossReportData.totalElements) {
      count += damageLossReportData.totalElements;
    }
    if (commentsData.totalElements) {
      count += commentsData.totalElements;
    }
    let alreadyAddedToCart = cart && cart.some(cartItem => cartItem.resourceId === kitDetails.kitInformationId);
    const { isIncompleteMaintained, isInvisible, isNotAvailable, isOk } = this.checkAvailabilityKit(
      kitDetails.kitStatuses,
      kitDetails.kitCondition,
      kitDetails.availabilityTo,
      kitDetails.isZMCCKit
    );
    const isStatusOk = !isIncompleteMaintained && !isInvisible && !isNotAvailable && isOk;

    let isDescriptionLengthMoreThanLimit = kitDetails.description && kitDetails.description.length > 180;

    let description = isFullDescriptionShown
      ? kitDetails.description
      : kitDetails.description &&
        (isDescriptionLengthMoreThanLimit ? kitDetails.description.substring(0, 180) + '...' : kitDetails.description);

    let isTwentyFourReservationAllowed = true;

    if (
      kitDetails &&
      kitDetails.stockInformation &&
      kitDetails.stockInformation.workflowCategoryAnswers &&
      kitDetails.stockInformation.workflowCategoryAnswers.length > 0
    ) {
      const allowTwentyFourHourReservation = kitDetails.stockInformation.workflowCategoryAnswers.find(
        question => question.workflowCategoryQuestions.workflowCategoryQuestionsId === 6,
      );
      if (allowTwentyFourHourReservation) {
        isTwentyFourReservationAllowed = allowTwentyFourHourReservation.value === 'yes';
      }
    }

    const hasERPActiavted = () =>{
      const { workFlowQuestions } = this.props;
      const workFlowCategoryData = workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[4] && workFlowQuestions.stockWorkflowDTOS[4]['stockWorkflowSettingDTO']
      const isWorkflowErp = workFlowCategoryData && workFlowCategoryData.find(
        item => item.categoryQuestionId === 21,
      );
      const finalValue= isWorkflowErp != null ? isWorkflowErp.categoryAnswer === 'yes' : false;
      return finalValue;
    }

    const hideNetValue = () => {
      const {user, kitDetails} = this.props;
      const kitStockId = kitDetails?.stockInformation?.id;
      if(user.admin) return true;
      const stock = user?.stockRoles?.find(role => role.stockId === kitStockId && role.roleName === "Stock Controller")
      return stock !== null && stock !== undefined;
    }

    const isErpActivated = Object.keys(kitDetails).length !== 0 && kitDetails && kitDetails.businessUnit.name !== 'RMS labs@location Partner' && hasERPActiavted() && hideNetValue();
    const isVrKit = kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassName && kitDetails.systemClass.systemClassName.toLowerCase() === 'VR Demo Kit'.toLowerCase();
    return (
      <div className='kit-details'>
        {displayActionMessage ? <ActionMessage type={type} message={strings[message] || message} /> : null}
        <div className='container-fluid mb-3'>
          <div className='d-flex'>
            <Breadcrumbs data={breadcrumbs} />
          </div>
          <div className='row my-3'>
            <div className='col-12 col-lg-auto'>
              <div className='d-flex justify-content-center mb-3'>
                {checkKitImage ? (
                  <ImageGallery images={desplayedImages} />
                ) : (
                  <img alt='' src={images.imagePlaceholder} className='dummy-image' />
                )}
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg'>
              <h2 className='title'>{kitDetails.kitName}</h2>
              <div className='kit-number-text'>{`${strings.kitNumber}: ${kitDetails.kitInformationId}`}</div>
              {!isLabsLocationPartner && !isZMCCKitType && (
                <div className='kit-number-text'>{`${strings.ibaseText}: ${
                  kitDetails.ibase ? kitDetails.ibase : '-'
                }`}</div>
              )}
              <div className='subtitle'>{strings.descriptionText}</div>
              <div className='description-container mb-3'>{description}</div>
              <div className='full-description' onClick={this.toggleFullDescription(isDescriptionLengthMoreThanLimit)}>
                <Icon
                  name='visibility'
                  width={24}
                  height={24}
                  fill={isFullDescriptionShown || !isDescriptionLengthMoreThanLimit ? '#737678' : '#0088d0'}
                  className='mr-2'
                />
                <span
                  style={{ color: isFullDescriptionShown || !isDescriptionLengthMoreThanLimit ? '#737678' : '#0088d0' }}
                >
                  {strings.fullDescription}
                </span>
              </div>
              {!isLabsLocationPartner && !isZMCCKitType && (
                <div>
                  <div className='list-price'>{strings.systemValue}</div>
                  <div className='price'>{getPriceBasedonLanguage(kitDetails.systemValue, this.props.language.language)}</div>
                </div>
              )}
              {isErpActivated && (
                <div>
                  <div className='list-price'>{strings.netValue}</div>
                  <div className='price'>{getPriceBasedonLanguage(kitDetails.netValue, this.props.language.language)}</div>
                </div>
              )}
              <div className='row no-gutters align-items-center mb-4'>
                {!alreadyAddedToCart ? (
                  <>
                    {isBookingAndReserveAllowed && (
                      <div className='col-lg-auto col-12 mr-2'>
                        <Button
                          bgFill={true}
                          iconName='arrow'
                          value={strings.bookNow}
                          onClick={() => bookNowHandler(kitDetails)}
                          disabled={!isStatusOk}
                        />
                      </div>
                    )}
                    {!isLabsLocationPartner && !isZMCCKitType && isBookingAndReserveAllowed && (
                      <div className='col-lg-auto col-12 mt-2 mt-lg-0 mr-2'>
                        <Button
                          bgFill={false}
                          iconName='reserve'
                          value={strings.reserve}
                          disabled={!isStatusOk || !isTwentyFourReservationAllowed}
                          onClick={() => bookNowHandler(kitDetails)}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <span className='mr-2'>{strings.addedToCart}</span>
                )}
                {!isLabsLocationPartner && !isZMCCKitType && kitDetails.kitStatuses && !isStatusOk && (
                  <div className='error-block mt-3 mb-3'>
                    {isIncompleteMaintained && (
                      <p className='mb-1'>{this.getErrorMessageForIncompleteMaintainedKit(kitDetails)}</p>
                    )}
                    {isInvisible && <p className='mb-1'>{strings.hideKitIsActivated}</p>}
                    {isNotAvailable && <p className='mb-1'>{strings.availabilityPeriodIsExpired}</p>}
                    {!isOk && <p className='dummyClassForSpec mb-1'>{strings.statusIsNotOk}</p>}
                  </div>
                )}
                {isStatusOk && !isTwentyFourReservationAllowed && (
                  <div className='error-block mt-3 mb-3'>{strings.twentyFourHourReservationNotAvailable}</div>
                )}
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg-auto'>
              <div className='d-flex justify-content-end'>
                {!hasOnlyDustUserRole && (
                  <div className='button-blue' data-test='copyKit-button' onClick={this.goToCopyKit}>
                    <Icon name='copy' width={24} height={24} fill='#0088d0' />
                    <div className='tooltiptext'>
                      Copy
                      <i />
                    </div>
                  </div>
                )}
                {isEditable && (
                  <div className='button-blue'>
                    <NavLink
                      to={{
                        pathname: isLabsLocationPartner
                          ? `/edit-llkit/${kitDetails.kitInformationId}`
                          : isZMCCKitType
                          ? `/edit-zmcc-kit/${kitDetails.kitInformationId}`
                          : `/edit-kit/${kitDetails.kitInformationId}`,
                        state: { edit: true },
                      }}
                    >
                      <Icon name='edit' width={24} height={24} fill='#0088d0' />
                    </NavLink>
                    <div className='tooltiptext'>
                      {strings.edit}
                      <i />
                    </div>
                  </div>
                )}
                {!hasOnlyDustUserRole && (
                  <ReactToPrint
                    trigger={() => (
                      <div className='button-blue'>
                        <IoIosPrint size={24} color='#0088d0' />
                        <div className='tooltiptext'>
                          {strings.print}
                          <i />
                        </div>
                      </div>
                    )}
                    content={() => this.printComponent}
                  />
                )}

                {!kitDetails.isTransactionAssociated && isDeletableAndCanCopied && (
                  <div
                    className='button-red'
                    data-toggle='modal'
                    data-target={`#confirmKitDeleteModal${kitDetails.kitInformationId}`}
                  >
                    <Icon name='delete' width={24} height={24} fill='#ff1a00' />
                    <div className='tooltiptext'>
                      {strings.delete}
                      <i />
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-end p-3'>
                {kitDetails.qrCode && <img src={kitDetails.qrCode} alt='' className='grCode-image' />}
              </div>
            </div>
          </div>
        </div>

        <div className='print-hidden'>
          <Print
            ref={ref => {
              this.printComponent = ref;
            }}
            kitDetails={kitDetails}
          />
        </div>

        <Tabs
          tabs={kitDetailsTabs(
            kitDetails,
            history,
            isEditable,
            this.props.match,
            count,
            kitDetailsAttachments,
            commentsData,
            damageLossReportData,
            updateKitAttachents,
            formValue,
            this.handleSaveAttachmentsKit,
            isAttachmentsLoading,
            isServiceManagerOrServiceTechnician,
            hasOnlyDustUserRole,
            user,
            transactionAttachments,
            this.props.workFlowQuestions,
            isVrKit,
            this.checkForShowingVRDetailsTab,
            this.props.availabilitySlotList
          )}
          currentTab={currentTab}
          saveCurrentTab={saveCurrentTab}
        />

        {isLoading && <div className='loader' />}

        <ConfirmDeletionPopup
          id={`confirmKitDeleteModal${kitDetails.kitInformationId}`}
          onConfirm={() => this.handleDelete(kitDetails.kitInformationId)}
          title={strings.confirmDeleteKit}
          subtitle={strings.confirmDeleteKitMessage}
        />
      </div>
    );
  }
}

export default KitDetails;

import { connect } from 'react-redux';

import InformationTab from './InformationTab.component';
import { fetchSystemMainComponent, fetchRoomsListForWarehouse } from '../../CreateZmccKit.actions';

const mapStateToProps = ({ authReducer, createZmccKitReducer, languageReducer }) => ({
  informationForm: createZmccKitReducer.informationForm,
  kitStatusForm: createZmccKitReducer.kitStatusForm,
  stocks: createZmccKitReducer.stocks,
  systemClasses: createZmccKitReducer.systemClasses,
  systemMainComponent: createZmccKitReducer.systemMainComponent,
  conditions: createZmccKitReducer.conditions,
  activeUntil: createZmccKitReducer.activeUntil,
  language: languageReducer,
  user: authReducer.user,
  kitDetails: createZmccKitReducer.kitDetails,
});

export default connect(mapStateToProps, {
  fetchSystemMainComponent,
  fetchRoomsListForWarehouse
})(InformationTab);

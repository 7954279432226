import React from 'react';
import cn from 'classnames';
import Dropdown from 'library/common/commonComponents/Dropdown/Dropdown';
import strings from 'resources/locales/Translate';

const Filter = ({
  isShowFilter,
  countries,
  categoriesAndSystems,
  transactionPositions,
  handleFilterChange,
}) => (
  <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
    <div className='container-fluid'>
      <div className='row'>
        <Dropdown
          data-test='c2c-loans-filter-stock'
          data={countries}
          hasSection={true}
          multiSelect={true}
          filter={true}
          clearMultiOption={true}
          idKey='id'
          displayValue={[{ key: 'locationName', separator: '' }]}
          optionsArrayKey='stockInformation'
          titleDisplay={[{ key: 'shortName', separator: ' ' }, { key: 'name', separator: '' }]}
          isRequired={true}
          placeholder={strings.pleaseSelect + '...'}
          label={strings.stock}
          onChangeValue={handleFilterChange.bind(this, 'stock')}
          mainContainerStyle='col-lg-4 mb-3'
        />
        <Dropdown
          data={categoriesAndSystems}
          hasSection={true}
          multiSelect={true}
          filter={true}
          clearMultiOption={true}
          idKey='systemMainComponentId'
          displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
          optionsArrayKey='systemMainComponent'
          titleDisplay={[{ key: 'systemClassName', separator: '' }]}
          isRequired={true}
          placeholder={strings.pleaseSelect + '...'}
          label={strings.categorySystem}
          onChangeValue={handleFilterChange.bind(this, 'systemClass')}
          mainContainerStyle='col-lg-4 mb-3'
        />
        <Dropdown
          data={transactionPositions}
          hasSection={false}
          multiSelect={true}
          filter={false}
          clearMultiOption={true}
          idKey='transactionPositionId'
          displayValue={[{ key: 'positionText', separator: '' }]}
          isRequired={true}
          placeholder={strings.pleaseSelect + '...'}
          label={strings.currentPosition}
          onChangeValue={handleFilterChange.bind(this, 'currentPosition')}
          mainContainerStyle='col-lg-4 mb-3'
        />
      </div>
    </div>
  </div>
);

export default Filter;

import {connect} from "react-redux";

import KitList from "../KitList";

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    authentication: state.authReducer,
    cart: state.loanList.cart
  }
};

export default connect(mapStateToProps)(KitList);

import Validators from 'library/utilities/Validators';

export const attendeeModel = [
    {
        label: 'name',
        value: "",
        type: 'text',
        placeholder: '',
        field:'attendeeName',
        validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
        required: true,
        styleClass: 'col-sm-6',
        showRedAsterik: true
      },
      {
        label: 'email',
        value: "",
        type: 'text',
        placeholder: '',
        field:'attendeeEmail',
        validators: [
          { check: Validators.email, message: 'invalidEmailError' },
          { check: Validators.required, message: 'requiredErrorMessage'}
        ],
        required: true,
        styleClass: 'col-sm-6',
        showRedAsterik: true
      },
      {
        label: 'function',
        value: "",
        type: 'text',
        placeholder: '',
        field:'functionData',
        validators: [],
        required: true,
        styleClass: 'col-sm-6',
        showRedAsterik: true,
        validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
      },
      {
        label: 'skillLevel',
        value: '',
        type: 'dropdown',
        placeholder: 'pleaseSelect',
        field: 'skillLevelId',
        validators: [],
        required: false,
        styleClass: 'col-sm-6',
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: false,
        idKey: 'id',
        displayValue: [{ key: 'name', separator: ' ' }],
        hideOptionalText: true
      },
      {
        label: 'accomodationNeeded',
        selected: false,
        type: 'checkbox',
        field: 'accomodationNeeded',
        styleClass: 'col-sm-12',
      },
      {
        label: 'specifyDetails',
        value: "",
        type: 'text',
        placeholder: '',
        field:'accomodationNeededData',
        validators: [],
        required: true,
        styleClass: 'col-sm-6',
        isDisplayed: true,
        showRedAsterik: true
      },
      {
        label: 'flightNeeded',
        selected: false,
        type: 'checkbox',
        field: 'flightNeeded',
        styleClass: 'col-sm-12',
      },
      {
        label: 'specifyDetails',
        value: "",
        type: 'text',
        placeholder: '',
        field:'flightNeededData',
        validators: [],
        required: true,
        styleClass: 'col-sm-6',
        isDisplayed: true,
        showRedAsterik: true
      },
      {
        label: 'visitMuseum',
        selected: false,
        type: 'checkbox',
        field: 'visitMuseumOfOptics',
        styleClass: 'col-sm-12',
      },
      {
        label: 'specifyDetails',
        value: "",
        type: 'text',
        placeholder: '',
        field:'visitMuseumOfOpticsData',
        validators: [],
        required: true,
        styleClass: 'col-sm-6',
        isDisplayed: true,
        showRedAsterik: true
      },
      {
        label: 'descisionMaker',
        value: '',
        type: 'enhanced-RadioButton',
        placeholder: '',
        field: 'descisionMaker',
        validators: [],
        required: true,
        styleClass: 'col-sm-3 col-md-12 mt-3',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },
]
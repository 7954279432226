import React, { useCallback } from 'react';
import cn from 'classnames';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';

const DelayedTransactionsFilter = ({
  isShowFilter,
  businessUnits,
  countries,
  loanTypes,
  stocks,
  filters,
  setFilters,
}) => {

  const handleFilter = useCallback((id, field, value) => {
    const idValue = Array.isArray(id) ? [...id] : id;
    const newFilters = {
      ...filters,
      [field]: idValue,
      [`${field}FullValue`]: value,
    }
    setFilters(newFilters);
  } , [filters, setFilters]);

  return (
    <div className={cn('filter-wrapper mt-3', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <Dropdown
            label={strings.businessUnit}
            data={businessUnits}
            field='businessGroup'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-3 mb-3'
            value={filters.businessGroup}
            fullValue={filters.businessGroupFullValue}
          />
          <Dropdown
            label={strings.countryLand}
            data={countries}
            filter
            field='country'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={false}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-3 mb-3'
            value={filters.country}
            fullValue={filters.countryFullValue}
          />
          <Dropdown
            label={strings.stock}
            data={stocks}
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            idKey='id'
            field='stock'
            displayValue={[{ key: 'locationName', separator: '' }]}
            isRequired={false}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-3 mb-3'
            value={filters.stock}
            fullValue={filters.stockFullValue}
          />
          <Dropdown
            label={strings.loanType}
            data={loanTypes}
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            idKey='id'
            field='loanTypes'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            multiSelect={false}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-3 mb-3'
            value={filters.loanTypes}
            fullValue={filters.loanTypesFullValue}
          />
        </div>
      </div>
    </div>
  );
}

export default DelayedTransactionsFilter;

import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import { useAdditionalInformation } from './AdditionalInformation.hook';

export const AdditionalInformation = (props) => {
  const {formValue} = props;
  const { additionalInformationForm } = formValue;

  const {
    handleNonFormComponent
  } = useAdditionalInformation(props);

    return (
      <>
        <h2 className='mt-3 zmcc-title-heading'>{strings.additionalInformation.toUpperCase()}</h2>
        <div className='mt-3 '>
          <InputField
            label={strings.detailedComments}
            field='detailedCommentsAdditionalInformation'
            formName='additionalInformationForm'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={additionalInformationForm.formData.detailedCommentsAdditionalInformation}
            error={additionalInformationForm.formData.detailedCommentsAdditionalInformationError}
            onChange={(e) => handleNonFormComponent(e, 'detailedCommentsAdditionalInformation', 'additionalInformationForm')}
            bigSizeInput={'bigger-input-field'}
            hideOptionalText={true}
          />
        </div>
      </>
    );
}

export default AdditionalInformation;
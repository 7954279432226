import { xbMainApplicationChecklist } from "./XBApplicationSampleDetails.constants";


export const useXBApplicationSampleDetails = (props) =>{
    const handleForm = ({ values, field, formName }) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              ...values,
            },
          },
        });
        props.setUnsavedChanges(true);
      };
    
      const handleNonFormComponent = (values, field, formName) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              [field]: values,
              [`${field}Error`]: null,
            },
          },
        });
      };

      const xbMainApplicationModel = () => {
        const model = [...xbMainApplicationChecklist];
        return model;
      };
    
      return {
        handleForm,
        handleNonFormComponent,
        xbMainApplicationModel
      };
}
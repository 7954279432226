import React, { Component } from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Steps from 'library/common/commonComponents/Steps/Steps';
import AlternateSlotMessage from 'modules/Calendar/component/AlternateSlotMessage/AlternateSlotMessage';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import { firstToUpperCase } from 'library/utilities/stringUtils';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { checkStockForRole } from 'library/utilities/checkStockForRole';
import Validators from 'library/utilities/Validators';
import { fetchFromStorage } from 'library/utilities/storage';

import AssignmentStep from './Components/AssignmentStep';
import CustomerDataStep from './Components/CustomerDataStep';
import ConfirmationStep from './Components/ConfirmationStep';
import {
  borrowerModel,
  componentsModel,
  descriptionModelKitLoan,
  descriptionModelSingleLoan,
  descriptionModelCourseLoan,
  typeOfDeliveryAddressModel,
  deliveryCommentModel,
  deliveryAddressModel,
  referenceToSapERPField,
  helpForBuildingAndDismantlingModel,
  reservationPeriodModelSingleLoan,
  reservationPeriodModelCourseLoan,
  taskIdModel,
  showLoanValueFieldModel,
  pickUpCommentModel,
  campaignIdModelForm
} from './kitLoanFormModel';
import $ from 'jquery';
import { LOAN_TYPES } from './KitLoan.constants';


class KitLoan extends Component {
  constructor(props) {
    super(props);
    let loanType = 'kitLoan';
    if (props.location.pathname.includes('single')) {
      loanType = 'singleLoan';
      document.title = 'Single Loan';
    } else if (props.location.pathname.includes('course')) {
      loanType = 'courseLoan';
      document.title = 'Course Loan';
    } else {
      document.title = 'Kit Loan';
    }
    this.state = {
      loanType,
      reservationPeriodForm: {
        formData: {
          reservationFrom: props.location.state ? props.location.state.fromDate : null,
          reservationTo: props.location.state ? props.location.state.toDate : null,
        },
      },
      borrowerForm: {
        formData: {
          borrowerId: props.user && props.user.accountId,
          borrowerIdFullValue: props.user,
          borrowerIdError: props.user && !!props.user.accountId ? '' : 'requiredField',
        },
        isFormValid: props.user && !!props.user.accountId,
      },
      descriptionForm: {
        formData:
          loanType === 'courseLoan'
            ? {
                reasonForLoanId: 1,
                reasonForLoanIdError: null,
                reasonForLoanIdFullValue: { id: 1, reasonForLoanId: 1, name: 'Course', deleted: false },
              }
            : {},
        isFormValid: false,
      },
      componentsForm: {
        formData: {
          stock: props.location.state ? props.location.state.stocks : null,
          stockFullValue: props.location.state ? props.location.state.stocksFullValue : null,
        },
        isFormValid: false,
      },
      typeOfDeliveryAddressForm: {
        formData: {},
        isFormValid: false,
      },
      deliveryAddressForm: {
        formData: {},
        isFormValid: false,
      },
      deliveryCommentForm: {
        formData: {},
        isFormValid: false,
      },
      helpForBuildingAndDismantlingForm: {
        formData: {},
        isFormValid: false,
      },
      campaignIdForm: {
        formData: {},
        isFormValid: false,
      },
      showCampaignIdFormBasedOnReasonForLoan: loanType === LOAN_TYPES.COURSE_LOAN ? true: false,
      showCampaignIdIfWorkflowEnabled: false,
      addresses: [],
      loanAttachments: [],
      additionalData: null,
      selectedKits: null,
      isShowCheckbox: false,
      activeStep: 1,
      isEditTypeOfDeliveryAddress: false,
      leadTime: null,
      opportunities: [],
      isOpportunityIdCrmError: false,
      enteredOpportunityId: {
        value: '',
        error: '',
      },
      isShowLoanValueField: false,
      currency: null,
      isShowLoanValueFieldForm: {
        formData: {},
        isFormValid: false,
      },
      kitLoanMaterialNumberAndDescription: null,
      showFormAdditionalSingleActivated: false,
      medUsAddressDropDownList: null,
      courseLoanMedStock: false,
      showCalenderBlockPopup: false,
      blockTransactionList: [],
      pickUpComment: false,
      pickUpCommentForm: {
        formData: {},
        isFormValid: false,
      },
      courseLoanMedTaiwan: false,
      adaptedLoanReasons: [],
      allProvinces: [],
      stateHasSpecialDeliveryTime: false,
      stateOrProvinceDeliveryDays : {deliveryDays: 0, returnDeliveryDays:0, reconditioningDays:0},
      isInfoHasAttributesState: false
    };
  }

  async componentDidMount() {
    const { fetchMasterData, fetchDataForKitLoan, fetchCartDataFromLocalStorage, masterData} = this.props;
    fetchDataForKitLoan();
    fetchCartDataFromLocalStorage();

    if (this.state.loanType === 'courseLoan') {
      await fetchMasterData(URLS.countryList + '?loantype=course-loan', actionTypes.FETCH_COUNTRY_LIST);
    } else if (this.state.loanType === 'singleLoan') {
      await fetchMasterData(URLS.countryList + '?loantype=single-loan', actionTypes.FETCH_COUNTRY_LIST);
    } else {
      await fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
    }
    this.fetchUserAddresses();

    if (this.state.loanType === 'kitLoan') {
      this.fillCrmData();
    }
    if (this.state.selectedKits && this.state.selectedKits.length) {
      this.getRefurbishmentValues();
    }
    this.getAndSetAdaptedLoanReasons({loantype: LOAN_TYPES.KIT_LOAN, stockId: 0});
    if (!masterData.activeProvinceCountries.length){
      this.props.getAllActiveProvinceCountries();
    }
  }

  componentDidUpdate(prevProps) {
    const { loanType, selectedKits } = this.state;
    const {language, fetchMasterData, fetchDataForKitLoan} = this.props;
    if (loanType === 'kitLoan' && !selectedKits && this.props.cart && this.props.cart.length) {
      const selectedKits = this.props.cart.filter(item => item.isSelected === true);
      this.setState({ selectedKits }, () => {
        this.fetchAdditionalLoanRequest(selectedKits);
      });
      this.checkShowingCheckbox({ selectedKits });
      this.getAndSetAdaptedLoanReasons({loantype: LOAN_TYPES.KIT_LOAN, stockId: 0});
    }
    if (language !== prevProps.language) {
      fetchDataForKitLoan();
      if (loanType === 'courseLoan') {
        fetchMasterData(URLS.countryList + '?loantype=course-loan', actionTypes.FETCH_COUNTRY_LIST);
      } else if (loanType === 'singleLoan') {
        fetchMasterData(URLS.countryList + '?loantype=single-loan', actionTypes.FETCH_COUNTRY_LIST);
      } else {
        fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
      }
    }

    if (prevProps.allProvinces && this.state.allProvinces && prevProps.allProvinces !== this.state.allProvinces) {
      this.setState({
        allProvinces: this.state.allProvinces,
        deliveryAddressForm: {
          ...this.state.deliveryAddressForm,
          formData: {
            ...this.state.deliveryAddressForm.formData,
            province: ''
          }
        }
      });
    }
  }

  getAndSetAdaptedLoanReasons = ({loantype, stockId}) => {

    const {loanType} = this.state
    if(loanType === LOAN_TYPES.SINGLE_LOAN && stockId != 0){
      const {fetchAdaptedLoanReasonForStock} = this.props
      if(fetchAdaptedLoanReasonForStock){
        fetchAdaptedLoanReasonForStock(stockId).then(res => {
          const {success, data} = res
          this.setState({adaptedLoanReasons: data})
        }).catch(err => {
          console.log(err)
          this.setState({adaptedLoanReasons: []})
        })
      }
    }

    const {selectedKits} = this.state
    // Set the loan reasons of the first kit's stock
    if(selectedKits?.length >= 1){
      if(selectedKits[0]?.kit?.stockInformation?.id){
        const stockInformationId = selectedKits[0].kit.stockInformation.id;
        const {fetchAdaptedLoanReasonForStock} = this.props
        fetchAdaptedLoanReasonForStock(stockInformationId).then(res => {
          const {success, data} = res
          this.setState({adaptedLoanReasons: data})
        }).catch(err => {
          console.log(err)
          this.setState({adaptedLoanReasons: []})
        })
      }
    }

  }

  trimOpportunityId = crmData => {
    const {opportunityIdCRM} = crmData;
    if (!opportunityIdCRM) return [];

    if (opportunityIdCRM.length === 10) {
      return [{ opportunityNumber: opportunityIdCRM.slice(1) }];
    }

    return [{ opportunityNumber: opportunityIdCRM }];
  };

  fillCrmData = () => {
    const crmData = fetchFromStorage(identifiers.crmParams);

    if (crmData) {
      const {
        city,
        country,
        department,
        email,
        firstname,
        gender,
        lastname,
        opportunityDescCRM,
        opportunityIdCRM,
        organization,
        phone,
        street,
        taskId,
        zip,
        houseNumber,
        region,
      } = crmData;
      const opportunitiesOfCRM = this.trimOpportunityId(crmData);
      this.setState({
        typeOfDeliveryAddressForm: {
          formData: {
            deliveryAddress: 'new',
          },
          isFormValid: false,
        },
        deliveryAddressForm: {
          formData: {
            salutation: gender === '01' ? 'ms' : 'mr',
            firstName: firstname ? firstname.replaceAll('_', ' ') : '',
            lastName: lastname ? lastname.replaceAll('_', ' ') : '',
            organization: organization ? organization.replaceAll('_', ' ') : '',
            department: department ? department.replaceAll('_', ' ') : '',
            street: street ? street.replaceAll('_', ' ') : '',
            houseNumber: houseNumber ? houseNumber.replaceAll('_', ' ') : '',
            postalCode: zip ? zip.replaceAll('_', ' ') : '',
            city: city ? city.replaceAll('_', ' ') : '',
            state: region ? region.replaceAll('_', ' ') : '',
            countryId: country ? this.getCountryIdByName(country) : null,
            phone: phone ? phone.replaceAll('_', ' ') : '',
            email: email,
          },
          isFormValid: false,
        },
        descriptionForm: {
          formData: {
            crmTaskId: taskId,
            name: opportunityDescCRM ? opportunityDescCRM.replaceAll('_', ' ') : '',
          },
          isFormValid: false,
        },
        opportunities: opportunitiesOfCRM,
      });
    }
  };

  getCountryIdByName = countryName => {
    const { masterData } = this.props;
    const { countries } = masterData;
    const country = countries.find(item => ((item.shortName.toUpperCase() === countryName.toUpperCase()) || (item.name.toUpperCase() === countryName.toUpperCase()))) || {};

    return country.id;
  };

  checkShowingCheckbox = async ({ selectedKits, stockId }) => {
    if (selectedKits && selectedKits.length) {
      const stockIds = selectedKits.map(item => item.kit.stockInformation.id);
      const statusArr = await Promise.all(stockIds.map(async key => await this.handleWorkflowData(key)));
      const isCheck = statusArr.some(status => status);
      if (isCheck) {
        this.setState({ isShowCheckbox: true });
      }
    } else if (stockId) {
      const isShowCheckbox = await this.handleWorkflowData(stockId);
      this.setState({ isShowCheckbox });
    }
  };

  checkShowCampaignIdEnableForWarehosue = (success, stockWorkflowSettings) =>{
    let found = false;
    const {selectedKits} = this.state;
    if(success && stockWorkflowSettings?.stockWorkflowDTOS?.length){
      let stockWorkflowSettingDTO = stockWorkflowSettings.stockWorkflowDTOS;
      stockWorkflowSettingDTO = stockWorkflowSettingDTO[0]?.stockWorkflowSettingDTO
      stockWorkflowSettingDTO.forEach((item, index) => {
        if(item?.categoryQuestionId === 37 && item?.categoryAnswer === "yes"){
          found = true;
        }
      })
    }

    const {loanType} = this.state;
    if(loanType === LOAN_TYPES.COURSE_LOAN){
      this.setState((prev) => {
        return {...prev, showCampaignIdIfWorkflowEnabled: found}
      })
      if(!found){
        this.handleForm({ values: {campaignId: '', campaignIdError: null}, field:"campaignId", formName:"campaignIdForm"})
      }
      return;
    }

    if(found){
      this.setState((prev) => {
        return {...prev, showCampaignIdIfWorkflowEnabled: prev.showCampaignIdIfWorkflowEnabled || found}
      })
    }
  }

  handleWorkflowData = async stockId => {
    const { loanType } = this.state;
    const { fetchWorkflowData } = this.props;
    const { success, data: stockWorkflowSettings } = await fetchWorkflowData(stockId);
    let index = 1;
    let workflowId = 14;
    let questionId;
    if (loanType === 'singleLoan') {
      index = 2;
      workflowId = 15;
      questionId = 17;
    } else if (loanType === 'courseLoan') {
      index = 3;
      workflowId = 16;
      questionId = 18;
    }
    this.checkShowCampaignIdEnableForWarehosue(success, stockWorkflowSettings)
    if (
      success &&
      stockWorkflowSettings &&
      stockWorkflowSettings.stockWorkflowDTOS &&
      stockWorkflowSettings.stockWorkflowDTOS.length > 0 &&
      stockWorkflowSettings.stockWorkflowDTOS[0] &&
      stockWorkflowSettings.stockWorkflowDTOS[0].stockWorkflowSettingDTO &&
      stockWorkflowSettings.stockWorkflowDTOS[0].stockWorkflowSettingDTO.length
    ) {
      const isShowLoanValueField =
        stockWorkflowSettings.stockWorkflowDTOS[0].stockWorkflowSettingDTO[2].categoryAnswer === 'yes';
      const currency = stockWorkflowSettings.currency && stockWorkflowSettings.currency.value;
      const pickUpComment = stockWorkflowSettings.stockWorkflowDTOS[0].stockWorkflowSettingDTO[5].categoryAnswer === 'yes' && stockWorkflowSettings.pickUpComment === true;

      this.setState({ isShowLoanValueField, currency, pickUpComment });
    }
    if (
      success &&
      stockWorkflowSettings &&
      stockWorkflowSettings.stockWorkflowDTOS &&
      stockWorkflowSettings.stockWorkflowDTOS.length &&
      stockWorkflowSettings.stockWorkflowDTOS[index] &&
      stockWorkflowSettings.stockWorkflowDTOS[index].stockWorkflowSettingDTO &&
      stockWorkflowSettings.stockWorkflowDTOS[index].stockWorkflowSettingDTO.length
    ) {
      let stockWorkflowDTOS = stockWorkflowSettings.stockWorkflowDTOS;
      const foundItem = stockWorkflowDTOS[index].stockWorkflowSettingDTO.find(
        item => item.categoryQuestionId === workflowId,
      );
      const leadTimeItem =
        stockWorkflowDTOS[index].stockWorkflowSettingDTO.find(item => item.categoryQuestionId === questionId) || {};
      this.setState({ leadTime: leadTimeItem.categoryAnswer });
      return foundItem ? foundItem.categoryAnswer === 'yes' : false;
    }
  };

  fetchAdditionalLoanRequest = async selectedKits => {
    const { additionalLoanRequestIsPossible, sapERPIsPossible } = await this.props.fetchAdditionalLoanRequest(
      selectedKits,
    );
    this.setState({ additionalLoanRequestIsPossible, sapERPIsPossible });
  };

  fetchUserAddresses = async () => {
    const {
      borrowerForm: { formData },
    } = this.state;
    if (formData && formData.borrowerId) {
      const { addresses, addressesForDropdown, userAddress } = await this.props.fetchBorrowerAddresses(
        formData.borrowerId,
      );
      this.handleForm({
        values: {
          userAddress,
        },
        formName: 'borrowerForm',
        field: 'address',
      });
      this.setState({ addresses, addressesForDropdown });
      this.fetchProvinces(userAddress.countryId);
    }
  };

  getValues = ({ field, fullValue, type, validatedValue }) => {
    if (type === 'dropdown') {
      return {
        [field]: validatedValue.value,
        [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
        [`${field}FullValue`]: fullValue,
      };
    }
    return {
      [field]: validatedValue.value,
      [`${field}Error`]: validatedValue.error && validatedValue.error.message ? validatedValue.error.message : null,
    };
  };

  validate = (value, field, validators) => {
    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message);
      if (error) {
        return { value, error };
      }
    }
    return { value, error: null };
  };

  validateForm = async ({ form, formName, model }) => {
    const newFormData = validateForm({ form, formName, model });
    await this.updateForm({ [formName]: newFormData });
    return newFormData;
  };

  addLoanAttachmentFile = file => {
    this.setState(prevState => ({
      loanAttachments: [...prevState.loanAttachments, file[0]],
    }));
  };

  removeLoanAttachmentFile = index => {
    const loanAttachments = [...this.state.loanAttachments];
    loanAttachments.splice(index, 1);
    this.setState({ loanAttachments });
  };

  updateDateOfCartItem = async (item, date, dateType, type, index) => {
    const { loanType } = this.state;
    if (loanType === 'kitLoan') {
      const countryId =
        (this.state.deliveryAddressForm.formData && this.state.deliveryAddressForm.formData.countryId) || 0;
      if (countryId) {
        this.props.checkAvailabilityOfKit(countryId, 0);
      }
      const selectedKits = await this.props.updateDateOfCartItem(item, date, dateType);
      this.setState({ selectedKits });
    } else {
      const { selectedKits } = this.state;
      selectedKits[index] = {
        ...selectedKits[index],
        [dateType]: date,
      };
      this.setState({ selectedKits });
    }
  };

  resetBorrowerData = () => {
    this.setState(prevState => ({
      typeOfDeliveryAddressForm: {
        isFormValid: false,
        formData: {},
      },
      deliveryAddressForm: {
        isFormValid: false,
        formData: {},
      },
    }));
  };

  checkForReasonForLoanValueToShowCampaignId = (formName) => {
    //Now we need to check if we have any resaonForLoan
    // If reasonForLaon is  Trade fair, course, workshop, Demo for SSC showroom then it is should show
    const {descriptionForm: {formData}} = this.state
    const {reasonForLoanId, reasonForLoanIdError, reasonForLoanIdFullValue} = formData;
    const allowedReasonForLoanIds = [12, 1, 13, 3]
    const allowedFixedCodes = ['i', 'a', 'j', 'c']
    let show = false;
    if(allowedReasonForLoanIds.indexOf(reasonForLoanId) != -1){
      if(reasonForLoanIdFullValue?.fixedCode && allowedFixedCodes.indexOf(reasonForLoanIdFullValue.fixedCode) != -1){
        show = true
      }
    }
    this.setState(prevState => {
      return {
        ...prevState,
        showCampaignIdFormBasedOnReasonForLoan: show,
      };
    })
    this.handleForm({ values: {campaignId: '', campaignIdError: null}, field:"campaignId", formName:"campaignIdForm"})
  }

  fetchProvinces = async (countryId) => {
    const provinceCountries = this.props.masterData?.activeProvinceCountries;
    if(provinceCountries.includes(countryId)){
      const {success, data} = await this.props.getProvincesOfCountry(countryId);
      let tempProvincesData =[];
      tempProvincesData= data;
      if (this.state.deliveryAddressForm?.formData?.state && !tempProvincesData.some(option => option.stateName === this.state.deliveryAddressForm.formData.state)) {
        tempProvincesData.push({ stateName: this.state.deliveryAddressForm.formData.state });
      }  
      if (success) {
        this.setState({
          allProvinces: data,
          deliveryAddressForm: {
            ...this.state.deliveryAddressForm,
            formData: {
              ...this.state.deliveryAddressForm.formData,
              province: ''
            }
          }
        });
        if (tempProvincesData.some(option => option.stateName === this.state.deliveryAddressForm.formData.state)){
          this.checkStateHasSpecialDeliveryTime(this.state.deliveryAddressForm.formData.state);
        }
      }
    } else{
      this.setState({
        allProvinces: [],
        deliveryAddressForm: {
          ...this.state.deliveryAddressForm,
          formData: {
            ...this.state.deliveryAddressForm.formData,
            province: ''
          }
        }
      });
    }
  }

  checkStateHasSpecialDeliveryTime = async (stateName) => {
    const {selectedKits, allProvinces, loanType, deliveryAddressForm, componentsForm} = this.state;
    let stockId;
    if (loanType == 'singleLoan'){
      stockId = componentsForm?.formData?.stock;
    } else {
      stockId = selectedKits?.[0]?.kit?.stockInformation?.id;
    }
    let reconditioningDeliveryDays = selectedKits?.[0]?.kit?.reconditioningDeliveryDays;
    const countryId = this.state.deliveryAddressForm?.formData?.countryId;
    const stateDetails = allProvinces.find(province => province.stateName == stateName);

    if (stockId && countryId && stateDetails?.stateId){
      this.props.checkAvailabilityOfKit(countryId, stateDetails.stateId);
      const {success, data} = await this.props.fetchStateHasSpecialDeliveryTime(stockId, this.state.deliveryAddressForm?.formData?.countryId, stateDetails.stateId);
      if (success){
        this.setState({ stateHasSpecialDeliveryTime : true});
        this.setState({ isInfoHasAttributesState: true}); 
        this.setState({ stateOrProvinceDeliveryDays : {deliveryDays: data.deliveryDays, returnDeliveryDays: data.returnDeliveryDays, reconditioningDays :reconditioningDeliveryDays} });
      } else{
        this.setState({ stateHasSpecialDeliveryTime: false, isInfoHasAttributesState: false });
      }
    } else{
      this.setState({ stateHasSpecialDeliveryTime: false, isInfoHasAttributesState: false });
      this.setState({ stateOrProvinceDeliveryDays : {deliveryDays: 0, returnDeliveryDays:0, reconditioningDays: 0}});
    }
  }

  handleForm = ({ values, field, formName }) => {
    let borrowerId;
    this.setState(
      prevState => {
        if (field === 'borrowerId') {
          borrowerId = prevState.borrowerForm.formData && prevState.borrowerForm.formData.borrowerId;
        }
        return {
          [formName]: {
            ...prevState[formName],
            formData: {
              ...((prevState[formName] && prevState[formName].formData) || {}),
              ...values,
            },
          },
        };
      },
      () => {
        if (field === 'borrowerId') {
          if (borrowerId !== values.borrowerId) {
            this.resetBorrowerData();
          }
          this.fetchUserAddresses();
        }
        if (field === 'deliveryAddress') {
          this.fetchProvinces(values['deliveryAddressFullValue']['countryId']);
          this.setState(prevState => ({
            deliveryAddressForm: {
              ...prevState.deliveryAddressForm,
              formData: {
                ...values[`${field}FullValue`],
                editButtonVisible: true,
              },
            },
          }));
        }
        if (field === 'countryId') {
          const {allProvinces} = this.state;
          if (this.props.masterData?.activeProvinceCountries.includes(values.countryId)){
            this.fetchProvinces(values.countryId);
            const stateDetails = allProvinces.find(province => province.stateName == this.state.deliveryAddressForm?.formData?.state);
            this.checkStateHasSpecialDeliveryTime(stateDetails);
            if (stateDetails){
              this.props.checkAvailabilityOfKit(values.countryId, stateDetails?.stateId ? stateDetails?.stateId : 0);
            } 
          } else{
            this.setState(prevState =>({
              deliveryAddressForm: {
                formData: {
                  ...prevState.deliveryAddressForm.formData,
                  // state: ''
                },
                isFormValid: prevState.deliveryAddressForm.isFormValid,
              },
            }))   
            this.props.checkAvailabilityOfKit(values.countryId, 0);       
          }
        }

        if (field === 'state') {
          const countryId = this.state.deliveryAddressForm?.formData?.countryId;
          if (this.props.masterData?.activeProvinceCountries.includes(countryId)){
            this.checkStateHasSpecialDeliveryTime(values.state);
          }
        }
        if (this.state.loanType === 'singleLoan' && field === 'stock') {
          this.setState(prevState =>({
            descriptionForm: {
              formData: {
                ...prevState.descriptionForm.formData,
               reasonForLoanId: '',
               reasonForLoanIdError: null,
               reasonForLoanIdFullValue: {}
              },
              isFormValid: prevState.descriptionForm.isFormValid,
            },
          }))
          const {stock} = values
          this.getAndSetAdaptedLoanReasons({stockId: stock})

        }
        if(formName === 'descriptionForm' && field === "reasonForLoanId"){
          this.checkForReasonForLoanValueToShowCampaignId(formName)
        }
        if (formName === 'componentsForm' && field === 'stock') {
          this.checkShowingCheckbox({ stockId: values.stock });
          this.getRefurbishmentValues({stockId: values.stock });
        }
      },
    );
  };

  updateForm = async data => {
    await this.setState({ ...data });
  };

  reservationPeriodModel = () => {
    const { formData } = this.state.reservationPeriodForm;
    let model =
      this.state.loanType === 'singleLoan'
        ? [...reservationPeriodModelSingleLoan]
        : [...reservationPeriodModelCourseLoan];
    if (formData && formData.reservationFrom) {
      model = [model[0], { ...model[1], minDate: formData.reservationFrom }];
    }
    if (formData && formData.reservationTo) {
      model = [{ ...model[0], maxDate: formData.reservationTo }, model[1]];
    }
    return model;
  };

  descriptionModel = () => {
    const { additionalLoanRequestIsPossible, loanType, sapERPIsPossible, courseLoanMedStock, courseLoanMedTaiwan  } = this.state;
    const { reasonForLoan } = this.props.masterData;
    let model = [...descriptionModelKitLoan];
    const crmData = fetchFromStorage(identifiers.crmParams);
    const { taskId } = crmData || {};

    if (loanType === 'kitLoan') {
      if (sapERPIsPossible) {
        model.push(referenceToSapERPField);
      }
      if (taskId) {
        model.push(taskIdModel);
      }
      if (loanType !== 'courseLoan' && this.checkMedusStock(null)) {
        model[1].placeholder = ''
        model[1].label = 'commentText'
      }else if (loanType !== 'courseLoan' && this.checkMedTaiwanWarehouse(null)) {
        model[1].label = 'commentTextForMedTaiwan';
        model[1].placeholder = ''
      }else{
        model[1].label = 'commentText';
        model[1].placeholder = 'commentPlaceholderForBookings'
      }
    } else if (loanType === 'singleLoan') {
      model = [...descriptionModelSingleLoan];
      if (loanType !== 'courseLoan' && this.checkMedusStock(null)) {
        model[1].placeholder = ''
        model[1].label = 'commentText'
      }else if (loanType !== 'courseLoan' && this.checkMedTaiwanWarehouse(null)) {
        model[1].label = 'commentTextForMedTaiwan';
        model[1].placeholder = ''
      }else{
        model[1].label = 'commentText';
        model[1].placeholder = 'commentPlaceholderForBookings'
      }
    } else if (loanType === 'courseLoan') {
      model = [...descriptionModelCourseLoan];
      if (courseLoanMedStock) {
        model[2].placeholder = ''
        model[2].label = 'commentText'
      }else if (courseLoanMedTaiwan) {
        model[2].label = 'commentTextForMedTaiwan';
        model[2].placeholder = ''
      }else{
        model[2].label = 'commentText';
        model[2].placeholder = 'commentPlaceholderForBookings'
      }
    }
    if (reasonForLoan && reasonForLoan.length) {
       if (loanType !== 'courseLoan' && this.checkMedusStock(null)) {
        model[0].options = reasonForLoan.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'k' && item.fixedCode !== 'e' && item.fixedCode !== 'f' && item.fixedCode !== 'a');
      }else{
        model[0].options = reasonForLoan.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'm' && item.fixedCode !== 'n' && item.fixedCode !== 'o');
      }
      if(this.state.adaptedLoanReasons.length > 0){
        if (loanType !== 'courseLoan' && this.checkMedusStock(null)) {
          model[0].options = this.state.adaptedLoanReasons.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'k' && item.fixedCode !== 'e' && item.fixedCode !== 'f' && item.fixedCode !== 'a');
        }else{
          model[0].options = this.state.adaptedLoanReasons.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'm' && item.fixedCode !== 'n' && item.fixedCode !== 'o');
        }
      }

    }
    return model;
  };
  componentsModel = () => {
    const { masterData, user } = this.props;
    const model = [...componentsModel];
    if (masterData.countries && masterData.countries.length) {
      model[0].options = checkStockForRole(user, masterData.countries, 'outbound staff');
    }
    return model;
  };
  borrowerModel = usersList => {
    const model = [...borrowerModel];
    if (usersList && usersList.length) {
      model[0].options = usersList;
    }
    return model;
  };

  typeOfDeliveryAddressModel = () => {
    const model = [...typeOfDeliveryAddressModel];
    if (this.state.addressesForDropdown && this.state.addressesForDropdown.length) {
      model[0].options = this.state.addressesForDropdown;
    }
    return model;
  };

  deliveryAddressModel = (countries, allProvinces, deliveryAddressForm) => {
    const {stateHasSpecialDeliveryTime, isInfoHasAttributesState , stateOrProvinceDeliveryDays} = this.state;
    const model = [...deliveryAddressModel];
    if(this.props.masterData?.activeProvinceCountries.includes(this.state.deliveryAddressForm?.formData?.countryId)){
      model[9] = {
        label: 'stateOrProvince',
        value: '',
        type: 'dropdown',
        placeholder: '',
        field: 'state',
        validators: [],
        required: false,
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: true,
        idKey: 'stateName',
        displayValue: [{ key: 'stateName', separator: '' }],
        excludeData: [],
        styleClass: 'col-sm-4',
        extraProps: {
          maxLength: 50,
        },
        showExtraInfo: stateHasSpecialDeliveryTime,
        infoMessage : `${
          strings.msgForStateOrProvinceSpecialDeliveryTime(stateOrProvinceDeliveryDays.deliveryDays, stateOrProvinceDeliveryDays.returnDeliveryDays, stateOrProvinceDeliveryDays.reconditioningDays)
        }`,
        isInfoHasAttributes: true,
        disabled: false
      }
      model[9].options = allProvinces;
      if (this.state.deliveryAddressForm?.formData?.state && !allProvinces.some(option => option.stateName === this.state.deliveryAddressForm.formData.state)) {
        model[9].options.push({ stateName: this.state.deliveryAddressForm.formData.state, stateId : 0 });
      }
    } else if ((this.state.typeOfDeliveryAddressForm?.formData?.deliveryAddress === 'new' || this.state.typeOfDeliveryAddressForm?.formData?.id === 'new') && !this.state.deliveryAddressForm?.formData?.countryId){
      model[9].disabled = true;
    } else if ((this.state.typeOfDeliveryAddressForm?.formData?.deliveryAddress === 'new' || this.state.typeOfDeliveryAddressForm?.formData?.id === 'new') && this.state.deliveryAddressForm?.formData?.countryId){
      model[9].disabled = false;
      model[9].showExtraInfo = false;
    } else if (this.state.typeOfDeliveryAddressForm?.formData?.deliveryAddress !== 'new'){
      model[9].disabled = false;
    }
    if (countries && countries.length) {
      model[10].options = countries;
    }
    return model;
  };

  onSelectCourseItem = item => {
    const selectedKits = this.state.selectedKits || [];
    selectedKits.push({ ...item, kit: { stockInformation: { id: item.stock.id } } });
    this.setState({ selectedKits }, () => {
      this.checkShowingCheckbox({ selectedKits });
    });
  };
  onSelectSingleItem = item => {
    const selectedKits = this.state.selectedKits || [];
    selectedKits.push({ ...item });
    this.setState({ selectedKits });
  };

  onAddKitloanMaterialNumber = item => {
    const kitLoanMaterialNumberAndDescription = this.state.kitLoanMaterialNumberAndDescription || [];
    kitLoanMaterialNumberAndDescription.push({ ...item });
    this.setState({ kitLoanMaterialNumberAndDescription });
  };

  isFormOpen = value => {
    this.setState({showFormAdditionalSingleActivated: value})
  }
  setIsOpportunityIdCrmError = isError => {
    this.setState({ isOpportunityIdCrmError: isError });
  };

  validateForms = async formsToValidate => {
    const { opportunities } = this.state;
    const isRequiredOpportunityID = this.checkRequiredOpportunityId();
    let campaignIdError = false
    let error = false;
    await Promise.all(
      formsToValidate.map(async ({ form, formName, model }) => {
        const valid = await this.validateForm({ form, formName, model });
        if (!valid.isFormValid) {
          error = true;
          if(formName === "campaignIdForm"){
            campaignIdError = true;
          }
        }
      }),
    );
    if (isRequiredOpportunityID && !opportunities.length) {
      this.setIsOpportunityIdCrmError(true);
      error = true;
    }
    if (!error) {
      return '';
    } else if (this.state.activeStep === 2 && this.state.borrowerForm.isFormValid) {
      return 'formInvalidBookingStep2';
    } else if(campaignIdError){
      return 'enterValidCampignId'
    }else {
      return 'formIsInvalid';
    }
  };

  checkLeadTimeOfBooking = () => {
    const { loanType, selectedKits, leadTime, reservationPeriodForm } = this.state;
    let isValidDate = true;
    let errorMessage;
    const week = 7;
    const isDays = leadTime % week;
    if (loanType === 'singleLoan') {
      isValidDate = Validators.date(reservationPeriodForm.formData.reservationFrom, leadTime);
      errorMessage = isDays ? 'singleLoanBookedDays' : 'singleLoanBookedWeeks';
    }
    if (loanType === 'courseLoan' && selectedKits.length) {
      isValidDate = Validators.date(selectedKits[0].reservationFrom, leadTime);
      errorMessage = isDays ? 'courseLoanBookedDays' : 'courseLoanBookedWeeks';
    }

    return { isValidDate, errorMessage };
  };

  showLeadTimeOfBookingError = errorMessage => {
    const { toggleActionMessage } = this.props;
    const { leadTime } = this.state;
    const week = 7;
    const amountOfDaysOrWeeks = leadTime % week ? leadTime : leadTime / week;
    errorMessage = strings[errorMessage](amountOfDaysOrWeeks);

    scrollToTop(500);
    toggleActionMessage(true, 'error', errorMessage, true);
  };

  goToSecondStep = async formsToValidate => {
    const { toggleActionMessage } = this.props;
    const { loanType, selectedKits, enteredOpportunityId, kitLoanMaterialNumberAndDescription, additionalLoanRequestIsPossible,showFormAdditionalSingleActivated } = this.state;
    const error = await this.validateForms(formsToValidate);

    const checkBusinessUnit = () => {
      const {componentsForm} = this.state;
      const businessUnits = ['MED', 'MED-OPT', 'MED-SUR'];
      const singleLoanBusinessUnit = componentsForm && componentsForm.formData && componentsForm.formData.stockFullValue;
      const kitLoanBusinessUnit =
      selectedKits && selectedKits.length > 0 && selectedKits[0] && selectedKits[0].kit;
      const showCustomerGroupField =
        kitLoanBusinessUnit && kitLoanBusinessUnit.stockInformation
          ? businessUnits.includes(kitLoanBusinessUnit.stockInformation.businessUnit.name)
          : businessUnits.includes(singleLoanBusinessUnit && singleLoanBusinessUnit.businessUnit.name);
      return showCustomerGroupField;
    };

    const checkStockCountry = () => {
      const {componentsForm} = this.state;
      const country = ['US'];
      const singleLoanCounty = componentsForm && componentsForm.formData && componentsForm.formData.stockFullValue;
      const kitLoanCountry = selectedKits && selectedKits.length > 0 && selectedKits[0] && selectedKits[0].kit;
      const UsCountry =
        kitLoanCountry && kitLoanCountry.stockInformation
          ? country.includes(kitLoanCountry.stockInformation.country.shortName)
          : country.includes(singleLoanCounty && singleLoanCounty.countryName);
      return UsCountry;
    };

    if (enteredOpportunityId.value) {
      this.handleEnteredOpportunityId({ error: (this.props.user && this.props.user.crmSystemId==='ngcrm'||(checkBusinessUnit() && checkStockCountry())) ? `${strings.pleaseMindThatFieldNeedsToHave(18)}  ${strings.confirmTheId()}` :'pleaseMindThat' });
    }

    if (error) {
      toggleActionMessage(true, 'error', error);
      scrollToTop(500);
    } else {
      let selectedErrorMessage = '';
      if ((loanType === 'kitLoan' || loanType === 'courseLoan') && (!selectedKits || !selectedKits.length)) {
        selectedErrorMessage = 'pleaseSelectKits';
      }
      if (loanType === 'kitLoan' && additionalLoanRequestIsPossible && showFormAdditionalSingleActivated) {
        selectedErrorMessage = 'materialnumberWarning';
        if (selectedErrorMessage) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'materialnumberWarning');
          return;
        }
      }
      if (loanType === 'singleLoan' && (!selectedKits || !selectedKits.length)) {
        selectedErrorMessage = 'materialnumberWarning';
        if (selectedErrorMessage) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'materialnumberWarning');
          return;
        }
      }

      if (selectedErrorMessage) {
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'pleaseSelectKits');
      } else {
        if (loanType === 'courseLoan') {
          this.attachDatesToSelectedKits();
        }
        let selectedError = false;
        if (loanType === 'kitLoan') {
          selectedError = this.checkSelectedKitsKitLoan();
        }
        const { isValidDate, errorMessage } = this.checkLeadTimeOfBooking();
        const isSameStocks = this.checkStocksCourseLoan();
        if (selectedError) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameReservationPeriod');
        } else if (!isValidDate) {
          this.showLeadTimeOfBookingError(errorMessage);
        } else if (!isSameStocks) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameStocks');
        } else if (!enteredOpportunityId.value) {
          if (this.props.appActions.displayActionMessage) {
            this.props.toggleActionMessage(false, '', '');
          }
          this.changeActiveStep(2);
        }
      }
    }
  };

  goToThirdStep = async formsToValidate => {
    const { toggleActionMessage } = this.props;
    const { loanType, selectedKits } = this.state;
    const error = await this.validateForms(formsToValidate);
    if (error) {
      toggleActionMessage(true, 'error', error);
      scrollToTop(500);
    } else {
      let selectedErrorMessage = '';
      if ((loanType === 'kitLoan' || loanType === 'courseLoan') && (!selectedKits || !selectedKits.length)) {
        selectedErrorMessage = 'pleaseSelectKits';
      }
      if (selectedErrorMessage) {
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'pleaseSelectKits');
      } else {
        let selectedError = false;
        const { isValidDate, errorMessage } = this.checkLeadTimeOfBooking();
        const isSameStocks = this.checkStocksCourseLoan();
        if (loanType === 'courseLoan') {
          selectedError = this.checkSelectedKitsCourseLoan();
        } else if (loanType === 'kitLoan') {
          selectedError = this.checkSelectedKitsKitLoan();
        }
        if (selectedError) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameReservationPeriod');
        } else if (!isValidDate) {
          this.showLeadTimeOfBookingError(errorMessage);
        } else if (!isSameStocks) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameStocks');
        } else {
          if (this.props.appActions.displayActionMessage) {
            this.props.toggleActionMessage(false, '', '');
          }
          this.changeActiveStep(3);
        }
      }
    }
  };

  checkSelectedKitsCourseLoan = () => {
    let selectedError = false;
    let reservationFrom;
    let reservationTo;
    this.state.selectedKits.forEach(item => {
      if (!reservationFrom) {
        reservationFrom = item.reservationFrom;
        reservationTo = item.reservationTo;
      } else {
        if (
          moment(reservationTo).format('YYYY-MM-DD') !== moment(item.reservationTo).format('YYYY-MM-DD') ||
          moment(reservationFrom).format('YYYY-MM-DD') !== moment(item.reservationFrom).format('YYYY-MM-DD')
        ) {
          selectedError = true;
        }
      }
    });
    return selectedError;
  };

  checkStocksCourseLoan = () => {
    const { selectedKits, loanType } = this.state;
    let isSameStocks = true;
    if (loanType === 'courseLoan' && selectedKits && selectedKits.length) {
      selectedKits.forEach(item => {
        if (item.stock.id !== selectedKits[0].stock.id) {
          isSameStocks = false;
        }
      });
    }
    return isSameStocks;
  };

  checkSelectedKitsKitLoan = () => {
    let selectedError = false;
    let start;
    let end;
    this.state.selectedKits.forEach(item => {
      if (!start) {
        start = item.start;
        end = item.end;
      } else {
        if (
          moment(end).format('YYYY-MM-DD') !== moment(item.end).format('YYYY-MM-DD') ||
          moment(start).format('YYYY-MM-DD') !== moment(item.start).format('YYYY-MM-DD')
        ) {
          selectedError = true;
        }
      }
    });
    return selectedError;
  };

  executeBooking = async formsToValidate => {
    const { deliveryAddressForm, loanAttachments, loanType, selectedKits, opportunities, enteredOpportunityId, additionalLoanRequestIsPossible, showFormAdditionalSingleActivated, campaignIdModelForm, campaignIdForm } =
      this.state;
    const { toggleActionMessage } = this.props;
    const error = await this.validateForms(formsToValidate);
    if (error) {
    } else if (deliveryAddressForm && deliveryAddressForm.formData && deliveryAddressForm.formData.countryId === 0) {
      this.props.toggleActionMessage(true, 'error', 'noCountryAdded');
      scrollToTop(500);
    } else {
      let selectedErrorMessage = '';
      if (loanType === 'kitLoan') {
      } else if (loanType === 'courseLoan' && (!selectedKits || !selectedKits.length)) {
        selectedErrorMessage = 'pleaseSelectKits';
      }
      if (loanType === 'kitLoan' && additionalLoanRequestIsPossible && showFormAdditionalSingleActivated) {
        selectedErrorMessage = 'materialnumberWarning';
        if (selectedErrorMessage) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'materialnumberWarning');
          return;
        }
      }
      if (selectedErrorMessage) {
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'pleaseSelectKits');
      } else {
        let selectedError = false;
        let reservationFrom;
        let reservationTo;
        selectedKits &&
          selectedKits.forEach(item => {
            if (!reservationFrom) {
              reservationFrom = item.reservationFrom;
              reservationTo = item.reservationTo;
            } else {
              if (
                moment(reservationTo).format('YYYY-MM-DD') !== moment(item.reservationTo).format('YYYY-MM-DD') ||
                moment(reservationFrom).format('YYYY-MM-DD') !== moment(item.reservationFrom).format('YYYY-MM-DD')
              ) {
                selectedError = true;
              }
            }
          });
        const { isValidDate, errorMessage } = this.checkLeadTimeOfBooking();
        const isSameStocks = this.checkStocksCourseLoan();
        if (selectedError) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameReservationPeriod');
        } else if (!isValidDate) {
          this.showLeadTimeOfBookingError(errorMessage);
        } else if (!isSameStocks) {
          scrollToTop(500);
          toggleActionMessage(true, 'error', 'sameStocks');
        } else if (enteredOpportunityId.value) {
          if (this.props.user.crmSystemId === "ngcrm") {
            this.handleEnteredOpportunityId({ error: `${strings.pleaseMindThatFieldNeedsToHave(18)}` });
          }else{
            this.handleEnteredOpportunityId({ error: 'pleaseMindThat' });
          }
        } else {
          let sendData = {};
          const arrayOfForms = [
            'borrowerForm',
            'typeOfDeliveryAddressForm',
            'deliveryAddressForm',
            'deliveryCommentForm',
            'helpForBuildingAndDismantlingForm',
            'descriptionForm',
            'isShowLoanValueFieldForm',
            'pickUpCommentForm',
            'campaignIdForm'
          ];
          if (loanType === 'courseLoan' || loanType === 'singleLoan') {
            arrayOfForms.push('reservationPeriodForm');
          }
          if (loanType === 'singleLoan') {
            arrayOfForms.push('componentsForm');
          }
          arrayOfForms.forEach(key => {
            if (this.state[key] && this.state[key].formData) {
              sendData = { ...sendData, ...this.state[key].formData };
            }
          });
          if (opportunities && opportunities.length) {
            sendData.opportunitiesNumber = opportunities.map(item => ({
              transactionOpportunityId: 0,
              opportunityNumber: item.opportunityNumber,
              opportunitDescription: null,
            }));
          }
          sendData.newAddressAdded =
            this.state.typeOfDeliveryAddressForm &&
            this.state.typeOfDeliveryAddressForm.formData &&
            this.state.typeOfDeliveryAddressForm.formData.id === 'new'
              ? true
              : this.state.typeOfDeliveryAddressForm.formData &&
                this.state.typeOfDeliveryAddressForm.formData.deliveryAddress === 'new'
              ? true
              : false;
          if (loanType === 'kitLoan') {
            const data = await this.props.createKitLoan(sendData, loanAttachments, selectedKits, this.props.history, this.state.kitLoanMaterialNumberAndDescription);
            if (data && data.length > 0) {
              this.setState({showCalenderBlockPopup: true, blockTransactionList: data});
              $('#calendar-block').modal('show');
            }
          } else if (loanType === 'courseLoan') {
            this.props.createCourseLoan(sendData, loanAttachments, selectedKits, this.props.history);
          } else {
            this.props.createSingleLoan(
              { ...sendData, stockId: sendData.stock },
              loanAttachments,
              this.props.history,
              selectedKits,
            );
          }
        }
      }
    }
  };

  attachDatesToSelectedKits = () => {
    this.setState(prevState => ({
      selectedKits: prevState.selectedKits.map(item => ({
        ...item,
        reservationFrom: this.state.reservationPeriodForm.formData.reservationFrom,
        reservationTo: this.state.reservationPeriodForm.formData.reservationTo,
      })),
    }));
  };

  setOpportunity = value => {
    const updatedOpportunities = [...this.state.opportunities, { opportunityNumber: value }];
    this.setState({ opportunities: updatedOpportunities });
  };

  setOpportunities = values => {
    const opportunities = values.map(item => ({ opportunityNumber: item }));
    this.setState({ opportunities });
  };

  updateShortDescription = (name, deliveryAddressForm) => {
    this.setState(prevState => ({
      descriptionForm: {
        formData: {
          ...prevState.descriptionForm.formData,
          name: name ? name : prevState.descriptionForm.formData.name,
        },
        isFormValid: prevState.descriptionForm.isFormValid,
      },
      deliveryAddressForm: {
        formData: {
          ...deliveryAddressForm,
        },
        isFormValid: false,
      },
      typeOfDeliveryAddressForm: {
        formData: {
          deliveryAddress: 'new',
        },
        isFormValid: false,
      },
    }));
  };

  updateCustomerForm = (customerName, customerFirstAndLastName) => {

  };

  removeOpportunity = key => {
    const updatedOpportunities = this.state.opportunities.filter((item, index) => index !== key);
    this.setState({ opportunities: updatedOpportunities });
  };

  handleEnteredOpportunityId = value => {
    this.setState(prevState => ({ enteredOpportunityId: { ...prevState.enteredOpportunityId, ...value } }));
  };

  checkBusinessUnitForSelectedKits = () => {
    const { selectedKits } = this.state;
    let isRMS = false;

    selectedKits.forEach(item => {
      if (item.kit && item.kit.stockInformation && item.kit.stockInformation.businessUnit && item.kit.stockInformation.businessUnit.designation === 'RMS') {
        if (item.kit && item.kit.stockInformation && item.kit.stockInformation.country.shortName === 'ZA' ) {
          isRMS =false
        }else{
          isRMS = true;
        }

      }
    });

    return isRMS;
  };

  checkRequiredOpportunityId = () => {
    const { selectedKits, descriptionForm, componentsForm } = this.state;
    const { user } = this.props;
    const isBusinessUnitRMSForSelectedKits =
      selectedKits && selectedKits.length && this.checkBusinessUnitForSelectedKits();
    const checkIsZeissEmail = user && user.email && user.email.split('@')
    const zeissUser = checkIsZeissEmail && checkIsZeissEmail[1].includes('zeiss.com');
    const isLoanAtTheCustomerSide = descriptionForm.formData.reasonForLoanIdFullValue && descriptionForm.formData.reasonForLoanIdFullValue.fixedCode === 'b';
    const isLoanAtTheShowroomSide = descriptionForm.formData.reasonForLoanIdFullValue && descriptionForm.formData.reasonForLoanIdFullValue.fixedCode === 'c';
    const isRequiredOpportunityID =
      (isBusinessUnitRMSForSelectedKits && zeissUser) && (isLoanAtTheCustomerSide ||isLoanAtTheShowroomSide);
    return isRequiredOpportunityID;
  };

  getRefurbishmentValues = async (stockId) =>{
    const {fetchValuesForRefurbishmentFess} = this.props;
    const {selectedKits,loanType,} = this.state;
    const success = await fetchValuesForRefurbishmentFess(selectedKits,loanType,stockId);
  }
  checkMedusStock = (courseLoanForm) =>{
    const {selectedKits,loanType,componentsForm} = this.state;
    let value = false;
    if (selectedKits && selectedKits.length > 0 && loanType === 'kitLoan') {
      let checker =   selectedKits.every(item => item.kit && item.kit.stockInformation && item.kit.stockInformation.businessUnit && item.kit.stockInformation.businessUnit.name === 'MED' && item.kit.stockInformation.country.shortName === 'US');
      return checker
    }
   if (loanType === 'singleLoan') {
      let value = componentsForm && componentsForm.formData && componentsForm.formData.stockFullValue && componentsForm.formData.stockFullValue.businessUnit.name === 'MED' && componentsForm.formData.stockFullValue.countryName === 'US';
      return value? true: false
   }
   if (loanType === 'courseLoan') {
    let value = courseLoanForm  && courseLoanForm.stockFullValue && courseLoanForm.stockFullValue.businessUnit.name === 'MED' && courseLoanForm.stockFullValue.countryName === 'US';
    if (value === true) {
      this.setState({courseLoanMedStock: true});
    }else{
      this.setState({courseLoanMedStock: false});
    }
    return
 }
  }

  checkMedTaiwanWarehouse = (courseLoanForm) =>{
    const {selectedKits,loanType,componentsForm} = this.state;
    let value = false;
    if (selectedKits && selectedKits.length > 0 && loanType === 'kitLoan') {
      let checker =   selectedKits.every(item => item.kit && item.kit.stockInformation && item.kit.stockInformation.businessUnit && item.kit.stockInformation.businessUnit.name === 'MED' && item.kit.stockInformation.country.shortName === 'TW');
      return checker
    }
   if (loanType === 'singleLoan') {
      let value = componentsForm && componentsForm.formData && componentsForm.formData.stockFullValue && componentsForm.formData.stockFullValue.businessUnit.name === 'MED' && componentsForm.formData.stockFullValue.countryName === 'TW';
      return value? true: false
   }
   if (loanType === 'courseLoan') {
    let value = courseLoanForm  && courseLoanForm.stockFullValue && courseLoanForm.stockFullValue.businessUnit.name === 'MED' && courseLoanForm.stockFullValue.countryName === 'TW';
    if (value === true) {
      this.setState({courseLoanMedTaiwan: true});
    }else{
      this.setState({courseLoanMedTaiwan: false});
    }
    return
 }
  }

   loadOptions = async (inputvalue) => {
    const {getSearchedAddress} = this.props
    const filterArray = this.state.addressesForDropdown && this.state.addressesForDropdown.length > 0 && this.state.addressesForDropdown.map(item => ({title:item.addressType ? item.addressType: item.name, value:item}));
     if (inputvalue) {
        const data = await getSearchedAddress(inputvalue);
        if (data && data.length > 0) {
         const newData =  data.map(item=>({title: `${item.organization}, ${item.postalCode}`, value: item}));
          this.setState({medUsAddressDropDownList:newData})
          return newData
        }

    }else{
      this.setState({medUsAddressDropDownList:filterArray})
      return filterArray
    }
  }

  handleSelectDropdownChanges = (value) => {
    this.setState(prevState => ({
      typeOfDeliveryAddressForm: {
        ...prevState.typeOfDeliveryAddressForm,
        formData: {
          ...value.value,
        },
        isFormValid: true
      },
    }));
    this.setState(prevState => ({
      deliveryAddressForm: {
        ...prevState.deliveryAddressForm,
        formData: {
          ...value.value,
          countryId: value.value.country ? value.value.country:value.value.countryId,
          editButtonVisible: true,
        },
      },
    }));

    this.setState({medUsAddressDropDownList:null})
  }
  getActiveContentByStep = () => {
    const {
      activeStep,
      additionalData,
      reservationPeriodForm,
      borrowerForm,
      componentsForm,
      deliveryAddressForm,
      deliveryCommentForm,
      descriptionForm,
      typeOfDeliveryAddressForm,
      loanAttachments,
      selectedKits,
      isShowCheckbox,
      helpForBuildingAndDismantlingForm,
      loanType,
      opportunities,
      isOpportunityIdCrmError,
      enteredOpportunityId,
      isShowLoanValueField,
      currency,
      isShowLoanValueFieldForm,
      additionalLoanRequestIsPossible,
      kitLoanMaterialNumberAndDescription,
      showFormAdditionalSingleActivated,
      courseLoanMedStock,
      showCalenderBlockPopup,
      blockTransactionList,
      pickUpComment,
      pickUpCommentForm,
      courseLoanMedTaiwan,
      campaignIdForm,
      showCampaignIdFormBasedOnReasonForLoan,
      showCampaignIdIfWorkflowEnabled,
    } = this.state;
    const isRequiredOpportunityID = this.checkRequiredOpportunityId();
    const { disableSave, history, user, masterData, toggleActionMessage, expectedLoanFees} = this.props;
    const { displayActionMessage } = this.props.appActions;
    const isMedUsStock = (loanType === 'kitLoan' || loanType === 'singleLoan') && this.checkMedusStock(null) ;
    switch (activeStep) {
      case 1:
        return (
          <AssignmentStep
            componentsForm={componentsForm}
            descriptionForm={descriptionForm}
            updateShortDescription={this.updateShortDescription}
            helpForBuildingAndDismantlingForm={helpForBuildingAndDismantlingForm}
            descriptionModel={this.descriptionModel(masterData.reasonForLoan)}
            reservationPeriodForm={reservationPeriodForm}
            helpForBuildingAndDismantlingModel={helpForBuildingAndDismantlingModel}
            componentsModel={this.componentsModel()}
            reservationPeriodModel={this.reservationPeriodModel()}
            handleForm={this.handleForm}
            updateForm={this.updateForm}
            selectedKits={selectedKits || []}
            updateDateOfCartItem={this.updateDateOfCartItem}
            onSelectCourseItem={this.onSelectCourseItem}
            onSelectSingleItem={this.onSelectSingleItem}
            loanAttachments={loanAttachments}
            addLoanAttachmentFile={this.addLoanAttachmentFile}
            removeLoanAttachmentFile={this.removeLoanAttachmentFile}
            history={history}
            disableSave={disableSave}
            goToSecondStep={this.goToSecondStep}
            isShowCheckbox={isShowCheckbox}
            loanType={loanType}
            setOpportunity={this.setOpportunity}
            setOpportunities={this.setOpportunities}
            removeOpportunity={this.removeOpportunity}
            opportunities={opportunities}
            setIsOpportunityIdCrmError={this.setIsOpportunityIdCrmError}
            isOpportunityIdCrmError={isOpportunityIdCrmError}
            isRequiredOpportunityID={isRequiredOpportunityID}
            enteredOpportunityId={enteredOpportunityId}
            handleEnteredOpportunityId={this.handleEnteredOpportunityId}
            overtakenDataForCourseLoan={this.props.location.state}
            isShowLoanValueField={isShowLoanValueField}
            currency={currency}
            showLoanValueFieldModel={showLoanValueFieldModel}
            isShowLoanValueFieldForm={isShowLoanValueFieldForm}
            handleWorkflowData={this.handleWorkflowData}
            overtakenDataForSingleLoan={this.props.location.state}
            expectedLoanFees ={expectedLoanFees}
            additionalLoanRequestIsPossible={additionalLoanRequestIsPossible}
            onAddKitloanMaterialNumber={this.onAddKitloanMaterialNumber}
            kitLoanMaterialNumberAndDescription={kitLoanMaterialNumberAndDescription}
            showFormAdditionalSingleActivated={showFormAdditionalSingleActivated}
            isFormOpen={this.isFormOpen}
            checkMedusStock={this.checkMedusStock}
            updateCustomerForm={this.updateCustomerForm}
            checkMedTaiwanWarehouse={this.checkMedTaiwanWarehouse}
            campaignIdModelForm={campaignIdModelForm}
            campaignIdForm={campaignIdForm}
            showCampaignIdFormBasedOnReasonForLoan={showCampaignIdFormBasedOnReasonForLoan}
            showCampaignIdIfWorkflowEnabled={showCampaignIdIfWorkflowEnabled}
          />
        );
      case 2:
        return (
          <CustomerDataStep
            borrowerModel={this.borrowerModel(masterData.usersList)}
            componentsModel={this.componentsModel()}
            deliveryAddressModel={this.deliveryAddressModel(masterData.countries, this.state.allProvinces, this.state.deliveryAddressForm)}
            typeOfDeliveryAddressModel={this.typeOfDeliveryAddressModel()}
            deliveryCommentModel={deliveryCommentModel}
            borrowerForm={borrowerForm}
            componentsForm={componentsForm}
            descriptionForm={descriptionForm}
            deliveryAddressForm={deliveryAddressForm}
            typeOfDeliveryAddressForm={typeOfDeliveryAddressForm}
            deliveryCommentForm={deliveryCommentForm}
            handleForm={this.handleForm}
            updateForm={this.updateForm}
            selectedKits={selectedKits || []}
            updateDateOfCartItem={this.updateDateOfCartItem}
            onSelectCourseItem={this.onSelectCourseItem}
            history={history}
            disableSave={disableSave}
            displayActionMessage={displayActionMessage}
            toggleActionMessage={toggleActionMessage}
            countries={masterData.countries}
            loanType={loanType}
            user={user}
            goToThirdStep={this.goToThirdStep}
            changeActiveStep={this.changeActiveStep}
            handleWorkflowData={this.handleWorkflowData}
            onSelectSingleItem={this.onSelectSingleItem}
            expectedLoanFees ={expectedLoanFees}
            addressesForDropdown={this.state.addressesForDropdown}
            loadOptions={this.loadOptions}
            handleSelectDropdownChanges={this.handleSelectDropdownChanges}
            medUsAddressDropDownList={this.state.medUsAddressDropDownList}
            isMedUsStock={isMedUsStock}
            checkMedusStock={this.checkMedusStock}
            courseLoanMedStock={courseLoanMedStock}
            pickUpComment={pickUpComment}
            pickUpCommentModel={pickUpCommentModel}
            pickUpCommentForm={pickUpCommentForm}
            checkMedTaiwanWarehouse={this.checkMedTaiwanWarehouse}
          />
        );
      case 3:
        return (
          <ConfirmationStep
            additionalData={additionalData}
            descriptionModel={this.descriptionModel(masterData.reasonForLoan)}
            updateShortDescription={this.updateShortDescription}
            borrowerModel={this.borrowerModel(masterData.usersList)}
            componentsModel={this.componentsModel()}
            reservationPeriodModel={this.reservationPeriodModel()}
            deliveryAddressModel={this.deliveryAddressModel(masterData.countrie, this.state.allProvinces, this.state.deliveryAddressForm)}
            typeOfDeliveryAddressModel={this.typeOfDeliveryAddressModel()}
            helpForBuildingAndDismantlingModel={helpForBuildingAndDismantlingModel}
            deliveryCommentModel={deliveryCommentModel}
            descriptionForm={descriptionForm}
            borrowerForm={borrowerForm}
            componentsForm={componentsForm}
            deliveryAddressForm={deliveryAddressForm}
            typeOfDeliveryAddressForm={typeOfDeliveryAddressForm}
            deliveryCommentForm={deliveryCommentForm}
            reservationPeriodForm={reservationPeriodForm}
            helpForBuildingAndDismantlingForm={helpForBuildingAndDismantlingForm}
            handleForm={this.handleForm}
            updateForm={this.updateForm}
            loanAttachments={loanAttachments}
            addLoanAttachmentFile={this.addLoanAttachmentFile}
            removeLoanAttachmentFile={this.removeLoanAttachmentFile}
            changeActiveStep={this.changeActiveStep}
            selectedKits={selectedKits || []}
            updateDateOfCartItem={this.updateDateOfCartItem}
            onSelectCourseItem={this.onSelectCourseItem}
            history={history}
            disableSave={disableSave}
            masterData={masterData}
            toggleActionMessage={toggleActionMessage}
            loanType={loanType}
            executeBooking={this.executeBooking}
            setOpportunity={this.setOpportunity}
            setOpportunities={this.setOpportunities}
            removeOpportunity={this.removeOpportunity}
            opportunities={opportunities}
            setIsOpportunityIdCrmError={this.setIsOpportunityIdCrmError}
            isOpportunityIdCrmError={isOpportunityIdCrmError}
            isRequiredOpportunityID={isRequiredOpportunityID}
            enteredOpportunityId={enteredOpportunityId}
            handleEnteredOpportunityId={this.handleEnteredOpportunityId}
            handleWorkflowData={this.handleWorkflowData}
            onSelectSingleItem={this.onSelectSingleItem}
            expectedLoanFees={expectedLoanFees}
            activeStep = {activeStep}
            additionalLoanRequestIsPossible={additionalLoanRequestIsPossible}
            onAddKitloanMaterialNumber={this.onAddKitloanMaterialNumber}
            kitLoanMaterialNumberAndDescription={kitLoanMaterialNumberAndDescription}
            showFormAdditionalSingleActivated={showFormAdditionalSingleActivated}
            isFormOpen={this.isFormOpen}
            addressesForDropdown={this.state.addressesForDropdown}
            loadOptions={this.loadOptions}
            handleSelectDropdownChanges={this.handleSelectDropdownChanges}
            medUsAddressDropDownList={this.state.medUsAddressDropDownList}
            isMedUsStock={isMedUsStock}
            checkMedusStock={this.checkMedusStock}
            courseLoanMedStock={courseLoanMedStock}
            showCalenderBlockPopup={showCalenderBlockPopup}
            blockTransactionList={blockTransactionList}
            pickUpComment={pickUpComment}
            pickUpCommentModel={pickUpCommentModel}
            pickUpCommentForm={pickUpCommentForm}
            updateCustomerForm={this.updateCustomerForm}
            checkMedTaiwanWarehouse={this.checkMedTaiwanWarehouse}
            campaignIdModelForm={campaignIdModelForm}
            campaignIdForm={campaignIdForm}
            showCampaignIdFormBasedOnReasonForLoan={showCampaignIdFormBasedOnReasonForLoan}
            showCampaignIdIfWorkflowEnabled={showCampaignIdIfWorkflowEnabled}
          />
        );
      default:
        break;
    }
  };

  changeActiveStep = activeStep => {
    scrollToTop(500);
    this.setState({ activeStep });
  };

  render() {
    const { activeStep, loanType } = this.state;
    const { displayActionMessage, type, message, isLoading, showAsIs } = this.props.appActions;
    const { displayMessage, from, to } = this.props.alternateMessageData;

    return (
      <div className='single-loan-container'>
        {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
        {displayMessage && (
          <AlternateSlotMessage
            from={moment(from).format('DD-MM-YYYY')}
            to={moment(to).format('DD-MM-YYYY')}
            onClose={() => this.props.closeAlternateDateSuggestionMessage()}
          />
        )}
        <Steps activeStep={activeStep} />
        <div className='container-fluid'>
          {activeStep === 1 && <h2 className='title-loan mb-4'>{strings[`new${firstToUpperCase(loanType)}`]}</h2>}
          {this.getActiveContentByStep()}
        </div>
        {isLoading && <div className='loader' />}
      </div>
    );
  }
}

export default KitLoan;

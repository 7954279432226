import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';

import { createKitBreadcrumbs } from '../CreateKit/CreateKit.constants';
import { generalInformationModel } from './CreateZmccKit.constants';
import { useCreateZmccKit } from './CreateZmccKit.hook';

const CreateZmccKit = props => {
  const {
    appActions,
    generalInformationForm,
    informationForm,
    updateForm,
    location,
    fetchStocks,
    createZmccKit,
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    kitStatusForm,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    masterData,
    fetchActiveUnitDate,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    history,
    match,
    fetchRoomsListForWarehouse,
    fetchUsersOfBusinessUnit,
    rooms,
    zmccUsers,
    language,
    vrDetalisForm
  } = props;
  const { displayActionMessage, type, message, isLoading } = appActions;
  const rmsZMCCBuData = masterData.businessUnit.filter(item => item.designation === "RMS_ZMCC")[0];
  const rmsBuData = masterData.businessUnit.filter(item => item.designation === "RMS")[0];

  const {
    title,
    tabs,
    addKitImageFile,
    removeKitImageFile,
    handleForm,
  } = useCreateZmccKit({
    history,
    location,
    updateForm,
    fetchStocks,
    generalInformationForm,
    createZmccKit,
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    informationForm,
    fetchActiveUnitDate,
    kitStatusForm,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    match,
    masterData,
    rmsZMCCBuData,
    rmsBuData,
    fetchRoomsListForWarehouse,
    rooms,
    fetchUsersOfBusinessUnit,
    zmccUsers,
    language,
    vrDetalisForm
  });

  return (
    <div className='create-kit-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <div className='container-fluid'>
        <Breadcrumbs data={createKitBreadcrumbs()} />
        <h1 className='page-title'>{title}</h1>

        <div className='row mb-3'>
          <div className='col-sm-5'>
            <h4 className='header-form-title'>{strings.imagesText}</h4>
            <FileUploader
              mimeType='image/*,.czi'
              files={kitImages}
              maxSize={50}
              errorMessage={strings.maxFileSize}
              onFileAdd={addKitImageFile}
              onRemove={removeKitImageFile}
            />
          </div>
          <div className='col-sm-7'>
            <h4 className='header-form-title'>{strings.generalInformation}</h4>
            <FormComponent
              model={generalInformationModel}
              formName='generalInformationForm'
              formValue={generalInformationForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
      </div>
      <Tabs tabs={tabs} />

      {isLoading && <div className='loader' />}
    </div>
  );
}

export default CreateZmccKit;

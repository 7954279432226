import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { freeAccessoriesModel } from './Accessories.constants';

const TAB_ID = 3;

export const CreateKitAccessories = (props) => {
  const { formValue, handleForm, saveKit, cancelCreateKit } = props;
  const { freeAccessoriesForm } = formValue;

  const handleSaveKit = () => {
    saveKit(TAB_ID);
  };

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.freeAccessoriesLabel}</h3>
            <FormComponent
              model={freeAccessoriesModel}
              formName='freeAccessoriesForm'
              formValue={freeAccessoriesForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={handleSaveKit} onCancel={cancelCreateKit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateKitAccessories;

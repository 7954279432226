import React from 'react';
import { generalInformationModel, projectDetailsModel, schedulingInformationModel } from './GeneralInformation.constants';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useGeneralInformation } from './GeneralInformation.hook';

export const GeneralInformation = (props) => {
  const {formValue} = props;
  const { generalInformationForm, schedulingInformationForm, projectDetailsForm  } = formValue;
  const { handleForm, generalInfoModel, schedulingInfoModel, projectDataModel } = useGeneralInformation(props);
  return (
    <>
      <h2 className='mt-4 zmcc-title-heading'>{strings.generalInformation.toUpperCase()}</h2>
      <div className='mt-3'>
        <FormComponent
          model={generalInfoModel()}
          formName='generalInformationForm'
          formValue={generalInformationForm.formData}
          onChange={handleForm}
        />
      </div>

      <h2 className='mt-4 zmcc-title-heading'>{strings.schedulingInformation}</h2>
      <div className='mt-3'>
        <FormComponent
          model={schedulingInfoModel()}
          formName='schedulingInformationForm'
          formValue={schedulingInformationForm.formData}
          onChange={handleForm}
        />
      </div>
      <h2 className='mt-4 zmcc-title-heading'>{strings.projectDetails}</h2>
      <div className='mt-3'>
        <FormComponent
          model={projectDataModel()}
          formName='projectDetailsForm'
          formValue={projectDetailsForm.formData}
          onChange={handleForm}
        />
      </div>
    </>
  );
};

export default GeneralInformation;

import Validators from 'library/utilities/Validators';

export const UPDATE_ZMCC_KIT_FORM = 'UPDATE_ZMCC_KIT_FORM';
export const FETCH_WAREHOUSES_FOR_ZMCC_BU = 'FETCH_WAREHOUSES_FOR_ZMCC_BU';
export const FETCH_SYSTEM_CLASSES_ZMCC_BU= 'FETCH_SYSTEM_CLASSES_ZMCC_BU';
export const FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU = 'FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU';
export const FETCH_WAREHOUSE_ROOMS_ZMCC_BU = 'FETCH_WAREHOUSE_ROOMS_ZMCC_BU';
export const FETCH_WAREHOUSE_USERS_ZMCC_BU = 'FETCH_WAREHOUSE_USERS_ZMCC_BU';
export const FETCH_ZMCC_BU_CONDITIONS = 'FETCH_ZMCC_BU_CONDITIONS';
export const FETCH_ZMCC_KIT_DETAILS = 'FETCH_ZMCC_KIT_DETAILS';
export const RESET_ZMCC_BU_KIT_FORM = 'RESET_ZMCC_BU_KIT_FORM';

export const generalInformationModel = [
  {
    label: 'kitName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',

    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-7'
  },
  {
    label: 'kitInformationId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitInformationId',
    validators: [],
    required: true,
    styleClass: 'col-sm-5',
    disabled: true
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12'
  },
];
  
import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import CreatingOrEditingEditionalComment from '../CreatingOrEditingEditionalComment';
import Icon from 'library/common/commonComponents/Icon/Icon';

const EditionalComment = ({
  editionalComment,
  currentEditionalComment,
  userId,
  handleEdit,
  handleForm,
  form,
  handleUpdate,
  handleDelete,
  resetCurrentEditionalComment,
  isLoading,
  kitDetail,
}) => {
  const { createdBy, updatedOn, text } = editionalComment;
  const name = `${createdBy.firstName} ${createdBy.lastName}`;
  const date = getDateInFormat('DD.MM.YYYY', updatedOn);
  const createdById = createdBy ? createdBy.id : null;
  const isShowEditButton = (userId === createdById) && kitDetail.systemClassActive;

  if (editionalComment.id === (currentEditionalComment && currentEditionalComment.id)) {
    return (
      <CreatingOrEditingEditionalComment
        form={form}
        handleForm={handleForm}
        handleCancel={resetCurrentEditionalComment}
        handleSave={handleUpdate}
        isLoading={isLoading}
      />
    );
  }

  return (
    <div className='editional-comment-wrapper'>
      <div className='arrow-right'></div>
      <div className='d-flex align-items-center'>
        <p className='text-italic'>
          <span>{name}, </span>
          <span>{date}</span>
        </p>
        {isShowEditButton && (
          <div className='d-flex  flex-direction-row justify-content-between align-items-center'>
            <Button
              bgFill={false}
              isGhost={true}
              iconName='edit'
              onClick={() => handleEdit(editionalComment)}
              styleClass='w-auto btn-no-border py-0 pr-2'
            />
            <div className='btn-no-border cursor-pointer' onClick={() => handleDelete({editionalComment, deleteStatus: false})}>
              <Icon name='delete' width={24} height={24} fill='#ff1a00' />
               <i />
              </div>
          </div>
        )}
      </div>
      <div className='description-container'>{text}</div>
    </div>
  );
};

export default EditionalComment;

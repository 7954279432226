import React from 'react';
import strings from 'resources/locales/Translate';

import Dropdown from '../FormComponent/Optimized/Dropdown';

const ItemsPerPage = ({ itemsPerPage, handleItemsPerPage, value }) => (
  <div className='d-flex align-items-center mr-3'>
    <span className='mr-2'>{strings.itemPerPage}</span>
    <Dropdown
      dataTest='items-per-page-dropdown'
      data={itemsPerPage}
      hasSection={false}
      multiSelect={false}
      filter={false}
      idKey='id'
      displayValue={[{ key: 'pages', separator: '' }]}
      isRequired={true}
      onChange={handleItemsPerPage}
      placeholder={itemsPerPage[0].pages}
      value={value && value.id}
      fullValue={value}
    />
  </div>
);

export default ItemsPerPage;

export const images = {
  checkboxSelected: require('resources/icons/selected.svg'),
  checkboxDeselected: require('resources/icons/deselected.svg'),
  fileIcon: require('resources/icons/insert-drive-file.svg'),
  imagePlaceholder: require('resources/images/placeholder.png'),
  instagramIcon: require('resources/icons/instagram.svg'),
  yammerIcon: require('resources/icons/yammer.svg'),
  tickMark: require('resources/icons/check-symbol.png'),
  returnToStock: require('resources/icons/return-to-stock.png'),
  returnToStockUnconfirmed: require('resources/icons/return-to-stock-unconfirmed.png'),
  lmImage: require('resources/images/LM_LSM980 Airyscan 2.jpg'),
  semImage: require('resources/images/SEM.jpg'),
  xbImage: require('resources/images/Xb-Image.png'),
  xrmImage: require('resources/images/XRM_7944_ZEISS-Xradia-520-Versa.jpg'),
};

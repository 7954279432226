import React, { Fragment } from 'react';
import get from 'lodash/get';

import Icon from 'library/common/commonComponents/Icon/Icon';
import SmallButton from 'library/common/commonComponents/SmallButton/SmallButton.component';
import {
  getIconByCondition,
  getIconByLLTransactionStatus,
  getIconByCurrentPosition,
} from 'library/utilities/getStatusAndConditionIcons';
import strings from 'resources/locales/Translate';

import CancellationRequestPopup from '../CancellationRequestPopup';
import { NavLink } from 'react-router-dom';

const DataExpandableTable = props => {
  const {
    data,
    history,
    saveCancellationRequest,
    row,
    index,
    match,
    setRejectCancelRequest,
    rejectCancelRequest,
    user,
    isDlaOrLab,
  } = props;
  const { transactionKit, transactionStatus, transactionPosition, isCancellationRequest, transactionsId } = row || {};
  const isExpandable = true;
  const phone = get(transactionKit, 'kitInformation.stockInformation.phone') || '***';
  const email = get(transactionKit, 'kitInformation.stockInformation.email') || '***';
  const note = strings.pleaseCall.replace('[phone]', phone).replace('[email]', email);
  const kitCondition = get(transactionKit, 'kitInformation.kitCondition.kitConditionTranslated');
  const hasKitCondition = kitCondition && kitCondition.length > 0;
  const status = get(transactionStatus, 'transactionStatusId') || '';
  const notCancelledStatus = status !== 5;
  const currentPosition = get(transactionPosition, 'transactionPositionTranslated');
  const hasCurrentPosition = currentPosition && currentPosition.length > 0;
  const detailPageNumber = (data.page - 1) * data.size + index + 1;

  const onShowDetailsClick = () => {
    history.push(`${match.url}/${transactionsId}?detailPage=${detailPageNumber}?islabordla=${isDlaOrLab}`);
  };
  const hasPermissions = () => {
    let isAllowed = false;
    if (!isAllowed && user && user.stockRoles && user.stockRoles.length) {
      const demoLoanStockInformation = get(transactionKit, 'kitInformation.stockInformation') || {};
      const demoLoanStockId = demoLoanStockInformation.id;

      if (demoLoanStockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'stock controller' ||
              role.roleName.toLowerCase() === 'dispatcher' ||
              role.roleName.toLowerCase() === 'outbound staff') &&
            demoLoanStockId === role.stockId
          ) {
            isAllowed = true;
          }
        });
      }
    }
    if (user && user.admin) {
      isAllowed = true;
    }
    return isAllowed;
  };
  return (
    <>
      <td />
      <td />
      <td colSpan='10'>
        <div className='d-flex flex-wrap mb-3'>
          {status && getIconByLLTransactionStatus(status, isExpandable)}
          {hasKitCondition && getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}
          {hasCurrentPosition && (
            <div className='d-flex align-items-center'>
              {currentPosition.map(position => (
                <Fragment key={position.positionText}>{getIconByCurrentPosition(position, isExpandable)}</Fragment>
              ))}
            </div>
          )}
        </div>

        <div className='d-flex flex-wrap mb-3'>
          <div className='ml-0'>
            <strong>{strings.commentText}</strong>
            <p className='mb-3'>{row.comment || 'No comment'}</p>
          </div>
        </div>

        <div className='mb-2'>
          <div className='d-flex'>
            <NavLink
              to={{
                pathname: `${match.url}/${transactionsId}?detailPage=${detailPageNumber}?islabordla=${isDlaOrLab}`,
              }}
              exact={true}
            >
              <SmallButton iconName='visibility' value={strings.showDetails} />
            </NavLink>
            {notCancelledStatus && isCancellationRequest && (
              <div className='d-flex'>
                <span className='pt-1 pl-4'>{strings.cancelRequestRaised}</span>
                {hasPermissions() && (
                  <>
                    <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                      <SmallButton bgFill={false} iconName='' value={strings.cancelBooking} className='ml-3' />
                    </div>
                    <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                      <SmallButton
                        bgFill={false}
                        iconName=''
                        value={strings.rejectCancelRequest}
                        className='ml-3'
                        onClick={() => setRejectCancelRequest(true)}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            {!isCancellationRequest && notCancelledStatus && (
              <div data-toggle='modal' data-target={`#cancellationRequestPopup${row.transactionsId}`}>
                <SmallButton
                  bgFill={false}
                  iconName=''
                  value={strings.requestCancel}
                  className='ml-3'
                  onClick={() => setRejectCancelRequest(false)}
                />
              </div>
            )}
          </div>
          <div className='d-flex align-items-center mt-3 mb-2'>
            <Icon name='info' width={24} height={24} className='mr-2' />
            <div>
              <strong>{strings.pleaseNote}</strong>
              <p>{note}</p>
            </div>
          </div>
        </div>
      </td>
      <CancellationRequestPopup
        id={`cancellationRequestPopup${row.transactionsId}`}
        transactionsId={row.transactionsId}
        saveCancellationRequest={saveCancellationRequest}
        isFinalCancel={isCancellationRequest}
        isRejectCancel={rejectCancelRequest}
        setRejectCancelRequest={setRejectCancelRequest}
      />
    </>
  );
};

export default DataExpandableTable;

import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import CreateZmccKit from './CreateZmccKit.component';
import {
  updateForm,
  fetchStocks,
  createZmccKit,
  resetForm,
  fetchRoomsListForWarehouse,
  fetchSystemClasses,
  fetchUsersOfBusinessUnit,
  fetchZMCCKitDetails,
} from './CreateZmccKit.actions';

const mapStateToProps = ({ appActionsReducer, masterDataReducer ,authReducer, createZmccKitReducer, languageReducer }) => ({
  appActions: appActionsReducer,
  generalInformationForm: createZmccKitReducer.generalInformationForm,
  informationForm: createZmccKitReducer.informationForm,
  freeAccessoriesForm: createZmccKitReducer.freeAccessoriesForm,
  furtherImportantComponentForm: createZmccKitReducer.furtherImportantComponentForm,
  salesComponentForm: createZmccKitReducer.salesComponentForm,
  kitStatusForm: createZmccKitReducer.kitStatusForm,
  kitImages: createZmccKitReducer.kitImages,
  kitImagesToDelete: createZmccKitReducer.kitImagesToDelete,
  kitAttachments: createZmccKitReducer.kitAttachments,
  kitAttachmentsToDelete: createZmccKitReducer.kitAttachmentsToDelete,
  kitDetails: createZmccKitReducer.kitDetails,
  kitInformationId: createZmccKitReducer.kitInformationId,
  rooms: createZmccKitReducer.rooms,
  zmccUsers: createZmccKitReducer.zmccUsers,
  language: languageReducer,
  user: authReducer.user,
  masterData: masterDataReducer,
  vrDetalisForm: createZmccKitReducer.vrDetalisForm,
});

export default connect(mapStateToProps, {
  updateForm,
  fetchStocks,
  createZmccKit,
  fetchMasterData,
  resetForm,
  fetchSystemClasses,
  fetchRoomsListForWarehouse,
  fetchUsersOfBusinessUnit,
  fetchZMCCKitDetails
})(CreateZmccKit);

import React, { useEffect, useState, useCallback } from 'react';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const useAccountStatementTab = props => {
  const { getAccountStatement, getStocks, exportList, accountStatementList, stocks, userList, fetchUserList, language } = props;
  const [state, setState] = useState({
    page: 1,
    size: 10,
    filter: {
      stock: null,
      stockFullValue: null,
      years: null,
      yearsFullValue: null,
      yearsError: null,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userList.length) {
      fetchUserList();
    }
  }, [userList, fetchUserList]);

  useEffect(() => {
    if (!stocks.length) {
      getStocks();
    }
    const filteredWarehouse = stocks?.filter(item => item.stockInformation !==null)
      if (filteredWarehouse && filteredWarehouse.length === 1) {
        setState({page: 1,
          size: 10,
          filter: {
            stock: filteredWarehouse[0].stockInformation[0].id,
            stockFullValue: filteredWarehouse[0].stockInformation,
            years: null,
            yearsFullValue: null,
            yearsError: null,}})
      }
  }, [stocks, getStocks]);

  useEffect(() => {

    getStocks();

  }, [language]);

  useEffect(() => {
    if (state.filter.stock && state.filter.yearsFullValue && !state.filter.yearsError) {
      const dateRange = state.filter.yearsFullValue.map(item => ({ from: item.dateFrom, to: item.dateTo }));
      const dataToSend = {
        page: state.page,
        size: state.size,
        stockIds: [state.filter.stock],
        dateRange,
      };

      getAccountStatement(dataToSend);
    }
  }, [
    state.page,
    state.size,
    state.filter.stock,
    state.filter.years,
    state.filter.yearsFullValue,
    state.filter.yearsError,
    getAccountStatement,
  ]);

  const handleFilterChange = useCallback((value, field, fullValue) => {
    const idValue = Array.isArray(value) ? [...value] : value;
    const error = fullValue.length > 3 ? 'youCantSelectMoreThanThreeValues' : null;

    setState(prevState => {
      const newData = { ...prevState };
      newData.filter[field] = idValue;
      newData.filter[`${field}FullValue`] = fullValue;
      newData.filter[`${field}Error`] = error;

      return { ...newData };
    });
  }, []);

  const handleItemsPerPage = useCallback(size => {
    setState(prevState => ({
      ...prevState,
      size,
    }));
  }, []);

  const handlePageChange = useCallback(page => {
    setState(prevState => ({
      ...prevState,
      page: page + 1,
    }));
  }, []);

  const exportTable = useCallback(async () => {
    const dateRange = state.filter.yearsFullValue.map(item => ({ from: item.dateFrom, to: item.dateTo }));
    const dataToSend = {
      stockIds: [state.filter.stock],
      dateRange,
    };

    setIsLoading(true);
    await exportList(dataToSend);
    setIsLoading(false);
  }, [state.filter.stock, state.filter.yearsFullValue]);

  const  showWarningIcon =(item) =>{
    if (item.compensationPoint && item.compensationPoint.pointsEarned === -1 ) {
      return(
       <Icon
       name='info'
       width={22}
       height={22}
       fill='#000'
       className='cursor-pointer'
       data-tooltip={strings.calculationIsNotPossible}
       data-tooltip-config={JSON.stringify({ direction: 'bottom' })}
     />
      )
    }else if (item.compensationPoint && item.compensationPoint.pointsEarned === -2 ) {
     return(
       <Icon
       name='info'
       width={22}
       height={22}
       fill='#000'
       className='cursor-pointer'
       data-tooltip={strings.maintainSystemMainComponentForCalutaion}
       data-tooltip-config={JSON.stringify({ direction: 'bottom' })}
     />)
    } else {
      return(
       <span>{item.compensationPoint ? item.compensationPoint.pointsEarned : '-'}</span>
      )
    }
  }
 

  const getCols = () => [
    {
      title: strings.date,
      name: 'date',
      isSort: false,
      render: item => {
        return <span>{getDateInFormat('DD.MM.YYYY', item.date)}</span>;
      },
    },
    {
      title: strings.transactionId,
      name: 'transactionId',
      isSort: false,
      render: item => {
        const url = item.compensationPoint ? `${process.env.REACT_APP_UI_CALLBACK_URL}/#/transactions/${item.compensationPoint.transactionId}` : '';
        return (
          <>
            {item.compensationPoint ? (
              <a href={url} target='_blank' rel='noopener noreferrer'>
                {item.compensationPoint.transactionId}
              </a>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      title: strings.performance,
      name: 'performance',
      isSort: false,
      render: item => {
        if (item.compensationPoint) {
          return (
            <div>
              <p>
                {strings.transactionDescription}: {item.compensationPoint.description}
              </p>
              <p>
                {strings.customer}: {item.compensationPoint.customerName}
              </p>
              <p>
                {strings.customerInstitute}: {item.compensationPoint.customerInstitute}
              </p>
              <p>
                {strings.duration}: {item.compensationPoint.duration} {item.compensationPoint.duration <=1 ? strings.hour : strings.hours}
              </p>
              <p>
                {strings.requestor}: {item.compensationPoint.requester}
              </p>
            </div>
          );
        }
        if (item.deductedPoint) {
          return (
            <div>
              <p>
                {strings.deductionOfPoints}
              </p>
              <p>
                {strings.editor}: {item.deductedPoint.editor}
              </p>
              <p>
                {strings.reason}: {item.deductedPoint.reason}
              </p>
              <p>
                {strings.salesRepresentative}: {item.deductedPoint.salesRepresentative}
              </p>
            </div>
          );
        }
      },
    },
    {
      title: strings.earned,
      name: 'earned',
      isSort: false,
      render: item => {
        return (
          <>
            {showWarningIcon(item)}
          </>
        );
      },
    },
    {
      title: strings.deducted,
      name: 'deducted',
      isSort: false,
      render: item => {
        return <span>{item.deductedPoint ? item.deductedPoint.value : '-'}</span>;
      },
    },
    {
      title: strings.sum,
      name: 'sum',
      isSort: false,
      render: item => {
        return <span>{item.pointsSum}</span>;
      },
    },
  ];

  const isShowTable = accountStatementList.content && accountStatementList.content.length > 0;
  const cols = getCols();

  return {
    state,
    cols,
    isShowTable,
    isLoading,
    handleItemsPerPage,
    handlePageChange,
    handleFilterChange,
    exportTable,
  };
};

import React, { PureComponent } from 'react';
import { formatBytes, getFileType } from 'library/utilities/fileHelperFunctions';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Icon from '../Icon/Icon';
import './fileListStyles.scss';

export default class SingleFileListView extends PureComponent {
  downloadFile = () => {
    const { isDownloadable, downloadUrl, downloadIdField, downloadStream, file } = this.props;
    if (isDownloadable && file.hasOwnProperty(downloadIdField)) {
      let url = downloadUrl.replace('{id}', file[downloadIdField]);
      if (downloadStream) {
        this.downloadStream(url, file);
      } else {
        window.open(url, '_target');
      }
    }
  }

  downloadStream = (url, file) => {
    const token = fetchFromStorage(identifiers.token);
    Axios(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        token: `${token}`,
      },
    })
      .then(response => {
        const newFile = new Blob([response.data], { type: file.type });
        saveAs(newFile, file.name);
      })
      .catch(error => {
        console.log(error);
      });
  };

  onRemove = e => {
    const { index, onRemove } = this.props;

    e.stopPropagation();
    onRemove(index)
  }

  render() {
    const { file, isUploadAllowed, isUploader, isNotRemoved } = this.props;

    return (
      <div
        className='single-file-list-view d-flex justify-content-between align-items-center cursor-pointer'
        onClick={this.downloadFile}
      >
        <div className='file-description d-flex align-items-center'>
          <Icon name='file' height={16} width={16} fill='#0088d0' className='file-icon' />
          <p className='file-name'>{file.name}</p>
        </div>
        <div className='file-info d-flex align-items-center'>
          <p className='type'>{getFileType(file.type)}</p>
          <p className='size'>{formatBytes(file.size)}</p>
          {((isUploadAllowed && !isNotRemoved) || isUploader) && (
            <button className='delete-file-button' onClick={this.onRemove}>
              <Icon name='delete' height={16} width={16} fill='#737678' />
            </button>
          )}
        </div>
      </div>
    );
  }
}

import { useEffect, useState } from 'react';
import { customerBackgroundModel, primaryPointOfContactModel } from './CustomerInformation.constants';
import Validators from 'library/utilities/Validators';

export const useCustomerInformation = (props) => {
  const {customerDetilsForm, primaryPointOfContactForm} = props.formValue
  const customerBackModel = () => {
    const model = [...customerBackgroundModel];
    model[0].options = props.marketSegments;
    model[4].options = props.realtionshipWithZeiss;
    model[1].options = props.marketSubSegments;
    model[5].options = props.sbuTypes;
    return model;
  };

  useEffect(() =>{
    primaryContactModel();
  }, [customerDetilsForm.formData.customerPointOfContact])

  const primaryContactModel = () => {
    const model = [...primaryPointOfContactModel];
    if (customerDetilsForm.formData.customerPointOfContact && customerDetilsForm.formData.customerPointOfContact === 'no') {
      model[0].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
      model[0].required = true;
      model[0].showRedAsterik = true;
      model[1].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }, { check: Validators.email, message: 'invalidEmailError' }]
      model[1].required = true;
      model[1].showRedAsterik = true;
    }else {
      model[0].validators = []
      model[0].required = false;
      model[0].hideOptionalText= true;
      model[0].showRedAsterik = false;
      model[1].validators = []
      model[1].required = false;
      model[1].hideOptionalText= true;
      model[1].showRedAsterik = false;
    }
    return model;
  }

  const handleForm = ({ values, field, formName }) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  return {
    handleForm,
    customerBackModel,
    primaryContactModel,
  };

};

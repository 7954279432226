import { useEffect, useState } from 'react';
import strings from 'resources/locales/Translate';

export const useDemoCenterInformation = (props) => {
  const {formValue} = props;
  const {  demoCenterInformationForm  } = formValue;
  const [hardOrSoftwareAccessoriesList, setHardOrSoftwareAccessoriesList] = useState([]);

  const [hardOrSoftwareAccessories, setHardOrSoftwareAccessories] = useState('');

  useEffect(() => {
    if (hardOrSoftwareAccessories !== '') {
     setHardOrSoftwareAccessories(''); 
    }
  },[demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList]);

  const addIntoHardOrSoftwareAccessoriesList  = (newItem) => {
    if (hardOrSoftwareAccessories === '') {
     props.updateSEMForm({ demoCenterInformationForm: { formData: { ...demoCenterInformationForm.formData, hardOrSoftwareAccessoriesError: 'requiredErrorMessage'}}});
      return;
    }
    setHardOrSoftwareAccessoriesList(
      demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList ? [...demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList,
      {
        zmccHardwareSoftwareAccessoriesId: null,
        hardwareAccessoriesText: newItem
      }
    ]
    : 
    [
      {
        zmccHardwareSoftwareAccessoriesId: null,
        hardwareAccessoriesText: newItem
      }
    ]
    );
    setHardOrSoftwareAccessories('');
    props.isClickedOnAddDemoCenter(true);
       
    handleNonFormComponent(
      demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList ? [...demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList,
        {
          zmccHardwareSoftwareAccessoriesId: null,
          hardwareAccessoriesText: newItem
        }
      ]
      : 
      [
        {
          zmccHardwareSoftwareAccessoriesId: null,
          hardwareAccessoriesText: newItem
        }
      ]
      , 'zmccHardwareSoftwareAccessoriesList', 'demoCenterInformationForm');
  }

  const handleNonFormComponent = (values, field, formName ) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleNonFormComponentNew = (values, field, formName ) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
        },
      },
    });
  };

  const onChangeInput = (value, field) =>{
    if (field == 'hardOrSoftwareAccessories'){
        handleNonFormComponent(value, 'hardOrSoftwareAccessories', 'demoCenterInformationForm')
        if (value) {
          props.isClickedOnAddDemoCenter(false);
        }
      return setHardOrSoftwareAccessories(value);
    }
    props.setUnsavedChanges(true);
  }
  const deleteItem = (i) => {
    setHardOrSoftwareAccessoriesList([...demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList.filter((item,index) => i!=index)]);
    handleNonFormComponent([...demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList.filter((item,index) => i!=index)], 'zmccHardwareSoftwareAccessoriesList', 'demoCenterInformationForm');
  }
  return {
    hardOrSoftwareAccessoriesList,
    addIntoHardOrSoftwareAccessoriesList,
    handleNonFormComponent,
    hardOrSoftwareAccessories,
    onChangeInput,
    deleteItem,
  };

};

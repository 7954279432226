import React, { useState } from 'react';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import AssignmentCancelPopup from "../Assignment/AssignmentCancelPopup.component";


const Confirmation = (props) => {
    const {
        setActiveStep,
        onContinueBooking, 
        onCancelClick, 
        isLoading
    } = props;

    return(
        <>
        <div className='row mt-2'>
            <div className='col-12'>
                <p>{strings.zmccConfirmationPage}</p>
            </div>
            <Button
                value={strings.back}
                onClick={() => props.setActiveStep(1)}
                bgFill={false}
                styleClass='add-button col-sm-1'
                iconName='arrow'
                iconPosition='left'
                iconStyle='arrow-left'
            /> 
            <Button
                value={strings.executeBooking}
                onClick={() => props.saveZmccSemData('execute', setActiveStep)}
                bgFill={true}
                styleClass='add-button col-sm-2 ml-2'
                iconName='save'
            />
        </div>
        <div>
            <Button
                value={strings.cancel}
                onClick={() => props.cancelSemZmccLoanData()}
                bgFill={false}
                styleClass='add-button col-sm-2 zmcc-trans-cancel-button1'
                iconName='cross'
            />

        </div>
        <AssignmentCancelPopup 
          id ='cancel-zmcc-loan-data-modal'
          onCancelClick={onCancelClick}
          onContinueBooking={onContinueBooking}
          isLoading={isLoading}
       />
        </>
        
    )
}

export default Confirmation;
import { useState, useCallback } from 'react';
import get from 'lodash/get';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import { regex } from 'library/common/commonConstants/RegexConstants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { checkStockRoles } from 'library/utilities/checkRoles';

import { deliveryAddressModel } from '../Information/InformationConstants';

export const useLLInformation = ({
  transactionDetails,
  labsLocationCountries,
  toggleActionMessage,
  onEditPress,
  updateLabsLocationTransactionDetails,
  editing,
  user,
}) => {
  const {
    name,
    comment,
    showLoanApproved,
    showHelpForDismantling,
    borrower,
    transactionStatus,
    salutation,
    firstName,
    lastName,
    organization,
    department,
    street,
    houseNumber,
    postalCode,
    city,
    country,
    phone,
    email,
    operator,
    customer,
    customerInstitute,
    transactionOpportunities,
    transactionsId,
    transactionTypesOfJobs,
    operatorUser,
    llPartnerName,
    llPartnerEmail,
    state
  } = transactionDetails;
  const hasLoanApproved = showLoanApproved === 'yes';
  const dropdownOptionsArray = [
    { id: 7, value: 'approved_l_l', name: strings.approvedByLLManager },
    { id: 8, value: 'confirmed_l_l', name: strings.confirmedByLLPartner },
    { id: 6, value: 'checked', name: strings.completed },
    { id: 5, value: 'cancelled', name: strings.cancelLLTransaction },
  ];
  const dropdownOptionsKitArray = [
    { id: 7, value: 'confirmed_l_l', name: strings.confirmedByLLPartner },
    { id: 6, value: 'checked', name: strings.completed },
    { id: 5, value: 'cancelled', name: strings.cancelLLTransaction },
  ];
  const transactionStatusOptions = hasLoanApproved ? dropdownOptionsArray : dropdownOptionsKitArray;
  const typesOfJobsValues = transactionTypesOfJobs.map(item => item.typesOfJobsId);
  const demoTimeValues = {};
  transactionTypesOfJobs.forEach(item => {
    if (item.typesOfJobsId) {
      demoTimeValues[item.typesOfJobsId] = item.demoTime;
    }
  });
  const [demoSiteForm, setDemoSiteForm] = useState({
    formData: {
      salutation,
      firstName,
      lastName,
      organization,
      department,
      street,
      houseNumber,
      postalCode,
      city,
      countryId: country.id,
      countryIdFullValue: { id: country.id, name: country.name },
      phone,
      email,
      state,
    },
  });
  const [shortDescription, setShortDescription] = useState({
    value: name,
    error: null,
  });
  const [requestor, setRequestor] = useState({
    value: borrower ? borrower.accountId : null,
    fullValue: borrower,
  });
  const [customerField, setCustomerField] = useState({
    value: customer,
    error: null,
  });
  const [customerInstituteField, setCustomerInstituteField] = useState({
    value: customerInstitute,
    error: null,
  });
  const [operatorField, setOperatorField] = useState({
    value: operator,
    fullValue: { id: operator, value: operator },
  });
  const [nameOfOperatorfield, setNameOfOperator] = useState({
    value: operatorUser ? operatorUser.accountId : null,
    fullValue: operatorUser,
  });
  const transactionStatusId = get(transactionDetails, 'transactionStatus.transactionStatusId');
  const transactionStatusFullValue = dropdownOptionsArray.find(item => item.id === transactionStatusId) || {};
  const [transactionStatusField, setTransactionStatusField] = useState({
    value: transactionStatus.transactionStatusId,
    fullValue: { name: transactionStatusFullValue.name, id: transactionStatusId },
  });
  const [commentField, setCommentField] = useState({
    value: comment,
    error: null,
  });

  const [llPartnerEmailFeild, setllPartnerEmailFeild] = useState({
    value: llPartnerEmail,
    error: null,
  });

  const [llPartnerNameFeild, setllPartnerNameFeild] = useState({
    value: llPartnerName,
    error: null,
  });

  const [helpForDismantlingCheckbox, setHelpForDismantling] = useState(showHelpForDismantling);
  const [opportunities, setOpportunities] = useState(transactionOpportunities);
  const [deletedTransactionOpportunityIds, setDeletedTransactionOpportunityIds] = useState([]);
  const [isOpportunityIdCrmError, setIsOpportunityIdCrmError] = useState(false);
  const [enteredOpportunityId, setEnteredOpportunityId] = useState({
    value: '',
    error: '',
  });
  const [typesOfJobs, setTypesOfJobs] = useState(typesOfJobsValues);
  const [demoTime, setDemoTime] = useState(demoTimeValues);
  const [isLoader, setIsLoader] = useState(false);

  const operatorModel = [
    { id: 'ZEISS', value: 'ZEISS' },
    { id: 'Partner', value: 'Partner' },
  ];

  const openConfirmPopup = useCallback(() => {
      $('#confirm-partner').modal('show');
    
  }, []);

  const onSaveClick = (value, fullValue) =>{
    if (value === 'ZEISS'){
      setllPartnerEmailFeild({
        value: '',
        error: null
      })
      setllPartnerNameFeild({
        value: '',
        error: null
      })
    }
    else if(value === 'Partner'){
      setNameOfOperator({
        value: null,
        fullValue: null
      })
    }

    $('#confirm-partner').modal('hide');
  }

  const onCloseClick = (value, fullValue) =>{
    if (value === 'ZEISS'){
      setNameOfOperator((prevState) => (
        {
        ...prevState,
        value: prevState.value,
        fullValue: prevState.fullValue
      }))
      
      setOperatorField((prevState) => ({
        ...prevState,
        value: 'Partner',
        fullValue: {id:'Partner', value:'Partner' }
      }))
    }
    else if (value === 'Partner'){
      setllPartnerEmailFeild((prevState) => ({
        ...prevState,
        value: prevState.value,
        fullValue: prevState.fullValue
      }))
      setllPartnerNameFeild((prevState) => ({
        ...prevState,
        value: prevState.value,
        fullValue: prevState.fullValue
      }))
      setOperatorField((prevState) => ({
        value: 'ZEISS',
        fullValue: {id:'ZEISS', value:'ZEISS' }
      }))
    }
    $('#confirm-partner').modal('hide');
  }

  const onChangeDropdown = (value, field, fullValue) => {
    if (field === 'requestor') {
      return setRequestor({ value, fullValue });
    }

    if (field === 'transactionStatus') {
      return setTransactionStatusField({ value, fullValue });
    }
    if (field === 'operatorField') {
      if (operator !== null) {
        openConfirmPopup();
      }
      
      return setOperatorField({ value, fullValue });
    }
    if (field === 'nameOfOperator') {
      return setNameOfOperator({ value, fullValue });
    }
  };

  const onChangeCheckbox = () => {
    setHelpForDismantling(!helpForDismantlingCheckbox);
  };

  const onChangeInput = (value, field) => {
    if (field === 'shortDescription') {
      return setShortDescription({
        value,
        error: null,
      });
    }

    if (field === 'commentField') {
      return setCommentField({
        value,
        error: null,
      });
    }

    if (field === 'customerField') {
      return setCustomerField({
        value,
        error: null,
      });
    }

    if (field === 'customerInstituteField') {
      return setCustomerInstituteField({
        value,
        error: null,
      });
    }

    if (field === 'llPartnerEmailFeild') {
      return setllPartnerEmailFeild({
        value,
        error: null,
      });
    }

    if (field === 'llPartnerNameFeild') {
      return setllPartnerNameFeild({
        value,
        error: null,
      });
    }
  };

  const onChangeDemoTime = (id, value) => {
    const inputValue = value.trim();

    if (inputValue && !regex.numberWithDecimals.test(inputValue)) return;
    if (inputValue && inputValue[0] === '.') return;
    if (inputValue && inputValue.split('.')[1] && inputValue.split('.')[1].length > 2) return;
    if (inputValue && inputValue > 99.99) return;
    if (inputValue && inputValue < 0) return;
    if (inputValue.includes('000')) return;

    setDemoTime(prevState => ({ ...prevState, [id]: inputValue }));
  };

  const onChangeTypeOfJobs = (_, id) => {
    if (typesOfJobs.includes(id)) {
      const ids = typesOfJobs.filter(item => item !== id);
      return setTypesOfJobs(ids);
    }

    const newTypes = [...typesOfJobs];
    newTypes.push(id);
    setTypesOfJobs(newTypes);
  };

  const onChangeForm = ({ values }) => {
    setDemoSiteForm({
      ...demoSiteForm,
      formData: {
        ...demoSiteForm.formData,
        ...values,
      },
    });
    
  };

  const getAddressModel = useCallback(() => {
    const newModel = [...deliveryAddressModel];
    newModel[10].options = labsLocationCountries;

    return newModel;
  }, [labsLocationCountries]);

  const addressModel = getAddressModel();

  const setOpportunity = value => {
    const updatedOpportunities = [
      ...opportunities,
      {
        opportunityNumber: value,
        transactionOpportunityId: 0,
      },
    ];
    setOpportunities(updatedOpportunities);
  };

  const removeOpportunity = key => {
    const updatedOpportunities = opportunities.filter((_, index) => index !== key);
    const deletedOpportunityId = opportunities.find((_, index) => index === key).transactionOpportunityId;
    let arrayIds = [];

    if (deletedOpportunityId) {
      arrayIds = [...deletedTransactionOpportunityIds, deletedOpportunityId];
    }

    setOpportunities(updatedOpportunities);
    setDeletedTransactionOpportunityIds(arrayIds);
  };

  const updateOpportunities = (value, key) => {
    const updatedOpportunities = [...opportunities];
    updatedOpportunities[key].opportunityNumber = value;
    updatedOpportunities[key].error = '';

    setOpportunities(updatedOpportunities);
  };

  const handleEnteredOpportunityId = value => {
    setEnteredOpportunityId(prevState => ({ ...prevState, ...value }));
  };

  const handleOpportunityIdCrmError = isError => {
    setIsOpportunityIdCrmError(isError);
  };

  const validateFields = () => {
    let isValid = true;

    if (!shortDescription.value) {
      isValid = false;
      setShortDescription(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (!requestor.value) {
      isValid = false;
      setRequestor(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (!customerField.value) {
      isValid = false;
      setCustomerField(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (!customerInstituteField.value) {
      isValid = false;
      setCustomerInstituteField(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (!commentField.value) {
      isValid = false;
      setCommentField(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }

    if (enteredOpportunityId.value) {
      isValid = false;
      if (user && user.crmSystemId === 'ngcrm') {
        handleEnteredOpportunityId({ error: `${strings.pleaseMindThatFieldNeedsToHave(18)}` });
      }else{
        handleEnteredOpportunityId({ error: 'pleaseMindThat' });
      }
    }

    const operatorId = operatorField.value === 'ZEISS' ? 1 : (operatorField.value === 'Partner' || operatorField.value === 'PARTNER') ? 2 : null;
    if (operatorId == 2){
      if (!llPartnerEmailFeild.value) {
        isValid = false;
        setllPartnerEmailFeild(prevState => ({
          ...prevState,
          error: 'requiredErrorMessage',
        }));
      }
      if(!llPartnerNameFeild.value){
        isValid = false;
        setllPartnerNameFeild(prevState => ({
          ...prevState,
          error: 'requiredErrorMessage',
        }));
      }
      if (llPartnerNameFeild.value && !regex.email.test(llPartnerEmailFeild.value)){
        isValid = false;
        setllPartnerEmailFeild(prevState => ({
          ...prevState,
          error: 'invalidEmailError',
        }));
      }
    }

    return isValid;
  };

  const onSave = async () => {
    const isValidFields = validateFields();
    const isValidForm = validateForm({ form: demoSiteForm, model: addressModel });
    if (!isValidFields || !isValidForm.isFormValid) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'formIsInvalid');
    }

    if (!typesOfJobs.length) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'pleaseSelectAnOption');
    }

    const opportunitiesNumber = opportunities.map(item => ({
      transactionOpportunityId: item.transactionOpportunityId,
      opportunityNumber: item.opportunityNumber,
    }));
    const hasOpportunities = !!opportunities.length;
    const hasDeletedOpportunities = !!opportunities.length;
    const operatorId = operatorField.value === 'ZEISS' ? 1 : 2;


    const {
      firstName,
      lastName,
      salutation,
      organization,
      department,
      street,
      houseNumber,
      postalCode,
      city,
      state,
      countryId,
      phone,
      email,
    } = demoSiteForm.formData;
    const typesOfJobsWithDemoTime = {};
    typesOfJobs.forEach(item => {
      if (item) {
        typesOfJobsWithDemoTime[item] = demoTime[item] ? demoTime[item] : null;
      }
    });
    const dataToSend = {
      name: shortDescription.value,
      reasonForLoanId: get(transactionDetails, 'reasonForLoan.reasonForLoanId'),
      opportunitiesNumber: hasOpportunities ? opportunitiesNumber : null,
      deletedTransactionOpportunityIds: hasDeletedOpportunities ? deletedTransactionOpportunityIds : null,
      helpForBuildingAndDismantling: helpForDismantlingCheckbox,
      comment: commentField.value,
      customer: customerField.value,
      customerInstitute: customerInstituteField.value,
      operator: operatorField.value ? operatorId : null,
      typesOfJobs: typesOfJobsWithDemoTime,
      borrowerId: requestor.value,
      firstName,
      lastName,
      salutation,
      organization,
      department,
      street,
      houseNumber,
      postalCode,
      city,
      state,
      countryId,
      phone,
      email,
      operatorId: nameOfOperatorfield.value,
      llPartnerEmail: llPartnerEmailFeild.value,
      llPartnerName:  llPartnerNameFeild.value
    };
    const isChangedStatus =
      transactionStatusField.fullValue &&
      transactionStatusField.fullValue.name !== transactionStatus.transactionStatusTranslated[0].name;
    const status = isChangedStatus ? transactionStatusField.fullValue.value : null;

    setIsLoader(true);
    const { success } = await updateLabsLocationTransactionDetails(transactionsId, dataToSend, status, editing);
    setIsLoader(false);

    if (success) {
      onEditPress();
    }
  };

  const stock = get(transactionDetails, 'transactionKit.stockInformation');
  const rolesForSaving = ['Manager', 'Stock Controller', 'Dispatcher', 'Borrower'];
  const rolesForEditing = ['Manager', 'Stock Controller', 'Dispatcher'];
  const borrowerRole = ['Borrower'];
  let operaterUserEditPermission = false;
  const operaterUserPermission = () => {
    if (
      user &&
      user.accountId === transactionDetails &&
      transactionDetails.operatorUser &&
      transactionDetails.operatorUser.accountId
    ) {
      return (operaterUserEditPermission = true);
    }
  };
  const hasSavePermission =
    (user && user.admin) || checkStockRoles(rolesForSaving, user, stock) || operaterUserPermission;
  const hasEditingPermissions = (user && user.admin) || checkStockRoles(rolesForEditing, user, stock);
  const isBorrower = checkStockRoles(borrowerRole, user, stock);

  const hasPermissions = true;

  return {
    demoSiteForm,
    shortDescription,
    requestor,
    transactionStatusField,
    commentField,
    transactionStatusOptions,
    helpForDismantlingCheckbox,
    customerField,
    customerInstituteField,
    operatorField,
    hasPermissions,
    addressModel,
    operatorModel,
    opportunities,
    enteredOpportunityId,
    isOpportunityIdCrmError,
    typesOfJobs,
    demoTime,
    isLoader,
    hasEditingPermissions,
    isBorrower,
    hasSavePermission,
    transactionStatusFullValue,
    nameOfOperatorfield,
    onChangeInput,
    onChangeDropdown,
    onChangeCheckbox,
    onChangeForm,
    setOpportunity,
    removeOpportunity,
    updateOpportunities,
    handleEnteredOpportunityId,
    onSave,
    handleOpportunityIdCrmError,
    onChangeDemoTime,
    onChangeTypeOfJobs,
    llPartnerEmailFeild,
    llPartnerNameFeild,
    openConfirmPopup,
    onSaveClick,
    onCloseClick
  };
};

import { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import { regex } from 'library/common/commonConstants/RegexConstants';

export const useOrderPopup = ({
  id,
  handleClickOnConfirmation,
  handleClickOnReserve,
  updateList,
  kitId,
  isReservedKit,
}) => {
  const [state, setState] = useState({
    value: '',
    error: '',
  });
  const [currentKitId, setCurrentKitId] = useState(null);
  const [isReservationLoading, setIsReservationLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [isDisabledReservation, setIsDisabledReservation] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setCurrentKitId(kitId);
  }, [kitId]);

  useEffect(() => {
    setIsDisabledReservation(isReservedKit);
  }, [isReservedKit]);

  const handleClosePopup = useCallback(() => {
    setCurrentKitId(null);
    setIsDisabledReservation(false);
    setState({
      value: '',
      error: '',
    });
    setError('');
    $(`#${id}`).modal('hide');
  }, [id]);

  const handleConfirmation = async () => {
    if (state.value.length < 10) {
      return setState(prevState => ({
        ...prevState,
        error: 'CRMQuoteIDShouldBe10Number',
      }));
    }
    setError('');
    setIsOrderLoading(true);
    const { success } = await handleClickOnConfirmation(currentKitId, state.value);
    setIsOrderLoading(false);

    if (success) {
      handleClosePopup();
      updateList();
    } else {
      setError('somethingWentWrongMessage');
    }
  };

  const handleReservation = async () => {
    setIsReservationLoading(true);
    await handleClickOnReserve(currentKitId);
    setIsReservationLoading(false);
    handleClosePopup();
  };

  const handleInput = value => {
    const inputValue = value.trim();
    if (inputValue.length > 10) return;
    if (inputValue && !regex.number.test(inputValue)) return; 
    setState({ value: inputValue, error: '' });
  };

  return {
    state,
    isReservationLoading,
    isOrderLoading,
    isDisabledReservation,
    error,
    handleConfirmation,
    handleClosePopup,
    handleReservation,
    handleInput,
  };
}

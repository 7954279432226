import Validators from 'library/utilities/Validators';

export const ratingModel = [
  {
    label: 'onTimeDelivery',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'onTimeDelivery',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'yes',
        value: 'yes'
      },
      {
        id: 'no',
        value: 'no'
      }
    ]
  },
  {
    label: 'orderCompleteness',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'orderCompleteness',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'yes',
        value: 'yes'
      },
      {
        id: 'no',
        value: 'no'
      }
    ]
  },
  {
    label: 'equipmentFunctionality',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'equipmentFunctionality',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'yes',
        value: 'yes'
      },
      {
        id: 'no',
        value: 'no'
      }
    ]
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
  },
];

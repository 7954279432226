export const de = {
  document: 'Dokumente and Trainings',
  contact: 'Kontakt',
  homepage: 'Homepage',
  calendar: 'Kalender',
  loan: 'Buchen',
  transaction: 'Transaktionen',
  kits: 'Geräte ',
  stocks: 'Lager',
  administration: 'Verwaltung',
  de: 'Deutsch',
  en: 'English',
  zh: 'Chinesisch',
  ja: 'Japanisch',
  ko: 'Koreanisch',
  fr: 'Französisch',
  es: 'Spanisch',
  pt: 'Portugiesisch',
  title: 'ZEISS Demo Management App',
  description:
    'Die „ZEISS Demo Management App“ unterstützt unsere globalen Vertriebs- und Demolager-Teams im Demoleihe-Prozess. Die App bietet einen zentralen Anlaufpunkt um Demo-Inventar einfach zu leihen, zu Lagern hinzuzufügen, zu verwalten und wieder abzuverkaufen. Ziel ist es, Kosteneffizienz zu gewährleisten und die Gewinnchancen im Vertrieb zu erhöhen. Bei Kommentaren oder Fragen können Sie sich gerne an unseren Support wenden.',
  myTransactions: 'Meine Transaktionen',
  kitLists: 'Kit-Listen',
  myAccount: 'Mein Konto',
  loginButton: 'Anmelden',
  loginTitle: 'Anmelden',
  loginDescriptionText:
    'Um die „ZEISS Demo Management App“ nutzen zu können, müssen Sie sich anmelden. Falls Sie noch keine persönlichen Zugangsdaten haben, können Sie sich jetzt registrieren.',
  registerButton: 'Registrieren',
  registerTitle: 'Noch kein Konto?',
  registerDescriptionText: 'Registrieren Sie sich jetzt und erhalten Sie Zugang zu unseren Demolagern.',
  yourBenefits: 'Ihre Vorteile',
  benefits1: 'Einfach und schnell Demo organisieren',
  benefits2: 'Vorgangstracking',
  benefits3: 'Unkomplizierte Abwicklung',
  kitLoan: 'Kit-Leihe',
  courseLoan: 'Kurs-Leihe',
  singleLoan: 'Einzel-Leihe',
  c2cLoan: 'C2C-Leihe',
  menu: 'Menü',
  back: 'Zurück',
  myBookings: 'Meine Buchungen',
  changeProfileData: 'Profildaten ändern',
  requestStockPermission: 'Lagerberechtigung anfragen',
  logout: 'Abmeldung',
  optionalField: '(optional)',
  descriptionText: 'Beschreibung',
  descriptionTextStep3: 'Auftragsübersicht',
  addressText: 'Adresse',
  pleaseSelect: 'Bitte auswählen',
  loanProcess: 'Leih-Vorgang',
  stock: 'Lager',
  stockMaxThree: 'Lager (max 20.)',
  categorySystem: 'System-Kategorie',
  loanFrom: 'Beginn der Leihstellung',
  loanTo: 'Ende der Leihstellung',
  showOptions: 'Optionen anzeigen',
  filterData: 'Daten filtern',
  filterStockPlaceholder: 'Filterbestände',
  filterCategoryPlaceholder: 'Klasse des Filtersystems',
  pleaseSelectDate: 'Bitte wählen Sie ein Datum',
  selectDate: 'Bitte wahlen',
  requiredErrorMessage: 'Feld darf nicht leer sein',
  invalidEmailError: 'E-Mail ist nicht gültig',
  invalidNumberError: 'Nummer ist nicht gültig',
  stockController: 'Lagerverwalter',
  stockControllerSingle: 'Lagerverwalter',
  locationName: 'Name des Demolagers',
  locationShortName: 'Kurzbezeichnung des Demolagers',
  country: 'Land',
  countryLand: 'Land',
  businessUnit: 'Geschäftsbereich',
  salutation: 'Anrede',
  firstName: 'Vorname',
  lastName: 'Nachname',
  organization: 'Organisation',
  department: 'Abteilung',
  street: 'Straße',
  number: 'Hausnummer',
  postalCode: 'Postleitzahl',
  city: 'Stadt',
  phone: 'Telefon',
  email: 'E-Mail',
  cancel: 'Abbrechen',
  cancelTransaction: 'Stornieren',
  save: 'Speichern',
  delete: 'Löschen',
  kitNumber: 'Artikel-Nummer',
  details: 'Details',
  bookNow: 'Jetzt buchen',
  newForLoan: 'Neueste Demo Kits',
  borrowedFrequently: 'Häufig ausgeliehen',
  stockInformationSavedSuccessfully: 'Demolager erfolgreich gespeichert',
  formIsInvalid: 'Bitte füllen Sie alle Pflichtfelder aus.',
  entitlementIndividuals: 'Berechtigte Personen',
  informationTabText: 'Informationen',
  entitlementTabText: 'Anspruch',
  errorOccuredText: 'Es ist ein Fehler aufgetreten.',
  navigation: 'Transaktionen:',
  address: 'Adresse',
  socialMedia: 'Social Media',
  createStock: 'Demolager anlegen',
  information: 'Informationen',
  entitlements: 'Berechtigungen',
  workflows: 'Arbeitsabläufe',
  createKit: 'Kit erstellen',
  storageLocation: 'Lagerort im Lager',
  loanOrder: 'Leihauftrag',
  referenceToSapERP: 'Leihauftrag',
  systemClassText: 'System-Kategorie',
  conditionText: 'Zustand',
  commentText: 'Kommentar',
  deliveryCommentText: 'Lieferkommentar',
  days: 'Tage',
  delivery: 'Lieferung',
  returnDelivery: 'Rücklieferung',
  reconditioning: 'Aufarbeitung',
  systemMainComponent: 'System/Hauptkomponente',
  serialNumber: 'Seriennummer',
  dateOfManufacture: 'Herstellungsdatum (MM/YYYYYYY)',
  countryOfOrigin: 'Herkunftsland',
  commodityCode: 'Warencode',
  kitStatus: 'Kit-Status',
  assignment: 'Zuweisung',
  mainComponent: 'Hauptkomponente',
  defaultPeriods: 'Standardzeiträume',
  packageDimensions: 'Abmessungen der Verpackung',
  availability: 'Verfügbarkeit',
  availableFrom: 'Verfügbar ab',
  generalInformation: 'Allgemeine Informationen',
  availableTo: 'Verfügbar bis',
  kitName: 'Name des Kits',
  ibaseText: 'iBase',
  systemValue: 'Bruttolistenpreis',
  marketingMaterialText: 'Marketingmaterial',
  hideKitText: 'Kit ausblenden',
  euroSymbol: '\u20AC',
  addButtonText: 'Hinzufügen',
  noDataFoundText: 'Keine Daten verfügbar',
  addFileText: 'Datei hinzufügen',
  imagesText: 'Bilder',
  searchForUsernameText: 'Suche nach Nutzername',
  filterByRoleText: 'Nutzerrollen filtern',
  userLabelText: 'Benutzer',
  roleLabelText: 'Nutzerrolle',
  specialDeliveryTimes: 'Länderübergreifende Lieferzeiten',
  targetCountry: 'Zielland',
  deliveryTimes: 'Lieferzeit',
  messages: 'Nachrichten',
  components: 'Komponenten',
  componentsWithComment: 'Komponenten (Bitte Materialnummer angeben)',
  accessories: 'Zubehör für',
  salesComponentLabel: 'Stückliste',
  furtherImportantComponentLabel: 'Weitere wichtige Komponenten',
  freeAccessoriesLabel: 'Kostenloses Zubehör',
  attachments: 'Anhänge',
  searchInput: 'Was Suchen Sie?',
  allCountries: 'Alle Länder',
  allBusinessUnits: 'Alle Geschäftsbereiche',
  kitSavedSuccessMessage: 'Kit erfolgreich gespeichert',
  kitSavedSuccessMessageWithHideKit:
    'Kit erfolgreich gespeichert. Bitte beachten Sie, dass das Kit nicht im Kalender angezeigt wird, wenn "Kit ausblenden" markiert ist.',
  kitSavedSuccessMessageWithLost: 'Das Demokit wird nicht im Kalender angezeigt, da es als verloren gemeldet wurde.',
  kitSavedSuccessMessageWithIncomplete: 'Das Demokit wird nicht im Kalender angezeigt, da es unvollständig ist.',
  kitSavedSuccessMessageWithDefective: 'Das Demokit wird nicht im Kalender angezeigt, da es als defekt gemeldet wurde.',
  kitSavedSuccessMessageWithRefurbishment: 'Kit saved successfully with kit reparation details.',
  somethingWentWrongMessage: 'Something went wrong',
  imprintsText: 'Impressum',
  legalNoticesText: 'Rechtshinweise',
  dataProtectionText: 'Datenschutz',
  termsAndConditionText: 'Geschäftsbedingungen',
  carlZeissInternational: 'ZEISS International',
  createKitCountryAndCommodityExtraMessage: 'Benötigt für die Zollabfertigung, wird auf Lieferscheinen gedruckt.',
  kitDetailsSavedWithMandatoryFieldsUnfilled:
    'Kit-Details wurden erfolgreich gespeichert, aber das Demo-Kit kann erst gebucht werden, wenn alle Pflichtfelder ausgefüllt sind.',
  // kitDetailsSavedWithMandatoryFieldsUnfilled: 'Die Felder sind rot markiert, die Pflichtfelder sind und ausgefüllt werden müssen, damit das Kit für die Buchung zur Verfügung steht.',
  requestStockPermissionButtonText: 'Lagerberechtigung anfragen',
  documentsPageTitleText: 'Dokumente',
  documentUploadedSuccessfully: 'Dokument(e) erfolgreich hochgeladen',
  noFilesUploadedErrorMessage: 'Keine Dateien hinzugefügt.',
  requestStockPermissionSuccess: 'Antrag auf Lagerzulassung erfolgreich abgeschlossen.',
  kitImageUploadedSuccessfully: 'Kit-Bilder wurden erfolgreich hochgeladen.',
  kitAttachmentUploadedSuccessfully: 'Kit-Anhänge wurden erfolgreich hochgeladen.',
  fillKitHeaderInformation: 'Bitte füllen Sie die Kopfinformationen des Kits aus.',
  stockImages: 'Bilder',
  stockImageUploadedSuccessfully: 'Stock Images wurde erfolgreich hochgeladen.',
  addAtleastOneStockController: 'Bitte mindestens einen Lagerverwalter hinzufügen',
  stockDeletedSuccessfully: 'Bestand erfolgreich gelöscht.',
  confirmDeleteStock: 'Bestand löschen?',
  confirmDeleteStockMessage: 'Sind Sie sicher, dass Sie den Bestand löschen möchten?',
  confirm: 'Bestätigen',
  maintainKits: 'Kit bearbeiten',
  copyKit: 'Kit kopieren',
  canNotDeleteNoEmptyStock: 'Es sind noch Demo-Kits auf Lager. Es ist nur möglich, leere Bestände zu löschen.',
  edit: 'Bearbeiten',
  showFilter: 'Filter einblenden',
  hideFilter: 'Filter ausblenden',
  itemPerPage: 'Einträge pro Seite',
  shortName: 'Kurzname',
  shortNameCountry: 'Länderkürzel',
  exportCurrentList: 'Liste exportieren',
  exportList: 'Liste exportieren',
  dateCreated: 'Erstellungsdatum',
  status: 'Status',
  currentPosition: 'Auslieferunggsstatus',
  showDetails: 'Zeige Details',
  createServiceTicket: 'Service Ticket erstellen',
  notAvailable: 'Nicht verfügbar',
  sold: 'Verkauft',
  toSell: 'Zum Verkauf',
  inRefurbishment: 'In Reparatur',
  ok: 'Ok',
  visible: 'Sichtbar',
  invisible: 'Unsichtbar',
  maintainStocks: 'Demolager konfigurieren',
  confirmDeleteKit: 'Kit löschen?',
  confirmDeleteKitMessage: 'Sind Sie sicher, dass Sie das Kit löschen wollen?',
  kitDeletedSuccessfully: 'Kit erfolgreich gelöscht.',
  available: 'Verfügbar',
  readyForLoan: 'Bereit zum Verleih',
  beforeDelivery: 'Vor Auslieferung',
  deliveryCustomer: 'Lieferung zum Kunde',
  atDemo: 'Demo',
  atDemoUnconfirmed: 'Demo (unbestätigt)',
  returnToStock: 'Rücklieferung zum Lager',
  returnToStockUnconfirmed: 'Rücklieferung zum LLager (unbestätigt)',
  fileAttached: 'Anhang',
  entitlementAtleastOneShouldBeAdded: 'Bitte mindestens einen Nutzer mit Rolle auswählen',
  kitAttachmentsDeleted: 'Kit-Anhänge erfolgreich gelöscht',
  duplicateStockNameError: 'Stock Name already exists. Please enter another stock name',
  kitCopySuccessMessage: 'Kit erfolgreich kopiert',
  kitCopySuccessMessageWithHideKit:
    'Kit erfolgreich kopiert. Bitte beachten Sie, dass das Kit nicht im Kalender angezeigt wird, wenn "Kit ausblenden" markiert ist.',
  kitCopySuccessMessageWithLost: 'Das Demokit wird nicht im Kalender angezeigt, da es als verloren gemeldet wurde.',
  kitCopySuccessMessageWithIncomplete:
    'Kit erfolgreich kopiert. Das Demokit wird nicht im Kalender angezeigt, da es unvollständig ist',
  kitCopySuccessMessageWithDefective:
    'Kit erfolgreich kopiert. Das Demokit wird nicht im Kalender angezeigt, da es als defekt gemeldet wurde.',
  kitCopySuccessMessageWithRefurbishment:
    'Kit erfolgreich kopiert. Das Demokit wird nicht im Kalender angezeigt, da es momentan im Service ist.',
  kitDetailsCopiedWithMandatoryFieldsUnfilled:
    'Kit-Details wurden erfolgreich kopiert, aber das Demo-Kit kann erst gebucht werden, wenn alle Pflichtfelder ausgefüllt sind.',
  addToLoanList: 'Zur Leih-Liste hinzufügen',
  removeFromLoanList: 'Aus der Leih-Liste entfernen',
  requestLoan: 'Leihe anfragen',
  requestLoanPopoverMessage:
    'Die von Ihnen gewählten Leihfristen betragen mehr als 2 Wochen, sie müssen angefordert und dann vom Lagerleiter bestätigt werden.',
  todayText: 'Heute',
  allStocksPlaceholder: 'Alle Bestände',
  allCategorySystemsPlaceholder: 'Alle Systeme',
  continueToLoanListText: 'Weiter zur Leih-Liste',
  displayEquipment: 'Ausstattung anzeigen',
  markForBookingText: 'Markierung für Buchung/Reservierung',
  removalText: 'Entfernen',
  beginningOfBorrowLabel: 'Beginn der Leihstellung',
  endOfBorrowLabel: 'Ende der Leihstellung',
  yourLoanListHeader: 'Ihre Leih-Liste',
  loanListLabel: 'Leih-Liste',
  loanListNoticeAddressTextNormal: 'Alle Artikel werden / verschickt.',
  loanListNoticeAddressTextBold: 'an die gleiche Adresse',
  loanListNoticeAddressWithBookingMessage:
    'Wenn Sie planenan verschiedene Adressen zu liefern, müssen separate Buchungen vorgenommen werden. Bitte nutzen Sie dazu die Checkbox „Markierung für Buchung/Reservierung“.',
  positionText: 'Position',
  createMoreBooking: 'Mehr Buchungen erstellen',
  reserveText: '24-h Reservierung',
  reserveKitForSale: 'Reservieren',
  continueBooking: 'Buchung fortsetzen',
  loanListBottomNoticeText1:
    'Wenn Sie den Buchungsprozess noch nicht abschließen möchten, können Sie die ausgewählten Positionen Ihrer Leih-Liste auch zunächst für 24 Stunden reservieren..',
  loanListBottomNoticeText2:
    'Bitte beachten Sie, dass eine Reservierung nur innerhalb von 24 Stunden möglich ist und dann automatisch gelöscht wird.',
  loanBookingComingSoon: 'Diese Funktion befindet sich derzeit in der Entwicklung.',
  conflictBooking: 'Überlappende Buchungen sind nicht erlaubt.',
  reasonForLoan: 'Grund für die Leihe',
  nameShortDescription: 'Name/kurze Beschreibung',
  customerOrderNumber: 'Kunden-Bestellnummer',
  contactIdCRM: 'Kontakt-ID CRM',
  opportunityIdCRM: 'Opportunity ID CRM',
  borrower: 'Leihnehmer',
  deliveryAddress: 'Lieferadresse',
  unsubscribeText: 'Abmelden',
  pastDateBooking: 'Sie können ein Kit nicht auf vergangene Termine buchen.',
  deliveryAddresses: 'Lieferadressen',
  zeissIdPortal: 'ZEISS ID-Portal',
  createNewDeliveryAddresses: 'Neue Lieferadresse anlegen',
  deliveryAddressesSubHeader: 'Hier können Sie Ihre Lieferadressen für Transaktionen anlegen und pflegen.',
  myDeliveryAddresses: 'Meine Lieferadressen',
  createDeliveryAddessFormHeader: 'Lieferadresse anlegen',
  saveAddressButtonText: 'Adresse speichern',
  state: 'Bundesland',
  addressTitle: 'Adresstitel',
  stockNameConflictError: 'Der Bestandsname ist bereits vorhanden. Bitte wählen Sie einen anderen Lagernamen aus.',
  iBaseConflictError: 'Die iBase-Nummer ist bereits vorhanden. Bitte wählen Sie eine andere iBase-Nummer aus.',
  additionalSingleLoanRequest: 'Zusätzliche Einzel-Leihe(Bitte Materialnummer(n) angeben)',
  deliveryComment: 'Lieferkommentar',
  newKitLoan: 'Neue Kit-Leihe',
  customerData: 'Kundendaten',
  confirmation: 'Bestätigung',
  confirmationStep3: 'Auftrag bestätigen',
  additionalLoanRequest: 'Zusätzliche Leihanfrage',
  position: 'Position ',
  selectedKits: 'Ausgewählte Geräte ',
  addItem: 'Element hinzufügen',
  backToLoanList: 'Zurück zur Leih-Liste',
  customerToCustomerLoan: 'Kunden-zu-Kunden-Leihe',
  backToAssignment: 'Zurück zur Zuordnung',
  yourAddress: 'Adresse des Leihnehmers',
  newSingleLoan: 'Neue Einzel-Leihe',
  pleaseNote: 'Bitte beachten Sie:',
  pleaseNoteText: 'Die Fristen für Lieferung und Überholung werden automatisch hinzugefügt.',
  reservationFrom: 'Reservierung von',
  reservationTo: 'Reservierung für',
  reservationPeriod: 'Reservierungszeitraum',
  addressSavedSuccessfully: 'Adresse erfolgreich gespeichert!',
  editAddress: 'Adresse bearbeiten',
  editDeliveryAddessFormHeader: 'Lieferadresse bearbeiten',
  kitLoanBookedSuccessfully: 'Kit loan booked successfully',
  kitReservedSuccessfully: 'Kit erfolgreich reserviert',
  demoPeriodError:
    'Demo period for all the kits should be same to continue your booking. Or you can make separate bookings for each kit',
  selectKitsForBooking: 'Select kits to continue your booking',
  oneOrMoreKitsNotAllowedForReserve:
    'Ein oder mehrere Kits sind für die 24-Stunden-Reservierung nicht verfügbar. Bitte entfernen Sie diese von der Reservierung.',
  createNewSingleLoan: 'Einzelleihe erfolgreich angelegt',
  helpForBuildingAndDismantling: 'Unterstützung für Auf- und Abbau benötigt',
  systemClassSystem: 'System-Kategorie/System',
  quantity: 'Anzahl',
  addKit: 'Kit hinzufügen',
  courseLoanBookedSuccessfully: 'Kurs-Leihe erfolgreich gebusht',
  singleLoanCanBeBookedOnlySixWeeks: 'Einzelkredit kann nur 2 Wochen im Voraus gebucht werden.',
  pleaseSelectKits: 'Bitte Gerät auswählen',
  executeBooking: 'Buchung durchführen',
  changeBorrower: 'Leihnehmer ändern',
  backToCustomerData: 'Zurück zu den Kundendaten',
  name: 'Name',
  begin: 'Beginn',
  end: 'Ende',
  approval: 'Bestätigung',
  shipped: 'Versendet',
  receiptAtTheCustomer: 'Eingang beim Kunden',
  receiptInStock: 'Eingang im Lager',
  checked: 'Geprüft',
  transactions: 'Transaktionen',
  changeHistory: 'Änderungshistorie',
  c2cLoanProcesses: 'C2C Leih - Prozess',
  createC2CLoan: 'C2C Leihe erstellen ',
  maintainC2CLoan: 'C2C Leihe bearbeiten ',
  remove: 'Entfernen',
  saveC2CLoan: 'C2C Leihe speichern ',
  c2CLoansCreated: 'C2C Leihe erfolgreich erstellt',
  c2CLoansSaved: 'C2C Leihe erfolgreich erfolgreich',
  startDate: 'Startdatum ',
  endDate: 'Enddatum ',
  changeDates: 'Datum ändern',
  rating: 'Bewertung',
  saveDates: 'Datum speichern ',
  adjustDates: 'Datum anpassen ',
  createMessage: 'Nachricht erstellen ',
  showShippingDocuments: 'Versandpapiere anzeigen',
  showReturnDeliveryNote: 'Rücklieferschein anzeigen',
  showAllMessages: 'Alle Nachrichten anzeigen',
  period: 'Zeitraum',
  borrowedKit: 'Verliehenes Kit',
  crmContactId: 'CRM Kontakt ID',
  crmOpportunityId: 'CRM Opportunity ID',
  crmOpportunityStatus: 'CRM Opportunity Status',
  crmiBase: 'CRM iBase',
  customerOrderId: 'Angebotsnummer',
  approveConfirm: 'Transaktionstatus',
  location: 'Lager',
  loanCreatedBy: 'Leihe erstellt  von',
  showTransactionDetails: 'Zeige Transaktion Details  ',
  returnGoodsBooking: 'Rückwarenbuchung',
  returnGoodsBookingMenuText: 'Retourenbuchung - Einzel-/Kursleihe',
  entered: 'Eingetragen ',
  date: 'Datum',
  deliveryNoteNumber: 'Rückwarenbuchung',
  completeness: 'Vollständigkeit',
  condition: 'Zustand',
  cancellationRequest: 'Stornierungsanfrage ',
  transactionStatus: 'Transanktionstatus',
  pleaseCall:
    'Wenn Sie Details in Ihrer Anfrage ändern möchten, bitte melden Sie sich telefonisch unter [phone] oder schreiben Sie eine Email an [email]. Vielen Dank.',
  dateType: 'Datumstyp',
  dateRange: 'Zeitraum',
  cancellationRequestSubmitted: 'Ihre Kündigungsanfrage wurde übermittelt.',
  rejectRequestCancellationRequestSubmitted: 'Your cancellation request has been deferred.',
  chooseCategory: 'Kategorie auswählen',
  crmId: 'CRM-ID',
  masterDataSavedSuccessfully: 'Stammdaten erfolgreich gespeichert',
  loanPeriodStart: 'Leih-Zeitraum von',
  loanPeriodEnd: 'Leih-Zeitraum bis',
  loanPeriod: 'Leih-Zeitraum ',
  deliveryFrom: 'Lieferung von',
  deliveryTo: 'Lieferung bis',
  returnDeliveryFrom: 'Rücklieferung von',
  returnDeliveryTo: 'Rücklieferung bis',
  reconditioningFrom: 'Aufbereitung von',
  reconditioningTo: 'Aufbereitung bis',
  returnGoodsBookingFrom: 'Rückwarenbuchung - von',
  returnGoodsBookingTo: 'Rückwarenbuchung - bis',
  editedBy: 'Bearbeitet durch',
  deliver: 'Auslieferung',
  loanApproved: 'Leihe genehmigt ',
  target: 'Soll',
  viewOnlyPermissions: 'Sie haben nur eine Leseberechtigung',
  datesSavedSuccessfully: 'Datum erfolgreich gespeichert.',
  savedSuccessfully: 'Erfolgreich gespeichert',
  c2cLoanProcessText: id => `Dieser Leihprozess ist Teil eines C2C-Prozesses. ${id && `Process ID: `}`,
  loanRequestApprovedSuccessfully: 'Leihanfrage bestätigt.',
  requestApprovedSuccessfully: 'Anfrage bestätigt.',
  requestCancelledSuccessfully: 'Anfrage erfolgreich storniert.',
  transactionUpdatedSuccessfully: 'Transaktion erfolgreich geändert.',
  errorSavingDates: 'Fehler beim Speichern des Datums.',
  requestAccepted: 'Antrag erfolgreich angenommen',
  requestRejected: 'Antrag erfolgreich abgelehnt',
  cancelledProcessess: 'Stornierte Transaktionen',
  archiveProcessess: 'Archivierte Transaktionen',
  damageOrLossReports: 'Fehler- und Verlustmeldung',
  loadMoreMessages: 'Mehr Nachrichten anzeigen',
  loadMore: 'Mehr laden',
  noMessageDamage: 'Keine Fehler- und Verlustmeldungen.',
  noMessage: 'Keine Nachrichten gefunden.',
  requestAlreadyExists: 'Sie haben diesen Bestand bereits angefordert. Bitte wählen Sie ein anderes Lager aus',
  requestCancel: 'Buchung stornieren?',
  cancelBooking: 'Stornierunganfrage bestätigen',
  rejectCancelRequest: 'Stornierung ablehnen',
  confirmRejectCancel: 'Sind Sie sicher, dass Sie diese Stornierung ablehnen möchten?',
  confirmBookingCancel: 'Sind Sie sicher, dass Sie die Transaktion stornieren möchten??',
  reasonCancelBooking:
    'Sind Sie sicher, dass Sie Ihre Buchung stornieren möchten? Wenn ja, geben Sie bitte den Grund in der Kommentarfeld unten an.',
  courseLoanCanBeBookedOnlySixWeeks: 'Kursleihe kann nur 3 Wochen im Voraus gebucht werden',
  loanRequests: 'Demoanfragen',
  sapOrderCreation: 'SAP-Auftragserstellung',
  goodReceipt: 'Wareneingang Einzel-/Kursleihe',
  listReceiving: 'Leihe in Demo',
  listReconditioning: 'Aufbereitung',
  incomingDemoKits: 'Wareneingang Demokits',
  outgoingGoods: 'Warenausgang',
  cancelledProcesses: 'Stornierte Transaktionen',
  archiveProcesses: 'Archivierte Transaktionen',
  cancellationApproval: 'Stornierungsanfragen',
  openProcesses: 'Offene Transaktionen',
  inProcess: 'In-Prozess',
  cancelRequestRaised: 'Hierfür wurde bereits eine Stornierungsanfrage gestellt.',
  noComponentsAvailableMessage: 'Keine Komponenten verfügbar',
  noFreeAccessoriesMessage: 'Kein kostenloses Zubehör verfügbar',
  administrationHeading: 'Bitte wählen Sie eine Option, um Daten zur Masterliste hinzuzufügen.',
  systemClassAlreadyExist: 'Systemklasse ist bereits vorhanden',
  systemComponentAlreadyExist: 'Die Hauptkomponente des Systems ist bereits vorhanden.',
  countryAlreadyExist: 'Land existiert bereits',
  selectLoanTypeMessage: 'Bitte wählen Sie den Kreditprozess aus',
  productId: 'Produkt ID',
  damageLossReport: 'Fehler- und Verlustmeldung',
  deliveryDocuments: 'Lieferdokumente',
  deliveryDocumentsHeading: 'Bitte konfigurieren Sie die Fußzeile Ihres Lieferscheins:',
  management: 'Management',
  law: 'Recht',
  companyName: 'Firmenname',
  groupName: 'Name der Geschäftseinheit',
  streetHouseNumber: 'Straße und Hausnummer',
  postalCodeCity: 'PLZ & Stadt',
  webAddress: 'Webadresse',
  placeOfJuridiction: 'Gerichtsstand',
  vatIdentificationNumber: 'USt-IdNr.',
  taxNumber: 'Steuernummer',
  weeeNumber: 'WEEE-Reg.-Nr.',
  name1: 'Name 1',
  name2: 'Name 2',
  name3: 'Name 3',
  name4: 'Name 4',
  deliveryDocumentFooterSavedSuccessfully: 'Fußzeile für Lieferdokumente erfolgreich konfiguriert',
  urlErrorMessage: 'URL ist falsch',
  webAddressErrorMessage: 'Bitte geben Sie die richtige Webadresse ein',
  twentyFourHourReservationNotAvailable: 'Nicht verfügbar für 24 Stunden Reservierung',
  fileDownloadError: 'Beim Herunterladen des Dokuments ist ein Fehler aufgetreten.',
  wrongCountryIdWhileBooking:
    'Die von Ihnen gewählte Adresse scheint unvollständig zu sein, bitte wählen Sie eine andere Adresse aus oder erstellen Sie eine neue.',
  newCourseLoan: 'Neue Kurs- Leihe',
  kitsNotSelected: 'Kits sind nicht ausgewählt',
  selectAll: 'Alle auswählen',
  reservedKitFor24Hours: '24',
  deleteDeliveryAddress: 'Lieferadresse löschen',
  deleteTheAddress: 'Sind Sie sicher, dass Sie die Adresse löschen wollen?',
  addressDeletedSuccessfully: 'Adresse erfolgreich gelöscht',
  addKitLoan: 'Kit-Leihe hinzufügen',
  kitLoanId: 'Transaktions ID',
  checkBeforeReturn: 'Kit-Prüfung vor Versand',
  receivedNextCustomer: 'Eingang beim nächsten Kunde',
  atStock: 'Im Lager',
  markToC2CLoan: 'Markiere C2C Leihe',
  c2cLoans: 'C2C Leihe',
  currentC2CLoanProcesses: 'Aktuelle C2C Leih-Prozesse',
  possibleC2CLoanProcesses: 'Vorschläge für C2C Leihen',
  toApprove: 'Zur Genehmigung',
  approved: 'Genehmigt',
  decline: 'Ablehnen',
  delayReturnTransactionMessage:
    'Verzögerungswarnung! Bitte senden Sie Ihr Demogerät so schnell wie möglich an das Lager zurück! Siehe Transaktion(en)',
  suggestAlternateMessage:
    'Leider ist der angegebene Zeitraum nicht möglich! Der nächstmögliche Zeitraum für diese Dauer wäre: {from} bis {to}. Wenn es nicht zu Ihnen passt, verwenden Sie bitte den Kalender, um einen anderen Platz zu finden. Vielen Dank für dein Verständnis!',
  yes: 'Ja',
  no: 'Nein',
  defective: 'Defekt',
  incomplete: 'Unvollständig',
  noKitAddedInCart: 'Keine Kits zur Leihliste hinzugefügt',
  confirmBooking: 'Buchung bestätigen',
  sameReservationPeriod: 'Der Reservierungszeitraum für alle Kits sollte gleich sein, um Ihre Buchung fortzusetzen.',
  orderIsShortlyBeforeDelivery: (phone, email) =>
    `Die Ware ist kurz vor der Auslieferung, bitte kontaktieren Sie Ihren Ansprechpartner im Lager per ${phone} oder ${email}.`,
  returnGoodsBookingSaved: 'Rückwarenbuchungsdetails erfolgreich gespeichert!',
  checkedDate: 'Geprüft',
  mr: 'Herr',
  ms: 'Frau',
  noDefaultAddressAdded: 'Keine Standardadresse hinzugefügt',
  editor: 'Bearbeiter',
  addFullDetailsForLoanListItem: 'Please add dates to execute booking.',
  startDateNotSelected: 'Bitte wählen Sie den Beginn des Ausleihdatums, um die Verfügbarkeit des Kits zu überprüfen.',
  startDateGreaterThanEndDate: 'Der Beginn des Datums darf nicht größer sein als das Ende des Entleihdatums.',
  addedToCart: 'In den Warenkorb gelegt',
  notInAvailableSlot:
    'Ausgewähltes Kit - {kitName} ist nicht auf dem verfügbaren Slot. Bitte ändern Sie die Termine für die Verfügbarkeit.',
  onTimeDelivery: 'Pünktliche Lieferung',
  orderCompleteness: 'Auftragsvollständigkeit',
  equipmentFunctionality: 'Gerätefunktionalität',
  saveRating: 'Bewertung speichern',
  alreadyBookedKitMessage: 'Dieses Kit ist bereits vom {from} bis {to} gebucht.',
  alreadyBookedKitBorrower: 'Leihnehmer: {borrower}',
  alreadyBookedKitStockController: 'Lagerverwalter: {stockController}',
  alreadyBookedKitCustomer: 'Kunde: {customerName}',
  youHaveToAddTransactionOrderedByDate: 'Sie müssen die Transaktionen sortiert nach Datum hinzufügen.',
  deleteC2C: 'Sind Sie sicher, dass Sie die Transaktion aus der C2C-Leihe entfernen möchten?',
  deleteC2CRemove: 'Sind Sie sicher, dass Sie die Transaktion stornieren und Sie aus der C2C-Leihe entfernen möchten? ',
  confirmRemoveC2C: 'Entfernen aus C2C?',
  transactionWillBeCancelled: transactionsId => `Transaktion(${transactionsId}) wird storniert.`,
  transactionWillBeChanged: ({ transactionsId, firstDate, lastDate }) =>
    `Versanddatum für Transaktion(${transactionsId}) wird verschoben von ${firstDate} auf ${lastDate}`,
  consignment: 'Kommissionierung bestätigen',
  confirmConsignmentMessage: 'Lieferung erfolgreich bestätigt.',
  availabilityMessage:
    'Das ausgewählte Kit ist für diesen Zeitraum nicht buchbar. Das Kit ist erhältlich von {from} und {to}.',
  contactUsTitle: 'Kontaktieren Sie uns',
  contactUsMessage: 'Nachricht',
  contactUsSubject: 'Betreff',
  contactMessageSentSuccessfully: 'Vielen Dank für Ihre Kontaktaufnahme.',
  kitNotAvailable: 'Dieses Kit ist nicht buchbar.',
  loginForFilter: 'Bitte loggen Sie sich ein, um die gefilterten Daten zu sehen.',
  notAllowedToFilter:
    'Sie haben nicht die entsprechenden Rechte, um Daten zu filtern. Bitte klicken Sie auf "Lager", filtern Sie nach Ihrem Geschäftsfeld und Ihrem Land und beantragen Sie die Berechtigung für die Demolager, die Sie benötigen. Nachdem der Lagerverwalter Ihre Anfrage genehmigt hat, haben Sie Zugriff auf die Demogeräte.',
  loanType: 'Art der Leihe',
  language: 'Sprache',
  loanTypeAlreadyExist: 'Art der Leihe ist bereits vorhanden',
  fullDescription: 'Vollständige Beschreibung',
  noKitCondition: 'Keine Bedingung',
  dateOfManufactureText: 'Herstellungsdatum',
  noCountryAdded: 'Das Land wird der ausgewählten Lieferadresse nicht hinzugefügt. Bitte geben Sie das Land an.',
  selectedKitsNotAvailable: 'Ausgewähltes(s) Kit(s) ist nicht buchbar.',
  and: 'und',
  availableKits: 'Verfügbar Kits',
  reservedKitMessage: 'Dieses Kit ist für 24 Stunden {from} bis {to} reserviert.',
  alreadyAddedKitWithSameDate: 'Dieses Kit ist bereits für die Buchung am selben Tag ausgewählt.',
  supportedDocumentsMessage: 'Es werden nur PDF, MS WORD, MS EXCEL Dateien unterstützt.',
  fileSizeLimitExceed: '{filename} Überschreitung der Upload-Grenze von 30 MB',
  maxFilesErrorMessage: 'Du kannst nicht mehr als {number} Dateien hochladen.',
  noChangesMade: 'Es wurden keine Änderungen vorgenommen.',
  pleaseSelectC2c: 'Sie haben keine neue Transaktion zu einer C2C-Leihe hinzugefügt.',
  stockDataMandatory: 'Stock information is mandatory. Please select stock to proceed.',
  kitLoanOrderText: 'Leihauftrag (Referenz auf SAP ERP)',
  kitInformationId: 'Kit-ID',
  pleaseClickAddButtonMessage: 'Bitte klicken Sie auf die Schaltfläche Hinzufügen.',
  businessUnitMandatory:
    'Business Unit ist obligatorisch. Bitte wählen Sie einen Geschäftsbereich aus, um fortzufahren.',
  sapErp: 'SAP ERP',
  maximumNumberOfTransactions: 'There can be maximum of 5 chains in a C2C transaction.',
  newDeliveryAddress: 'Neue Lieferadresse',
  twentyFourHourReservation: 'Reserviert für 24 Stunden',
  currentPositionDateDisabledMessageFirstPart: requested =>
    `Sie können die Ist-Daten für die aktuelle Transaktion #${requested}-a erst eingeben, wenn die vorherige Transaktion `,
  currentPositionDateDisabledMessageSecondPart: ' mit diesem Demo Kit beendet, storniert oder abgelehnt wurde.',
  cancellationComment: 'Stornierungskommentar Kommentar',
  linkedLoans: 'Verknüpfte Leihe',
  emptyDropDownField: key => `Das Feld ${key} ist leer. Bitte wählen Sie eine andere Option aus dem Dropdown.`,
  emptyAccountId: 'Die Zeiss ID fehlt für einige User. Bitte regelmäßig prüfen.',
  loanTypeMandatoryForTransactionFilter: 'Bitte wählen Sie die Leih-Vorgang, bevor Sie Filter hinzufügen.',
  loanTypeNoticeMessage: 'Bitte wählen Sie die Leih-Vorgang, um die Filter zu aktivieren.',
  lost: 'Verloren',
  hasToBeBefore: (fieldOne, fieldTwo) => `${fieldTwo} Datum sollte größer sein als die ${fieldOne} Datum`,
  hasToBeBeforeOrEqual: (fieldOne, fieldTwo) => `${fieldTwo} Datum sollte größer oder gleich ${fieldOne} Datum sein`,
  nameDepartment: 'Name/Abteilung',
  selectProblem: 'Bitte wählen problem',
  providingMoreDetails: 'Könnten Sie mir bitte ein paar mehr Details liefern?',
  suggestionText:
    'Vorschlag:\n\u00b7 Was haben Sie bisher getan um das Problem zu lösen?\n\u00b7 Was ist Ihre Erwartung?\n\u00b7 Was war das Resultat?\n\u00b7 Gibt es eine alternative Telefonnummer auf der wir Sie erreichen können?',
  ticketUploadDocument:
    'Laden Sie ein Dokument, Bild oder Video des Fehlers hoch, damit wir den Vorgang schneller bearbeiten können.',
  ticketLimitedSize: 'Max. 5 Dateien | Max. Größe pro Datei: 100 MB',
  ticketDoNotUpload:
    'Bitte laden Sie keine schützenswerten Dateien wie zum Beispiel geheime Dokumente oder Personendaten hoch. Bitte nur generische Inhalte wie die Fehlernachricht oder das falsche Verhalten des ZEISS-Gerätes hochladen. Persönliche oder geheime Daten sollten unkenntlich gemacht werden.',
  ticketSystemMainComponent: 'System-/Hauptkomponente',
  deleteAllFilters: 'Alle Filter entfernen',
  print: 'Drucken',
  locationText: 'Demolager',
  noPackageDimensionsAvailable: 'Es wurden keine Details zur Verpackung angegeben',
  yearOfManufacture: 'Herstellungsjahr',
  monthOfManufacture: 'Herstellungsmonat',
  noCommentAvailable: 'Kein Kommentar verfügbar',
  maxLengthErrorMessage: 'Sie können nur 20 Lager auswählen',
  serviceTicket: 'Service Ticket',
  ticketId: 'Ticket-ID',
  createdBy: 'Erstellt von',
  problem: 'Problem',
  pleaseLoginAgain: 'Bitte loggen Sie sich erneut ein, um ein Ticket zu erstellen',
  createdOn: 'Erstellt am',
  createServiceTicketSuccessfully: 'Serviceticket erfolgreich angelegt. Ihr Antrag wird nach einiger Zeit erscheinen.',
  noDeliveryAddress: 'Keine Lieferadresse',
  demoKit: 'Demo-Kit',
  availableKitDetails: 'Zeitraum der Verfügbarkeit',
  allowedFormats: 'Only .PDF, .JPG, .PNG, .DOC, .XLS and .czi files ares allowed',
  maxFileSize: 'Die Datei ist zu groß. Sie können nur Dateien bis max. 30 MB anhängen.',
  from: 'Von',
  to: 'Bis',
  pleaseSelectPeriodForRefurbishment: 'Bitte Zeitraum für Aufbereitung auswählen. Dieser wird im Kalender geblockt.',
  refurbishmentDateError: 'refurbishmentFrom should be less than refurbishmentTo date',
  formInvalidBookingStep2:
    'Bitte klicken Sie auf den Button "Adresse bearbeiten" und überprüfen Sie, ob alle Pflichtfelder ausgefüllt sind. Tipp: Häufig verwendete Lieferadressen können Sie in Ihren Kontodaten speichern. Dies beschleunigt den Buchungsvorgang.',
  showCustomerInfoPage: 'Zeige Kundeninformation zu Verpackung und Rückversand auf Rücklieferschein',
  doYouWantToBlockSamePeriodForMarkedKits: 'Möchten Sie diesen Zeitraum für alle markierten Geräte übernehmen?',
  signature: 'Zeige Signaturfeld auf Lieferdokumenten',
  legalParagraphEnglish: 'Rechtlicher Hinweis (Englisch)',
  refresh: 'Aktualisierung',
  scrapped: 'Verschrottet',
  dismantled: 'Aufgelöst',
  incompleteMaintained: 'Unvollständige Kitdaten',
  sameStocks: 'Die Vorräte für alle Kits sollten gleich sein, damit Sie Ihre Buchung fortsetzen können.',
  singleLoanBookedWeeks: days => `Einzel-Leihe kann nur ${days} Wochen im Voraus gebucht werden.`,
  courseLoanBookedWeeks: days => `Kursleihe kann nur ${days} Wochen im Voraus gebucht werden`,
  singleLoanBookedDays: days => `Einzel-Leihen können nur ${days} Tage im Voraus gebucht werden.`,
  courseLoanBookedDays: days => `Kurs-Leihen können nur ${days} Tage im Voraus gebucht werden.`,
  statistics: 'Statistiken',
  overview: 'Überblick',
  utilization: 'Auslastungsgrad',
  wonOpportunities: 'Gewonnene Opportunities',
  reparationTime: 'Reparaturquote',
  bookings: 'Buchungen',
  timePeriod: 'Zeitraum',
  discontinued: 'Abgebrochen',
  refurbishment: 'Aufbereitung',
  requested: 'Angefordert',
  createStockButton: 'Lager erstellen',
  createLLStockButton: 'L@L - Lager erstellen',
  currentTransaction: 'Aktuelle Transaktion',
  conflictWithTransaction: ids =>
    `Es gibt einen Konflikt mit Transaktion ${ids}, möchten Sie die anderen Transaktionen trotzdem aktualisieren?`,
  adoptChangeForSubsequentTransactions: 'Änderung für folgende Transaktionen übernehmen:',
  conflictingWithOtherTransactions:
    'Die von Ihnen ausgewählten Daten erzeugen Konflikte mit anderen Transaktionen. Bitte versuchen Sie es mit anderen Daten nochmal.',
  relatedTransactionsConfirm: 'Änderungen übernehmen',
  relatedTransactionsCancel: 'Änderungen nicht übernehmen',
  conflict: 'Konflikt',
  start: 'Starten',
  returnBookingDate: 'Rückwarenbuchungsdatum',
  conditionReturnGoodBooking: 'Beschaffenheit',
  commentReturnGoodBooking: 'Anmerkungen',
  tracesOfUse: 'Gebrauchsspuren',
  orderDeliveryNumber: 'Rückwarenbuchung',
  teamBookings: 'Teambuchungen',
  users: 'Benutzer',
  members: 'Mitglieder',
  subjectTeamBookings:
    'Diese Leih-Übersicht soll Mitarbeiter im Vertriebsinnendienst unterstützen. Sie können Buchungen der Vertriebsmitarbeiter, die Sie betreuen anschauen und falls nötig Änderungen veranlassen. Bitte klicken Sie auf "Hinzufügen" um deren Buchungen in der Liste zu ergänzen. Der Mitarbeiter erhält anschließend eine E-Mail und wird darüber informiert.',
  userRemoveSuccessfully: 'Benutzer erfolgreich entfernt',
  alreadyBookedComment: 'Kommentar: {comment}',
  activateSellOffFunction: 'Verkaufsfunktion aktivieren',
  externalBorrowerCanSeePlatform: 'Externer Leihnehmer kann Plattform sehen.',
  shopManagers: 'Shop-Managers',
  shopManager: 'Shop-Manager',
  kitViewDuration: 'Für wie viele Wochen kann ein Benutzer ein Kit reservieren?',
  salesDateEarlierThanAvailableToDate:
    'Sie haben ein Verkaufsdatum gewählt, das vor dem bisher verfügbaren Datum liegt. Möchten Sie dies ändern?',
  contactUsText:
    'Bitte beachten Sie, dass von unserem Help-Desk nur technische oder funktionelle Anfragen zur Demo Management App beantwortet werden. Für Status-Informationen zu ihren Leih-Transaktionen wenden sie sich bitte an Ihren Lagerverwalter. Vielen Dank.',
  kitIsNoLongerAvailable:
    'Das Kit ist ab dem {date} nicht mehr verfügbar. Bei Rückfragen wenden Sie sich bitte an {emailAddress}.',
  subscribe: 'Abonnieren',
  subscribeAndGetNotified:
    'Sie möchten sich informieren lassen, wenn Demo-Geräte einer bestimmten System-Kategorie neu im Shop angeboten werden? Wählen Sie ihr Land und die gewünschten System-Kategorien und Sie bekommen eine Email, wenn Ihr Lagerverwalter passende Demo-Geräte zum Verkauf anbietet.',
  youCanNotHaveMore: 'Sie können nicht mehr als 10 Abonnements haben.',
  subscribedOn: 'Abonniert am',
  noSubscriptionsAvailable: 'Keine Abonnements verfügbar',
  pleaseMindThat:
    'Bitte beachten Sie, dass die Opportunity ID aus 9 Zahlen bestehen muss und bestätigen Sie die ID mit einem Klick auf "+".',
  youCanNotEnter: 'Sie können nicht dieselbe Opportunity ID in zwei Felder eingeben',
  noQuestionsAvailable: 'Keine Fragen verfügbar',
  questions: 'Fragen',
  postYourQuestion: 'Stellen Sie Ihre Frage',
  enterYourQuestionHere: 'Geben Sie hier Ihre Frage ein',
  answerQuestion: 'Frage beantworten',
  postYourAnswer: 'Veröffentlichen Sie Ihre Antwort',
  enterYourAnswerHere: 'Geben Sie Ihre Antwort hier ein',
  editAnswer: 'Antwort bearbeiten',
  showMore: 'Mehr anzeigen',
  notOk: 'Nicht in Ordnung',
  transport: 'Transport',
  myObservations: 'Meine Beobachtungen',
  addKitForSale: 'Kit zum Verkauf anlegen',
  currency: 'Währung',
  grossListPrice: 'Bruttolistenpreis',
  transferPrice: 'Transferpreis',
  internalIdentifier: 'Interne ID',
  visibility: 'Sichtbarkeit des Angebots',
  salesDate: 'Verkaufsdatum',
  deliveryTimeInWeeksNumberField: 'Lieferzeit in Wochen (bitte als Zahl eingeben)',
  deliveryTimeInWeeks: 'Lieferzeit in Wochen',
  salesComment: 'Verkauf Kommentar',
  kitForSale: 'Bausatz zu verkaufen',
  kitForSaleSavedSuccessfully: 'Bausatz zum Verkauf erfolgreich gespeichert',
  pleaseSelectUser: 'Bitte wählen Sie einen Benutzer',
  demoKits: 'Demo-Kits',
  observe: 'Beobachten',
  order: 'Bestellung',
  itemDetails: 'Artikel-Details',
  itemName: 'Artikel Name',
  itemDescription: 'Artikel Beschreibung',
  sellDetails: 'Details verkaufen',
  notes: 'Hinweise',
  singleItemName: 'Einzelner Artikelname',
  contactPerson: 'Kontaktperson',
  demoSale: 'Marketplace',
  salesOffers: 'Verkaufsangebote',
  results: 'Ergebnisse',
  yearOfManufacturing: 'Herstellungsjahr',
  singleItemsKit: 'Einzelteile/Kit',
  singleItems: 'Einzelteile',
  transferPriceInEUR: 'Transferpreis in EUR',
  mySubscriptions: 'Meine Abonnements',
  notifyMe: 'Benachrichtigungen abonnieren',
  createSingleItem: 'Einzelteile erstellen',
  stopObservation: 'Beobachtung beenden',
  observations: 'Beobachtungen',
  archive: 'Archiv',
  cancelReservation: 'Reservierung stornieren',
  cancelReservationPopupTitle: 'Sind Sie sicher, dass Sie die Reservierung stornieren möchten?',
  reservation: 'Reservierung',
  reservedTill: reservationTo => `Reserviert bis ${reservationTo} von`,
  youHaveOrderDemoKit:
    'Herzlichen Glückwunsch! Sie haben soeben ein Demokit gekauft. Um den Kauf zu verifizieren geben Sie bitte eine Quote ID an:',
  enterCRMQuoteID: 'Bitte geben Sie die CRM Quote ID ein',
  createQuoteFirst: 'Reservieren, ich muss erst eine Quote erstellen',
  CRMQuoteIDShouldBe10Number: 'CRM-Angebots-ID sollte aus 10 Zahlen bestehen',
  pleaseVerifyPrice: 'Bitte überprüfen Sie den Preis',
  price: 'Preis',
  kitOrderedSuccessfully: 'Kit erfolgreich bestellt.',
  year: 'Jahr',
  month: 'Monat',
  years: 'Jahre',
  months: 'Monate',
  youCantSelectMoreThanThreeValues: `Sie können nicht mehr als drei Werte auswählen`,
  purchaseRequests: 'Kaufgesuche',
  addPurchaseRequest: 'Einen Kaufantrag hinzufügen',
  budget: 'Budget',
  validity: 'Gültigkeit',
  whatAreYouLookingFor: 'Was suchen Sie?',
  haveRequestFor: 'Haben einen Antrag für',
  purchaseRequestsWasAdded: 'Purchase Requests wurde erfolgreich hinzugefügt',
  writeComment: 'Einen Kommentar schreiben',
  comments: 'Anmerkungen',
  daysLeft: 'Verbleibende Tage',
  showDeliveryDocument: 'Lieferkommentar auf Lieferdokumenten anzeigen',
  youCanNotCreateOverlappingBookings:
    'Es ist nicht erlaubt überlappende Buchungen zu erstellen. Bitte passen Sie die Daten entsprechend an.',
  createFolder: 'Ordner erstellen',
  folderName: 'Folder name',
  editFolderName: 'Name des Ordners',
  folderDeletedSuccessfully: 'Ordner erfolgreich gelöscht',
  folderCreatedSuccessfully: 'Ordner erfolgreich erstellt',
  daysInStock: 'Tage im Lager',
  daysInRefurbishment: 'Tage im Service',
  daysInLoan: 'Tage in Demo',
  daysInTransport: 'Tage in Transport',
  mandatoryFieldIsMissing: 'Dieses Kit ist nicht buchbar, weil das folgende Pflichtfeld nicht gefüllt ist:',
  mandatoryFieldsAreMissing: 'Dieses Kit ist nicht buchbar, weil die folgenden Pflichtfelder nicht gefüllt sind:',
  availabilityPeriodIsExpired: 'Dieses Kit ist nicht buchbar, weil der Zeitraum der Verfügbarkeit abgelaufen ist.',
  statusIsNotOk: 'Dieses Kit ist nicht buchbar, weil der Kit-Status nicht "Ok" oder "Kit zum Verkauf" ist.',
  hideKitIsActivated: 'Dieses Kit ist nicht buchbar, weil "Kit ausblenden" aktiviert ist.',
  oneMonth: 'ein Monat',
  pleaseConvertGrossListPrice: 'Bitte rechnen bruttolistenpreis in Euro um.',
  forSaleReserved: 'Zu verkaufen - Reserviert',
  kitIsAlreadyDisplayedInShop: 'Das Kit ist bereits im Shop veröffentlicht',
  pleaseConvertLocalSalesPrice: 'Bitte rechnen Sie den lokalen Verkaufspreis in Euro um.',
  purchaseRequestWasUpdated: 'Purchase Request was updated successfully',
  purchaseRequestWasDeleted: 'Purchase Request was deleted successfully',
  deletePurchaseRequest: 'Delete Purchase Request',
  areYouSureYouWantToDeletePurchaseRequest: 'Are you sure you want to delete Purchase Request?',
  transportTime: 'Transport Time',
  orders: 'Bestellungen',
  salesDetails: 'Details zum Verkauf',
  internalNotes: 'Interne Notizen',
  crmQuoteID: 'CRM Angebots-ID',
  customer: 'Kunde',
  purchaseOrder: 'Bestellnummer',
  salesOrder: 'Verkaufsauftrag',
  technicalControl: 'Technische Kontrolle',
  internalOrder: 'Invest/Innenauftrag',
  desinfection: 'Desinfektion',
  completenessCheck: 'Vollständigkeitskontrolle',
  productionOrderReparationOrder: 'FAUF/Servicemeldung/Reparaturbestellung',
  internalStockOrder: 'ZUB',
  deliveryDate: 'Liefertermin',
  pleaseMindThatFieldNeedsToHave: value => `Bitte beachten Sie, dass das Feld ${value} Ziffern haben muss.`,
  exportData: 'Daten exportieren',
  exportTransactionData: 'Transaktionsdaten exportieren',
  exportKitData: 'Kitdaten exportieren',
  taskId: 'Task ID',
  subscriptions: 'Benachrichtigungen',
  subscribeNew: 'Benachrichtigungen abonnieren',
  pleaseSubscribeSystemClass:
    'Bitte abonniere eine Systemklasse und eine Hauptkomponente um Updates in der Benachrichtigungsleiste für diese zu erhalten. Zusätzlich können Sie eine Zusammenfassung der Updates per Mail abonnieren.',
  subscribeWasAdded: 'Subscribe was added successfully',
  subscribeWasUpdated: 'Subscribe was updated successfully',
  subscribeWasDeleted: 'Subscribe was deleted successfully',
  deleteSubscription: 'Delete Subscription',
  areYouSureYouWantToDeleteSubscription: 'Are you sure you want to delete Subscription?',
  masterData: 'Master Data',
  dusdPermissions: 'Demo Unit Status Tool - Berechtigungseinstellungen',
  serviceManager: 'Service Manager',
  serviceTechnician: 'Service Technician',
  permissionsWereUpdated: 'Permissions were updated successfully',
  dusdTask: 'Demo Unit Status Tool - Aufgabenverwaltung',
  addTask: 'Aufgabe hinzufügen',
  tasks: 'Aufgaben',
  task: 'Aufgabe',
  systemMain: 'System Hauptkomponente',
  taskWasAdded: 'Task was added successfully',
  taskWasDeleted: 'Task was deleted successfully',
  taskWasUpdated: 'Task was updated successfully',
  deleteTask: 'Delete Task',
  areYouSureYouWantToDeleteTask: 'Are you sure you want to delete Task?',
  new: 'New',
  assigned: 'Assigned',
  completed: 'Completed',
  expired: 'Expired',
  subscribeToDUSD: 'Abonniere Änderungen zum Demo Status',
  demoUnitStatus: 'Demo Unit Status',
  yourName: 'Ihr Name',
  sistemRestricted: 'Geringfügige Fehler',
  systemDown: 'System arbeitet nicht',
  noIssues: 'Keine Probleme',
  productHierarchy: 'Produkthierarchie',
  stockDashboard: 'Lager Dashboard',
  businessGroup: 'Geschäftsgruppe',
  thisWeek: 'Diese Woche',
  goodsDelivered: 'Gelieferte Waren',
  demoLoan: 'Demo-Leihe',
  incomingGoods: 'Eingehende Waren',
  goodsConfirmed: 'Waren bestätigt',
  demoActivity: 'Demo-Aktivität',
  delayedTransactions: 'Verspätete Transaktionen',
  fiscalYear: 'Geschäftsjahr',
  systemClassOptional: 'Systemklasse (Optional)',
  thisMonth: 'Dieser Monat',
  dayOrDayes: dayNumber => (dayNumber === 1 ? 'Tag' : 'Tage'),
  minorIssues: 'Minor Issues',
  systemNotWorking: 'System Not Working',
  noTaskAvailable: 'Keine Aufgabe verfügbar',
  addDemoUnitStatus: 'Demo Unit Status hinzufügen',
  dueToTheMissingIBase:
    'Due to the missing iBase number on this demokit, a service ticket cannot be created in CRM. Please create a ticket manually if needed.',
  statusSubmittedSuccessfully: 'Status erfolgreich übermittelt.',
  notificationSettings: 'Benachrichtigungseinstellungen',
  demoUnitStatusSubscriptions: 'Demo Unit Status Benachrichtigungen',
  notifyMeVia: 'Benachrichtige mich durch',
  webApp: 'Benachrichtigungsleiste in der Web Applikation',
  mobileApp: 'Mobile Applikation (Benachrichtigungsleiste und Push-Benachrichtigungen)',
  notificationSettingsUpdatedSucessfully: 'Benachrichtigungseinstellungen erfolgreich aktualisiert.',
  notifications: 'Benachrichtigungen',
  settings: 'Einstellungen',
  clearAll: 'Alle löschen',
  yesterday: 'Gestern',
  demoLoanMessages: 'Nachrichten zu Demoleihprozessen',
  noNotifications: 'Keine Benachrichtigungen',
  demoLoanMessagesTooltip:
    'Welche Nachrichten Sie erhalten ist in den Lagereinstellungen konfiguriert. Wenn Sie zu viele Informationen bekommen oder Informationen vermissen, bitte kontaktieren Sie Ihren Lagerverwalter. Eine Übersicht zu den Lagerverwaltern finden Sie unter „Lager“',
  demoUnitStatusSubscriptionTooltip:
    'Der Zweck dieses neuen Features ist es den Status von Demogeräten speziell nach einer Markteinführung zu dokumentieren und zu kommunizieren. Wenn Sie über den Status einer Geräteklasse informiert werden wollen, bitte abonnieren Sie die Benachrichtigungen dafür. Anschließend werden Sie über die Benachrichtigungsleiste informiert sobald Kollegen den Status (Keine Probleme, Geringfügige Fehler, System arbeitet nicht) nach Aufbau eines Gerätes bei einem Kunden melden. Weitere Informationen zum Feature finden Sie unter "Dokumente und Trainings"',
  commentSubmittedSuccessfully: 'Kommentar erfolgreich abgeschickt.',
  solvedBy: 'Gelöst von',
  taskCompleted: 'Aufgabe abgeschlossen',
  addComment: 'Einen Kommentar hinzufügen',
  createCustomerCareTicket: 'Customer Care Ticket erstellen',
  search: 'Suchen',
  opportunityList: 'Opportunity-Liste',
  opportunityName: 'Opportunity Name',
  customerID: 'Kunden-ID',
  customerName: 'Name des Kunden',
  contactAddress: 'Kontaktadresse',
  searchWithCustomerName: 'Suche mit Kundenname',
  transactionAttachmentsUploadedSuccessfully: 'Transaktionsanhänge wurden erfolgreich hochgeladen.',
  transactionAttachmentsDeletedSuccessfully: 'Transaktionsanhänge erfolgreich gelöscht.',
  kitData: 'Demokit-Daten',
  ontimeShippingRate: 'Pünktlicher Warenausgang',
  ontimeRecievedInStock: 'Pünktlicher Wareneingang',
  totalDelayedKits: 'Anzahl verspätete Kits',
  reconditioningTime: 'Durchschnittliche Aufbereitungszeit pro Kit',
  demoUnitStatusTool: 'Demo Unit Status Tool',
  permissions: 'Berechtigungen',
  demoUnitStatusDefective: 'Der Demo Kit Status wurde auf "Defekt" geändert.',
  demoUnitStatusOk: 'Der Demo Kit Status wurde auf "Ok" geändert.',
  dustManager: 'DUST Manager',
  dustUser: 'DUST User',
  emailNotification: 'E-Mail Benachrichtigung',
  loanReasons: 'Gründe für die Ausleihe',
  fullScreen: 'Vollbild',
  lastUpdated: min => `Zuletzt aktualisiert vor ${min}m`,
  demoPerformed: 'Durchgeführte\nDemos',
  incomingDemoBooking: 'Eingegangene\nDemobuchungen',
  demoWithOppId: 'Demos mit\nOpportunity ID',
  oppDemoWinRate: 'Gewinnrate für Demos mit Opportunity ID',
  demoReturnedInTime: 'Rate der rechtzeitig zurückgesandten Systeme ',
  demoShippedInTime: 'Rate der pünktlich versendeten Systeme',
  demoSatisfactionIndex: totalRatedTransactions =>
    `Demo Zufriedenheitsindex (basierend auf ${totalRatedTransactions} Ratings)  `,
  onTimeDeliveryRate: 'Liefertermintreue',
  demos: 'Demos',
  performed: 'Ausgeführt',
  planned: 'Geplant',
  dustMaintainStatus: 'Demo Unit Status Tool - Kit-Status ändern',
  maintainStatus: 'Kit-Status ändern',
  enterSerialNumber: 'Serialnummer eingeben',
  noResultFound: 'Kein Ergebnis gefunden',
  allActiveLateTransactions: 'Alle offenen verspäteten Transaktionen',
  lateReturns: 'Verspätete Rücksendungen',
  activeKitConditionAsOn: date => `Kit Status zum ${date}`,
  utilizationRate: 'Nutzungsrate',
  reparationRate: 'Reparaturrate',
  transportationRate: 'Transportrate',
  idleTime: 'Leerlaufzeit',
  mostUtilizedKits: 'Kits mit der höchsten Nutzungsrate (Stand = heute)',
  leastUtilizedDemo: 'Kits mit der niedrigsten Nutzungsrate (Stand = heute)',
  workingCapitalInStock: 'Umlaufvermögen im Lager',
  valuesInTEUR: 'Wert in TEUR',
  interfaceMonitoring: 'Schnittstellen-Monitoring',
  endSystem: 'Zielsystem',
  statusCode: 'Status Code',
  message: 'Nachricht',
  transactionId: 'Transaktions ID',
  kitId: 'Kit-ID',
  transactionName: 'Transaktionsname',
  time: 'Uhrzeit',
  searchForTransactions: 'Suche nach Transaktionen',
  userPermissions: 'Nutzerberechtigungen',
  none: 'Keine',
  deleteAllPermissions: 'Alle Berechtigungen löschen',
  deleteAllPermissionsForThisUser: 'Alle Berechtigungen für User löschen',
  permissionForAll: 'Berechtigung für alle Lager',
  permission: 'Berechtigung',
  stockPermissions: 'Lagerberechtigungen',
  doYouWantToDelete: 'Wollen Sie alle Berechtigungen für diesen User löschen?',
  doYouWantToSetPermission: role =>
    `Wollen Sie die Berechtigung ${role} für diesen User in allen gefilterten Lagern setzen?`,
  setForAllStocks: 'Für alle Lager ändern',
  pleaseSaveYourChanges: 'Wollen Sie Ihre Änderungen speichern',
  setPermissionForAll: 'Berechtigung für alle Lager setzen',
  allChangesAreSaved: 'Ihr Änderungen wurden gespeichert.',
  manageKitPictures: 'Kit-Bilder verwalten',
  kitPictures: 'Kit-Bilder',
  addImage: 'Bild hinzufügen',
  systemMainComponentKitPicture: 'System Hauptkomponente',
  allowedImgFormats: 'Only .PDF and .JPG files ares allowed',
  imageUploadedSuccessfully: 'Bild erfolgreich hochgeladen.',
  errorUploadingImage: 'Fehler beim Upload des Bildes. Bitte erneut versuchen.',
  imageWasDeletedSuccessfully: 'Das Bild wurde erfolgreich gelöscht.',
  errorDeletingImage: 'Fehler beim Löschen des Bildes. Bitte nochmal versuchen.',
  attachmentsStock: 'Dokumente',
  c2cloanTooltip:
    ' Das ist eine Kunde-zu-Kunde Leihe. Klicken Sie auf den Reservierungsbalken um mehr Details zu sehen.',
  partnerPermissions: 'L@L Berechtigungen',
  actualizePermissions: ' L@L und ZMCC Berechtigungen aktualisieren',
  LLPermissionsSuccessSaved: 'L@L Berechtigungen erfolgreich gespeichert',
  mindToChange:
    'Bitte denken Sie daran die Verfügbarkeitsperiode Ihrer Demokits entsprechend zum neuen Vertragsende anzupassen, sodass Ihre Geräte weiterhin gebucht werden können. Vielen Dank.',
  activeUntil: 'Aktiv bis:',
  createLLKit: 'l@l Kit erstellen',
  partnerSite: 'Standort des Partners',
  buildingAndRoom: 'Gebäude & Raum',
  function: 'Funktion',
  labsLocation: 'l@l',
  labsLocationPartner: 'labs@location Partner',
  demo: 'Demo',
  typesOfJobs: 'Art des Jobs',
  customerInstitute: 'Institut des Kunden',
  operator: 'Operator',
  customerSite: 'Customer Site',
  stockAddress: 'labs@location Partner Adresse',
  requestor: 'Beantragt von',
  pleaseSelectAnOption: 'Please select an option',
  slectedKitsAreNotAvailableForBooking: 'Ausgewählte Kits sind nicht buchbar',
  maintainLLKit: 'Maintain l@l kit',
  copyLLKit: 'Copy l@l kit',
  allowedFormatssForDemoKit: 'Only .PDF, .JPG, .PNG, .DOC, .XLS, .CZI, .MKV and .MP4 files ares allowed',
  labsLocationFullName: 'l@l',
  requestCreatedBy: 'Anfrage erstellt von',
  demoSite: 'Ort der Demo',
  demoTime: 'Zeit in Stunden',
  reserve: 'Reservieren',
  approvedByLLManager: 'Genehmigt von l@l Manager',
  confirmedByLLPartner: 'Bestätigt von l@l Partner',
  dust: 'DUST',
  kitAttachments: 'Kit Anhänge',
  transactionAttachmentForKit: 'Transaktionsbeilage für das Kit',
  copy: 'Kopieren',
  toBeApprovedByLLManager: 'Muss vom l@l Manager bestätigt werden',
  toBeApprovedByLLPartner: 'Muss vom l@l Partner bestätigt werden',
  demoConfirmed: 'Demo bestätigt',
  cancelled: 'Storniert',
  labsLocationManagement: 'labs@location Management',
  calculationMethod: 'Berechnungsmethode',
  accountStatement: 'Kontoauszüge',
  controllingReport: 'Controlling Report',
  kpis: 'Kennzahlen',
  continent: 'Kontinent',
  pointsPerHour: 'Punkte pro Stunde (Seiten pro Application Note)',
  pleaseSelectValueFromDropdown: 'Bitte einen Wert im Dropdown auswählen.',
  shouldChangesApplyToAllContinents: 'Sollen die Änderungen für alle Kontinente übernommen werden?',
  applyForAll: 'Für alle übernehmen',
  saveOnlyForSelectedContinent: 'Nur für gewählten Kontinent übernehmen',
  selectContinent: 'Kontinent auswählen',
  valuesSavedSuccessMessage: 'Werte erfolgreich gespeichert',
  demoStart: 'Beginn der Demo',
  demoEnd: 'Ende der Demo',
  delayWarning: 'Verzögerungswarnung! Diese Kit-Leihe sollte ursprünglich am {date} abgeschlossen sein.',
  transactionDescription: 'Transaktionsbeschreibung',
  duration: 'Dauer',
  earned: 'Verdient',
  deducted: 'Entnommen',
  sum: 'Summe',
  performance: 'Leistung',
  calculationIsNotPossible: 'Berechnung nicht möglich, da der Operator nicht in der Transaktion gepflegt wurde.',
  activeSystems: 'Aktive Systeme',
  itIsNotPossibleToAddDemoUnitStatus:
    'Es ist nicht möglich einen Demo Unit Status für dieses Kit anzulegen. Das Demo Unit Status Tracking für dieses System wurde vom DUST Manager deaktiviert. Bitte kontaktieren Sie demoloanapp@zeiss.com um weitere Informationen zu erhalten.',
  areYouSureYouWantToRemoveSystem:
    'Wollen Sie das System wirklich entfernen? Alle DUST User werden darüber per E-Mail informiert.',
  deactivateSystem: 'System deaktivieren',
  activateSystem: 'System aktivieren',
  noActiveSystemsAvailable: 'Demo Unit Status Tracking ist momentan für kein System aktiviert.',
  demoUnitStatusCanOnlyBeAddedToSystems:
    'Der Demo Unit Status kann nur bei Systemen gepflegt werden, welche in dieser Liste stehen. Wenn ein System wieder von der Liste entfernt wird, kann kein Demo Unit Status mehr ergänzt werden. Zuvor gepflegte Demo Unit Status bleiben am System sichtbar.',
  activateSystemAndEnableForDUSTTracking: 'System aktivieren und für Demo Unit Status Tracking freigeben',
  dateOfActivation: 'Datum der Aktivierung',
  add: 'Hinzufügen',
  deactivate: 'Deaktivieren',
  activate: 'Aktivieren Sie',
  pleaseAddSystemClassAndSystemMainComponent:
    'Bitte System-Kategorie und Hauptkomponente auswählen um Demo Unit Status Tracking für die Systeme zu erlauben.',
  recordAlreadyExists: 'Datensatz existiert bereits',
  notPossibleToAddDemoUnitStatusForThisDemoKit:
    'Es ist nicht möglich einen Demo Unit Status für dieses Kit anzulegen. Das Demo Unit Status Tracking für dieses System wurde vom DUST Manager deaktiviert. Bitte kontaktieren Sie demoloanapp@zeiss.com um weitere Informationen zu erhalten.',
  areYouSureYouWantToActivateSystem:
    'Wollen Sie das System wirklich für das DUST Status Tracking aktivieren? Alle DUST User werden darüber per E-Mail informiert.',
  on: 'An',
  off: 'Aus',
  deductPoints: 'Punkte einlösen',
  pointDeduction: 'Punktabzug',
  reason: 'Grund',
  salesRepresentative: 'Vertrieb',
  deductionOfPoints: 'Einlösung von Punkten',
  numberOfPartners: 'Anzahl der Partner',
  numberOfTransactions: 'Anzahl durchgeführter Demos',
  opportunitiesWon: 'Anzahl gewonnene Opportunties',
  opportunitiesLost: 'Anzahl verlorene Opportunties',
  opportunitiesInProcess: 'Anzahl laufende Opportunties',
  opportunitiesDiscontinued: 'Abgebrochene Opportunities',
  opportunityWinRate: 'Opportunity Gewinn-Rate',
  opportunityLostRate: 'Opportunity Verlust-Rate',
  mainUtilizedMainComponents: 'Top 10 - Am häufigsten genutzte Hauptkomponenten',
  total: 'Gesamt',
  totalAmountOfDemos: 'Gesamtanzahl der geleisteten Stunden',
  noOfBookings: 'Anzahl der Buchungen',
  showMap: 'Zeige Karte',
  mapTitle: 'Überblick labs@location Partner Standorte',
  myLocation: 'Mein Standort',
  selectPartner: 'Partner auswählen und zurück zum Kalender',
  latitude: 'Breitengrad',
  longitude: 'Längengrad',
  selectLocation: 'Standort auswählen',
  addLocationToMap: 'Standort in Karte hinzufügen',
  preName: 'Vorname',
  surName: 'Nachname',
  earnedPoints: 'Zugefuhrt Punkte',
  earnedEuros: 'Zugefuhrt Euros',
  deductedPoints: 'Verbrauch Punkte',
  deductedEuros: 'Verbrauch Euros',
  sumPoints: 'Bestand Punkte',
  sumEuros: 'Bestand Euros',
  nameOfOperator: 'Name des Operators',
  loanValue: 'Wert der Leihe in',
  returnDeliveryAddress: 'Adresse für Rückversand ändern',
  itemNumber: 'Sachnummer',
  division: 'Sparte',
  systemClassification: 'System Einteilungsklassen',
  kitDetailesSavedWithoutDivisionAndItemNumber:
    'Kit erfolgreich gespeichert. Warnung! Die automatische Leihauftragsanlage kann nicht genutzt werden, wenn die Felder "Sparte" und "Sachnummer" nicht gepflegt sind',
  kitCopiedWithoutmandatoryFiledAndItemNumberDivision:
    'Kit-Details wurden erfolgreich kopiert, aber das Demo-Kit kann erst gebucht werden, wenn alle Pflichtfelder ausgefüllt sind. Warnung! Die automatische Leihauftragsanlage kann nicht genutzt werden, wenn die Felder "Sparte" und "Sachnummer" nicht gepflegt sind',
  kitWithoutmandatoryFiledAndItemNumberDivision:
    'Kit-Details wurden erfolgreich gespeichert, aber das Demo-Kit kann erst gebucht werden, wenn alle Pflichtfelder ausgefüllt sind. Warnung! Die automatische Leihauftragsanlage kann nicht genutzt werden, wenn die Felder "Sparte" und "Sachnummer" nicht gepflegt sind',
  maintainSystemMainComponentForCalutaion:
    'Die Punkte können nicht berechnet werden. Bitte die System-Hauptkomponente in der zugehörigen System-Einteilungsklasse pflegen.',
  divisionOrItemNumberMissing:
    'Bitte zunächst Sparte und Sachnummer am Demokit pflegen. Anschließend kann ein Leihauftrag erstellt werden.',
  createLoanOrder: 'Leihauftrag erstellen',
  returnLoanOrder: 'Leihgutabholung',
  sapERPMappingTable: 'SAP Mapping Tabelle',
  sapERPMappingList: 'Mapping List',
  addMapping: 'Mapping hinzufügen',
  accountId: 'Account ID',
  accountName: 'Account Name',
  houseNumber: 'Hausnummer',
  comment: 'Anmerkung',
  mappingWasAdded: 'Mapping erfolgreich erstellt',
  mappingWasDeleted: 'Mapping erfolgreich gelöscht',
  mappingWasUpdated: 'Mapping erfolgreich aktualisiert',
  confirmDeleteMapping: 'Mapping löschen?',
  confirmDeleteMappingMessage: 'Sind Sie sicher, dass Sie das Mapping löschen wollen?',
  accountAlreadyExists: 'Account ID existiert bereits',
  materialNumber: 'Materialnummer',
  materialnumberWarning: 'Bitte Materialnummer eingeben.',
  orderType: 'Aufragsart',
  distributionChannel: 'Vertriebsweg',
  billing: 'Faktura',
  partner: 'ZI Partner',
  orderData: 'ZA Partner & Auftragsdaten/Submission',
  singleLoanDescription: 'Bezeichnung',
  more: '...mehr',
  noAccessRights: 'Sie haben keine Berechtigung zum Speichern.',
  customerIdSap: 'Kundennummer SAP ID',
  goodsRecipientIdSap: 'Warenempfänger SAP ID',
  text: 'Formularkopftext',
  shippingInstruction: 'Versandvorschrift',
  submissionData: 'Submission data',
  legalParagraphGerman: 'Rechtlicher Hinweis (Deutsch)',
  costFactorGrossListPricePerLoan: 'Kostenfaktor Bruttolistenpreis pro Leihe',
  costFactorGrossListPricePerDayDelay: 'Kostenfaktor Bruttolistenpreis pro Tag Verspätung',
  costFactorLoanValuePerLoan: 'Kostenfaktor auf Leihwert pro Leihe',
  numberOfDaysWhereLoanIsFree: 'Anzahl der kostenfreien Tage für eine Leihe',
  materialNumberOfTheStand: ' Materialnummer Stativ',
  refurbishmentFees: 'Aufarbeitungsgebühren',
  labsAtLocationActivity: 'labs@location Aktivität',
  expectedRefurbishmentFees: 'Voraussichtliche Aufarbeitungsgebühren',
  penaltyFeesPerDayWhenLoanIsReturnedLate: 'Strafgebühren pro Tag, wenn Leihe zu spät zurückgesendet wird',
  theFeesAreSettledInternallyWithTheBorrowingSsc: ' Die Gebühren werden intern mit der leihenden SSC verechnet.',
  january: 'Januar',
  february: 'Februar',
  march: 'März',
  april: 'April',
  may: 'Mai',
  june: 'Juni',
  july: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',
  labsAtLocationReservation: 'labs@location Reservierung',
  walkOffSalesOrder: 'Auftragsnummer Abverkauf',
  requestPickup: 'Transportanfrage',
  pickUpDate: 'Abholdatum',
  pickUpFromWarehouse: 'Abholung im Lager (Lieferung zum Kunden)',
  PickUpFromCustomer: 'Abholung beim Kunden (Rücklieferung ins Demolager)',
  emailSubject: TransactionNumber => `ZEISS Transportanfrage - Transaction ${TransactionNumber} - Lieferung zum Kunden`,
  pickUp: 'Abholung',
  pickFromWarehoueBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickAddressForWhereHouse,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    packageDimensions,
    deliveryAdress,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Sehr geehrte Damen und Herren,\n\nBitte organisieren Sie mir den folgenden Transport:\n\n**Abholadresse:*${pickAddressForWhereHouse}\n\n${showPicUpComment && pickUpComment? `**Bemerkung zur Abholung:* ${pickUpComment}`:''}\n\n**Abholdatum:* ${targetShippingDate}\n\n**Zieladresse:*\n${deliveryAdress}\n${deliveryComment?`**Lieferkommentar*: ${deliveryComment}` :''}\n**Lieferdatum:* ${receiptAtTheCustomer}\n**System:* ${kitName}\n**Serialnummer:* ${serialNumber}${packageDimensions ? `\n**Maße/Gewicht:* ${packageDimensions}` : ''}\n**Link zur Transaktion:* ${linkToTransaction}\n\nBitte geben Sie mir eine Rückmeldung und schicken Sie mir ggf. eine Auftragsbestätigung.\n\nMit freundlichen Grüßen\n${firstName} ${lastName}\n${organization}\n${phone} `,
  customerEmailSubject: transactionNumber =>
    `ZEISS Transportanfrage - Transaction ${transactionNumber} - Lieferung zu ZEISS`,
  pickCustomerBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickAddressForCustomer,
    deliveryAdress,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    packageDimensions,
    targetReturnDeliveryDate,
    receiptAtStock,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Sehr geehrte Damen und Herren,\n\nBitte organisieren Sie den folgenden Transport:\n**Abholadresse:*\n${deliveryAdress}\n\n${showPicUpComment && pickUpComment ? `**Bemerkung zur Abholung:* ${pickUpComment}`:''}\n\n**Abholdatum:* ${targetReturnDeliveryDate}\n\n**Zieladresse:*\n${pickAddressForCustomer}\n\n${deliveryComment?`**Lieferkommentar*: ${deliveryComment}` :''}\n**Lieferdatum:*${receiptAtStock}\n**System:* ${kitName}\n**Serialnummer:* ${serialNumber}${packageDimensions ? `\n**Maße/Gewicht:* ${packageDimensions}` : ''}\n**Link zur Transaktion:* ${linkToTransaction}\n\nBitte geben Sie mir eine Rückmeldung und schicken Sie mir ggf. eine Auftragsbestätigung.\n\nMit freundlichen Grüßen\n${firstName} ${lastName}\n${organization}\n${phone} `,
  subject: 'Betreff',
  emailText: 'Email Text',
  emailSent: 'E-Mail erfolgreich versendet',
  meantimeToDemo: 'Meantime to Demo',
  refurbishmentTime: 'Durchschnittliche Aufbereitungszeit pro Kit',
  loanPeriodOverdrawn: 'Leihdauer überzogen',
  reparationComment: 'Kommentar zur Aufarbeitung',
  kitInReparation: 'Kit in Reparatur',
  kitInReparationComment: 'Kommentar: {comment}',
  addRequest: 'Anfrage hinzufügen',
  requestDemo: 'Demo anfragen',
  continueToBooking: 'Weiter zur Buchung',
  demoRequestList: 'Liste Demoanfragen',
  yourDemoRequestList: 'Ihre Demo Anfragenliste',
  maintainStock: 'Lager bearbeiten',
  maintainLabsAndLocationPartner: 'l@l Partner bearbeiten',
  completed: 'Abgeschlossen',
  customerId: 'Auftraggeber',
  goodsRecipientId: 'Warenempfänger',
  deliveryBlock: 'Liefersperre',
  formularHeaderText: 'Texte - Formularkopftext (wird auf Kommissionierungsbeleg angedruckt)',
  shippingInstructions: 'Texte - Versandvorschrift (wird auf Kommissionierungsbeleg angedruckt)',
  searchCustomer: 'Suche Auftraggeber',
  newReturnDeliveryAddress: 'Neue Rücksendeadresse',
  stkRequestor: 'Anforderer',
  llPartner: 'l@l Partner',
  llManager: 'l@l Manager',
  maxLoanDuration: 'Max. Leihdauer (in Arbeitstagen)',
  fillDefaultPeriods: 'Please fill default period details',
  netValue: 'Nettowert',
  labsAndLocationResvationBooking: 'labs@location Buchung',
  backToCalendar: 'Zurück zum Kalender',
  backToAssignment: 'Zurück zur Zuordnung',
  labsAndLocationSystem: 'System',
  labsAndLocationReasonForLoan: 'Art der Demo',
  availabilityPeriod: 'Zeitraum der Verfügbarkeit',
  customerGroup: 'Kundengruppe 3',
  productIdMissing: 'Ein Leihauftrag kann nur mit Produkt ID erstellt werden. Bitte füllen Sie das Feld.',
  esbCorrelationId: 'ESB Correlation ID',
  exchangeStockForKitErrorMessage:
    'Bitte machen Sie eine Leihgutabholung bevor Sie das Lager ändern. Sie können dazu den Button “Leihgutabholung” neben dem Feld Leihauftrag (Referenz auf SAP ERP) nutzen. Falls das neue Lager ebenfalls extern administriert wird, können Sie nach abspeichern des Lagerwechsels einen neuen Leihauftrag für das neue Lager erstellen.',
  homeBooking: 'Buchen',
  homeKitloanCalendar: 'Kit-Leihe Kalender',
  homeKitloanCalendarDescription: 'Buche Systeme für Deine Kundendemonstration',
  homeLabsAndLocationCalendar: 'labs@location Kalender',
  homeLabsAndLocationCalendarDescription: 'Beantrage Demo Support bei unseren labs@location Partnern',
  homeKitsList: 'Kit-Liste',
  homeKitslistDescription: 'Verschaffe Dir einen Überblick über verfügbare Demogeräte',
  homeMarketplaceDescription: 'Finde passende Gebrauchtgeräte & Zubehör für unsere Kunden',
  homeDocumentDescription: 'Entdecke Richtlinien und Trainingsdokumentationen für die DMA',
  homeMyTransaction: 'Meine Anfragen',
  homeMyTransactionDescription: 'Verschaffe Dir einen Überblick über Deine Buchungen',
  warehouseDescription: 'Verschaffe Dir einen Überblick über alle Lager und beantrage Berechtigungen',
  homeMyAccountDescription: 'Verwalte deine Zeiss ID Account-Daten',
  maintainDemoUnitStatus: 'Kit-Status melden',
  maintainDusDescription: 'Erfasse den DUST Status für ein Gerät',
  subscribeToSystems: 'Benachrichtigungen abonnieren',
  subscribeToSystemsDescription: 'Für welche Systeme willst du im Fall eines neuen DUST Status benachrichtigt werden?',
  activeSystemDescription: 'Finde heraus für welche Systeme DUST Tracking aktiviert ist',
  taskDescription: 'Verschaffe Dir einen Überblick über alle DUST Aufgaben',
  demoRequestDescription: 'Demoanfragen bestätigen oder ablehnen',
  openProcessesDescription: 'Verschaffen Sie sich einen Überblick über offene Demobuchungen',
  homelabsAndLocationKitListDescription: 'Verwalten Sie Ihre Demogeräte',
  homeLabsAndLocationDescription: 'Schauen Sie sich existierende Buchungen im Kalender an',
  homeLabsLocationDcomentDesc: 'Entdecke Trainingsdokumentationen über die DMA',
  homelabslocationMyAccountDesc: 'Verwalten Sie Ihren Zeiss ID Account',
  homeMyAccount: 'Mein Account',
  homeNonRmsDecription: 'Entdecke Richtlinien und Trainingsdokumentationen für die DMA',
  pickupRequest: 'Transportanfrage',
  noPickupRequests: 'No messages for Pick-up request',
  deliveryDays: 'Lieferzeit in Tagen',
  returnDeliveryDays: 'Rücklieferzeit in Tagen',
  reconditioningDays: 'Aufarbeitungszeit in Tagen',
  contactEmail: 'E-Mail Adresse des Kontakts',
  accountName: 'Name des Kunden',
  contactFirstName: 'Vorname des Kontakts',
  contactLastName: 'Nachname des Kontakts',
  enableSerchText: 'Bitte geben Sie mindestens einen Wert für die Suche ein.',
  shippingDocumentErrorMessage:
    'Leider konnte kein Lieferschein aus SAP ERP geholt werden. Bitte nutzen Sie den Demo Management App Lieferschein oder prüfen Sie in SAP woran es liegt.',
  updatedBySystem: 'Updated by System',
  confirmed: 'Bestätigt',
  delivered: 'Ausgeliefert',
  transactionRequest: 'Anfrage',
  transactionApproved: 'Genehmigt',
  transactionCancelled: 'Abgebrochen',
  transactionChecked: 'Geprüft',
  transactionDelivered: 'Ausgeliefert',
  activeLoanOrderMessage1:
    'Warnung - Eine Leihauftragsnummer ist am Kit vorhanden. Bitte beachten Sie, dass Sie die Leihauftragsnummer am Kit zurückgeben müssen, bevor Sie eine neue auf Transaktionsebene erstellen. Klicke hier ',
  activeLoanOrderMessage2: ' um das Kit zu öffnen.',
  returned: 'Zurückgesendet',
  sendMaterialMessage:
    'Nur markierte Materialen werden der Leihgutabholung übergeben. Bitte die restlichen Materialien manuell in SAP bearbeiten.',
  createReturnLoanOrder: 'Leihgutabholung erstellen',
  confirmTheId: () => ' and click "+" to confirm the ID.',
  returnLoanOrderDivisonMissing:
    'Bitte pflegen Sie die Sparte und die Sachnummer am Demokit um einen Leihauftrag erstellen zu können.',
  reasonForLLLoan: 'Grund für die Anfrage',
  cancelLLTransaction: 'Ablehnen',
  clear: 'Clear Selection',
  operatorChangeWarningMessage:
    'Wenn Sie den Operator ändern, wird der aktuell gepflegete Name des Operators gelöscht.',
  deliveryNote: 'Lieferschein',
  returnDeliveryNote: 'Rücklieferschein',
  maitenanceCheckList: 'Checkliste “Demo Ready”',
  unpackedFrom: 'Ausgepackt von:',
  abnormalities: 'Auffälligkeiten:',
  maintenanceChecklist: 'Checkliste “Demo Ready”',
  arrivalAndUnpacking: 'Ankunft und Auspacken',
  installation: 'Installation',
  setUpBy: 'Aufgebaut von:',
  issues: 'Fehler: Auffälligkeiten',
  temporaryFilesBackup: 'Sicherung von temporären Kundendaten:',
  systemDemoReady: 'System ist Demobereit:',
  nps: 'NPS (0-10):',
  yesButRestricted: 'ja, mit Einschränkungen',
  demo: 'Demo',
  dismantling: 'Abbau',
  dismantlingBy: 'Abgebaut von:',
  laserSaftey: 'Lasersicherheit',
  electronicSecurity: 'Elektronische Sicherheit:',
  filesFromPCDeleted: 'Dateien vom PC gelöscht:',
  maintenanceCompleteness: 'Vollständigkeit:',
  maintenanceDemoKit: 'Demokit…',
  canBeShipped: 'Kann zum nächsten Kunde versendet werden',
  mustBeShipped: 'Muss zur Aufbereitung an Lager geschickt werden',
  discussionWithProdCenter:
    'Abstimmung mit Produktionswerk, ob Auffälligkeiten vom Service Techniker behoben werden können',
  maintenanceComment: 'Anmerkung:',
  maintenanceIssues: 'Fehler:',
  dateAndNameOfUser: 'Datum und Name des Systemnutzers:',
  addSingleLoan: 'Einzelleihe hinzufügen',
  addSingleItem: 'Einzelleihe hinzufügen',
  otherAddress: 'Andere Adresse',
  llPartnerStatus: 'l@l Partner status:',
  labsLocationKitBookedSucessfully: 'labs@location Anfrage erfolgreich erstellt.',
  customerType: 'Customer type',
  accountIdSap: 'Account ID SAP',
  supportingAsset: 'Unterstützendes Equipment',
  addSingleItemInfo: 'Bitte klicken Sie “Hinzufügen +” um das Material zu speichern.',
  materialNumberFormatError: 'Bitte geben Sie die Materialnummer in folgendem Format ein: 123456-7891-123.',
  restrictSpecialCharacters:
    'Bitte entfernen Sie folgende Sonderzeichen aus dem Text: “>” and “<“ Werden diese Zeichen nicht entfernt, kann kein Lieferdokument für das Demokit erstellt werden.',
  viewDetails: 'Zeige Details',
  waitingForLoanOrder: 'Warten auf Leihauftragsnummer',
  saveToSap: 'Speichern und an SAP senden',
  sapMessage:
    'Bitte senden Sie diese Anfrage nur, wenn der vorherige Versuch fehlgeschlagen ist (siehe AIF Monitor in SAP). Andernfalls wird der Leihauftrag doppelt in SAP angelegt.',
  resendRequest: 'Erneut senden',
  labslocationMessage:
    'Diese Funktion wird bald freigeschaltet. Anschließend können Sie auch Ihre labs@location Anfragen über die Demo Management App verwalten. Wir werden Sie informieren.',
  crmErrorMessage: 'labs@location wird bald freigeschaltet. Wir werden Sie informieren.',
  sapDetailsChangeMessage: ' Wenn Sie Details ändern möchten, öffnen Sie bite die Lagerkonfiguration (Arbeitsabläufe).',
  stockWelcomeMessage: (firstName, lastName) => `Hallo ${firstName} ${lastName}, `,
  stockWelcomeMessageDesc:
    'Willkommen in der ZEISS Demo Management App! Um mit der Applikation arbeiten zu können, benötigen sie zunächst Zugangsrechte zum Demolager wo sie arbeiten oder zu den Demolagern wo Sie Geräte leihen möchten. Bitte filtern sie im Bereich "Lager" nach Ihrem Geschäftsfeld und Ihrem Land. Anschließend werden Ihnen die verfügbaren Lager angezeigt und Sie können per Knopfdruck die Lagerberechtigungen anfragen. Nachdem der Lagerverwalter Ihre Anfrage bestätigt hat, bekommen Sie eine Mail und können auf das Lager zugreifen. Trainingsmaterial zur Demo Management App finden Sie oben rechts unter',
  stockPopupDocument: '"Dokumente und Trainings."',
  yourDemoLoanAppTeam: ' Ihr Demo Management App Team',
  calendarKitNotFoundText: 'Sie können kein passendes Kit finden? Kontaktieren Sie Ihren Lagerverwalter hier:',
  nameAndEmail: 'Gewähltes Lager und Email',
  kitDemandSucessMessage: 'Vielen Dank! Ihr Lagerverwalter wurde per Email informiert.',
  requestDemoKit: 'Neues Demokit beantragen',
  stockPopupRequestionPermission: 'Lagerberechtigungen anfragen',
  Views: 'Aufrufe',
  addMaterialNumberInfo: 'Klicke + um Item einzuloggen',
  Age: 'Alter',
  firstSalesOrder: 'Erster Auftrag',
  addSingleLoanMaterial: 'Bitte klicken Sie “Element hinzufügen” um das Material zu speichern.',
  inavalidFirstSalesOrderMessage: 'Bitte geben Sie die erste Vertriebsauftragsnummer für dieses Gerät ein.',
  addressConfig: 'Adresskonfiguration',
  addAddress: ' Adresse hinzufügen',
  setDefault: 'Standardadresse festlegen',
  c2cFirstTransactionWillBeChanged: transactionsId =>
    `Bitte prüfen Sie das Versanddatum von Transaktion ${transactionsId} und korrigieren Sie es falls notwendig. Durch die Stornierung von Transaktion ${transactionsId} könnte sich die Lieferzeit geändert haben`,
  c2cMiddleTransactionWillBeChanged: transactionsId =>
    `Das Versanddatum und das Kundeneingangsdatum für Transaktion ${transactionsId} wurde geändert um die Lücke in der C2C Kette zu schließen. Bitte prüfen Sie die Daten und korrigieren Sie diese wenn notwendig`,
  correctOpportunityMessage: 'Bitte tragen Sie eine korrekte Opportunity ID ein.',
  addNewAddress: 'Neue Adresse hinzufügen',
  showStorageLocation: 'Zeige Lagerort im Lager auf dem Lieferschein',
  selectDeliveryAddress: 'Please select Devilvery Address',
  qunatityFieldIsRequired: 'Quantity field is required',
  active: 'Activ',
  maintainRMSZMCC: 'Zeiss Microscopy Kundencenter bearbeiten',
  dispatcher: 'Dispatcher',
  zmccManager: 'ZMCC Manager',
  readOnly: 'Leseberechtigung',
  manager: 'Manager',
  stockReconditioning: 'Service',
  rooms: 'Räume',
  addRooms: 'Raum hinzufügen',
  deleteRommWarningMessage: 'Soll der Raum wirklich gelöscht werden?',
  deleteRoomTitle: 'Raum löschen',
  uniqueRoomNameErrorMessaage: 'Der Name des Raums existiert bereits.',
  roomDeleteMessage: 'Room Deleted Sucessfully',
  roomAddHintMessage: 'Bitte “Raum hinzufügen” klicken um Raum einzuloggen',
  zmcc: 'ZMCC',
  inputRoomLabel: 'Raum',
  createZMCCKit: 'Create ZMCC Kit',
  maintainZmccKit: 'Maintain ZMCC kit',
  copyZmccKit: 'Copy ZMCC kit',
  zmccFullForm: 'ZEISS Microscopy Kundencenter',
  room: 'Room',
  owner: 'Owner',
  isMobileOrExternal: 'Ist mobil/extern',
  roomNotAddedMesage: 'Wenn kein Raum am Kit gepflegt ist, ist es nicht sichtbar im Kalendar.',
  limitedDemoAbility: 'Beschränkt demofähig',
  malfunction: 'Betriebsstörung',
  crmAccountId: 'CRM Konto-ID',
  lateReturnExportError:
    'Bitte selektieren Sie eine Business Group und ein Land. Anschließend können Sie die Daten exportieren.',
  costInformation: 'Kosteninformation',
  crmAccountIdError: 'Bitte geben Sie die CRM-Konto-ID ein.',
  zmccCalendar: 'ZMCC Kalender',
  homeZmccDescription: 'Beantrage Demo Support von unseren Zeiss Microscopy Customer center',
  zmccRequest: 'ZMCC Anfrage',
  labsLocationRequest: 'labs@location Anfrage',
  calendarBlockingMessage: `Sehr geehrter User,\n\nfür Ihren Lagerverwalter und den nächsten Leihnehmer ist es wichtig, dass Sie die Ist-Daten für "Eingang beim Kunden" und "Rücklieferung" der Ausleihe hinzufügen. Auf diese Weise können wir sicherstellen, dass alle Ihre Anforderungen rechtzeitig erfüllt und die Systeme rechtzeitig an Ihre Kunden ausgeliefert werden. Wenn Sie keine aktuellen Daten hinzufügen können, weil die ursprüngliche Leihfrist verlängert werden muss, wenden Sie sich bitte an Ihren Lagerverwalter damit er die Daten anpassen kann.\n\nBitte fügen Sie Ihren folgenden Transaktionen Ist-Daten hinzu bevor Sie die nächste Ausleihe beantragen. Dies würde uns sehr helfen. Vielen Dank für Ihr Verständnis!`,
  additionalSingleLoanCreatedSuccesfully: (transactionsId) => `Zusätzliche Einzelleihe wurde erfolgreich erstellt: Transaction Number ${transactionsId}`,
  PickUpFromLastCustomer: 'Abholung beim letzten Kunden (Kunde-zu-Kunde Transport)',
  pickCustomerToCustomerBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickupAddressForCustomerToCustomer,
    deliveryAdress,
    targetShippingDate,
    targetReturnDeliveryDate,
    kitName,
    serialNumber,
    packageDimensions,
    receiptAtTheCustomer,
    receiptAtStock,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Sehr geehrte Damen und Herren,\n\nBitte organisieren Sie den folgenden Transport:\n**Abholadresse:*\n${pickupAddressForCustomerToCustomer}\n\n${showPicUpComment && pickUpComment ? `**Bemerkung zur Abholung:* ${pickUpComment}`:''}\n\n**Abholdatum:* ${targetShippingDate}\n\n**Zieladresse:*\n${deliveryAdress}\n\n${deliveryComment?`**Lieferkommentar*: ${deliveryComment}` :''}\n**Lieferdatum:*${receiptAtTheCustomer}\n**System:* ${kitName}\n**Serialnummer:* ${serialNumber}${packageDimensions ? `\n**Maße/Gewicht:* ${packageDimensions}` : ''}\n**Link zur Transaktion:* ${linkToTransaction}\n\n\nBitte geben Sie mir eine Rückmeldung und schicken Sie mir ggf. eine Auftragsbestätigung.\n\nMit freundlichen Grüßen\n${firstName} ${lastName}\n${organization}\n${phone} `,
  customerToCustomerEmailSubject: TransactionNumber =>
    `ZEISS Transportanfrage - Transaction ${TransactionNumber} - Lieferung von Kunde zu Kunde`,
  borrowerInfoMessage: 'Auf Grund des in Jena stattfindenden Umzuges des Demolagers kann es im April und Mai 2023 zu verlängerten Lieferzeiten kommen. Bitte fragen Sie Leihen entsprechend früher an. Vielen Dank.',
  gotIt: 'Verstanden',
  attention: 'Achtung',
  pickupComment: 'Bemerkung zur Abholung',
  trackingNumber: 'Trackingnummer',
  crateId: 'Kistennummer',
  alphanumericError: 'Bitte nur alphanumerische Zeichen eingeben',
  vrDetails: 'VR Details',
  windowsUserPassword: 'Windows User Passwort',
  userName: 'Nutzername',
  password: 'Passwort',
  linkToClickableURL: 'Link zum Onboarding Material',
  warningForAutomizedOptions: 'Bitte beachten Sie, dass Sie nur eine Option für die automtaische Meilensteinrückmeldung auswählen können.',
  ageInDays: 'Alter in Tagen',
  prevTransactionMessage: 'Springe zur letzten Transaktion mit diesem Kit',
  nextTransactionMessage: 'Springe zur nächsten Transaktion mit diesem Kit',
  export: 'Export',
  receiptNextCustomer: 'Eingang nächster Kunde',
  generalInformation: 'Allgemeine Informationen',
  customerInformation: 'Informationen über den Kunden',
  applicationSampleDetails: 'Details zu Applikationen & Proben',
  demoCenterInformation: 'Informationen Demo Center',
  additionalInformation: 'Zusätzliche Informationen',
  logisticsCoordination: 'Logistik & Koordination',
  thisFormIsIntendedFor: 'Dieses Formular ist bestimmt für ein(e)',
  nameOfRequestor: 'Name des Antragstellers',
  bestWayToReachMe: 'Präferierter Kontaktweg',
  mobile: 'mobil',
  suggestedDate: 'Wunschdatum',
  alternateDate: 'Alternatives Datum',
  primaryInstrument: 'Primäres Instrument',
  needTheDataWithin: 'Ich brauche den Demo-Report bis:',
  MultiSystemDemo: 'Bitte erklären Sie warum Sie mehr als ein Instrument demonstriert werden soll',
  opportunityId: 'Opportunity ID',
  firstDemoForThisProject: 'Erste Demo für das Projekt?',
  customerApplyingForFunding: 'Schon budgetiert oder muss der Kunde noch Budget beantragen?',
  schedulingInformation: 'INFORMATIONEN ZUM TERMIN',
  projectDetails: 'PROJEKTDETAILS',
  website: 'Webseite',
  pointOfContact: 'Kontaktperson',
  nda: 'Bestehendes NDA',
  designationTitle: 'Titel',
  marketSegment: 'Marktsegment',
  marketSubSegment: 'Markt Subsegment',
  customerMicroscopeExperience: 'Aktuelle Kundenerfahrung(en) mit aktuellen Mikroskopen?',
  zeissCustomer: 'Zeiss-Kunde',
  relationshipWithZeiss: 'Einstellung zu Zeiss',
  customerDetails: 'DETAILS ZUM KUNDEN',
  primaryPointOfContact: 'Hauptansprechpartner (falls andere Person als oben angegeben)',
  customerBackground: 'HINTERGRUND DES KUNDEN',
  next: 'Next',
  back: 'Back',
  mainApplicationChecklist: 'MAIN APPLICATION CHECKLISTE',
  subHeading: 'Was ist das primäre Ziel dieses Demo-/Probelaufs?\nBitte so genau wie möglich beschreiben.',
  highResolutionImaging: 'High Resolution Imaging',
  lowkvImagingForCharging: 'Low kV imaging for charging/beam sensitive sample',
  materialContrastImaging: 'Material Contrast Imaging',
  vpMode: 'VP mode',
  wetImaging : 'Wet imaging in EP mode with Peltier cooling stage',
  largeAreaMpping: 'Large Area Mpping (Atlas)',
  stemImaging: 'STEM Imaging',
  stageBias: 'Stage Bias',
  eds: 'EDS',
  ebsd: 'EBSD',
  correlativeMicroscopy: 'Correlative Microscopy (add details below)',
  smartPi: 'SmartPI',
  threeView: '3View',
  raman: 'Raman',
  cryo: 'Cryo',
  mineralogic: 'Mineralogic',
  othersSuchAsWds: 'Andere und/oder Dritte (wie WDS, AFM, metrology mit 3DSM oder Neo Pixel, Details bitte unten ergänzen)',
  detailedCompetitionMicroscopeModels: 'Detaillierte Wettbewerber Mikroskop-Modelle?',
  doYouHaveAnyDataFromTheCustomer: 'Haben Sie detaillierte Daten vom Kunden (PPT oder Bilder von anderen Instrumenten',
  smapleInformation: 'PROBENINFORMATIONEN',
  totalNumberOfSamples: 'Anzahl der Proben',
  detailedDescription: 'Bitte geben Sie detaillierte Beschreibungen aller Proben an und fügen Sie unterstützende Bilder und Dokumente bei. Geben Sie die erwartete Größe der Merkmale, die Materialien und die Definition der interessierenden Region/en an.',
  manipulateSamples: 'Können wir die Proben vor der Bildgebung manipulieren (beschichten, brechen, reißen, kleben usw.)?',
  statusSamples: 'Status der Proben nach Demo-/Probenlaufaktivität?',
  samplesCantManipulated: `Wenn die Proben nicht manipuliert werden können, geben Sie bitte die Vorsichtsmaßnahmen und sonstige probenspezifische Anmerkungen (Handhabung, Lagerung, Empfindlichkeit, Prioritäten usw.) an)`,
  attachFile: 'Attach File',
  systemAccessoriesText: ` Bitte beschreiben Sie den beabsichtigten Inhalt (Software/Hardware/Zubehör), der in der Demo gezeigt werden soll. Was wurde dem Kunden angeboten/besprochen?`,
  hardOrSoftwareAccessories: 'Hardware/Software/Zubehör',
  detailedComments: 'Detaillierte Anmerkungen',
  shippingSamples: 'Versand von Mustern:',
  demoAttendees: 'Demo-Teilnehmer',
  skillLevel: 'Qualifikationsniveau',
  descisionMaker: 'Entscheider',
  toDoList: 'To-Do Liste',
  alreadySlotReadyToBookOnThatRoom: 'Already slot ready to book on that room',
  removeFromSlotAvailiability: 'Remove from slot Availiability',
  fillWithValidInformation: 'Validations failed please fill it',
  zmcccancelWarningMessage: 'Wenn Sie abbrechen, werden Ihre Änderungen nicht gespeichert. Sind Sie sicher, dass Sie fortfahren möchten?',
  zmcccancelWarningMessage:'If you cancel all your changes will not be saved. Are you sure you want to continue?',
  teams: "Teams",
  formIsInvalidWithValidations: 'Please fill all the fields with proper validations',
  commentMsgForZmcc:'Please explain in comment why more than one demo is needed for this project.',
  suggestedAndALternateCantBeSame: "suggested date and alternate dates can't be same",
  maxc2cLengthIs5: 'Max allowed c2c chain length is 5',
  availableToBook: 'Available slot',
  bookedTransaction: 'Booked Transaction',
  clickBackAndFillThem: 'Click back and fill them',
  zmccSuccessCreation: 'Ihr Anfrage wurde erfolgreich übermittelt. Sie werden per Email informiert, wenn Anfrage bestätigt wurde.',
  selectCategory: 'wählen Sie aus in welcher Produktkategorie Sie eine Demo anfragen möchten:',
  zmccConfirmationPage: 'Alles erledigt! Wenn Sie alle Angaben gemacht haben, können Sie den Antrag jetzt abschicken.',
  reparationSlots: 'Reparaturen',
  kitReparationId: 'kitReparationId',
  kitReparationDeltedSuccessfully: 'Der Reparaturslot wurde erfolgreich gelöscht',
  continue: 'continue',
  cancelWarningMessageForReparation:'Sind Sie sicher, dass Sie diesen Reparatur-Slot löschen wollen?',
  continueForm: 'Formular fortsetzen',
  category: 'Kategorie',
  priority: 'Priorität',
  requestDate: 'Anfragedatum',
  lastModified: 'Zuletzt geändert',
  waitingForConfirmation: 'Warten auf Bestätigung',
  dueDate: 'Fälligkeitsdatum',
  estimatedDays: 'Geschätze Dauer in Tagen',
  additionalInstruments: 'Weitere Instrumente',
  infoMessageForAdditionalInstrument: 'Bitte ergänzen Sie weitere benötigte Systeme im Textfeld',
  zmccStartDate: 'Start Datum',
  reserve24 : 'Für 24 Std. reservieren',
  zmccReservation: 'ZMCC Reservierung',
  ccEmail: 'CC E-mail',
  clickOnAdd: 'Please click on "+" to add E-mail',
  demoDateAndTime: 'Demo Datum und Uhrzeit',
  zmccSystems: 'System(e)',
  crmDemoRequestID: 'CRM Demo Request ID',
  zmccNameOFRequestor: 'Antragsteller',
  zmccCustomerInstitute: 'Insitut des Kunden',
  connectedTransaction: 'Verknüpfte Transaktion',
  subscribers: 'Abonnenten',
  samplesAt: 'Proben',
  isCorrelative: 'Ist korrelativ',
  zmccDemoSite: 'Ort der Demo',
  timeTravel: 'Reisezeit',
  travelLog: 'Reiseprotokoll',
  onHold: 'On Hold',
  inProgress: 'In progress',
  waitingForSamples: 'Waiting for Samples',
  waitingForProcessing: 'Waiting for processing',
  doneStatus: 'Erledigt',
  priorityEroor: 'Please select Priority',
  setShippingAddressForZmcc: 'Als Lieferadresse für Proben anzeigen',
  zmccSystemClass: 'Systemklasse',
  maintainDeliveryAddress: 'Lieferadresse bearbeitens',
  deleteAddress: 'Adresse löschen',
  demoRequestForm: 'Demo-Antragsformular',
  allowedFormatssForZmccTransaction: 'ppt, pptx, .xls, xslx., .docx, doc, pdf, jpeg, jpg, png files ares allowed',
  timeManagement: 'Zeitmanagement',
  precallWithZmcc: 'Hat bereits ein Vorgespräch mit dem ZMCC stattgefunden?',
  zmccDocumentLink: 'Bitte legen Sie das Sicherheitsdokument als Anlage vor. Hier ist der',
  accomodationNeeded: 'Unterkunft benötigt?',
  flightNeeded: 'Flug benötigt (muss von Sales gebucht werden)',
  visitMuseum: 'Zusätzliches Besucherprogramm',
  fileDoesnotExist: 'File does not exist',
  crossSectioning: 'Cross-sectioning',
  temLamellaPrep: 'TEM Lamella Prep',
  fibTomography: 'FIB Tomography',
  nanoPatterning: 'Nano-patterning',
  threeDEds: '3D-EDS',
  threeDEbsd: '3D-EBSD',
  largeAreaMapping: 'Large Area Mapping',
  fsLaser: 'fs laser',
  addDetails: 'Others (Please add details below)',
  tofSims: 'ToF SIMS',
  xbDetailsForCustomer: 'Haben wir Daten vom Kunden? (PPT oder Bilder von anderen Instrumenten)',
  xbDetailedDescription: 'Bitte beschreiben Sie alle Muster detailliert und fügen Sie Bilder und Dokumente bei. Geben Sie die erwarteten Merkmalsgrößen, Materialien, die Definition der interessierenden Bereiche und die detaillierte Anwendung auf Proben an.',
  xbStatusSamples: 'Status der Proben nach Demo-/Musterlauf-Aktivität?',
  xbSamplesCantManipulated: 'Wenn die Proben nicht manipuliert werden können, geben Sie bitte die Vorsichtsmaßnahmen und andere probenspezifische Kommentare an (Handhabung, Lagerung, Empfindlichkeit, Prioritäten usw.)',
  demoDetails: 'DEMO DETAILS',
  rankMessage: 'Bitte geben Sie eine Rangfolge der einzelnen Parameter in der Reihenfolge ihrer Priorität an. Bitte wiederholen Sie keinen Wert.',
  intendedSystemText: 'Bitte geben Sie den Verwendungszweck des Systems an',
  customerBudget: 'Der Kunde hat ein Budget für Folgendes',
  failureAnalysis: 'Failure Analysis',
  researchRandD: 'Research / R&D',
  processDevelopment: 'Process Development',
  production: 'Production',
  qaqc: 'QA/QC',
  flatPanel: 'Flat Panel',
  fourtyX: '40X',
  art: 'ART',
  xrmPrimaryGoals: 'Was sind die Hauptziele dieser Demo/des Probelaufs?',
  xrmCustomerCompetition: 'Aktuelle Kundensysteme und Wettbewerb?',
  xrmApplicationInformation: 'APPLIKATIONSINFORMATIONEN',
  xrmApplicationInforDescText: `Bitte geben Sie eine detaillierte Beschreibung der Anwendung(en), auf die sich der Kunde konzentriert, um den Ansatz der Analyse besser zu verstehen`,
  xrmPhysicallyManipulateSamples: 'Können wir die Muster physisch manipulieren (kleben, epoxidieren, kleben, zuschneiden, brechen, usw.)? *In der Regel sollte der Kunde alle notwendigen Vorbereitungen für das Muster übernehmen',
  samepleDescription: 'PROBENBESCHREIBUNG',
  xrmOverAllSamples: 'Bitte beschreiben Sie die Gesamtprobe (einschließlich Handhabung, Lagerung, Empfindlichkeit, Prioritäten usw.)',
  sampleSpecs: 'PROBENSPEZIFIZIERUNG',
  totalSampleSize: 'Gesamtgröße der Probe',
  xrmFeatureSizes: 'Merkmalsgrößen (μm-mm): z. B. Gesteinsporendurchmesser, Drahtgröße, Kurz-, Durchgangsmaß, Knorpeldicke. Bitte geben Sie das so genau wie möglich an, da dies dem Anwendungstechniker eine optimale Bildgebung ermöglicht',
  xrmFeatureOfRegion: 'Bitte definieren Sie das Merkmal oder den Bereich, der Sie interessiert: Beschreiben Sie hier und fügen Sie ggf. Soft- oder Hardcopies von Skizzen, Diagrammen, Bildern oder Abbildungen aus optischen, REM- oder gleichwertigen Verfahren sowie die prozentuale Zusammensetzung in Volumenprozent bei:',
  xrmExceptedMaterial: 'Welche Materialien und chemischen Zusammensetzungen sind in der Region von Interesse zu erwarten, z. B. Kunststoff, Kupfer, organische Stoffe, Silikate?',
  xrmSubstrate: 'Was ist das Substrat oder das umgebende Material? z. B. Siliziumdioxid, Laminat, Knochen',
  xrmContrastApplied: `Wurde ein Kontrastmittel verabreicht? Falls ja, geben Sie bitte den Typ an`,
  xrmSampleDetails: 'DETAILS ZUR PROBE',
  bookAccomodation: 'Book accommodation',
  bookflight: 'Book flight',
  todoVisitMuseum: 'Organize visit of museum of optics',
  specifyDetails: 'Bitte Details spezifizieren',
  lmApplicationAndSamples: 'APPLICATION & SAMPLES',
  primaryApplicationForThisCustomer: 'Was ist die Hauptanwendung für diesen Kunden? (Live-Zelle, 3D-Bildgebung, hoher Durchsatz ...)',
  sampleGoingToProvideForThisDemo: 'Welche Beispiele werden für diese Demo bereitgestellt?',
  learningAboutTheseSamples: 'Was möchten Sie über diese Proben erfahren? (Erwartungen)',
  justificationPoints: 'Schlüsselfaktoren (make/break Punkte)',
  aboutCompetitionInThisSale: 'Was wissen wir über die Konkurrenz bei diesem Projekt?',
  othersComments: 'Andere Anmerkungen',
  lmTotalNumberOfSamples: 'Gesamtzahl der Proben',
  lmStatusOFSamples: 'Status der Proben nach der Demo-/Probelaufaktivität',
  lmDoYouhaveCustomerData: 'Liegen uns Daten des Kunden vor? (ppt oder Bilder von anderen Instrumenten)',
  zmccFormName: type =>`Demoanfrageformular ${type}`,
  for: 'für',
  requestType: 'Art der Anfrage',
  zmccTransactionStatusUpdatedMessage: 'Transaction status updated sucessfully',
  commentPlaceholderForBookings:'Bitte geben Sie uns Informationen zur Installation, Rücksendung, Details zum Leihgrund, spezielle Lieferanforderungen oder andere benötigte Teile. Vielen Dank.',
  linkedReservations: 'Verknüpfte Reservierungen',
  continueAppMessage: 'Ihre Datei wird heruntergeladen. Sie können weiter in der DMA arbeiten.',
  successMessageForExportListTransaction: 'The transaction data is exported successfully',
  errorMessageForExportListTransaction: 'Something went wrong. The transaction export failed. Please try again.',
  successMessageForExportListKit: 'The kit data is exported successfully',
  errorMessageForExportListkit: 'Something went wrong. The kit export failed. Please try again.',
  kitLoanOrderTextUpdated: 'Letzte Leihauftragsnummer (Referenz zu SAP ERP)',
  alreadyBudgeted: 'Budget vorhanden',
  applyingForFunding: 'Budget beantragt',
  submitMaintenanceChecklist: 'Checkliste “Demo Ready”  ausfüllen',
  close: 'Schließen',
  arrivalForm: 'Checkliste “Demo Ready”  ausfüllen (Ankunft)',
  demoForm: 'Checkliste “Demo Ready”  ausfüllen (Demo)',
  dismantlingForm: 'Checkliste “Demo Ready”  ausfüllen (Abbau)',
  systemAvailability: 'Systemverfügbarkeit',
  addSlot: 'Zeitfenster hinzufügen',
  recurring: 'Wiederkehrend?',
  listLabelRecurring: 'Wiederkehrend:',
  deletAvailablitySlotMessage: 'Sind Sie sicher, dass Sie das Zeitfenster für das System entfernen möchten?',
  selectKits: 'Kits auswählen',
  deleteSlot: 'Zeitfenster löschen',
  addNewSlot: 'Neues Zeitfenster hinzufügen',
  editSlot: 'Zeitfenster bearbeiten',
  slecteDaysError: 'Bitte Daten für die Wiederkehrend-Option pflegen.',
  slotCreatedSucessfully: 'Daten erfolgreich gespeichert',
  deletdSucessFully: 'Availability slot removed successfully.',
  commentTextForMedTaiwan: 'Kommentar (Bitte geben Sie uns Informationen zur Installation, Rücksendung, Details zum Leihgrund, spezielle Lieferanforderungen oder andere benötigte Teile. Vielen Dank.)',
  headerTextArrival: 'Ankunft',
  zmccSelectKits: 'Kits auswählen',
  campaignId: "Kampagnen-ID",
  campiagnIdCharacter: "C -",
  campaignIdErrorMessage: "Campaign ID should have 8 digits",
  campaignShouldBeNumeric: "Campaign ID should be numeric",
  enterValidCampignId: 'Please enter valid Campaign Id',
  operator: 'Operator',
  operatorHaveActiveTransactions: 'Der Operator kann nicht entfernt werden, da er einer ZMCC-Anfrage zugewiesen ist. Bitte entfernen Sie den Benutzer als Operator aus der Anfrage und versuchen Sie es erneut.',
  operatorHaveActiveTransactionMessage: (message) => `${message}`,
  mySchedule: 'Mein Kalender',
  startTime: 'Startzeit',
  endTime: 'Endzeit',
  allDay: 'Ganzer Tag',
  scheduleInputTitle: 'Titel',
  selectType: 'Typ auswählen',
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
  businessTravel: 'Geschäftsreise',
  otherType: 'Sonstiges',
  vacation: 'Urlaub',
  workFromHome: 'Home Office',
  addSchedule: 'Kalender hinzufügen',
  adaptLoanValidationMessage: "Bitte mindestens einen Leihgrund auswählen.",
  reasonLoanToOemPartner: 'Leihe für OEM Partner',
  reasonInHouseLoan: 'Inhouse-Leihe',
  reasonDemoInSscShowroom: 'Demo im SSC Ausstellungsraum',
  reasonDemoAtTheCustomers: 'Demo beim Kunde',
  reasonCourse: 'Kurs',
  reasonLoanBeforeUnitDelivery: 'Leihe vor System-Auslieferung',
  reasonLoanUnitDurationRepair: 'Leihe für den Zeitraum der Reparatur',
  reasonStrategicLongTermLoan: 'Strategische Langzeit-Leihe',
  reasonTradeFair: 'Messe',
  reasonWorkshop: 'Workshop',
  reasonLoanToDealer: 'Leihe an Dealer',
  reasonAvailableNoDemo: "Verfügbar/keine Demo",
  reasonRMAReturn: 'RMS Rücksendung',
  reasonWalkOff: 'Abverkauf',
  systemCategory: 'System Kategorie',
  attendeeName:'Teilnehmer',
  addMoreButtonText: 'Hinzufügen mehr',
  editSchedule: 'Kalender bearbeiten',
  assignOperator: 'Assign Operator',
  endTimeShouldBeLessThanStartTime: "Die Endzeit sollte größer sein als die Startzeit",
  pleaseSelectAtleaseOnOperatorToAssign: 'Please select atleast one operator to assign',
  reassignOperator: "Reassign",
  viewSchedule: 'Kalender anzeigen',
  deleteScheduledEvent: 'Data deleted successfully',
  scheduleType: 'Typ',
  thisEvent: 'Dieses Event',
  thisAndFollowingEvents: 'Dieses und folgende Events',
  allEvents: 'Alle Events',
  deleteRecurringEvent: 'Delete recurring event',
  UpdateRecurringEvent: 'Update recurring event',
  adoptChangeForSubsequentReservation: 'Übernehmen Sie die Änderung für nachfolgende Reservierungen:',
  labsLocationUserWelcomeMessage: (firstName, lastName) => `Hallo ${firstName} ${lastName}, herzlich willkommen bei der ZEISS Demo Management App. Bitte informieren Sie Ihren labs@location Manager, dass Sie sich erfolgreich registriert haben. Er wird Ihnen die erforderlichen Berechtigungen erteilen. Danach können Sie mit der Arbeit beginnen. Vielen Dank.`,
  send:'Senden',
  transactionNumber:'Transaction Number',
  createBooking: 'Create Booking',
  xrmLabel: 'XRM',
  xbLabel: 'XB',
  semLabel: 'SEM',
  lmLabel: 'LM',
  noTypeAdded: 'Art der Anfrage nicht ausgewählt',
  messageForKitSlotEdit: 'Warning: Availability of other systems was created along with this system. The changes will apply to all those systems.',
  zmccStatus: 'Status des Zmcc',
  demoProcess: "Demo-Prozess",
  demoStart: "Demostart",
  demoEnd: "Demo-Ende",
  warehouses: "Lagerhäuser",
  lAndlPartner: "labs@location-Partner",
  zmccWarehouses: "ZMCC",
  selectRequestType: 'Wählen Sie den Anfragetyp aus',
  pleaseSelectRequestTypeToContinue: "Bitte wählen Sie den Anfragetyp aus, um fortzufahren",
  week:'Woche',
  canEnterMultipleEmails:'Verwenden Sie kommagetrennte E-Mail-Adressen für mehrere Empfänger.',
  appUrlChangeSubject: 'Umbenennung der Webseite',
  appUrlChangeBodyOne: `Wir möchten Sie darüber informieren, dass wir unsere App umbenennen werden. Der bisherige Name "Demo Loan App" wird durch "Demo Management App" ersetzt. Der Grund für diese Änderung ist, dass unser System schon länger nicht mehr nur für Leihprozesse verwendet wird, sondern auch für den Marketplace, das DUST-Tool und zukünftig für weitere Demo-Prozesse wie das Demo-Management im Kundencenter sowie für labs@location-Anfragen. Wir hoffen, dass diese Änderungen dazu beitragen werden, unsere Webseite noch benutzerfreundlicher und umfassender zu gestalten. Wenn Sie `,
  appUrlChangeBodyTwo : ` in Ihren Browser-Favoriten abgespeichert haben, ändern Sie den Link bitte zu`,
  thankYou: 'Thank you.',
  dontShowAgain: 'Nicht wieder anzeigen',
  leaveWarningMessage: "Die von Ihnen vorgenommenen Änderungen werden nicht gespeichert.",
  leave: 'Verlassen',
  returnDeliveryAddress: "Rücksendeadresse",
  returnDeliverAddressNotAddedForCountry: 'Für das Land in der Lieferadresse wurde vom Lagerverwalter aus zollrechtlichen Gründen eine andere Rücklieferadresse als die des Leihgebenden Lagers gepflegt. Wenn Sie das Land ändern wollen, prüfen Sie bitte gemeinsam mit Ihrem Lagerverwalter die Rücklieferadressse.',
  day: 'Tag',
  kitslotIsnotValiable: 'System not available',
  legendConfirm :'Bestätigte Demo mit allen verfügbaren Informationen',
  legendMissing : 'Probe, NDA, Bestätigung des Kunden, Hotelbuchung, etc.',
  legendNonIssue: 'Ohne Demobezug (manuelle Einträge, Platzhalter, etc.)',
  legendNotAvailable: 'Nicht verfügbar (System in Reparatur, System ausgefallen, System nicht mehr verfügbar)',
  myQueries: 'Meine gesicherten Suchen',
  saveQuery: 'Save Query',
  openProcess: 'Offene Transaktionen',
  updateExistingFilters: 'Update existing filters',
  newQuery: 'Create new query',
  queryResult: 'Query Result',
  cancelOperation: 'Cancel the operation',
  updateQueryErrorMessage: 'Please pick an option',
  sbu: 'SBU',
  atleastOneAttendeeIsMandatory: 'Atleast one attendee is mandatory.',
  mustHaveAttendee: 'Atleast one attendee is required.',
  pleaseFillRequiredFieldsForAttendee: 'Please fill required fields for attendee.',
  dateModified: 'Letzte Änderung',
  by:"von",
  startOfDemo: 'Demo-Start',
  endOfDemo: 'Demo-Ende',
  labslocationOnCart: 'labs@location',
  hour:'Stunde',
  hours:'Stunden',
  pages:'Seiten',
  availableSystems: "Available Systems",
  replaceToLocation: 'Standort',
  emailMessageCategoryHedaReplace: 'Standort',
  wareHouseStatus: 'Status des Demolagers',
  locationStatus: 'Status des Standorts',
  externallyManagedlabel: 'Extern verwaltetes Standort',
  dmaReporting: 'DMA Reporting',
  getData: 'Get data',
  toptenCountriesUsgae: 'Top 10 Countries DMA Usage',
  requestsCreatedBy: 'Requests Created By',
  requestsShippedBy: 'Requests Shipped By',
  specialProvinceDeliveryTimes: 'Special Province Delivery Times',
  targetProvince: 'Target Province',
  msgForStateOrProvinceSpecialDeliveryTime : (deliveryDays, returnDeliveryDays, reconditioningDays) => `Bitte berücksichtigen Sie, dass wir ${deliveryDays} Tage Lieferzeit, ${returnDeliveryDays} Tage Rücklieferungszeit und ${reconditioningDays} Tage Wiederaufbereitungszeit benötigen, wenn wir das System in diese Provinz/Bundesland schicken. Wenn die Buchung nicht ausgeführt werden kann, prüfen Sie bitte den Kalender oder bitten Sie Ihren Demo-Standortleiter um Unterstützung.`,
  stateOrProvince: 'State/Province',
};

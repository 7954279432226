import React from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import Button from 'library/common/commonComponents/Button/Button';

import './deleteButton.styles.scss'

const DeleteButton = (props) => {
  const { handleDelete, id, iconName, value, disabled } = props;

  const openPopup = () => {
    if ($(`#confirmKitDeleteModal${id}`) && $(`#confirmKitDeleteModal${id}`).modal) {
      $(`#confirmKitDeleteModal${id}`).modal('show');
    }
  };

  return (
    <div>
      <Button
        dataTest='permissions-filter-button'
        bgFill={false}
        isGhost={true}
        iconWidth={16}
        iconHeight={16}
        iconStyle='ml-1'
        iconName={iconName}
        onClick={openPopup}
        value={value}
        styleClass='delete-button'
        disabled={disabled}
      />
      <ConfirmDeletionPopup
        id={`confirmKitDeleteModal${id}`}
        onConfirm={() => handleDelete(id)}
        title={strings.confirmDeleteKit}
        subtitle={strings.confirmDeleteKitMessage}
      />
    </div>
  )
};

export default DeleteButton;

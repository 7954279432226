import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import { getDUSTColor, getDUSTTitle } from 'library/utilities/getDUSTColor';
import moment from 'moment';
import {getIconByStatus, getIconByCondition} from 'library/utilities/getStatusAndConditionIcons';

export const dataTable = (title) => [
  {
    title: '#',
    name: 'number',
    isSort: false,
    render: (item) => {
      return (
        <span>{item?item.kitInformationId:''}</span>
      )
    },
  },
  {
    title: `${strings.status}/${strings.conditionText}`,
    name: 'status',
    isSort: false,
    render: (item, _, isUnfolded) => {
      const { kitStatuses, kitCondition } = item;
      const isExpandable = isUnfolded;
      let doNotShowInReparation = false;
      if (item.kitReparation && item.kitReparation.length) {
        item.kitReparation.map(timeSlot => {
          if (moment(timeSlot.reparationFrom).isBefore(moment())) {
            doNotShowInReparation = true;
          }
        })
      }

      return (
        <div className={cn({ 'd-flex': !isUnfolded })}>
           {
             kitStatuses && kitStatuses.length ?
            <div className={cn({ 'd-flex': !isUnfolded })}>
              {
                kitStatuses.map(item => {
                  if (item && item.kitStatusesTranslated && item.kitStatusesTranslated.length) {
                    if (doNotShowInReparation && item.kitStatusId == 13) {
                      return null
                    } else {
                    return (
                      <Fragment key={item.kitStatusId}>
                        {getIconByStatus(item, isExpandable)}
                      </Fragment>
                      )
                    }
                  }

                  return null;
                })
              }
            </div> : null
          }
          {
            kitCondition && kitCondition.kitConditionTranslated && kitCondition.kitConditionTranslated.length ?
            <>
                {
                  kitCondition.kitConditionId == 5 && !doNotShowInReparation ? null :
                    getIconByCondition(kitCondition, isExpandable)
                }
            </>
            : null
          }
        </div>
      )
    },
  },
  {
    title: strings.dust,
    name: 'dust',
    isSort: false,
    render: (item, _, isUnfolded) => {
      const code = get(item, 'kitDUSDCommentLast.kitDUSDCommentStatus.code');
      const colorIcon = getDUSTColor(code);
      const title = getDUSTTitle(code);

      return (
        <div className={cn('d-flex align-items-center justify-content-center')}>
          <div className={colorIcon} />
          {isUnfolded && <span className='ml-1'>{title}</span>}
        </div>
      )
    },
  },
  {
    title: strings.kitName,
    name: 'kitName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.kitName}</span>
      )
    },
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.serialNumber}</span>
      )
    },
  },
  {
    title: strings.dateOfManufactureText,
    name: 'dateCreated',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.dateOfManufacture ? dayjs(item.dateOfManufacture).format('DD.MM.YYYY') : null}</span>
      )
    },
  },
  {
    title: strings.systemValue,
    name: 'systemValue',
    isSort: true,
    render: (item) => {
      return (
       <span>{item.systemValue}</span>
      )
    },
  },
  {
    title: {title},
    name: 'stockName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.stockInformation && item.stockInformation.locationName}</span>
      )
    },
  },
  {
    title: strings.countryLand,
    name: 'country',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.stockInformation && item.stockInformation.country && item.stockInformation.country.name}</span>
      )
    },
  },
  {
    title: strings.currentPosition,
    name: 'currentPosition',
    isSort: true,
    render: (item) => {
      const currentPosition = get(item, 'transactionPosition.transactionPositionTranslated[0].positionText');
      const organizationName = get(item, 'organizationName');
      const showedOrganizationName = organizationName ? `[${organizationName}]` : '';

      return (
        <span>{currentPosition} {showedOrganizationName}</span>
      )
    },
  },
]

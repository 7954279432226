import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import './stepsStyle.scss';
import strings from 'resources/locales/Translate';

const Steps = ({ activeStep , actionFrom, forZmccBooking}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const steps = [
    {
      step: 1,
      title: strings.assignment,
      activeStyle: cn({ 'active-step': activeStep === 1 || activeStep === 2 || activeStep === 3 }),
    },
    {
      step: 2,
      title: strings.customerData,
      activeStyle: cn({ 'active-step': activeStep === 2 || activeStep === 3 }),
    },
    {
      step: 3,
      title: strings.confirmation,
      activeStyle: cn({ 'active-step': activeStep === 3 }),
    },
  ];

  const zmccSteps = [
    {
      step: 1,
      title: strings.assignment,
      activeStyle: cn({ 'active-step': activeStep === 1 || activeStep === 2 || activeStep === 3 }),
    },
    {
      step: 2,
      title: strings.confirmation,
      activeStyle: cn({ 'active-step': activeStep === 2 }),
    },
  ];

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const updatedSteps = actionFrom === 'zmccSemBooking' ? zmccSteps : steps;
  return (
    <div className={`container-fluid ${forZmccBooking? forZmccBooking:'border-steps'}`}>
      <ul className='steps-container'>
        {updatedSteps.map((item, i) => {
          if (width > 600 || activeStep === i + 1) {
            return (
              <li className={item.activeStyle} key={item.step}>
                <span className='number-step'>{item.step}</span>
                <span className='text-step'>{item.title}</span>
              </li>
            );
          }
          return (
            <li className={item.activeStyle} key={item.step}>
              <span className='number-step'>{item.step}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Steps;

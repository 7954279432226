export const FETCH_INTERFACE_MONITORING = 'INTERFACE_MONITORING/FETCH_INTERFACE_MONITORING';
export const CLEAR_INTERFACE_MONITORING = 'INTERFACE_MONITORING/CLEAR_INTERFACE_MONITORING';

export const interfaceMonitoringBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'administration',
  },
  {
    name:'interfaceMonitoring',
  },
];

export const endSystem = [
  {
    id: 3,
    name: 'SAP ERP',
  },
  {
    id: 1,
    name: 'SAP CRM',
  },
  {
    id: 2,
    name: 'ZEISS Portal',
  },
];

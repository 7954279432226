import React, { useLayoutEffect, useState } from 'react';

import strings from 'resources/locales/Translate';

const DeliveryTooltip = ({ transactionDetails, id }) => {
  const {
    firstName,
    lastName,
    organization,
    department,
    street,
    postalCode,
    city,
    state,
    houseNumber,
    country,
    phone,
    email,
  } = transactionDetails;
  const [left, setLeft] = useState(0);
  const isAddressExist = firstName || lastName || organization || department || street || postalCode || city || state || country || phone || email;

  useLayoutEffect(() => {
    const infoButton = document.getElementById(id);
    const coordinates = infoButton.getBoundingClientRect();
    setLeft(coordinates.left);
  }, [id]);
  
  return (
    <div className='d-flex'>
      <p className='info-button cursor-pointer'>i</p>
      <div className='delay-tooltip' style={{ left: left + 80 }}>
        {(firstName || lastName) && (
          <div>{`${firstName} ${lastName}`}</div>
        )}
        {organization && <div>{organization}</div>}
        {department && <div>{department}</div>}
        {street && (
          <div>{street} {houseNumber}</div>
        )}
        {(postalCode || city) && (
          <div>{postalCode} {city}</div>
        )}
        {state && <div>{state}</div>}
        {country && <div>{country.name}</div>}
        {phone && (
          <div>{strings.phone}: {phone}</div>
        )}
        {email && (
          <div>{strings.email}: {email}</div>
        )}
        {!isAddressExist && (
           <div>{strings.noDeliveryAddress}</div>
        )}
      </div>
    </div>
  );
};

export default DeliveryTooltip;

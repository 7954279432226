import dayjs from 'dayjs';
import moment from 'moment';
import { regex } from '../common/commonConstants/RegexConstants';
import EditMaterialNumberAndDescriptionPopup from 'modules/Transactions/TransactionDetails/Tabs/EditMaterialNumberAndDescriptionPopup/EditMaterialNumberAndDescriptionPopup.component';

export default class Validators {
  static email(value, message) {
    const strLength = value ? value.length : 0;

    if (strLength > 0) {
      let result = regex.email.test(value);
      if (!result) {
        return { error: true, message: message };
      }
    }
    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message: message };
    }
    return false;
  }

  static cost(value, message) {
    const strLength = value ? value.length : 0;

    if (strLength > 0) {
      let result = regex.price.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static number(value, message) {
    const strLength = value ? value.toString().length : 0;

    if (strLength > 0) {
      let result = regex.number.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static numberOrDecimal(value, message){
    const strLength = value ? value.toString().length : 0;
    if (strLength > 0) {
      let result = regex.numberOrDecimal.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static numberOfcharacters(value, message, minDate, length) {
    const strLength = value ? value.toString().length : 0;

    if (strLength && (strLength !== length)) {
      return { error: true, message };
    }
    return false;
  }

  static date(value, minDate) {
    const minDateForBooking = moment().add(minDate, 'days').format('YYYY-MM-DD');
    const selectedValue = dayjs(value).format('YYYY-MM-DD');
    const isInvalidDate = dayjs(selectedValue).isBefore(dayjs(minDateForBooking));
    if (value && isInvalidDate) {
      return false;
    }
    return true;
  }

  static url(value, message) {
    const strLength = value ? value.length : 0;

    if (strLength > 0) {
      let result = regex.url.test(value);
      if (!result) {
        return { error: true, message: message };
      }
    }
    return false;
  }

  static maxLength(value, message, minDate, length) {
    const isMaxLength = value && value.length > length ? true : false;

    if (isMaxLength) {
      return { error: true, message: message };
    }
    return false;
  }

  static materialNumber(value, message) {
    const strLength = value ? value.toString().length : 0;
    if (strLength > 0) {
      let result = regex.materailNumber.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static alphaNumericWithSpace(value, message) {
    const strLength = value ? value.toString().length : 0;
    if (strLength > 0) {
      let result = regex.alphaNumericWithSpace.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static opportunityStartingWith200(value, message) {
    const strLength = value ? value.toString().length : 0;
    if (strLength > 0) {
      let result = regex.validCrmCheck.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }
  static opportunityCheckForalphanumeric(value, message) {
    const strLength = value ? value.toString().length : 0;
    if (strLength  <= 18) {
      let result = regex.alphaNumeric.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }

  static campaignIdValidation(value, message){
    const strLength = value ? value.toString().length: 0;
    if(strLength === 0){
      return false;
    }
    let result = regex.number.test(value);

    if(strLength > 8 && result){
      return { error: true, message: message };
    }
    if(strLength === 8 && result){
      return false;
    }
    if(strLength < 8 && result)
      return { error: true, message: message };
  }

  static campaignIdShouldBeNumeric(value, message){
    const strLength = value ? value.toString().length : 0;
    if(strLength === 0){
      return false;
    }
    let result = regex.number.test(value);
    if(!result){
      return {error: true, message: message};
    }
    return false;
  }

  static mobileAlphaNumericWithPlus(value, message) {
    const strLength = value ? value.toString().length : 0;
    if (strLength > 0) {
      let result = regex.mobileAlphaNumericWithPlus.test(value);
      if (!result) {
        return { error: true, message: message };
      }
      return false;
    }
    return false;
  }
}

import React, { Component } from 'react';

import FilesListView from '../FileList/FilesListView';
import FilesGridView from '../FileList/FilesGridView';

export default class FileUploader extends Component {
  async handleRemove(index) {
    const { files, maxFiles, onMaxFileReached, onRemove } = this.props;
    await onRemove(index);
    if ((files && files.length - 1) < maxFiles) {
      onMaxFileReached(false);
    }
  }

  render() {
    const {
      files,
      onFileAdd,
      viewType,
      mimeType,
      maxSize,
      isUploadAllowed,
      isDownloadable,
      downloadStream,
      downloadUrl,
      downloadIdField,
      onReject,
      maxFiles,
      onMaxFileReached,
      availableTypes,
      errorMessage,
      isNotRemoved,
      isUploader,
    } = this.props;
    let disableOnlyUpload = true;
    if (maxFiles > -1) {
      disableOnlyUpload = files && files.length < maxFiles;
    }
    if (!disableOnlyUpload) {
      onMaxFileReached(true);
    }
    return (
      <div className='file-uploader'>
        {viewType === 'list' ? (
          <FilesListView
            files={files}
            maxSize={maxSize}
            maxFiles={maxFiles}
            mimeType={mimeType}
            viewType={viewType}
            onFileAdd={onFileAdd}
            downloadUrl={downloadUrl}
            downloadStream={downloadStream}
            isDownloadable={isDownloadable}
            downloadIdField={downloadIdField}
            isUploadAllowed={isUploadAllowed}
            onMaxFileReached={onMaxFileReached}
            disableOnlyUpload={disableOnlyUpload}
            onReject={onReject}
            onRemove={index => this.handleRemove(index)}
            availableTypes={availableTypes}
            errorMessage={errorMessage}
            isNotRemoved={isNotRemoved}
            isUploader={isUploader}
          />
        ) : (
          <FilesGridView
            files={files}
            maxSize={maxSize}
            maxFiles={maxFiles}
            mimeType={mimeType}
            viewType={viewType}
            onFileAdd={onFileAdd}
            downloadUrl={downloadUrl}
            isDownloadable={isDownloadable}
            downloadStream={downloadStream}
            downloadIdField={downloadIdField}
            isUploadAllowed={isUploadAllowed}
            onMaxFileReached={onMaxFileReached}
            disableOnlyUpload={disableOnlyUpload}
            onReject={onReject}
            onRemove={index => this.handleRemove(index)}
            availableTypes={availableTypes}
            errorMessage={errorMessage}
            isNotRemoved={isNotRemoved}
            isUploader={isUploader}
          />
        )}
      </div>
    );
  }
}

FileUploader.defaultProps = {
  viewType: 'list',
  mimeType: '',
  maxSize: 100, // 100MB for default
  isUploadAllowed: true,
  isDownloadable: false,
  downloadUrl: '',
  downloadIdField: '',
  maxFiles: -1,
  onReject: () => {},
  onMaxFileReached: () => {},
  availableTypes: '',
};

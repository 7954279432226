import React, { useState } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import AttachmentsBlock from './AttachmentsBlock.component';
import styles from './KitAttachments.module.scss';

const Attachments = props => {
  const { kitDetailsAttachments, transactionAttachments, hasTransactionAttachmentsSection } = props;
  const [viewType, setViewType] = useState('list');
  const listClasses = cn('w-auto p-0 mr-3', {
    [styles.iconBlack]: viewType === 'list',
    [styles.iconGray]: viewType !== 'list',
  });
  const gridClasses = cn('w-auto p-0', {
    [styles.iconBlack]: viewType === 'grid',
    [styles.iconGray]: viewType !== 'grid',
  });

  const toggleToListViewType = () => {
    setViewType('list');
  };

  const toggleToGridViewType = () => {
    setViewType('grid');
  };

  return (
    <div className='kit-attachments-container'>
      <div className='container-fluid form-container'>
        <div className='toggle-container d-flex align-items-center justify-content-between'>
          <h3 className='form-section-title mb-0'>{strings.attachments}</h3>
          <div className='d-flex'>
            <Button
              iconName='list_view'
              bgFill={false}
              isGhost={true}
              onClick={toggleToListViewType}
              styleClass={listClasses}
              iconWidth='32'
              iconHeight='32'
            />
            <Button
              iconName='grid_view'
              bgFill={false}
              isGhost={true}
              onClick={toggleToGridViewType}
              styleClass={gridClasses}
              iconWidth='32'
              iconHeight='32'
            />
          </div>
        </div>

        <AttachmentsBlock
          id='kitAttachmentsId'
          title={hasTransactionAttachmentsSection ? strings.kitAttachments : null}
          viewType={viewType}
          files={kitDetailsAttachments}
          downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadAttachment}
        />
        {hasTransactionAttachmentsSection && (
          <AttachmentsBlock
            id='transactionAttachmentsId'
            title={strings.transactionAttachmentForKit}
            viewType={viewType}
            files={transactionAttachments}
            downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadLoanAttachment}
          />
        )}
      </div>
    </div>
  );
};

export default Attachments;

import { connect } from 'react-redux';

import DelayedTransactionsFilter from './DelayedTransactionsFilter.component';

const mapStateToProps = ({ languageReducer, statisticsReducer }) => ({
  language: languageReducer,
  businessUnits: statisticsReducer.businessUnits,
  countries: statisticsReducer.countries,
});

export default connect(mapStateToProps)(DelayedTransactionsFilter);

import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { useAdditionalSingleLoanPopup } from './AdditionalSingleLoanPopup.hook';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from '../../../../../library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';

export const AdditionalSingleLoanPopup = ({ id, createSingleLoanUsingExistingKitLoan, transactionDetails, transactionsType, detailPage, editMaterialNumberPermission }) => {
  const {
    deleteTransactionMaterialNumber,
    handleOnChange,
    newTransactionMaterialValue,
    addTransactionMaterialNumber,
    transactionMaterials,
    handleOnChangeExstingValue,
    saveMaterialNumber,
    onCloseClick,
    isLoading,
    error,
    showForm,
    onShowFileds,
  } = useAdditionalSingleLoanPopup({ id, transactionDetails, createSingleLoanUsingExistingKitLoan, transactionsType, detailPage });
  return (
    <Modal
      id={id}
      title={strings.add}
      confirmAction={() => saveMaterialNumber(transactionMaterials)}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      className='modal-width-for-addMaterial'
    >
      <div>
        <div className='my-2'>
          {(
            <div className='row'>
              <div className='col-4'>{strings.materialNumber}</div>
              <div className='col-4'>{strings.singleLoanDescription}</div>
              <div className='col-2'>{strings.quantity}</div>
            </div>
          )}
          {transactionMaterials &&
            transactionMaterials.length > 0 &&
            transactionMaterials.map((item, index) => (
              <>
                <div className='row d-flex align-items-end'>
                  <InputField
                    isRequired
                    onChange={e => handleOnChangeExstingValue(e, index, 'materialNumber')}
                    value={`${item.materialNumber}`}
                    className='col-4'
                  />

                  <InputField
                    isRequired={false}
                    onChange={e => handleOnChangeExstingValue(e, index, 'decription')}
                    value={`${item.description}`}
                    className='col-4'
                  />
                  <InputField
                    isRequired
                    onChange={e => handleOnChangeExstingValue(e, index, 'quantity')}
                    value={`${item.quantity ? item.quantity : ''}`}
                    className='col-2'
                    maxLength={2}
                  />
                  <div className='col-1'>
                    <button
                      type='button'
                      className='delete-button'
                      onClick={() => deleteTransactionMaterialNumber(index)}
                    >
                      <Icon name='delete' width={24} height={24} fill='#0088d0' className='fix-delete' />
                    </button>
                  </div>
                </div>
              </>
            ))}
        </div>
        {showForm && (
          <>
            <div className='row d-flex align-items-end'>
              <InputField
                isRequired
                onChange={e => handleOnChange(e, 'materialNumber')}
                value={newTransactionMaterialValue.materialNumber}
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                doValidateForm={true}
                className={(error === strings.qunatityFieldIsRequired || error === `${strings.materialnumberWarning} and ${strings.materialNumberFormatError}` || error === strings.addMaterialNumberInfo) ? 'col-4 mb-4' : error === strings.materialNumberFormatError ? 'col-4 mt-3' :'col-4'}
                error={
                  (error === strings.materialNumberFormatError ? strings.materialNumberFormatError : error === strings.materialnumberWarning ? strings.materialnumberWarning : '') 
                }
              />
              <InputField
                isRequired={false}
                onChange={e => handleOnChange(e, 'decription')}
                value={newTransactionMaterialValue.description}
                className={ error === strings.materialNumberFormatError ? 'col-4 mb-5 mt-0' : error ? 'col-4 mb-4' : 'col-4'}
                validators={[]}
              />
              <InputField
                isRequired
                onChange={e => handleOnChange(e, 'quantity')}
                value={newTransactionMaterialValue.quantity}
                validators={[
                  { check: Validators.required, message: strings.requiredErrorMessage },
                  { check: Validators.number, message: 'invalidNumberError' },
                ]}
                doValidateForm={true}
                className={
                  error === strings.invalidNumberError || error === strings.qunatityFieldIsRequired
                    ? 'col-2 mt-2'
                    : error === strings.materialnumberWarning ||
                      error === strings.addMaterialNumberInfo ||
                      error === `${strings.materialnumberWarning} and ${strings.materialNumberFormatError}` ||
                      error === 'Quantity fields are required.' ||
                      error === `${strings.qunatityFieldIsRequired}`
                    ? 'col-2 mb-4'
                    : error === strings.materialNumberFormatError ?
                    'col-2 mb-5'
                    : 'col-2'
                }
                maxLength={2}
                error={(error === strings.invalidNumberError || error === strings.qunatityFieldIsRequired) && 'error'}
              />
              <div className={error === strings.materialNumberFormatError ? 'col-1 mb-5' :error ? 'col-1 mb-4' : 'col-1'}>
                <button
                  type='button'
                  className='delete-button'
                  onClick={() =>
                    addTransactionMaterialNumber(
                      newTransactionMaterialValue.materialNumber,
                      newTransactionMaterialValue.description,
                      newTransactionMaterialValue.sapReturnable,
                      newTransactionMaterialValue.quantity,
                      newTransactionMaterialValue.checked,
                      newTransactionMaterialValue.sold,
                    )
                  }
                >
                  <Icon name='plus' width={24} height={24} fill='#0088d0' className='fix-delete' />
                </button>
              </div>
            </div>
            <div className='mt-2'>{!error && strings.addMaterialNumberInfo}</div>
          </>
        )}
        {error && (transactionMaterials.length >0 || error === strings.addMaterialNumberInfo || error === strings.qunatityFieldIsRequired) && (error !== strings.materialnumberWarning) && <div className='error-block'>{error}</div>}
        {editMaterialNumberPermission && !showForm && (
          <Button
            styleClass='col-3 mt-3'
            value={strings.add}
            iconName='plus'
            onClick={onShowFileds}
            disabled={transactionMaterials.length >= 20}
          />
        )}
      </div>
    </Modal>
  );
};

export default AdditionalSingleLoanPopup;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Button from 'library/common/commonComponents/Button/Button';
import Dropdown from 'library/common/commonComponents/Dropdown/Dropdown';
import Table from 'library/common/commonComponents/Table/Table';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';
import AdjustDates from './CreateC2CLoanFrames/AdjustDates';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { breadcrumbsData, dataTable } from './CreateC2CLoanConstants';
import { clearC2CLoans, createC2Loans } from './CreateC2CLoanActions';
import {
  adjustTransactionDates,
  cancelTransaction,
  getTransactionDetails,
  getC2CSuggestions,
  updateTransactionStatus,
} from '../TransactionDetails/TransactionDetailsActions';
import './createC2CLoan.style.scss';
import { camelCase } from 'library/utilities/stringUtils';

const INITIAL_SORT = {
  transactionsId: null,
  targetDeliveryFrom: null,
  targetReservationFrom: null,
  targetReservationTo: null,
  targetReturnDeliveryFrom: null,
  targetReconditioningTo: null,
  targetReturnConditioningFrom: null,
  checked: null,
};

const setInitialData = (state, sort, sortBy, currentTransactionsId) => {
  let addLoanData = [];
  let tableData = [];
  let currentTransaction = null;
  if (state.oldC2CLoans) {
    state.oldC2CLoans.forEach(item => {
      let newAllNextC2C = item.allNextC2c;
      if (item.allNextC2c && item.allNextC2c.length > 1) {
        newAllNextC2C = sortByDate(item.allNextC2c, 'targetReservationFrom', 'asc');
      }
      if (item.transactionsId === currentTransactionsId) {
        currentTransaction = item;
      }
      tableData.push({
        ...item,
        oldLoan: true,
        allNextC2c: newAllNextC2C,
      });
    });
  }
  if (state.newC2CLoans) {
    state.newC2CLoans.forEach(item => {
      let newAllNextC2C = item.allNextC2c;
      if (item.allNextC2c && item.allNextC2c.length > 1) {
        newAllNextC2C = sortByDate(item.allNextC2c, 'targetReservationFrom', 'asc');
      }
      if (item.transactionsId === currentTransactionsId) {
        currentTransaction = item;
      }
      tableData.push({
        ...item,
        newLoan: true,
        allNextC2c: newAllNextC2C,
      });
    });
  }
  if (state.addC2CLoans) {
    if (state.addC2CLoans.before || state.addC2CLoans.after) {
      state.addC2CLoans.before.forEach(item => {
        addLoanData.push({ ...item, before: true });
      });
      state.addC2CLoans.after.forEach(item => {
        addLoanData.push({ ...item, after: true });
      });
    } else {
      addLoanData = [...state.addC2CLoans];
    }
  }
  return {
    addLoanData: findAvailableDataDropdown(addLoanData),
    currentTransaction,
    tableData: sortData(markFirstLast(tableData), sort, sortBy),
  };
};

const markFirstLast = (tableData, old) => {
  const sorted = sortByDate(tableData, 'targetReservationFrom', 'asc');
  const data = [];
  sorted.forEach((item, i) => {
    if (i === 0) {
      data.push({ ...item, first: true, last: false, oldLoan: old || item.oldLoan });
    } else if (i + 1 === sorted.length) {
      data.push({ ...item, first: false, last: true, oldLoan: old || item.oldLoan });
    } else {
      data.push({ ...item, first: false, last: false, oldLoan: old || item.oldLoan });
    }
  });
  return data;
};

const sortData = (tableData, sort, sortBy) => {
  if (sortBy === 'transactionsId') {
    return sortByTransactionsId(tableData, sort[sortBy] ? sort[sortBy] : 'asc');
  } else {
    return sortByDate(tableData, sortBy, sort[sortBy] ? sort[sortBy] : 'asc');
  }
};

const sortByTransactionsId = (data, order) => {
  const sorted = data.sort((a, b) => {
    let x = a.transactionsId;
    let y = b.transactionsId;
    if (order === 'asc') {
      return x < y ? -1 : 1;
    } else if (order === 'desc') {
      return x > y ? -1 : 1;
    }
    return x < y ? -1 : 1;
  });
  return sorted;
};

const sortByDate = (data, sort, order) => {
  const sorted = data.sort((a, b) => {
    let first = order === 'desc' ? 1 : 0;
    let second = order === 'desc' ? 0 : 1;
    let x = a[sort] ? new Date(a[sort]).getTime() : first;
    let y = b[sort] ? new Date(b[sort]).getTime() : second;
    if (order === 'asc') {
      return x < y ? -1 : 1;
    } else if (order === 'desc') {
      return x > y ? -1 : 1;
    }
    return x < y ? -1 : 1;
  });
  return sorted;
};

const findAvailableDataDropdown = data => {
  let after = false;
  let beforeIndex;
  const newData = [];
  data.forEach((item, i) => {
    let newItem = { ...item, disabled: true };
    if (item.after && !after) {
      after = true;
      newItem = { ...newItem, disabled: false };
    }
    if (item.before) {
      beforeIndex = i;
    }
    newData.push(newItem);
  });
  if (beforeIndex !== undefined) {
    newData[beforeIndex] = { ...newData[beforeIndex], disabled: false };
  }
  return newData;
};

export class CreateC2CLoan extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      !state.tableData.length &&
      props.location.state &&
      (props.location.state.oldC2CLoans ||
        props.location.state.newC2CLoans ||
        props.location.state.oldC2CLoans.transactionsId)
    ) {
      return {
        ...setInitialData(props.location.state, state.data, state.data.sortBy, parseInt(props.match.params.id, 10)),
        currentTransactionsId: props.location.state.oldC2CLoans.transactionsId || parseInt(props.match.params.id, 10),
        dataLoaded: true,
      };
    } else if (
      !state.dataLoaded &&
      props.transactionDetails &&
      props.transactionDetails.transactionsId === parseInt(props.match.params.id, 10) &&
      props.possibleC2CLoans
    ) {
      const data = setInitialData(
        {
          oldC2CLoans: [
            ...props.transactionDetails.allPrevC2c,
            { ...props.transactionDetails, current: true },
            ...props.transactionDetails.allNextC2c,
          ],
          currentTransactionsId: parseInt(props.match.params.id, 10),
          newC2CLoans: [],
          addC2CLoans: props.possibleC2CLoans,
        },
        state.data,
        state.data.sortBy,
        parseInt(props.match.params.id, 10),
      );
      return {
        ...data,
        dataLoaded: true,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.dropdownRef = null;
    this.state = {
      addLoanData: [],
      currentTransactionDetails: null,
      currentTransactionsId: null,
      dataLoaded: false,
      dropdownError: '',
      loadingDelete: false,
      tableData: [],
      transactionsType: 'c2c',
      transactionsTypeString: 'c2cLoans',
      isShowFilter: false,
      data: {
        page: 1,
        size: 10,
        sortBy: 'targetReservationFrom',
        sort: {
          ...INITIAL_SORT,
          targetReservationFrom: 'asc',
        },
        filter: {
          stock: null,
          category: null,
          status: null,
          condition: null,
          searchTerm: null,
        },
      },
    };
  }

  componentDidMount() {
    const { match, possibleC2CLoans, transactionDetails } = this.props;
    const transactionsType = match.path
      .replace('/:id/create-c2c-loan', '')
      .replace('/:id/view-c2c-loan', '')
      .replace('/:id/maintain-c2c-loan', '')
      .replace('/:id', '')
      .replace('/', '');
    let transactionsTypeString = '';
    transactionsType.split('-').forEach((item, index) => {
      if (index !== 0) {
        transactionsTypeString += item.charAt(0).toUpperCase() + item.slice(1);
      } else {
        transactionsTypeString = item;
      }
    });
    if (transactionsTypeString === 'c2c') {
      transactionsTypeString = 'c2cLoans';
    }
    this.setState({ transactionsType, transactionsTypeString, transactionsId: match.params.id }, () => {
      if (
        !possibleC2CLoans ||
        !transactionDetails ||
        (match.params &&
          match.params.id &&
          parseInt(transactionDetails.transactionsId, 10) !== parseInt(match.params.id, 10))
      ) {
        this.getTransactionDetailsWithSuggestions();
      }
    });
  }

  getTransactionDetailsWithSuggestions = () => {
    const transactionsId = get(this.props, 'match.params.id', null);
    if (transactionsId) {
      this.props.getTransactionDetails(transactionsId, 1, 'c2c').then(({ success, transactionDetails }) => {
        if (transactionDetails) {
          let currentTransactionsId = transactionDetails.transactionsId;
          if (
            transactionDetails.allNextC2c &&
            transactionDetails.allNextC2c.length &&
            transactionDetails.allNextC2c[transactionDetails.allNextC2c.length - 1] &&
            transactionDetails.allNextC2c[transactionDetails.allNextC2c.length - 1].transactionsId
          ) {
            currentTransactionsId =
              transactionDetails.allNextC2c[transactionDetails.allNextC2c.length - 1].transactionsId;
          }
          this.setState({ currentTransactionsId }, async () => {
            await this.props.getC2CSuggestions(currentTransactionsId);
            this.setState({ dataLoaded: false, loadingDelete: false });
          });
        }
      });
    }
  };

  onAddClick = () => {
    const { addLoanData, tableData } = this.state;
    if (tableData.length === 5) {
      this.props.toggleActionMessage(true, 'error', 'maximumNumberOfTransactions');
    } else {
      const data = this.dropdownRef.getSelected();
      if (data.selectedSingleValue) {
        const newAddLoanData = [];
        let newTableData = [];
        addLoanData.forEach(item => {
          if (data.selectedSingleValue.transactionsId !== item.transactionsId) {
            newAddLoanData.push(item);
          }
        });
        newTableData = [...tableData, { ...data.selectedSingleValue, newLoan: true }];
        this.setState(
          {
            addLoanData: findAvailableDataDropdown(newAddLoanData),
            tableData: sortData(markFirstLast(newTableData), this.state.data.sort, this.state.data.sortBy),
            dropdownError: '',
          },
          () => {
            this.dropdownRef.resetSelectedValue();
          },
        );
      }
    }
  };

  handleSort = name => {
    this.setState(
      prevState => {
        return {
          data: {
            sort: {
              ...INITIAL_SORT,
              [name]: prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc',
            },
            sortBy: name,
          },
        };
      },
      () =>
        this.setState({
          tableData: sortData(markFirstLast(this.state.tableData), this.state.data.sort, this.state.data.sortBy),
        }),
    );
  };

  handleDelete = (itemToDelete, prevTransaction, nextTransaction) => () => {
    if (itemToDelete.first || itemToDelete.last) {
      this.handleDeleteItem(itemToDelete.transactionsId);
    } else {
      const datesToChange = {
        targetDeliveryFrom:
          (moment(itemToDelete.targetDeliveryFrom).isValid() &&
            moment(itemToDelete.targetDeliveryFrom).add(1, 'day').format('YYYY-MM-DD')),
        deliveryDate:
          (moment(prevTransaction.reservationTo).isValid() &&
            moment(prevTransaction.reservationTo).format('YYYY-MM-DD')),
      };
      if (moment(prevTransaction.targetReturnDeliveryTo).isValid()) {
        datesToChange['targetDeliveryTo'] = moment(prevTransaction.targetReturnDeliveryTo).add(1, 'day').format('YYYY-MM-DD');
      }
      if (moment(nextTransaction.targetReservationFrom).isValid()) {
        datesToChange['targetReservationFrom'] = moment(itemToDelete.targetReservationFrom).add(1, 'day').format('YYYY-MM-DD');
      }
      if (moment(nextTransaction.targetReservationTo).isValid()) {
        datesToChange['targetReservationTo'] = moment(nextTransaction.targetReservationTo).format('YYYY-MM-DD');
      }
      if (moment(nextTransaction.targetReconditioningTo).isValid()) {
        datesToChange['targetReconditioningTo'] = moment(nextTransaction.targetReconditioningTo).format('YYYY-MM-DD');
      }
      if (moment(nextTransaction.targetReconditioningFrom).isValid()) {
        datesToChange['targetReconditioningFrom'] = moment(nextTransaction.targetReconditioningFrom).format(
          'YYYY-MM-DD',
        );
      }
      if (moment(nextTransaction.targetReturnDeliveryFrom).isValid() || nextTransaction.targetReturnDeliveryDate) {
        datesToChange['targetReturnDeliveryDate'] =
          moment(nextTransaction.targetReturnDeliveryFrom).format('YYYY-MM-DD') ||
          moment(nextTransaction.targetReturnDeliveryDate).format('YYYY-MM-DD');
      }
      if (moment(nextTransaction.targetReturnDeliveryTo).isValid()) {
        datesToChange['targetReturnDeliveryTo'] = moment(nextTransaction.targetReturnDeliveryTo).format('YYYY-MM-DD');
      }
      this.handleCancelAndChangeItem({
        itemToCancel: itemToDelete.transactionsId,
        itemToChange: nextTransaction.transactionsId,
        datesToChange,
      });
    }
  };

  handleDeleteItem = id => {
    const { addLoanData, tableData } = this.state;
    let newAddLoanData = [...addLoanData];
    let itemToDelete = null;
    const newTableData = [];
    tableData.forEach(item => {
      if (id !== item.transactionsId) {
        newTableData.push({ ...item, oldLoan: false });
      } else {
        newAddLoanData = [...newAddLoanData, { ...item, oldLoan: false }];
        itemToDelete = item;
      }
    });
    if (itemToDelete && itemToDelete.newLoan) {
      this.setState({
        addLoanData: findAvailableDataDropdown(sortByTransactionsId(newAddLoanData)),
        tableData: markFirstLast(newTableData),
      });
    } else {
      this.props.clearC2CLoans([itemToDelete.transactionsId]).then(async ({ success }) => {
        await this.props.updateTransactionStatus(id,'cancelled');
        if (success) {
          this.setState({
            addLoanData: findAvailableDataDropdown(sortByTransactionsId(newAddLoanData)),
            tableData: markFirstLast(newTableData),
          });
        }
      });
    }
  };

  handleCancelAndChangeItem = ({ itemToCancel, itemToChange, datesToChange }) => {
    this.setState({ loadingDelete: true }, async () => {
      this.props.clearC2CLoans([itemToCancel], false).then(async ({ success }) => {
        await this.props.updateTransactionStatus(itemToCancel, 'cancelled');
        await this.props.adjustTransactionDates(datesToChange, itemToChange, 'c2c');
        this.getTransactionDetailsWithSuggestions();
      });
    });
  };

  onEditClick = currentTransactionDetails => () => {
    this.setState({ currentTransactionDetails }, () => {
      const id = 'adjustDatesCreateC2CModal';
      if ($(`#${id}`) && $(`#${id}`).modal) {
        $(`#${id}`).modal('show');
      }
    });
  };

  onCancelPress = () => {
    this.props.history.goBack({ screen: 'create-c2c' });
  };

  createC2CPress = () => {
    const newIds = [];
    const sendIds = [];
    const newTableData = [];
    this.state.tableData.forEach(item => {
      newTableData.push({
        ...item,
        newLoan: false,
      });
      sendIds.push(item.transactionsId);
      if (item.newLoan) {
        newIds.push(item.transactionsId);
      }
    });
    let save = false;
    if (this.props.match && this.props.match.url && this.props.match.url.includes('maintain-c2c-loan')) {
      save = true;
    }
    if (newIds.length) {
      this.setState({ loading: true }, () => {
        this.props.createC2Loans(this.props.match.params.id, sendIds, save).then(({ success }) => {
          if (success) {
            this.setState({
              loading: false,
              tableData: sortData(markFirstLast(newTableData, true), this.state.data.sort, this.state.data.sortBy),
            });
            this.props.history.push(`/c2c/${this.state.transactionsId}/view-c2c-loan`);
          } else {
            this.setState({
              loading: false,
            });
          }
        });
      });
    } else {
      this.props.toggleActionMessage(true, 'error', 'pleaseSelectC2c');
      scrollToTop(500);
    }
  };

  updateTransaction = transactionDetails => {
    this.setState(state => {
      const newTableData = [];
      state.tableData.forEach(item => {
        if (item.transactionsId === transactionDetails.transactionsId) {
          newTableData.push({ ...item, ...transactionDetails });
        } else {
          newTableData.push(item);
        }
      });
      return { tableData: markFirstLast(newTableData) };
    });
  };

  onEditPress = () => {
    this.props.history.push(`/c2c/${this.state.transactionsId}/maintain-c2c-loan`);
  };

  render() {
    const {
      addLoanData,
      currentTransaction,
      currentTransactionDetails,
      data,
      dropdownError,
      loading,
      loadingDelete,
      tableData,
      transactionsId,
      transactionsType,
      transactionsTypeString,
    } = this.state;
    const { adjustTransactionDates, appActions, isLoading, user } = this.props;
    const { displayActionMessage, type, message } = appActions;
    let title = strings.createC2CLoan;
    let buttonText = strings.createC2CLoan;
    let edit = false;
    let view = false;
    if (this.props.match && this.props.match.url && this.props.match.url.includes('maintain-c2c-loan')) {
      title = strings.maintainC2CLoan;
      buttonText = strings.saveC2CLoan;
      edit = true;
    }
    if (
      this.props.match &&
      this.props.match.url &&
      !this.props.match.url.includes('maintain-c2c-loan') &&
      !this.props.match.url.includes('create-c2c-loan')
    ) {
      view = true;
      title = `${strings.c2cLoan} #${tableData.length && tableData[0].c2cId ? tableData[0].c2cId : ''}`;
    }
    let transactionType = '';
    if (currentTransaction && currentTransaction.type) {
      transactionType = camelCase(currentTransaction.type);
    }
    return (
      <>
        {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
        <div className='container-fluid mb-3'>
          <Breadcrumbs
            data={breadcrumbsData({
              transactionType,
              transactionsType,
              transactionsTypeString,
              transactionsId,
              edit,
              view,
            })}
            history={this.props.history}
          />
          <div className='row no-gutters align-items-center justify-content-between mb-4'>
            <h1 className='page-title mb-0'>{title}</h1>
            {view && (
              <div className='col-auto'>
                <Button
                  dataTest='edit-transaction-button'
                  bgFill={false}
                  iconName='edit'
                  onClick={this.onEditPress}
                  value={strings.edit}
                />
              </div>
            )}
          </div>
          {!view && (
            <div className='d-flex flex-md-row flex-column align-items-md-center mb-4'>
              <div className='d-flex flex-column flex-grow-1'>
                <Dropdown
                  ref={ref => {
                    this.dropdownRef = ref;
                  }}
                  dataTest='createC2C-loan-dropdown'
                  data={addLoanData}
                  hasSection={false}
                  multiSelect={false}
                  filter={false}
                  idKey='transactionsId'
                  displayValue={[{ key: 'transactionNumber', separator: ' - ', name: 'name' }]}
                  isRequired={true}
                  placeholder={strings.pleaseSelect + '...'}
                  disabled={false}
                  label={strings.kitLoanId}
                  mainContainerStyle='d-flex flex-column flex-grow-1'
                  value={null}
                />
                <div className='error-message'>{dropdownError ? strings[dropdownError] : '-'}</div>
              </div>
              <Button
                dataTest='create-c2c-add-kit-loan-button'
                bgFill={false}
                iconName='plus'
                styleClass='btn-max-width-300 ml-md-3 mt-2 mt-md-0 add-kit-loan-button'
                onClick={this.onAddClick}
                value={strings.addKitLoan}
              />
            </div>
          )}
          <Table
            user={user}
            cols={dataTable(user, this.onEditClick, tableData, view)}
            rowId={'transactionsId'}
            rows={tableData}
            sortFields={data.sort}
            handleSort={this.handleSort}
            handleDelete={this.handleDelete}
          />
          {!view && (
            <div className='d-flex flex-row justify-content-between mt-3'>
              <Button
                dataTest='create-c2c-cancel-button'
                bgFill={false}
                isGhost={true}
                styleClass='w-auto mt-2'
                iconName='arrow'
                iconPosition='left'
                iconStyle='rotate-180 ml-0 mr-1'
                onClick={this.onCancelPress}
                value={strings.cancel}
              />
              <Button
                dataTest='create-c2c-create-button'
                bgFill
                styleClass='w-auto mt-2'
                loading={loading}
                value={buttonText}
                iconName='check'
                onClick={this.createC2CPress}
              />
            </div>
          )}
          {currentTransactionDetails && (
            <AdjustDates
              id='adjustDatesCreateC2CModal'
              adjustTransactionDates={adjustTransactionDates}
              getTransactionDetailsWithSuggestions={this.getTransactionDetailsWithSuggestions}
              enableSave={true}
              showScheduler={false}
              transactionDetails={currentTransactionDetails}
              updateTransaction={this.updateTransaction}
              visible={this.state.visible}
            />
          )}
          {(loadingDelete || isLoading) && <div className='loader' />}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, transactionDetailsReducer }) => ({
  appActions: appActionsReducer,
  isLoading: appActionsReducer.isLoading,
  language: languageReducer,
  possibleC2CLoans: transactionDetailsReducer.possibleC2CLoans,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  user: authReducer.user,
});

export default connect(mapStateToProps, {
  adjustTransactionDates,
  cancelTransaction,
  clearC2CLoans,
  createC2Loans,
  getTransactionDetails,
  getC2CSuggestions,
  toggleActionMessage,
  updateTransactionStatus,
})(CreateC2CLoan);

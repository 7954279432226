import React, {PureComponent} from 'react';

import './fileListStyles.scss';
import SingleFileListView from './SingleFileListView';
import Uploader from '../Uploader/Uploader';

export default class FilesListView extends PureComponent {

  handleRemove(index) {
    this.props.onRemove(index);
  }

  render() {
    const {
      files,
      mimeType,
      maxSize,
      onFileAdd,
      isUploader = true,
      isUploadAllowed,
      isDownloadable,
      downloadUrl,
      downloadIdField,
      downloadStream,
      onReject,
      maxFiles,
      onMaxFileReached,
      disableOnlyUpload,
      availableTypes,
      errorMessage,
      isNotRemoved,
    } = this.props;

    return (
      <div className='file-list-container'>
        {files.length > 0 ? (
          <div className='list'>
            {files.map((file, key) => (
              <SingleFileListView
                file={file}
                key={key}
                index={key}
                downloadUrl={downloadUrl}
                isDownloadable={isDownloadable}
                downloadIdField={downloadIdField}
                downloadStream={downloadStream}
                onRemove={(index) => this.handleRemove(index)}
                isUploadAllowed={isUploadAllowed}
                isNotRemoved={isNotRemoved}
              />
            ))}
          </div>
        ) : null}
        {isUploadAllowed && disableOnlyUpload && isUploader ? (
          <Uploader
            allFiles={files}
            mimeType={mimeType}
            maxSize={maxSize}
            maxFiles={maxFiles}
            onReject={onReject}
            onFileAdd={onFileAdd}
            onMaxFileReached={onMaxFileReached}
            availableTypes={availableTypes}
            errorMessage={errorMessage}
          />
        ) : null}
      </div>
    )
  }
}
